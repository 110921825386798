import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { getExceptionNextPage, getExceptionRemainingPages} from '../reducers/adminUserReducer.js';
import { put, takeLatest, call, select } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    // Dispatch GETEXCEPTIONUSERS_LOADING action
    yield put(createServiceAction(serviceType, 'loading')());
    let nextpage =yield select(getExceptionNextPage)
    const options = {
      userType: action.data.userType,
      page: nextpage[action.data.userType] || 1,// Temporarily setting static page number till server side pagination in ag grid is implemented
      pageCount: 500,
      supplierId: action.data.supplierId? action.data.supplierId: null
    }; 
    // Trigger Call to API endpoint
    const res = yield call(getRequest, apiConfig.getExceptionUsers.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){  
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        // Dispatch GETEXCEPTIONUSERS_LOADING action with response object
        yield put(createServiceAction(serviceType, 'success')({...response}));
        const remainingPages = yield select(getExceptionRemainingPages);
        // Trigger call again for next set of records till there are pages remaining
        if(remainingPages[action.data.userType] > 0){
          yield put(createServiceAction('getExceptionUsersLazyLoad', 'requested')(action.data));
        }
      }
    }
  } catch (e) {
    // Dispatch GETEXCEPTIONUSERS_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getExceptionUserSagas() {
  const serviceType = 'getExceptionUsers';
  const serviceTypeLazyLoad = 'getExceptionUsersLazyLoad';
  // Listen to GETEXCEPTIONUSERS_REQUESTED actions
  yield takeLatest(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeLatest(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);

}

export default getExceptionUserSagas;
