import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import moment from 'moment';

function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());
        let options = {};
        options = action.data;
        const url = apiConfig.updateNotification.endPointUrl;
        const res = yield call(putRequest, url, options);
        const todayDate = moment.utc(new Date()).format('YYYY-MM-DD');
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
        if (!statusErrorMessage) {
            const response = yield res.json();
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response);
            if (!serverErrorMessage && response.errorCode === 0) {
                yield put(createServiceAction(serviceType, 'success')(response));
                const message = `${response.message}`;
                // Display success message
                yield call(displayMessage, 'success', message, 5000);
                yield put(createServiceAction('listNotification', 'requested')(todayDate));
            }
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));
    }
}

function* updateNotificationSagas() {
    const serviceType = 'updateNotification';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default updateNotificationSagas;
