const dashBoardConstants = {
  supplierPageTitle: 'SUPPLIER DASHBOARD',
  factoryPageTitle: 'FACTORY DASHBOARD',
  rslUserPageTitle: 'RSL TEAM',
  labUserPageTitle: 'LAB TEAM',
  trfButton: 'CREATE TRF',
  chartTitle: 'SUMMARY OF TICKETS',
  rslUserColumns: [
    'rsl',
    'supplierName',
    'materialName',
    'materialNumber',
    'refId',
    'brandInfo',
    'productType',
    'defaultTest',
    'testingType',
    'overAllStatus',
    'action',
    'labReceivedDate',
    'favourites'
  ],
  supplierUserColumns: ['rsl', 'materialName', 'materialNumber','refId', 'defaultTest', 'overAllStatus', 'action','labReceivedDate', 'favourites'],
  factoryUserColumns: ['rsl', 'supplierName', 'materialName', 'materialNumber','refId',  'defaultTest', 'overAllStatus', 'action','labReceivedDate', 'favourites'],
  labUserColumns: ['rsl', 'supplierName', 'labLocationId', 'action', 'labReceivedDate','labReference', 'reviewAction', 'proceedAction','labReceivedDate']
};

export default dashBoardConstants;
