import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Container } from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import summaryTableConfig from '../../configs/gridConfigs/materialList/materialListConfig.js';
import Grid from '../../components/grid/Grid.js';
import isUndefined from 'lodash/isUndefined';
import { getMaterialData, getMaterialRemainingPages, isMaterialLoading, getMaterialNextPage } from '../../reducers/materialListReducer.js';

class EditMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materialName: ''
        };
        this.setGridParams = this.setGridParams.bind(this);
        this.columnDefs = summaryTableConfig;
        this.onCellClicked = this.onCellClicked.bind(this)
    }
   
    componentDidMount() {
        const { fetchMaterialData } = this.props;
        fetchMaterialData()
    } 

    setGridParams = params => this.gridParams = params;

    materialListRequest = matName => this.setState({ materialName: matName });
    

    onCellClicked (params) {
        const { updateMaterialStatus } = this.props;
        const colId = params.api.getFocusedCell().column.colId;
    
        if (colId === 'activeStatus' && isUndefined(params.event.target.checked)) {
            updateMaterialStatus({ materialId: params.data.id, 
                status: !params.data.isActive,
                });
        } 
      }
    


    render() {
        const { materialDetails} = this.props;
        
        return (
            <div >
                <Container section className="ml1-sm mr1-sm mt2-sm tab-class-container tab-class padding-0">
                    <div className="ncss-col-sm-12 ncss-input-container">
                        <div className="ncss-col-sm-12 align-left">
                        <div className="pt6-sm pb3-sm" >
                        <Grid
                            setParams={this.setGridParams}                                             
                            onCellClicked={this.onCellClicked.bind(this)}
                            rowData={materialDetails && materialDetails.data ? materialDetails.data : []} 
                            columnDefs={this.columnDefs}  
                            customNoRowTemplete={
                                materialDetails === undefined && "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                              }                           
                            />
                        </div>
                        </div>
                    </div>
                </Container>
    
            </div>
        );
    }
}


const mapStateToProps = state => ({
    editMaterialForm: getFormValues('editMaterial')(state),
    materialDetails: getMaterialData(state),
    remainingPages: getMaterialRemainingPages(state),
    materialNextPage:getMaterialNextPage(state),
    isMaterialLoading: isMaterialLoading(state)
});

const mapDispatchToProps = dispatch => ({
    fetchMaterialData: (data, history) => {
        dispatch(createServiceAction('materialDetails', 'requested')({...data, history }));
    },
    updateMaterialStatus: (data) => {
        dispatch(createServiceAction('changeMaterialStatus', 'submitted')(data));
    }
});

export default compose(reduxForm({ form: 'editMaterial' }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(EditMaterial);
