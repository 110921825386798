import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
//import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getNextPage, getlistLicenseeRemainingPages } from '../reducers/licenseeReducer';

function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());
        const options = {
            "page": yield select(getNextPage),
            "pageCount": 50,
            "isAll": action.data.isAll,
            "searchSup": `${action.data.searchSup}`
        };

        const res = yield call(getRequest, apiConfig.listLicensee.endPointUrl, options);
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
        if (!statusErrorMessage) {
            const response = yield res.json();
            //const response = sampleData
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response);
            if (!serverErrorMessage && response.errorCode === 0) {
                yield put(createServiceAction(serviceType, 'success')(response.data));
                const remainingPages = yield select(getlistLicenseeRemainingPages);
                // Trigger call again for next set of records till there are pages remaining
                if (remainingPages > 0) {
                    yield put(createServiceAction('listLicenseeLazyLoad', 'requested')());
                }

            }
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));

    }
}

function* listLicensee() {
    const serviceType = 'listLicensee';
    const serviceTypeLazyLoad = 'listLicenseeLazyLoad';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
    yield takeLatest(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);

}

export default listLicensee;