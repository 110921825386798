import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {
    Button,
    Container,
    TextInput,
    DatePicker,
    H5,
    ConfirmationBox
} from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { convertDateToDBFormat } from '../../util/index.js'
import { selectEmail, selectUserDetails } from '../../reducers/authReducer.js';
import { getEnableLitmosSubmit } from '../../reducers/dashboardReducer.js'
import moment from 'moment';

const validateCertificateId = value =>  value && !/^[0-9]{9}$/.test(value) &&
'Invalid input, expect 9 digits.'

export class Litmos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitModal: false,
        };

    }


    submitLitmosData = (resubmit) => {
        const { litmosInfo, submitLitmos, email, shouldEnableLitmosSubmit } = this.props;
        shouldEnableLitmosSubmit(true);
        const reqObj = {};
        reqObj.certificate = litmosInfo.certificateId;
        reqObj.completion = convertDateToDBFormat(litmosInfo.completion) || '';
        const expirationDate = litmosInfo.completion; // expiration date = completion + 2years
        expirationDate.setFullYear( new Date(moment(litmosInfo.completion).utc().format('DD-MMM-YYYY')).getFullYear() + 2);
        reqObj.expiration = convertDateToDBFormat(expirationDate);
        reqObj.email = email;
        reqObj.renew = resubmit;
        submitLitmos(reqObj);
    }
    closeSubmitModal = () => {
        this.setState({ submitModal: false });
    };
    openConfirmationPopup = () => {
        this.setState({ submitModal: true });
    }
    shouldEnableReSubmit = () => {
        const { userDetails, litmosSubmit,invalid } = this.props;
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = new Date(moment(userDetails.litmos.expiration).utc().format('DD-MMM-YYYY'));
        const utc2 = new Date(moment(new Date()).utc().format('DD-MMM-YYYY'));
        return ((Math.floor((utc1 - utc2) / _MS_PER_DAY) <= 45) ||  userDetails.LitmosStatus !== "completed" || Math.floor(utc1 - utc2) === 1) && !litmosSubmit && !invalid ? true :false
        
    }
    shouldEnableSubmit =()=>{
        const { litmosInfo,litmosSubmit, invalid } = this.props;
        if(litmosInfo.certificateId !== '' && litmosInfo.completion !== "" && !litmosSubmit && !invalid){
            return true
        }
        return false
    }
    render() {
        const { userDetails, initialValues } = this.props;
        const { submitModal } = this.state

        if (userDetails.certificateId !== "") {
            initialValues.certificateId = userDetails.certificateId
            initialValues.completion = new Date(userDetails.completion)
        }
        return (
            <form >
                <Container extraClassName="ncss-col-sm-12  display-flex" section>
                    <div className="ncss-col-sm-8">
                        <div className=" ncss-input-container ">
                            <H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm">Certificate ID#</H5>
                            <div className="ncss-col-sm-6"><Field component={TextInput} name="certificateId" type="text" validate={validateCertificateId} /></div>
                        </div>
                        <div><H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm mt2-sm">Completion Date</H5>
                            {/* <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl4-sm"></Label> */}
                            <div className="ncss-col-sm-6">
                                <Field
                                    component={DatePicker}
                                    defaultValue={initialValues && initialValues.completion !== '' ? initialValues.completion : null}
                                    showTime={false}
                                    name="completion"
                                />
                            </div>
                        </div>
                        <div className="ncss-col-sm-12">
                        {userDetails.litmos === '' || userDetails.litmos === null ?
                            <><div className="ncss-col-sm-6"></div>
                            <Button
                                className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected mt2-sm ${this.shouldEnableSubmit() ? '' : 'disabled'}`}
                                onClick={() => { this.submitLitmosData() }}
                            >SUBMIT
                            </Button></>
                            :<><div className="ncss-col-sm-4"></div>
                            <Button
                                className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected mt2-sm`}
                                onClick={() => { this.openConfirmationPopup() }}
                            >RE-SUBMIT
                            </Button><h6 className='commentStyle ncss-col-sm-4 pt2-sm'><span className="astericks">&#42;</span><span className="astericks">&#42;</span>
                            Allow 7-10 working days for processing. Do not re-submit unless you are instructed to do so by RSL Support</h6></> }
                            </div> 
                    </div>
                    <div className="ncss-col-sm-4 commentStyle" >
                        <h6 className='pb2-sm'>For questions on RSL training contact, <strong>Chemistry.Training@nike.com</strong></h6>
                        <h6 className='pb2-sm' ><strong>Note:</strong> When an email address is requested for Training make sure to use the same email address you used for the RSL application login. </h6>
                    </div>
                </Container>
                <div className="ncss-col-sm-12">
                    <h6 className="u-bold support-link">For any other questions,please reach out to <span className='text-color-accent'>RSLSupport@nike.com</span></h6>
                </div>
                <ConfirmationBox
                    message={
                        'Do you want to Re-submit Litmos ?'
                    }
                    submitModal={submitModal}
                    submitValue={
                        () => {
                            this.submitLitmosData(true);
                        }
                    }
                    closeModal={this.closeSubmitModal}
                />
            </form>

        )
    }
}

Litmos.propTypes = {

};

const mapStateToProps = state => ({
    litmosInfo: getFormValues('Litmos')(state),
    email: selectEmail(state),
    userDetails: selectUserDetails(state),
    litmosSubmit: getEnableLitmosSubmit(state)
});

const mapDispatchToProps = dispatch => ({
    submitLitmos: data => {
        dispatch(createServiceAction('addLitmos', 'requested')(data));
    },
    shouldEnableLitmosSubmit :(data)=>{
        dispatch(createServiceAction('enableLitmos', 'requested')(data));
    }
});
export default compose(
    reduxForm({
        form: 'Litmos',
        initialValues: {
            completion: '',
            certificateId: ""
        },
        enableReinitialize: true, destroyOnUnmount: false
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Litmos);
