import React, { Component } from 'react';

import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";

export default class CustomDateFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { date: null }
        this.onDateChanged = this.onDateChanged.bind(this)
    }

    getValue() {
        const { date } = this.state;
        return date;
    };
    handleChange = date => {
        this.setState({
            date: date
        });
    };
    getDate() {
        const { date } = this.state;
        return date;
    }

    setDate(date) {
        this.setState({
            date
        });
    }
    isFilterActive() {
        return true
    };


    render() {
        const { date } = this.state;
        return (
            <div className="ag-input-wrapper custom-date-filter" role="presentation" ref="flatpickr">
                <Flatpickr
                    value={date}
                    className="flat-picker-filter"
                    onChange={this.onDateChanged}
                />
                <button class="clear_button" title="clear" data-clear onClick={this.onDateClear}>
                    <i class="fas fa-times"></i>
                </button>
            </div>
        );
    }
    updateAndNotifyAgGrid = date => {
        const { onDateChanged } = this.props;
        this.setState({
            date
        },
            //Callback after the state is set. This is where we tell ag-grid that the date has changed so
            //it will proceed with the filtering and we can then expect ag-Grid to call us back to getDate
            onDateChanged
        );
    }


    //*********************************************************************************
    //          LINKING THE UI, THE STATE AND AG-GRID
    //*********************************************************************************
    onDateClick = e => {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let convertedDate = null;
        if ((isIE || isSafari) && e.target.value) {
            const dateArray = e.target.value.split('-');
            convertedDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])

            this.setState({ date: convertedDate });
            this.updateAndNotifyAgGrid(convertedDate)
        }

    }
    onDateChanged = selectedDates => {
        this.setState({ date: selectedDates[0] });
        this.updateAndNotifyAgGrid(selectedDates[0])

    }
    onDateClear = () => {
        this.setState({ date: null });
        this.updateAndNotifyAgGrid(null)
    }
}