import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
        const options = {
            supplierName : action.data
        }
   
      const res = yield call(getRequest, apiConfig.supplierDetails.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if(!statusErrorMessage){  
        // Parse response object
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.errorCode === 0) {
          yield put(createServiceAction(serviceType, 'success')(response));
        }
      }
    } 
    else {
      yield put(createServiceAction(serviceType, 'success')({}));
    }
  } catch (e) {
     
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* supplierDetailsSagas() {
    const serviceType = 'supplierDetail';
   
  
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  }
export default supplierDetailsSagas;

