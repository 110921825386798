const dashBoardConstants = {
    rslUserColumns: [
      'trfId',
      'supplierName',
      'supLocName',
      'materialType',
      'material',
      'materialNumber',
      'otherMaterial',
      'refId',
      'PDMNumber',
      'brand',
      'product',
      'labLocation',
      'defaultTest',
      'testingType',
      'overAllStatus',
      'statusValue',
      'labRecDate',
      'serviceRequested',
      'favourites',
      'activeStatus',
      'notes'
    ],
    supplierUserColumns: ['trfId','supplierName','supLocName','materialType', 'material', 'materialNumber','otherMaterial','refId','PDMNumber','brand', 'product', 'defaultTest','testingType', 'overAllStatus', 'statusValue','labRecDate','serviceRequested', 'favourites'],
    factoryUserColumns: ['trfId', 'supplierName','supLocName','materialType', 'material', 'materialNumber','otherMaterial','refId','PDMNumber','brand', 'product', 'defaultTest','testingType', 'overAllStatus', 'statusValue','labRecDate','serviceRequested', 'favourites'],
    labUserColumns:['trfId', 'supplierName','supLocName','materialType', 'material', 'materialNumber','otherMaterial','refId','PDMNumber','brand', 'product', 'defaultTest','testingType','labRefNum', 'overAllStatus', 'statusValue','labRecDate','serviceRequested'],
  };
  
  export default dashBoardConstants;
  