import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { getArchiveNextPage, getArchiveRemainingPages } from '../reducers/archiveReducer.js';

import { put, takeLatest, call, select } from 'redux-saga/effects';

function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());
        const options = {
            ...(action.data.q ? { q: action.data.q } : {}),
            limit: 500,
            page: yield select(getArchiveNextPage),
            ...(action.data.fromDate ? { fromDate: action.data["fromDate"] } : {}),
            ...(action.data.toDate ? { toDate: action.data["toDate"] } : {}),
        };

        const res = yield call(getRequest, apiConfig.getArchive.endPointUrl, options);
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
        if (!statusErrorMessage) {
            const response = yield res.json();
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response);
            if (!serverErrorMessage && response.errorCode === 0) {
                yield put(createServiceAction(serviceType, 'success')(response));
                const remainingPages = yield select(getArchiveRemainingPages);
                if (remainingPages > 0) {

                    yield put(createServiceAction(`getArchiveLazyLoad`, 'requested')(action.data));
                }
                else {
                    yield put(createServiceAction(`getArchiveLazyLoad`, 'completed')(true));
                  }
            }else {
                yield put(createServiceAction(serviceType, 'server-error')());
            }
        } 
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));
    }
}

function* getArchiveSagas() {
    const serviceType = 'getArchive';
    const serviceTypeLazyLoad = 'getArchiveLazyLoad';
    yield takeLatest(createServiceAction(serviceType, 'requested').type, listener, serviceType);
    yield takeLatest(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);
}

export default getArchiveSagas;
