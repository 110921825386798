import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, all } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    const infoPopDuration = 1000;
    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    let res = {};
    if(action.data.length > 1){
      res = yield all( 
        action.data.map((data) => call(postRequest, apiConfig.setExceptionUsers.endPointUrl, data))
      );
    } else {
      res = yield call(postRequest, apiConfig.setExceptionUsers.endPointUrl, action.data[0]);
    }
    // For Multiple Parallel request calls 
    if(Array.isArray(res)) {
      const responseData = [];
      for(let i = 0; i < res.length; i++){
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res[i], infoPopDuration);
        if(!statusErrorMessage){
          const response = yield res[i].json();
          const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
          // Display server error message
          if (!serverErrorMessage && response.errorCode === 0) {
            // Add to response array only for success callbacks
            responseData.push(response);
          } else {
            break;
          }
        } else {
          break;
        }
      }
      // Considered as success only if all parallel api calls are success
      if(responseData.length > 0 && responseData.length === res.length) {
        yield put(createServiceAction(serviceType, 'success')(responseData[0].data));
        const message = `${responseData[0].message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
      }
    // For Single request
    } else {
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
      if(!statusErrorMessage){  
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
        if (!serverErrorMessage && response.errorCode === 0) {
          yield put(createServiceAction(serviceType, 'success')(response.data));
          const message = `${response.message}`;
          // Display success message
          yield call(displayMessage, 'success', message, 5000);
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addLocationSagas() {
  const serviceType = 'exceptionUsers';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default addLocationSagas;
