import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const options = {
        trfId: action.data.trfId,
        favStatus: action.data.status
      };
      const favRefreshOptions = {
        status: action.data.buttonStatus,
        supplierName: action.data.supplierName
      }
      const infoPopDuration = 1000;
      const res = yield call(putRequest, apiConfig.setFavourites.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
      if (!statusErrorMessage) {
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
        if (!serverErrorMessage && response.errorCode === 0) {
          // yield put(createServiceAction('getUserProfile', 'requested')());
          // yield take(createServiceAction('getUserProfile', 'success').type);
          yield put(createServiceAction('set-favorite', 'success')(response.data));
          if (action.data.buttonStatus === 'Favorites') {
            yield put(createServiceAction('testSummary', 'requested')(favRefreshOptions));
          }
          yield put( createServiceAction('testSummaryCount', 'requested')({ isClearHistory: false, status: action.data.buttonStatus, supplierName:  action.data.supplierName, fromDate:  action.data.fromDate }))
          const message = `${response.message}`;
          // Display success message
          yield call(displayMessage, 'success', message, 1000);
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addOrRemoveFavouritesSagas() {
  const serviceType = 'updateFavourites';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default addOrRemoveFavouritesSagas;
