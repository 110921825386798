import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import TrfReview from '../../trf_review/TrfReview';

class TrfInfo extends Component {
  componentDidMount() {
    const { displaySubmitButtonFrf, displayClearButtonFrf } = this.props;
    displaySubmitButtonFrf(false);
    displayClearButtonFrf(false);
  }
  
  render() {
    const { trfResult } = this.props;
    return (
      <form className="form">
        <div>
          {!isEmpty(trfResult) && (
            <TrfReview from="trfDetails" trfData={typeof trfResult !== 'undefined' ? trfResult.data : null} />
          )}
          
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    trfResult: state.frf.trfDetail
  };
  
}
TrfInfo.propTypes = {
  trfResult: PropTypes.object,
  displaySubmitButtonFrf: PropTypes.func,
  displayClearButtonFrf: PropTypes.func
};
export default reduxForm({
  form: 'trfinfo'
})(connect(mapStateToProps)(TrfInfo));
