import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());

        const res = yield call(getRequest, apiConfig.activeLicensee.endPointUrl);
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
        if (!statusErrorMessage) {
            const response = yield res.json();
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response);
            if (!serverErrorMessage && response.errorCode === 0) {
                yield put(createServiceAction(serviceType, 'success')(response));
            }
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));
    }
}


function* activeLicenseelisSaga() {
    const serviceType = 'activeLicensee';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default activeLicenseelisSaga;