import { put, takeEvery, call, take } from 'redux-saga/effects';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    // Post Lab Results
    const res = yield call(postRequest, apiConfig.labResults.endPointUrl, action.data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        const info = { trfId: action.data.trfId, pageCount: 1000 }
        yield put(createServiceAction(serviceType, 'success')(response));
        // Request for Retrieving Pass/Fail Results
        yield put(createServiceAction('testDetailsForLab', 'requested')(info));
        // Wait for call to finish
        yield take(createServiceAction('testDetailsForLab', 'success').type);
      } else {
        yield put(createServiceAction(serviceType, 'error')());
      }
    } else {
      yield put(createServiceAction(serviceType, 'error')());
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* labResultsSubmitAndRetrieveSagas() {
  const serviceType = 'labResultsSubmit';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default labResultsSubmitAndRetrieveSagas;
