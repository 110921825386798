import { eventChannel, END } from 'redux-saga';
import storage from 'localforage';

import { finishAuthCheck, setAuthCheckError, setUserLogin, setUserLogout, checkUserExist, checkValidGroup } from '../actions/authActions.js';
import { selectUserDetails, logout } from '../reducers/authReducer.js';
import { call, put, takeEvery, select, take } from 'redux-saga/effects';
import { isOffline } from '../reducers/offlineReducer.js';
import { setGlobalHeader } from '../util/http';
import { oAuthConfig } from '../configs/oAuthConfig.js';
import { createServiceAction } from '../util/actionHelper.js';
import { history } from '../util/index.js';

const activityKey = 'idle';
const activityStore = storage.createInstance({ name: 'activity-store' });

const locationKey = 'location';
const routeStore = storage.createInstance({ name: 'route-store ' });

const routeStoreObj = {
  getItem: key => routeStore.getItem(key),
  setItem: (key, val) => routeStore.setItem(key, val),
  removeItem: key => routeStore.removeItem(key)
};

const activityStoreObj = {
  getItem: key => activityStore.getItem(key),
  setItem: (key, val) => activityStore.setItem(key, val)
};

function idleChecker() {
  // Store the last user active time
  const setlastIdleTime = () => {
    activityStore.setItem(activityKey, Date.now());
  };

  // Events that user activity needs to be monitored
  window.onload = setlastIdleTime;
  window.onmousemove = setlastIdleTime;
  window.onmousedown = setlastIdleTime;
  window.ontouchstart = setlastIdleTime;
  window.onclick = setlastIdleTime;
  window.onkeypress = setlastIdleTime;
  window.addEventListener('scroll', setlastIdleTime, true);
}

function watchForTokenExpiration(tokenWithallowedIdleTime) {
  const { token } = tokenWithallowedIdleTime;
  
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      emitter(tokenWithallowedIdleTime);
      // End setinterval execution when token gets expired
       if((((token.expiresAt-30) * 1000) < Date.now()) || !token.expiresAt){
        emitter(END);
      }
    }, 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

function* logoutUser(action) {
  try {
    const { skipRedirect } = action.payload;
   // yield call(oAuth.logout);
    //yield call(routeStoreObj.removeItem, locationKey);
    yield put(setUserLogout(true));
    yield put(setUserLogin(null));
    if (!skipRedirect) {
      history.push('/');
    }
  } catch (err) {
    yield put(setAuthCheckError(err));
  }
}

function* initializeUserAuth(action) {
    try {
      
      
      yield call(idleChecker);
      
      const state = yield select();

      const loginToken = yield select(selectUserDetails)
      const logoutStatus = yield select(logout)
     
      if ((Object.keys(loginToken).length ===0) ) {
        if (isOffline(state)) {
          yield put(finishAuthCheck());
        } 
      } else {
        yield call(setGlobalHeader, 'Authorization', `Bearer ${loginToken.accessToken}`);
        const channel = yield call(watchForTokenExpiration, {
          token: loginToken,
          allowedIdleTime: oAuthConfig.allowedIdleTime,
          logout: logoutStatus 
        })

        yield takeEvery(channel, function* tokenExpirationWatcher(tokenWithallowedIdleTime) {
          const logoutStatus = yield select(logout)
          tokenWithallowedIdleTime.logout = logoutStatus
          const { token, allowedIdleTime } = tokenWithallowedIdleTime;
         
          if (((token.expiresAt-30)*1000) <= Date.now()) {
            const lastActiveTime = yield call(activityStoreObj.getItem, activityKey);
           // if last active time is 30 mins ago (allowedIdleTime), then logout user otherwise refresh session
            
            if (!(Date.now() - lastActiveTime <= allowedIdleTime)) {
              yield put(setUserLogin(null));
              yield put(setUserLogout(true));
              sessionStorage.setItem("token-satus", "expired");
              localStorage.removeItem('okta-cache-storage')
              localStorage.removeItem('okta-token-storage')
              yield call(logoutUser, { payload: { skipRedirect: true} });
             
            }
          }
        });
        

        yield put(createServiceAction('getUserProfile', 'requested')());
        yield take(createServiceAction('getUserProfile', 'success').type);
        const userDetails = yield select(selectUserDetails);
        const existingUser = userDetails.userId;
        const userType = userDetails.group;
        const savedUserType = userDetails.savedUserType;
        const userInactive  = userDetails.userInactive;
        let createUserRoute = null;
        if (!existingUser) {
         
          if (userType === 'Rsl' || userType === 'Nlo') {
            //createUserRoute = '/error'
            if(userInactive){
              yield put(checkUserExist(false))
              createUserRoute = '/error';
            } else {
            yield put(createServiceAction('createUserProfile', 'requested')());
            yield take(createServiceAction('createUserProfile', 'success').type);
            }
          } 
          
          else {
            //createUserRoute = '/error';
            if((userInactive && userDetails.deactivated) || !userDetails.profileUpdated){
            yield put(checkUserExist(false))
            history.push('/error');
            }
          }
        } else if((userInactive && userDetails.deactivated && (userDetails.group === "Suppliers" || userDetails.group ==="Factories"))||(!userDetails.profileUpdated)){
          yield put(checkUserExist(false))
            history.push('/error');
        }
        else if(userType !== savedUserType) {
          yield put(checkValidGroup(false))
          createUserRoute = '/error-mismatch';
        }
  
        // Redirect user after OKTA authentication
        const previousRoute = yield call(routeStoreObj.getItem, locationKey);
        if (previousRoute) {
          yield call(history.replace, createUserRoute || previousRoute);
          yield call(routeStoreObj.removeItem, locationKey);
        } else if (createUserRoute) {
          yield call(history.push, createUserRoute);
        }
        yield put(finishAuthCheck());
        if (createUserRoute) {
          yield call(history.push, createUserRoute);
        }
      }
    } catch (err) {
      yield put(setAuthCheckError(err));
    }
  }

function* loginSagas() {
    yield takeEvery('AUTH_CHECK_REQUESTED', initializeUserAuth);
  
  }
  
  export default loginSagas;