const userInfoConstants = {
    userInfo: ['name', 'email', 'contact'],
    userInfoEdit: ['name', 'email', 'compBAddr', 'facilityName','userType', 'editUser', 'activeStatus'],
    userInfoTPException: ['tpException', 'name', 'email', 'supplierName'],
    userInfoTPExceptionRSL: ['tpException', 'name', 'email'],
    userInfoTPExceptionFactoryOrSupplier: ['tpException', 'name', 'email', 'supplierName'],
    userInfoTPExceptionAllUsers: ['tpException', 'name', 'email', 'userType', 'supplierName'],
    supplierInfo:['supplierFacilityName', 'companyType','locationName','city','country','OSHId','editSupplier','addLocation','supplierActiveStatus']
};
  
export default userInfoConstants;
  