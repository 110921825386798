const config = [
  {
    colId: 'materialNumber',
    headerName: 'Material Number',
    field: 'materialNumber',
    suppressMenu: true,
    minWidth:50,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'materialName',
    headerName: 'Material Name',
    field: 'materialName',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    minWidth:50,
    suppressMenu: true,
    tooltipField: 'materialName',
    suppressMovable: true,
    cellStyle: { color: '#3498db' },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'materialDesc',
    headerName: 'Material Description',
    field: 'materialDesc',
    minWidth:50,
    suppressMenu: true,
    tooltipField: 'materialDesc',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMovable: true,
    cellStyle: { color: '#3498db' },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'styleNumber',
    headerName: 'Style Number',
    field: 'styleNumber',
    suppressMenu: true,
    minWidth:50,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    headerName: "Active/De-Activate",
    field: "IsActive",
    colId: "activeStatus",
    suppressSizeToFit: true,
    minWidth:150,
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressFilter: true,
    suppressMenu: true,
    tooltipField: 'IsActive',
    suppressMovable: true,
    cellRenderer: params => {
    //   return `<input type='checkbox' ${params.node.data.favExists ? 'checked' : ''} />`;
    return `<label class="switch">
    <input type="checkbox" ${params.node.data.isActive ? 'checked' : ''} >
    <span class="slider round"></span>
  </label>`
    }
},
];
export default config;
