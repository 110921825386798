import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const options = {
        supplierId: action.data,
        onlyInternal:true
      };

      const res = yield call(getRequest, apiConfig.locationSearch.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if(!statusErrorMessage){ 
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.errorCode === 0) {
          yield put(createServiceAction(serviceType, 'success')(response));
          yield put(createServiceAction('locationDetails', 'requested')(response.data.locationDetails[0].locationId));
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* locationSearchSagas() {
  const serviceType = 'addSupplierLocationSearch';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default locationSearchSagas;
