import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {

    const model = {
      trfId: null
    };
    const result = pick(action.data, keys(model));
    const data = { ...result };
    const infoPopDuration = 1000;
  
    yield call(displayMessage, 'info', 'Updating Status ...', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, apiConfig.updateStatus.endPointUrl, data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if(!statusErrorMessage){
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        let differenceMonth = sessionStorage.getItem('currentSelectedMonth') ? sessionStorage.getItem('currentSelectedMonth') : 12;
        let day = new Date()
        day.setMonth(day.getMonth() - differenceMonth)
        let dateArray = day.toLocaleDateString('en-US').split('/')
        let fromDate = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]
        yield put(createServiceAction('testSummary', 'requested')({"status":'InProgress',"fromDate":fromDate}));
      }
    } 
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateStatusSagas() {
 
  const serviceType = 'updateStatus';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default updateStatusSagas;
