import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import productInfo from '../constants/json/checkboxes/productInfo.json';
import { onChangeRetest } from '../actions/trfActions';

import get from 'lodash/get';
import { put, takeEvery, call, take } from 'redux-saga/effects';

function* listener(serviceType, action) {
  yield put(createServiceAction(serviceType, 'loading')());
  if (action.data) {
    const options = {
      trfId: action.data.trfId
    };

    const res = yield call(getRequest, apiConfig.getTrfForEdit.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        if(response.data.originalTRF !== null){
          yield put(onChangeRetest('Yes'));
        }else{
          yield put(onChangeRetest('No'));
        }
        if ((get(response, 'data.locationId') && get(response, 'data.supplierId')) ||(get(response, 'data.locationId') && get(response, 'data.facilityId'))) {
          const productLabel = productInfo.filter(e => e.id === response.data.product)
          yield put(createServiceAction('locationSearch', 'requested')({ supplierId: response.data.supplierId !== null ? response.data.supplierId:action.data.supplierId,
          facilityId:response.data.facilityId !== null ?response.data.facilityId:action.data.facilityId, type: 'editTRF', history: action.data.history })); 
          yield take(createServiceAction('locationSearch', 'success').type);
          yield put(createServiceAction('materialSearch', 'requested')({ supplierLocation: response.data.locationId, prodType: productLabel[0].label }));
          yield put(createServiceAction(serviceType, 'success')(response.data));
          yield put(createServiceAction(serviceType, 'after_success')());
        }
      }
    }
  }
}

function* trfUpdateSagas() {
  yield takeEvery(createServiceAction('trfUpdate', 'requested').type, listener, 'trfUpdate');
}

export default trfUpdateSagas;
