import find from 'lodash/find';
import union from 'lodash/union';

import individualTest from '../../../constants/json/checkboxes/individualTest.json';

const config = [
  {
    colId: 'class',
    headerName: 'Chemical Class',
    headerTooltip: 'Chemical Class',
    field: 'class',
    suppressMenu: true,
    width:300,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: {  'font-weight': 'normal' },
    tooltip: (params) => params.value,
    suppressMovable: true,
    valueGetter: params => {
      let renderedText = '';
      if (params.data && params.data.class) {
        renderedText = find(
          union(
            individualTest[0].common,
            individualTest[0].toys,
            individualTest[0].electronic,
            individualTest[0].packaging,
            individualTest[0].jewelry
          ),
          ['id', params.data.class]
        ).label;
      }
      return renderedText;
    }
  },
  {
    colId: 'chemicalName',
    headerName: 'Chemical Name',
    headerTooltip: 'Chemical Name',
    field: 'chemicalName',
    width: 250,
    tooltip: (params) => params.value,
    suppressMovable: true,
    suppressMenu: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: { 'font-weight': 'normal' },

  },
  {
    colId: 'casNo',
    headerName: 'CAS Number',
    headerTooltip: 'CAS Number',
    field: 'casNo',
    tooltip: (params) => params.value,
    suppressMovable: true,
    suppressMenu: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: { 'white-space': 'normal', 'font-weight': 'normal' },
  },
  {
    colId: 'labLimit',
    headerName: 'Required Laboratory Reporting Limit',
    headerTooltip: 'Required Laboratory Reporting Limit',
    field: 'labLimit',
    width:450,
    suppressMenu: true,
    suppressMovable: true,
    tooltip: (params) => params.value,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: { 'white-space': 'normal', 'font-weight': 'normal' },
  },
  {
    colId: 'nikeLimit',
    headerName: 'Nike Limit',
    headerTooltip: 'Nike Limit',
    field: 'nikeLimit',
    width: 200,
    tooltip: (params) => params.value,
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: {'font-weight': 'normal' },
  },
  {
    colId: 'result',
    headerName: 'Test Result',
    headerTooltip: 'Test Result',
    field: 'result',
    suppressMenu: true,
    suppressMovable: true,
    tooltip: (params) => params.value,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: { 'white-space': 'normal', 'font-weight': 'normal' },
    valueGetter: params => {
      let renderedText = params.data.result;
      const classValue = 532;
      const classValueAsbestos = 504;
      if (params.data && params.data.result !== ' ') {
        if (params.data.class === classValue || params.data.class === classValueAsbestos) {
          if (params.data.result === -2) {
            renderedText = 'Present';
          } else if (params.data.result === -3) {
            renderedText = 'Absent';
          } else {
            renderedText = 'Invalid Test Result Value '
          }
        }else if (params.data.result < 0) {
          if (params.data.result === -4) {
            renderedText = 'Not Tested';
          }
          else if (params.data.result === -1) {
            renderedText = 'Not Detected';
          }
          else {
          renderedText = 'Invalid Test Result Value '
          }
        }
      } 
      return renderedText;
    }
  },
  {
    colId: 'unit',
    headerName: 'Unit',
    headerTooltip: 'Unit',
    field: 'unit',
    width: 100,
    suppressMenu: true,
    suppressMovable: true,
    tooltip: (params) => params.value,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: { 'white-space': 'normal', 'font-weight': 'normal' },
  },
  {
    colId: 'status',
    headerName: 'Pass/Fail',
    headerTooltip: 'Pass/Fail',
    field: 'status',
    suppressMenu: true,
    suppressMovable: true,
    tooltip: (params) => params.value,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: { 'white-space': 'normal', 'font-weight': 'normal' },
  }
];
export default config;