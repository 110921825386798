
import get from 'lodash/get';

import { createServiceAction } from '../util/actionHelper.js';
import { clearExceptionList, hideProgress, clearEditLocationList , clearEditSupplierForm} from '../actions/adminUserActions.js';
import { toggleAddSupplerButton } from '../actions/navigationActions.js';

const defaultState = {
  getExceptionNextPage: {},
  totalPages: {},
  getExceptionRemainingPages: {},
  getRemainingPages: 0,
  getDetailsNextPage: 1,
  getprogressException: {}
};

let addSupplierArray = {
  supplierName: '',
  companyType: '',
  locationName: '',
  address1: '',
  country: '',
  city: '',
  OSHId:'',
  parentCompany:'',
  mLocCode:'',
  isError: true
};

let updateSupplierArray = {
  companyName: '',
  address1: '',
  country: '',
  city: '',
  OSHId:'',
  parentCompany:'',
  mLocCode:'',
  isError: true,

};

function isAddsupplierValidated(formDetails) {
  if (formDetails.meta.form === 'addSupplierInfo') {
    if (formDetails.meta.field.indexOf('supplierName') !== -1) {
      addSupplierArray.supplierName = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('companyType') !== -1) {
      addSupplierArray.companyType = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('newAddress1') !== -1) {
      addSupplierArray.address1 = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('newCountry') !== -1) {
      addSupplierArray.country = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('newCity') !== -1) {
      addSupplierArray.city = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('OSHId') !== -1) {
      addSupplierArray.OSHId = formDetails.payload;
    }
    if (
      addSupplierArray.supplierName !== '' &&
      addSupplierArray.supplierName.length >= 3 &&
      addSupplierArray.companyType !== '' &&
      addSupplierArray.address1 !== '' &&
      addSupplierArray.country !== '' &&
      addSupplierArray.city !== '' &&
      addSupplierArray.OSHId !== ''
    ) {
      addSupplierArray.isError = false;
    } else {
      addSupplierArray.isError = true;
    }
  }
  return addSupplierArray.isError;
}

function isUpdatesupplierValidated(formDetails) {
  if(formDetails.meta.form === 'editLocationInfo') {
    if (formDetails.meta.field.indexOf('companyName') !== -1) {
      updateSupplierArray.companyName = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('address1') !== -1) {
      updateSupplierArray.address1 = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('country') !== -1) {
      updateSupplierArray.country = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('city') !== -1) {
      updateSupplierArray.city = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('OSHId') !== -1) {
      updateSupplierArray.OSHId = formDetails.payload;
    }
    if (
      (updateSupplierArray.address1 !== '' &&
      updateSupplierArray.country !== '' &&
      updateSupplierArray.city !== '' && updateSupplierArray.companyName !== '' && 
      updateSupplierArray.OSHId !== '')
    ) {
      updateSupplierArray.isError = false;
    } else {
      updateSupplierArray.isError = true;
    }
  }
  // if (formDetails.meta.form === 'addSupplierInfo') {
  //   if (formDetails.meta.field.indexOf('address1') !== -1) {
  //     updateSupplierArray.address1 = formDetails.payload;
  //   }
  //   if (formDetails.meta.field.indexOf('country') !== -1) {
  //     updateSupplierArray.country = formDetails.payload;
  //   }
  //   if (formDetails.meta.field.indexOf('city') !== -1) {
  //     updateSupplierArray.city = formDetails.payload;
  //   }
    
  //   if (
  //     updateSupplierArray.address1 !== '' &&
  //     updateSupplierArray.country !== '' &&
  //     updateSupplierArray.city !== ''
  //   ) {
  //     updateSupplierArray.isError = false;
  //   } else {
  //     updateSupplierArray.isError = true;
  //   }
  // }
  return updateSupplierArray.isError;

}
function clearValues() {

  addSupplierArray = {
    supplierName: '',
    companyType: '',
    locationName: '',
    address1: '',
    country: '',
    city: '',
    OSHId:'',
    parentCompany:'',
    isError: true
  };

  updateSupplierArray = {
    locationName: '',
    companyName: '',
    address1: '',
    country: '',
    city: '',
    supplierId: '',
    OSHId:'',
    parentCompany:'',
    isError: true
  };
}
const pageCount = 500
export default function adminUser(state = defaultState, action) {
  switch (action.type) {
    case toggleAddSupplerButton.type:
      return { ...state, updateSupplierArray: clearValues() };
    case '@@redux-form/CHANGE':
      return { ...state, errorStatusAddSupplier: isAddsupplierValidated(action), errorStatusUpdateSupplier: isUpdatesupplierValidated(action) };
    case createServiceAction('getExceptionUsers', 'requested').type:
      return {
        ...state,
        exceptionRSLUsers: [],
        exceptionSupplierOrFactoryUsers: [],
        exceptionAllUsers: [],
        getExceptionNextPage: {},
        getprogressException: {}
      }
    case createServiceAction('getExceptionUsers', 'success').type:
      state.totalPages[action.data.data.userType] = Math.ceil(parseInt(action.data.data.totalCount) / pageCount)
      state.getExceptionNextPage[action.data.data.userType] = parseInt(action.data.data.currentPage) + 1
      state.getExceptionRemainingPages[action.data.data.userType] = Math.ceil(parseInt(action.data.data.totalCount) / pageCount) - parseInt(action.data.data.currentPage)

      if (state.totalPages[action.data.data.userType] > 1 && state.getExceptionNextPage[action.data.data.userType] === 2) {
        state.getprogressException[action.data.data.userType] = (parseInt(action.data.data.currentPage) / state.totalPages[action.data.data.userType]) * 100
      }

      return {
        ...state,
        getprogressCurrent: state.getprogressException[action.data.data.userType],
        getExceptionNextPage: state.getExceptionNextPage,
        getExceptionRemainingPages: state.getExceptionRemainingPages,
        exceptionRSLUsers: action.data.data.userType === 'Rsl' && get(action.data, `data.userList`, []),
        exceptionSupplierOrFactoryUsers: action.data.data.userType === 'Suppliers' && get(action.data, `data.userList`, []),
        exceptionAllUsers: action.data.data.userType === 'All' && get(action.data, `data.userList`, [])
      }

    case createServiceAction('getExceptionUsersLazyLoad', 'success').type:
      if (action.data.data.currentPage <= state.totalPages[action.data.data.userType]
        && state.totalPages[action.data.data.userType] !== 0)
        state.getprogressException[action.data.data.userType] = (parseInt(action.data.data.currentPage) / state.totalPages[action.data.data.userType]) * 100
      else
        state.getprogressException[action.data.data.userType] = 0
      state.totalPages[action.data.data.userType] = Math.ceil(parseInt(action.data.data.totalCount) / pageCount)
      state.getExceptionNextPage[action.data.data.userType] = parseInt(action.data.data.currentPage) + 1
      state.getExceptionRemainingPages[action.data.data.userType] = Math.ceil(parseInt(action.data.data.totalCount) / pageCount) - parseInt(action.data.data.currentPage)

      if (state.getExceptionNextPage[action.data.data.userType] > state.totalPages[action.data.data.userType]) {
        state.getExceptionNextPage[action.data.data.userType] = 1
        state.totalPages[action.data.data.userType] = 0
      }
      return {
        ...state,
        getprogressCurrent: state.getprogressException[action.data.data.userType],
        getExceptionNextPage: state.getExceptionNextPage,
        getExceptionRemainingPages: state.getExceptionRemainingPages,
        exceptionRSLUsers: action.data.data.userType === 'Rsl' && state.exceptionRSLUsers.concat(action.data.data.userList),
        exceptionSupplierOrFactoryUsers: action.data.data.userType === 'Suppliers' && state.exceptionSupplierOrFactoryUsers.concat(action.data.data.userList),
        exceptionAllUsers: action.data.data.userType === 'All' && state.exceptionAllUsers.concat(action.data.data.userList)
      }


    case createServiceAction('userDetails', 'requested').type:
      return { ...state, getprogress: 0, getDetailsNextPage: 1, userDetails: [] }

    case createServiceAction('userDetails', 'success').type:
      let totalPages = Math.ceil(parseInt(action.data.data.totalCount) / pageCount)
      if (state.getDetailsNextPage > totalPages) {
        state.getDetailsNextPage = 1
        totalPages = 0
      }
      state.getDetailsNextPage = parseInt(action.data.data.currentPage) + 1
      state.getRemainingPages = Math.ceil(parseInt(action.data.data.totalCount) / pageCount) - parseInt(action.data.data.currentPage)
      if (totalPages > 1 && state.getDetailsNextPage === 2) {
        state.getprogress = (action.data.data.currentPage / totalPages) * 100
      }

      return { ...state, userDetails: action.data.data, getDetailsNextPage: state.getDetailsNextPage, getprogress: state.getprogress };

    case createServiceAction('userDetailsLazyLoad', 'success').type:
      let totalPagesLazyload = Math.ceil(parseInt(action.data.data.totalCount) / pageCount)
      state.getDetailsNextPage = parseInt(action.data.data.currentPage) + 1
      state.getRemainingPages = Math.ceil(parseInt(action.data.data.totalCount) / pageCount) - parseInt(action.data.data.currentPage)
      if (action.data.data.currentPage > totalPagesLazyload) {
        state.getDetailsNextPage = 1
        totalPagesLazyload = 0
      }
      if (action.data.data.currentPage <= totalPagesLazyload && totalPagesLazyload !== 0)
        state.getprogress = (action.data.data.currentPage / totalPagesLazyload) * 100
      else
        state.getprogress = 0

      let temp = state.userDetails
      temp.currentPage = action.data.data.currentPage
      temp.userList = temp.userList.concat(action.data.data.userList);
      return { ...state, getprogress: state.getprogress, userDetails: temp, getDetailsNextPage: state.getDetailsNextPage };

    case createServiceAction('userDataById', 'requested').type:
      return { ...state, userDataById: undefined };

    case createServiceAction('userDataById', 'success').type:
      return { ...state, userDataById: action.data.data };

    case createServiceAction('addSupplierSearch', 'requested').type:
      return { ...state, supplierData: [] };

    case createServiceAction('addSupplierSearch', 'success').type:
      return { ...state, supplierData: action.data.data };

    case createServiceAction('addSupplierSearch', 'error').type:
      return { ...state, supplierData: undefined };

    case createServiceAction('addSupplier', 'success').type:
      return { ...state, addSupplierResult: action.data.data };

    case createServiceAction('addSupplierLocationSearch', 'requested').type:
      return { ...state, supplierLocationList: [] };

    case createServiceAction('addSupplierLocationSearch', 'success').type:
      return { ...state, supplierLocationList: action.data.data.locationDetails };

    case createServiceAction('locationDetails', 'requested').type:
      return { ...state, locationDetails: [] };

    case createServiceAction('locationDetails', 'success').type:
      return { ...state, locationDetails: action.data.data };
    
    case createServiceAction('listComments', 'requested').type:
      return { ...state, comments:null };

    case createServiceAction('listComments', 'success').type:
        return { ...state, comments: action.data.data.comments };

    case clearExceptionList.type:
      return {
        ...state,
        exceptionRSLUsers: [],
        exceptionSupplierOrFactoryUsers: [],
        exceptionAllUsers: [],
        exceptionUsers: [],
        getprogressCurrent: {}
      }

    case hideProgress.type:
      return {
        ...state,
        getprogressCurrent: state.getprogressCurrent === 100 ? 0 : state.getprogressCurrent,
        getprogress: state.getprogress === 100 ? 0 : state.getprogress
      }
    case clearEditSupplierForm.type:
      return { ...state, updateSupplierArray: clearValues(), locationDetails: []};
    case clearEditLocationList.type:
      return { ...state, supplierLocationList: [] };

    default:
      return state;
  }
}


export const getDetailsNextPage = state => state.adminUser.getDetailsNextPage
export const getRemainingPages = state => state.adminUser.getRemainingPages
export const getExceptionNextPage = state => state.adminUser.getExceptionNextPage
export const getExceptionRemainingPages = state => state.adminUser.getExceptionRemainingPages
