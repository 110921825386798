import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    
    const infoPopDuration = 1000;
    const res = yield call(postRequest, apiConfig.saveFilterHistory.endPointUrl, action.data.data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        const message = `${response.message}`;
        yield put(createServiceAction(serviceType, 'success')(action.data.data));
        yield call(displayMessage, 'success', message, infoPopDuration);

        // if(action.data.isClearHistory){
        yield put(createServiceAction('testSummaryCount', 'requested')({ isClearHistory:action.data.isClearHistory, status: action.data.status, supplierName: action.data.supplierName, fromDate: action.data.fromDate }));
        // }
        if(action.data.isClearHistory){
          yield put(createServiceAction('testSummary', 'requested')({ status: action.data.status, supplierName: action.data.supplierName, fromDate: action.data.fromDate }));
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* saveFilterData() {
  const serviceType = 'saveFilterData';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default saveFilterData;
