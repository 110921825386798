import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Collapsible, Container } from '../../../components/index.js';
import Grid from '../../../components/grid/Grid.js';
import TrfInfo from '../trfInfo/TrfInfo';
import LabInputs from '../../trf_review/labInputs/LabInputs.js';
import testDetailConfig from '../../../configs/gridConfigs/labResults/testDetailConfig.js';


const style = {
  fontWeight: '800',
  fontSize: '1.8rem',
  lineHeight: '1.5',
  color: '#0078B9'
};

class ReviewForCompleted extends Component {
  componentDidMount() {
    const { displayClearButtonLab, displaySubmitButtonLab } = this.props;
    displayClearButtonLab(false);
    displaySubmitButtonLab(false);
  }

  render() {
    const { testData, displayClearButtonLab, displaySubmitButtonLab, trfDetail } = this.props;
    return (
      <div className="page-align-items TestDetails ncss-row vertical-align-baseline m0-bottom-sm" style={{ marginTop: '115px' }} >
        <div>
          <Collapsible
            trigger={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
                <div className="ncss-col-sm-1 ">
                  <i className="fas fa-caret-down fa-pull-right" />
                </div>
              </div>
            }
            triggerWhenOpen={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
                <div className="ncss-col-sm-1">
                  <i className="fas fa-caret-up fa-pull-right" />
                </div>
              </div>
            }
            triggerStyle={style}
            triggerClassName="collapsible"
          >
            <TrfInfo
              from="review" showTitle={false}
              displayClearButtonLab={displayClearButtonLab}
              displaySubmitButtonLab={displaySubmitButtonLab}
            />
          </Collapsible>
        </div>
        <div>
          <Collapsible
            trigger={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> TEST RESULTS</div>
                <div className="ncss-col-sm-1 ">
                  <i className="fas fa-caret-down fa-pull-right" />
                </div>
              </div>
            }
            triggerWhenOpen={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> TEST RESULTS</div>
                <div className="ncss-col-sm-1">
                  <i className="fas fa-caret-up fa-pull-right" />
                </div>
              </div>
            }
            triggerStyle={style}
            triggerClassName="collapsible"
          >
            <div className="dashboard">
              <Container section className="p0-top-sm m0-sm">
                <div className="ncss-row">
                  <div className="ncss-col-sm-12">
                    <Grid 
                      columnDefs={testDetailConfig} 
                      rowData={testData} 
                      showFilter={false} 
                      headerHeight={42} 
                    />
                  </div>
                </div>
              </Container>
            </div>
          </Collapsible>
        </div>
        <div>
          <Collapsible
            trigger={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0">ADDITIONAL LAB INPUTS</div>
                <div className="ncss-col-sm-1 ">
                  <i className="fas fa-caret-down fa-pull-right" />
                </div>
              </div>
            }
            triggerWhenOpen={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0">ADDITIONAL LAB INPUTS</div>
                <div className="ncss-col-sm-1">
                  <i className="fas fa-caret-up fa-pull-right" />
                </div>
              </div>
            }
            triggerStyle={style}
            triggerClassName="collapsible"
          >
            <LabInputs trfDetail={trfDetail} />
          </Collapsible>
        </div>
      </div>
    );
  }
}
ReviewForCompleted.propTypes = {
  testData: PropTypes.array,
  trfDetail: PropTypes.object,
  displayClearButtonLab: PropTypes.func,
  displaySubmitButtonLab: PropTypes.func
};
export default ReviewForCompleted;
