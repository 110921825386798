
export const testPackageArry = (packages) => {
    let myJson={};
    let list =[];
    const filteredPackages = packages && packages.length > 0 && packages.filter(ele => ele !==false)
    for (let i = 0; i < filteredPackages.length; i++) {
      const splitColumn = filteredPackages[i].split(":")
      const name = splitColumn[0]
      const nameValue = splitColumn[1]
      myJson = {
        "TP1": "", "TP2": "",
        "CUSTOM": ""
      }
      if (nameValue === "TP1") {
        list.push(myJson);
        for (let tp1 = 0; tp1 < list.length; tp1++) {
          if (list[tp1]['TP1'] === '') {
            list[tp1]['TP1'] = name
            break
          }
        }
      }
      else if (nameValue === "TP2") {
        list.push(myJson);
        for (let tp2 = 0; tp2 < list.length; tp2++) {
          if (list[tp2]['TP2'] === '') {
            list[tp2]['TP2'] = name;
            break
          }
        }
      } else if (nameValue === "CUSTOM") {
        list.push(myJson);
        for (let j = 0; j < list.length; j++) {
          if (list[j]['CUSTOM'] === '') {
            list[j]['CUSTOM'] = name
            break
          }
        }
      }
    }
      const filterd =list.filter(e => Object.keys(e).length)
      var filteredResult = filterd.filter(function (el) {
        if(el.TP1 === '' && el.TP2==='' && el.CUSTOM === ''){
          return filteredResult;
        }
        else{
          return el 
        }
      });
    return filteredResult;
  }
