import React from 'react';


class PageTemplate extends React.Component {

  render() {
    const { pageNum, totalPages, header,isTRFReport,isLabUser} = this.props
    return (
      <div className="test_details">
        <div className="header" style={{ position: "absolute", top: "20px", display: "inline-block" }}>
          <div className="ncss-col-sm-12 mt1-sm pdf_header">
          <div className={isTRFReport ? "ncss-col-sm-5" :  "ncss-col-sm-3"}>
            <h3 className="fs20-sm lh20-sm fs24-md lh24-md padding-18 fs28-lg lh28-lg inline-display relative-position">
            <div className={isTRFReport ? "ncss-brand header-brand-pdf":"ncss-brand text-color-accent  header-brand-pdf "}>
              {header}</div>
            </h3>
            </div>
            <div className={isTRFReport ? "ncss-col-sm-5" :  "ncss-col-sm-4"}>
            <div className={isTRFReport ? "ncss-brand header-brand-pdf":"ncss-brand text-color-accent header-brand-pdf ml-40"}
            >
            {isTRFReport ? sessionStorage.getItem("trfCreatedDate"):  "Test Date: " + sessionStorage.getItem("labRecDate")} </div>
            </div>
            {!isTRFReport && 
            (<div className="ncss-col-sm-5">{!isLabUser &&
            <div className="ncss-brand text-color-red  header-brand-pdf ml-expiry10">
            {"Expiration Date: "} {sessionStorage.getItem("expiry")}
            </div>
            }
            </div>)}
            <div className={isTRFReport ? "ncss-col-sm-2 padding-logo" : "ncss-col-sm-2 padding-logo ml-logo180"}>
              <i className="adjust-icon g72-swoosh nav-brand fs18-sm" />
            </div>
          </div>
        </div>
        <div className="footer ncss-col-sm-12" style={{ position: "absolute", bottom: "30px", display: "inline-block" }}>
          <span className="ncss-col-sm-10 footer-margin">{header} - {sessionStorage.getItem("reportId")} </span>
          <span className="ncss-col-sm-2 padding-pageNo"> Page {pageNum} of {totalPages}</span>
        </div>
      </div>
    );
  }
}

export default PageTemplate;