import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';

import { put, takeEvery, call } from 'redux-saga/effects';


function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());
        if (action.data) {
            const options = {
                supName: `${action.data}`
            };
            const res = yield call(getRequest, apiConfig.searchLicensee.endPointUrl, options);
            const response = yield res.json();

            yield put(createServiceAction(serviceType, 'success')(response));


        } else {
            yield put(createServiceAction(serviceType, 'success')({}));
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));
    }
}

function* addLicenseeSearchSagas() {
    const serviceType = 'addLicenseeSearch';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default addLicenseeSearchSagas;
