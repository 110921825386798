const config = [
    {
        field: 'supplierName',
        rowGroup: true,
        hide: true,
    },
    {
        colId: 'name',
        headerName: 'Name',
        field: 'name',
        suppressFilter: true,
        suppressMovable: true,
        suppressMenu: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'email',
        headerName: 'E-Mail',
        field: 'email',
        suppressFilter: true,
        suppressMovable: true,
        suppressMenu: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        headerName: "Actions",
        field: "action",
        colId: "action",
        pinned: 'right',
        suppressToolPanel: true,
        suppressSizeToFit: true,
        width: 100,
        suppressFilter: true,
        suppressMenu: true,
        //minWidth: 120,
        // maxWidth: 130,
        tooltipField: 'reviewAction',
        suppressMovable: true,
        cellRenderer: params => {
            if (params.data && params.data.userId) {
                return `<div class="test_button"><i class="fas fa-trash"></i> Remove</div>`;
            }

        }
    },
];
export const autoGroupColumnDefForList =
{

    minWidth: 350,
    cellRenderer: 'agGroupCellRenderer',
    tooltipField: 'supplierName',
    cellRendererParams: { checkbox: false },

}
export default config;
