import React from 'react';
import { reduxForm, getFormValues, Field } from "redux-form";
import {
    Button,
    Container, TextInput, H1, ConfirmationBox,
    H5
} from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { connect } from 'react-redux';
import Label from '../../components/ncss/Label';
import { selectEmail, selectFullName, isSupplierUser, isFactoryUser, selectUserDetails } from '../../reducers/authReducer.js';
import {getEnableOSHSubmit} from '../../reducers/dashboardReducer.js';
const oshValidate = value =>
    value && !/^[a-zA-Z]{2}[a-zA-Z0-9]{13}$/.test(value) &&
    'Incorrect OS ID, please provide the correct OS ID as defined in opensupplyhub.'

export class OSHActionCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitModal: false,
            enableSubmit: true
        };
    }
    shouldEnableSubmit = () => {
        const { locationInfo, userDetails,enableOSHSubmit } = this.props;
        if (locationInfo && locationInfo.OSHId) {
            if (locationInfo.OSHId) {
             return userDetails.OSHStatus === "completed" || enableOSHSubmit ? true : false
            }
        }

        return true;
    }
    handleSubmit = () => {
        const { locationInfo, createRequestAccess, email, shouldEnableOSHSubmit } = this.props;
        shouldEnableOSHSubmit(true)
        this.setState({enableSubmit: false})
        if (locationInfo) {
            createRequestAccess({ OSHId: locationInfo.OSHId, email: email })
        }
    }

    validateuser = (props) => {
        const { isFactoryUser, isSupplierUser, userDetails } = this.props
        if ((isSupplierUser || isFactoryUser) && !userDetails.userInactive &&
            userDetails.OSHId !== "") {
            return true
        }
        return false
    }
    closeSubmitModal = () => {
        this.setState({ submitModal: false });
    };
    openConfirmationPopup = () => {
        this.setState({ submitModal: true });
    }

    render() {
        const { userDetails, initialValues, isSupplierUser, isFactoryUser, enableOSHSubmit,invalid } = this.props;
        const { submitModal } = this.state
        if (userDetails.OSHId !== "") {
            initialValues.OSHId = userDetails.OSHId
        }
        return (
            isSupplierUser || isFactoryUser ?
                <form className="form">
                    <div className="userpage">
                        <div>
                            <Container extraClassName="ncss-col-sm-12 display-flex" section >
                                <div className='ncss-col-sm-3 mtAC-4'>
                                    <div className="ie-icon-align iconStyle">
                                        <i className="fas fa-user-circle" />
                                    </div>
                                    <H5 className="u-bold text-size displayTextAC">SUPPLIER PROFILE</H5>
                                </div>
                                <div className="ncss-col-sm-5 user-padding-empty">

                                    {userDetails &&
                                        <div className="ncss-input-container ">
                                            <Label className="ncss-col-sm-5 u-bold p2-top-sm">
                                                ENTER OS ID <span className="astericks ml-neg3">&#42;</span>
                                            </Label>
                                            <div className="ncss-col-sm-7 ncss-input-container">
                                                <Field
                                                    component={TextInput}
                                                    placeholder="Eg: CN2019067NZ95AM"
                                                    name="OSHId"
                                                    value={userDetails.OSHId}
                                                    validate={oshValidate}
                                                />
                                            </div>
                                            {userDetails.OSHId === "" || userDetails.OSHId === null ?
                                                <Button
                                                    className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected  ${userDetails.OSHStatus === "completed" || enableOSHSubmit || invalid ? 'disabled' : ''
                                                        }`}
                                                    onClick={() => { this.handleSubmit() }}
                                                >SUBMIT
                                                </Button>
                                                : <><Button
                                                    className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected  ${userDetails.OSHStatus === "completed" || enableOSHSubmit || invalid ? 'disabled' : ''
                                                        }`}
                                                    onClick={() => { this.openConfirmationPopup() }}
                                                >RE-SUBMIT
                                                </Button>
                                                <h6 className='commentStyle'><span className="astericks">&#42;</span><span className="astericks">&#42;</span>
                            Allow 7-10 working days for processing. Do not re-submit unless you are instructed to do so by RSL Support</h6></>}
                                        </div>
                                    }
                                    <ConfirmationBox
                                        message={
                                            'Do you want to Re-submit OS ID ?'
                                        }
                                        submitModal={submitModal}
                                        submitValue={
                                            () => {
                                                this.handleSubmit();
                                            }
                                        }
                                        closeModal={this.closeSubmitModal}
                                    />

                                    <div className='pr4-sm' ><Label className="ncss-label pr3-sm u-bold">Facility Name</Label><span>:</span>
                                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc ">{userDetails.facilityName !== '' ? userDetails.facilityName : `Not Available`}</Label></div>
                                    <div className='pr4-sm'><Label className="ncss-label pr3-sm u-bold">Parent Company Name</Label><span>:</span>
                                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc ">{userDetails.parentCompany !== '' ? userDetails.parentCompany : `Not Available`}</Label></div>
                                    <div className='pr4-sm'><Label className="ncss-label pr3-sm u-bold">Facility Address</Label><span>:</span>
                                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc">{userDetails.addr1 !== '' ? userDetails.addr1 : `Not Available`}</Label></div>
                                    <div className='pr4-sm'><Label className="ncss-label pr3-sm u-bold">City</Label><span>:</span>
                                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc">{userDetails.city !== '' ? userDetails.city : `Not Available`}</Label></div>
                                    <div className='pr4-sm'><Label className="ncss-label pr3-sm u-bold">Country</Label><span>:</span>
                                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc">{userDetails.country !== '' ? userDetails.country : `Not Available`}</Label></div>
                                    {userDetails.OSHStatus === "pending" && userDetails.facilityName !== '' &&
                                        <h6 className='pb2-sm'>Please verify the OS ID provided above and review the address.If the information is not correct, please resubmit OS ID. For further clarification, please reach out to
                                            <span className='text-color-accent'> RSLSupport@nike.com</span></h6>
                                    }
                                </div>
                                <div className="ncss-col-sm-4 commentStyle">
                                    <h6 className='pb2-sm'>Your profile is incomplete; OS ID is required.</h6>
                                    <h6 className='pb2-sm'>For access to testing functionality, please proceed with required onboarding instructions to complete your profile.
                                    </h6>
                                    <h6 className='pb2-sm'>
                                        Search for your facility on  <a className="u-bold" href="https://opensupplyhub.org/" rel="noopener noreferrer" target="_blank"  >https://opensupplyhub.org/ . </a>
                                    </h6>
                                    <h6>  Your facility’s address needs to be an EXACT match. 
                                        If your facility is  <strong>found</strong>, copy and paste your OS ID into appropriate field in the Action Center.

                                    </h6>
                                    <h6 className='pb2-sm'>If your facility is <strong>not found</strong>, create facility record by contributing to Open Supply Hub (OSH). To contribute to OSH, please start with registering.</h6>
                                       <h6> Register here: <a className="u-bold" href="https://opensupplyhub.org/auth/register" rel="noopener noreferrer" target="_blank"  >https://opensupplyhub.org/auth/register .</a></h6>
                                        <h6>Once you have registered your facility, complete the “upload data” by following these instructions:  <a className="u-bold" href="https://info.opensupplyhub.org/resources/preparing-data" rel="noopener noreferrer" target="_blank"  >https://info.opensupplyhub.org/resources/preparing-data .</a></h6>
                                        <h6>When adding your facility to the Open Supply Hub, we recommend using the “RSL Onboarding Vendor Validation Template.”

                                    </h6>
                                    <h6 className='pb2-sm'>If you have any questions, please contact <strong>RSLSupport@nike.com </strong></h6>
                                    <h6><strong>Note:</strong>  When an email address is requested for OS ID registration make sure to use the same email address you used for the RSL application login.</h6>
                                    {/* <h6 className='pb2-sm'>In case the facility is not found, please register here <a className="u-bold" href="https://opensupplyhub.org/auth/register" rel="noopener noreferrer" target="_blank"  >https://opensupplyhub.org/auth/register</a></h6> */}
                                </div>
                            </Container>
                            <div className="ncss-col-sm-12">
                                <h6 className="u-bold support-link">For any other questions,please reach out to <span className='text-color-accent'>RSLSupport@nike.com</span></h6>
                            </div>
                        </div>
                    </div>
                </form>
                :
                <Container section className="user">
                    <H1>
                        Access Denied. Please contact RSLSupport@nike.com
                    </H1>
                </Container>
        )
    }
}
const mapStateToProps = state => ({
    locationInfo: getFormValues('location')(state),
    email: selectEmail(state),
    username: selectFullName(state),
    isSupplierUser: isSupplierUser(state),
    isFactoryUser: isFactoryUser(state),
    enableOSHSubmit: getEnableOSHSubmit(state),
    userDetails: selectUserDetails(state),

});
const mapDispatchToProps = dispatch => ({
    createRequestAccess: (data) => {
        dispatch(createServiceAction('verifyOSHId', 'requested')(data));
    },
    enableDashboard: (data) => {
        dispatch(createServiceAction('enableDashboard', 'requested')(data));
    },
    shouldEnableOSHSubmit :(data)=>{
        dispatch(createServiceAction('enableOSHSubmit', 'requested')(data));
    }
});

export default reduxForm({
    form: "location", initialValues: {
        OSHId: ""
    }, enableReinitialize: true, destroyOnUnmount: false
})(
    connect(mapStateToProps, mapDispatchToProps)(OSHActionCenter)
);