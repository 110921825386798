import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { selectUserDetails } from '../reducers/authReducer.js';

import { getTestSummaryNextPage, getMaterialRemainingPages, getSelectedButton, stopCall  } from '../reducers/dashboardReducer.js';

import { put, takeLatest, call, select } from 'redux-saga/effects';

// function delay(ms) {
//   return new Promise(resolve => setTimeout(() => resolve(true), ms))
// }

function* listener(serviceType, action) {
  try {
    // Dispatch TESTSUMMARY_LOADING action
    yield put(createServiceAction(serviceType, 'loading')());
    const { labId } = yield select(selectUserDetails);
    // Pass LabId in request params if Lab id exist (Lab id will have value for lab user)
    const options = {
      status: action.data["status"],
      supplierName: action.data["supplierName"],
      facilityName: action.data["facilityName"],
      ...(action.data.fromDate ? { fromDate: action.data["fromDate"] } : {}),
      page: yield select(getTestSummaryNextPage), // Temporarily setting static page number till server side pagination in ag grid is implemented
      pageCount: 5000, // Temporarily setting static count till server side pagination in ag grid is implemented
      ...(labId ? { labId } : {}),
    };
    const favOptions = {
      page: yield select(getTestSummaryNextPage), // Temporarily setting static page number till server side pagination in ag grid is implemented
      pageCount: 500, // Temporarily setting static count till server side pagination in ag grid is implemented
      ...(labId ? { labId } : {}),
    };
    let res;
    // Trigger Call to API endpoint
    if (action.data["status"] === 'Favorites') {
      //call(delay, 1000);
      res = yield call(getRequest, apiConfig.testSummaryFavorites.endPointUrl, favOptions);
    } else {
      // call(delay, 1000);
      res = yield call(getRequest, apiConfig.testSummary.endPointUrl, options);
    }


    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        // Dispatch TESTSUMMARY_LOADING action with response object

        yield put(createServiceAction(serviceType, 'success')({ ...response, selectedStatus: action.data }));
        const remainingPages = yield select(getMaterialRemainingPages);
        // Trigger call again for next set of records till there are pages remaining
        const currentActiveStatus = yield select(getSelectedButton)
        const stopApiCall = yield select(stopCall)
        if (remainingPages > 0 && action.data.status === currentActiveStatus  && !stopApiCall) {
          yield put(createServiceAction(`testSummaryLazyLoad`, 'requested')(action.data));
        }
        else if(remainingPages <= 0 && !stopApiCall) {
          yield put(createServiceAction(`testSummaryLazyLoad`, 'completed')(true, action.data));
        }
      }
      else {
        yield put(createServiceAction(serviceType, 'server-error')());
    }
    }
  } catch (e) {
    // Dispatch TESTSUMMARY_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* testSummarySagas() {
  const serviceType = 'testSummary';
  const serviceTypeLazyLoad = 'testSummaryLazyLoad'
  // Listen to TESTSUMMARY_REQUESTED actions
  yield takeLatest(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeLatest(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);

}

export default testSummarySagas;
