import React, { Component } from 'react';
import { reduxForm, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { VideoPlayer, IconButton } from '../../../../components/index.js';
import { createServiceAction } from '../../../../util/actionHelper.js';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#app-host');

class AddMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopoverOpen: false
        };

    }

    onPlayClick = (path, isBegining) => {
        const { logVideo } = this.props;
        const pathParts = path.split('/')
        if (isBegining) {
            const fileName = pathParts[pathParts.length - 1]
            logVideo(fileName)
        }

    }


    render() {
        const { isModalOpen,
            toggleModal,
            activeVideo
        } = this.props;
        return (
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => { toggleModal(false) }}
                className="modal-container video-modal-container"
                overlayClassName="modal-overlay"
            >
                <IconButton icon="times" className="modal-close-btn" onClick={() => { toggleModal(false) }} />
                <form className="form video-section-form">
                    <div className="ncss-col-sm-12">

                        <div className="video-title">{activeVideo.title}</div>
                        <div className=" ncss-col-sm-12 video-section">
                            <VideoPlayer
                                poster={activeVideo.poster}
                                url={activeVideo.path}
                                controls={true}
                                onPlayClick={this.onPlayClick}
                            />

                        </div>

                    </div>
                </form>
            </Modal>
        );
    }
}

AddMaterial.propTypes = {
    isModalOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    activeVideo: PropTypes.object,
};
const mapStateToProps = state => ({
    videoPlayerStates: getFormValues('videoPlayerModal')(state)
});

const mapDispatchToProps = dispatch => ({
    clearMaterialFilled: () => {
        const forms = ['videoPlayerModal'];
        forms.map(form => dispatch(reset(form)));
    },
    logVideo: (video) => {
        dispatch(createServiceAction('logVideo', 'requested')(video));
    },
});

export default reduxForm({ form: 'videoPlayerModal' })(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddMaterial));
