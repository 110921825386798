import React from 'react';
import find from 'lodash/find';
import brandInfo from '../../../constants/json/checkboxes/brandInfo.json';


function dateConversion(date) {
  var dateAsString1 = date;
  let convertedDate1 = '';
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if(dateAsString1 !== null){
  var dateParts = dateAsString1.split("-");
  let months1 = { Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06", Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12" };
  convertedDate1 = Number(dateParts[2]) + '-' + Number(months1[dateParts[1]]) + '-' + Number(dateParts[0]) + ' 00:00:00'

  var cellDate1 = new Date(convertedDate1);
  if (isIE || isSafari) {
    return new Date(Number(dateParts[2]), Number(months1[dateParts[1]] - 1), Number(dateParts[0]), 0, 0, 0)

  }
  return cellDate1;
}

}
const config = [
  {
    colId: 'rsl',
    headerName: 'TRF No.',
    field: 'rsl',
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    pinned: 'left',
    suppressSizeToFit: true,
    width: 135,
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: true
    },
  },
  {
    colId: 'supplierName',
    headerName: 'Supplier Name',
    field: 'supplierName',
    suppressSizeToFit: true,
    width: 270,
    suppressResize: false,
    tooltipField: 'supplierName',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'supLocName',
    headerName: 'Supplier Location',
    field: 'supLocName',
    suppressSizeToFit: true,
    width: 270,
    suppressResize: false,
    tooltipField: 'supLocName',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'matTypeName',
    headerName: 'Material Type',
    field: 'matTypeName',
    tooltipField:'matTypeName',
    suppressSizeToFit: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false,

    },
    width: 270,
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'materialName',
    headerName: 'Material Name',
    field: 'materialName',
    tooltipField: 'materialName',
    suppressSizeToFit: true,
    width: 220,
    suppressFilter: false,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'otherMaterial',
    headerName: 'Material Number',
    field: 'otherMaterial',
    suppressSizeToFit: true,
    width: 200,
    suppressMenu: true,
    suppressMovable: true,
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'materialNumber',
    headerName: 'PCX Number',
    field: 'materialNumber',
    suppressSizeToFit: true,
    width: 200,
    suppressMenu: true,
    suppressMovable: true,
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'refId',
    headerName: 'Material ID',
    field: 'refId',
    suppressSizeToFit: true,
    width: 200,
    suppressMenu: true,
    suppressMovable: true,
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'PDMNumber',
    headerName: 'PDM Number',
    field: 'PDMNumber',
    suppressSizeToFit: true,
    width: 200,
    suppressMenu: true,
    suppressMovable: true,
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'brandInfo',
    headerName: 'Brand Tested For',
    field: 'brandInfo',
    filter: 'agSetColumnFilter',
    suppressSizeToFit: true,
    width: 150,
    keyCreator: params => find(brandInfo, ['id', params.value]).value,
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false,
      textCustomComparator: function (filter, value, filterText) {
        var filterTextLoweCase = filterText.toLowerCase();
        var valueLowerCase = value.toString().toLowerCase();
        function contains(target, lookingFor) {
          if (target === null) return false;
          return ((find(brandInfo, ['id', parseInt(target)]).value).toLowerCase()).indexOf(lookingFor.toLowerCase()) >= 0;
        }
        var literalMatch = contains(valueLowerCase, filterTextLoweCase);
        return literalMatch
      }

    },
    suppressMenu: true,
    suppressMovable: true,
    cellRendererFramework: params => {
      if (find(brandInfo, ['id', params.value])) {
        return <i className={find(brandInfo, ['id', params.value]).icon} />;
      }
      return '';
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'prodTypeName',
    headerName: 'Product Type',
    field: 'prodTypeName',
    filter: 'agSetColumnFilter',
    suppressSizeToFit: true,
    width: 150,
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'labLocName',
    headerName: 'Lab ',
    field: 'labLocName',
    suppressSizeToFit: true,
    width: 200,

    filter: 'agSetColumnFilter',
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'defaultTest',
    headerName: 'Default Test',
    field: 'defaultTest',
    suppressSizeToFit: true,
    width: 170,
    suppressFilter: false,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'testingType',
    headerName: 'Testing Type',
    field: 'testingType',
    suppressSizeToFit: true,
    width: 170,
    suppressFilter: false,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'overAllStatus',
    headerName: 'Overall Test Result',
    field: 'overAllStatus',
    pinned: 'left',
    suppressSizeToFit: true,
    suppressToolPanel: true,
    width: 170,
    suppressFilter: false,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: params => {
      if (params.value && (params.value === 'Pass')) {
        return { color: '#87e300' };
      }
      else if (params.value && (params.value === 'Fail' || params.value === 'Kid Fail')) {
        return { color: '#ea723f' };
      }
      return { color: '#3498db' };
    }
  },
  {
    colId: 'labAccept',
    headerName: 'Lab Accept Date',
    field: 'labAccept',
    tooltipField: 'labAccept',
    width: 200,
    suppressResize: false,
    suppressSizeToFit: true,
    suppressFilter: false,
    filter: "agDateColumnFilter",
    comparator: function (date1, date2) {
      var cellDate1 = dateConversion(date1);
      var cellDate2 = dateConversion(date2);
      
      if (cellDate1 === null && cellDate2 === null) {
        return 0;
      }
      if (cellDate1 === null) {
        return -1;
      }
      if (cellDate2 === null) {
        return 1;
      }
      return cellDate1 - cellDate2;

    },
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: ['equals', 'inRange'],
      inRangeInclusive: true,
      comparator: function (filterLocal, cellValue) {
        /* Seems LabAcceptDate has null value, so we are checking null condition & passing only the not null values  */
        if(cellValue !== null){
        var cellDate = dateConversion(cellValue);
        if (filterLocal) {
          if (filterLocal.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocal) {
            return -1;
          }
          if (cellDate > filterLocal) {
            return 1;
          }
        }
      }
    }
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'labReceivedDate',
    headerName: 'Test Report Date',
    field: 'labReceivedDate',
    tooltipField: 'labReceivedDate',
    width: 200,
    suppressResize: false,
    suppressSizeToFit: true,
    suppressFilter: false,
    filter: "agDateColumnFilter",
    comparator: function (date1, date2) {
      var cellDate1 = dateConversion(date1);
      var cellDate2 = dateConversion(date2);

      if (cellDate1 === null && cellDate2 === null) {
        return 0;
      }
      if (cellDate1 === null) {
        return -1;
      }
      if (cellDate2 === null) {
        return 1;
      }
      return cellDate1 - cellDate2;

    },
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: ['equals', 'inRange'],
      inRangeInclusive: true,
      comparator: function (filterLocal, cellValue) {
        var cellDate = dateConversion(cellValue);
        if (filterLocal) {
          if (filterLocal.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocal) {
            return -1;
          }
          if (cellDate > filterLocal) {
            return 1;
          }
        }
      }
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'action',
    headerName: 'Status',
    field: 'action',
    pinned: 'right',
    suppressToolPanel: true,
    tooltipField: 'action',
    width: 160,
    suppressResize: false,
    suppressSizeToFit: true,
    suppressFilter: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'labRequested',
    headerName: 'Lab Service Requested',
    field: 'labRequested',
    suppressSizeToFit: true,
    width: 150,
    suppressFilter: false,
    suppressMenu: true,
    filter: 'agSetColumnFilter',
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
 

  {
    headerName: "Actions",
    field: "reviewAction",
    colId: "reviewAction",
    pinned: 'right',
    suppressToolPanel: true,
    suppressSizeToFit: true,
    width: 100,
    suppressFilter: true,
    suppressMenu: true,
    tooltipField: 'reviewAction',
    suppressMovable: true,
    cellRenderer: params => {
      if (params.data.action === 'Review' && params.data.userLabLocationId === params.data.labLocationId) {
        return `<div class="test_button"><i class="fas fa-clipboard-check"></i> Review TRF</div>`;
      }
    }
  },
  {
    headerName: " ",
    field: "proceedAction",
    colId: "proceedAction",
    pinned: 'right',
    suppressToolPanel: true,
    suppressSizeToFit: true,
    width: 180,
    suppressFilter: true,
    suppressMenu: true,
    tooltipField: 'proceedAction',
    suppressMovable: true,
    cellRenderer: params => {
      if (params.data.action === 'Review' && params.data.userLabLocationId === params.data.labLocationId) {
        return `<div class="test_button_proceed"><i class="fas fa-flask"></i> Accept TRF - Begin Testing </div>`;
      }
    }
  },
  {
    headerName: "Favorites",
    field: "favExists",
    colId: "favourites",
    pinned: 'right',
    suppressSizeToFit: true,
    width: 100,
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressFilter: true,
    suppressMenu: true,
    tooltipField: 'favourites',
    suppressMovable: true,
    cellRenderer: params => {
      return `<input type='checkbox' ${params.node.data.favExists ? 'checked' : ''} />`;
    }
  }
];
export default config;
