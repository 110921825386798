const defaultState = {
    errorClassName:false
}

let Location = {
    locationName: '',
    address1: '',
    city: '',
    country: ''
};


function isValidated(formDetails){
 if (formDetails.meta.form === 'AddLocation') {
  
    if (formDetails.meta.field.indexOf('locationName') !== -1) {
      Location.locationName = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('address1') !== -1) {
      Location.address1 = formDetails.payload;
     
    }
    if (formDetails.meta.field.indexOf('city') !== -1) {
      Location.city = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('country') !== -1) {
      Location.country = formDetails.payload;
    }
    
    
    if (
      Location.locationName !== '' &&
      Location.address1 !== '' &&
      Location.city !== '' &&
      Location.country !== ''
    ) {
     return true
    } else {
      return false
    }
}
}

function clearValues(action) {

  Location = {
    locationName: '',
    address1: '',
    city: '',
    country: ''
};

}

export default function addLocation(state=defaultState, action) {
    switch (action.type) {
      case '@@redux-form/CHANGE':
        return { ...state, errorClassName: isValidated(action) };
      case '@@redux-form/RESET':
        return { ...state, errorClassName: clearValues(action) };
      default:
        return state;
    }
}