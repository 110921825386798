import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import TrfReview from '../../trf_review/TrfReview';

class TrfInfo extends Component {
  componentDidMount() {
    const { displaySubmitButtonQrf, displayClearButtonQrf } = this.props;
    displaySubmitButtonQrf(false);
    displayClearButtonQrf(false);
  }

  render() {
    const {  trfResult,  showTitle } = this.props;
    return (
      <form className="form">
        {!isEmpty(trfResult) && (
          <TrfReview trfData={typeof trfResult !== 'undefined' ? trfResult.data : null} showTitle={showTitle}/>
        )}
      </form>
    );
  }
}

TrfInfo.defaultProps = {
  showTitle: true
};
TrfInfo.propTypes = {
  from: PropTypes.string,
  trfResult: PropTypes.object,
  displaySubmitButtonQrf: PropTypes.func,
  displayClearButtonQrf: PropTypes.func,
  showTitle: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    trfResult: state.qrf.trfDetail
  };
}

export default reduxForm({ form: 'trfinfo' })(connect(mapStateToProps)(TrfInfo));
