


const config = [
    {
        colId: 'trfId',
        headerName: 'TRF No.',
        field: 'trfId',
        suppressToolPanel: true,
        toolPanelClass: "ag-hide-toolpanel",
        pinned: 'left',
        suppressSizeToFit: true,
        width: 135,
        suppressMenu: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            defaultOption: 'contains',
            suppressAndOrCondition: true,
            caseSensitive: true
        },


    },
    {
        colId: 'supplierName',
        headerName: 'Supplier Name',
        field: 'supplierName',
        suppressSizeToFit: true,
        width: 270,
        suppressResize: false,
        tooltipField: 'supplierName',
        suppressFilter: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            defaultOption: 'contains',
            suppressAndOrCondition: true,
            caseSensitive: false
        },
        suppressMenu: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'supLocName',
        headerName: 'Supplier Location',
        field: 'supLocName',
        suppressSizeToFit: true,
        width: 270,
        suppressResize: false,
        tooltipField: 'supLocName',
        suppressFilter: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            defaultOption: 'contains',
            suppressAndOrCondition: true,
            caseSensitive: false
        },
        suppressMenu: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'materialType',
        headerName: 'Material Type',
        field: 'materialType',

        suppressSizeToFit: true,
        filter: 'agTextColumnFilter',
        width: 270,
        suppressMenu: true,
        suppressMovable: true,

        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'material',
        headerName: 'Material Name',
        field: 'material',
        tooltipField: 'material',
        suppressSizeToFit: true,
        width: 220,
        suppressFilter: false,
        suppressMenu: true,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            defaultOption: 'contains',
            suppressAndOrCondition: true,
            caseSensitive: false
        },
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'otherMaterial',
        headerName: 'Material Number',
        field: 'otherMaterial',
        suppressSizeToFit: true,
        width: 200,
        suppressMenu: true,
        suppressMovable: true,
        suppressFilter: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          suppressAndOrCondition: true,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        }
      },
      {
        colId: 'materialNumber',
        headerName: 'PCX Number',
        field: 'materialNumber',
        suppressSizeToFit: true,
        width: 200,
        suppressMenu: true,
        suppressMovable: true,
        suppressFilter: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          suppressAndOrCondition: true,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        }
      },
      {
        colId: 'refId',
        headerName: 'Material ID',
        field: 'refId',
        suppressSizeToFit: true,
        width: 200,
        suppressMenu: true,
        suppressMovable: true,
        suppressFilter: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          suppressAndOrCondition: true,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        }
      },
      {
        colId: 'PDMNumber',
        headerName: 'PDM Number',
        field: 'PDMNumber',
        suppressSizeToFit: true,
        width: 200,
        suppressMenu: true,
        suppressMovable: true,
        suppressFilter: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          suppressAndOrCondition: true,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        }
      },
    {
        colId: 'brand',
        headerName: 'Brand Tested For',
        field: 'brand',
        filter: 'agSetColumnFilter',
        suppressSizeToFit: true,
        width: 150,
        suppressMenu: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'product',
        headerName: 'Product Type',
        field: 'product',
        filter: 'agSetColumnFilter',
        suppressSizeToFit: true,
        width: 150,

        suppressMenu: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'labLocation',
        headerName: 'Lab ',
        field: 'labLocation',
        suppressSizeToFit: true,
        width: 200,
        //filter: 'agSetColumnFilter',
        //filterParams: { selectAllOnMiniFilter: true },


        suppressMenu: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'defaultTest',
        headerName: 'Default Test',
        field: 'defaultTest',
        suppressSizeToFit: true,
        width: 170,
        suppressFilter: false,
        suppressMenu: true,
        suppressMovable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            defaultOption: 'contains',
            caseSensitive: false
        },
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'overAllStatus',
        headerName: 'Overall Test Result',
        field: 'overAllStatus',
        pinned: 'left',
        suppressSizeToFit: true,
        suppressToolPanel: true,
        width: 170,
        suppressFilter: false,
        suppressMenu: true,
        suppressMovable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            defaultOption: 'contains',
            caseSensitive: false
        },
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        cellStyle: params => {
            if (params.value && (params.value === 'Pass')) {
                return { color: '#87e300' };
            }
            else if (params.value && (params.value === 'Fail' || params.value === 'Kid Fail')) {
                return { color: '#ea723f' };
            }
            return { color: '#3498db' };
        }
    },
    {
      colId: 'testingType',
      headerName: 'Testing Type',
      field: 'testingType',
      tooltipField: 'testingType',
      width: 200,
      suppressResize: false,
      suppressSizeToFit: true,
      suppressFilter: false,
      suppressMenu: true,
      suppressMovable: true,
      icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
      }
  },
    {
        colId: 'labRecDate',
        headerName: 'Test Report Date',
        field: 'labRecDate',
        tooltipField: 'labRecDate',
        width: 200,
        suppressResize: false,
        suppressSizeToFit: true,
        suppressFilter: false,
        suppressMenu: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'statusValue',
        headerName: 'Status',
        field: 'statusValue',
        pinned: 'right',
        suppressToolPanel: true,
        tooltipField: 'action',
        width: 160,
        suppressResize: false,
        suppressSizeToFit: true,
        suppressFilter: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultOption: 'contains',
          caseSensitive: false
        },
        suppressMenu: true,
        suppressMovable: true,
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        }
      },
    {
        colId: 'serviceRequested',
        headerName: 'Lab Service Requested',
        field: 'serviceRequested',
        suppressSizeToFit: true,
        width: 150,
        suppressFilter: false,
        suppressMenu: true,
        filter: 'agSetColumnFilter',
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
      colId: 'labRefNum',
      headerName: 'Lab Reference Number',
      field: 'labRefNum',
      tooltipField: 'labRefNum',
      suppressSizeToFit: true,
      width: 200,
      suppressMenu: true,
      suppressResize: false,
      suppressMovable: true,
      suppressFilter: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        caseSensitive: false
      },
      icons: {
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      }
  },
    {
        headerName: "Favorites",
        field: "favExists",
        colId: "favourites",
        pinned: 'right',
        suppressSizeToFit: true,
        width: 100,
        suppressToolPanel: true,
        toolPanelClass: "ag-hide-toolpanel",
        suppressFilter: true,
        suppressMenu: true,
        tooltipField: 'favourites',
        suppressMovable: true,
        cellRenderer: params => {
          return `<input type='checkbox' ${params.node.data.favExists ? 'checked' : ''} />`;
        }
    },
    {
        headerName: "Active/De-Activate",
        field: "isActive",
        colId: "activeStatus",
        pinned: 'right',
        suppressSizeToFit: true,
        width: 150,
        suppressToolPanel: true,
        toolPanelClass: "ag-hide-toolpanel",
        suppressFilter: true,
        suppressMenu: true,
        tooltipField: 'activeStatus',
        suppressMovable: true,
        cellRenderer: params => {
        //   return `<input type='checkbox' ${params.node.data.favExists ? 'checked' : ''} />`;
        return `<label class="switch">
        <input type="checkbox" ${params.node.data.isActive ? 'checked' : ''} >
        <span class="slider round"></span>
      </label>`
        }
    },
    {
        headerName: "Notes",
        field: "notes",
        colId: "notes",
        pinned: 'right',
        suppressToolPanel: true,
        suppressSizeToFit: true,
        width: 100,
        suppressFilter: true,
        suppressMenu: true,
        tooltipField: 'notes',
        suppressMovable: true,
        cellRenderer: params => {
         
            return `<div class="grid_comments_button mt1-sm"><i class="fas fa-comments"></i> </div>`;
          
        }
      },
];
export default config;
