import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { H6, ConfirmationBox, Container } from '../../../components/index.js';
import {  formatDate } from '../../../util/index.js';
import { createServiceAction } from '../../../util/actionHelper.js';

class LabInputs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      fileName: ''
    };
    this.openConfirmationPopup = this.openConfirmationPopup.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  openConfirmationPopup = (fileName) => {
    this.setState({ submitModal: true, fileName: fileName });
  }

  downloadFile = (requestParams) => {
    const { downloadAttachment } = this.props;
    downloadAttachment({ ...requestParams, formType: 'lab' });
  }

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  render() {
    const { trfDetail, isLabUser, isRslUser } = this.props;
    
    const { fileName, submitModal } = this.state;
    return (
      <div>
        <Container section className="p0-top-sm m0-sm lab">
        
        <div className="ncss-row ">
              <div className="ncss-col-sm-offset-1 ncss-col-sm-2 description u-bold p2-sm ">Lab Reference Number:</div>
              <div className="ncss-col-sm-3 u-bold ">
                <H6 className="pl0-sm ">
                  {get(trfDetail, 'data.trfDetails.labRefNum') === null ? 'Not Added' : get(trfDetail, 'data.trfDetails.labRefNum')}
                </H6>
              </div>

          {isRslUser || isLabUser  ? (
            <Fragment>
            <div className="ncss-col-sm-offset-1 ncss-col-sm-2 description u-bold p2-sm ">Lab Accept Date:</div>
            <div className="ncss-col-sm-3  u-bold ">
              <H6 className="pl0-sm">
                {
                get(trfDetail, 'data.trfDetails.labAccept') !== null ?
                get(trfDetail, 'data.trfDetails.labAccept', '') && 
                formatDate(get(trfDetail, 'data.trfDetails.labAccept'))
              : 'Not Available'}
              </H6>
            </div></Fragment> ) : (
            <Fragment>
            <div className="ncss-col-sm-offset-1 ncss-col-sm-2 description u-bold p2-sm ">Test Report Date:</div>
            <div className="ncss-col-sm-3  u-bold ">
              <H6 className="pl0-sm">
                {get(trfDetail, 'data.trfDetails.labRecDate', '') 
                && formatDate(get(trfDetail, 'data.trfDetails.labRecDate'))}
              </H6>
            </div>
            </Fragment>
            )

          }
        </div>

        <div className="ncss-row ">
        <Fragment>
        <div className="ncss-col-sm-offset-1 ncss-col-sm-2 description u-bold p2-sm ">Comments:</div>
            <div className="ncss-col-sm-3  u-bold ">
              <H6 className="pl0-sm">
                {get(trfDetail, 'data.trfDetails.labComment') === null ? 'Not Added' : get(trfDetail, 'data.trfDetails.labComment')}
              </H6>
            </div>
          </Fragment>
        {isLabUser || isRslUser  ? (
          <Fragment>
          <div className="ncss-col-sm-offset-1 ncss-col-sm-2 description u-bold p2-sm ">Test Report Date:</div>
          <div className="ncss-col-sm-3  u-bold ">
             <H6 className="pl0-sm">
                {get(trfDetail, 'data.trfDetails.labRecDate', '') 
                && formatDate(get(trfDetail, 'data.trfDetails.labRecDate'))}
              </H6>
          </div>
          </Fragment>
         ) : ''}
        </div>
        <Fragment>
          <div className="ncss-row ">
            
            <div className="ncss-col-sm-offset-1 ncss-col-sm-2 description u-bold p2-sm ">Attachments:</div>
            <div className="ncss-col-sm-3 u-bold ">
              <H6 className="pl0-sm ">
                {get(trfDetail, 'data.labAttachmentDetails', []).length > 0 ?
                  get(trfDetail, 'data.labAttachmentDetails', []).map(data => (
                    <div key={data.fileName}>
                      <div className="tooltip"> {data.fileName.length > 10 ? data.fileName.substring(0, 10) + '...' : data.fileName}
                        <span className="tooltiptext" >{data.fileName}</span>
                      </div>
                      <div className="inline-flex-display download_button" title="Download file" onClick={() => { this.openConfirmationPopup(data.fileName) }}>
                        <i className="fa fa-download"></i>
                      </div>
                    </div>))
                  : 'Not Added'
                }
              </H6>
            </div>
          </div></Fragment>
        </Container>
        <ConfirmationBox
          message={`Do you want to download the ${fileName}?`}
          submitModal={submitModal}
          submitValue={
            () => {
              this.downloadFile({ id: get(trfDetail, 'data.trfDetails.trfId'), fileName: fileName });
            }
          }
          closeModal={this.closeSubmitModal}
        />
      </div>
    );
  }
}

LabInputs.propTypes = {
  trfDetail: PropTypes.object,
  downloadAttachment: PropTypes.func
};


const mapDispatchToProps = dispatch => ({
  downloadAttachment: data => {
    dispatch(createServiceAction('downloadAttachment', 'requested')(data));
  }
});


export default connect(
  null,
  mapDispatchToProps
)(LabInputs);

