import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Container, ContentPopup, ProgressBar, Spinner } from '../../components/index.js';
import Grid from '../../components/grid/Grid.js';
import supplierGridConfig from '../../configs/gridConfigs/userPage/supplierGridConfig.js';
import UserInfoGrid from '../userInfoGrid/userInfoGrid.js';
import { toggleAddMaterailModal } from '../../actions/trfActions.js';
import { clearEditLocationList } from '../../actions/adminUserActions.js';
import AddLocation from './AddLocation/AddLocation';
import { createServiceAction } from '../../util/actionHelper.js';
import { history } from '../../util/index.js';
import { getsuppliersData, supplierAllLoadFinish, getTotalPages, getSuppliersNextPage} from '../../reducers/listAllSupplierReducer.js';
import { isRslUser } from '../../reducers/authReducer.js';

import AddSupplierModal from '../userInfoGrid/addSupplier/AddSupplier.js';


class AddSupplier extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      isOpenSupplierList: false,
      status: null,
      activeNewSupplierAdd: true,
      activeEditLocation: false,
      activeEditSupplier: false,
      locationId: null,
      locationName: null,
      supplierId: null,
      supplierModal: false
    };

    this.closeSupplierListModal = this.closeSupplierListModal.bind(this);
    this.openSupplierListModal = this.openSupplierListModal.bind(this);
    this.newSupplierAdd = this.newSupplierAdd.bind(this);
    this.editLocationButton = this.editLocationButton.bind(this);
    this.cancelAddSupplier = this.cancelAddSupplier.bind(this);
    this.userInfoFetch = this.userInfoFetch.bind(this);

  }
  componentDidMount() {
    const { clearEditLocationList, requestAllSupplierList } = this.props;
    clearEditLocationList();
    requestAllSupplierList();

  }

 
  filterUpdateLocationInfo = (supplierInfo, locationId, locationName) => {
    const resultArray = {}
    const { updateLocationData } = this.props;
    resultArray.locId = locationId;
    resultArray.locName = locationName;
    resultArray.addr1 = supplierInfo.address1;
    resultArray.addr2 = supplierInfo.address2;
    resultArray.city = supplierInfo.city;
    resultArray.country = supplierInfo.country;

    updateLocationData(resultArray);

  }
  userInfoFetch = (userId) => {
    const { userInfoById } = this.props;
    userInfoById(userId)
  }
  openSupplierModal = (status) =>{
    this.setState({supplierModal:status})
  }

  onRowClick = params => {
    const { change, requestAddSupplierLocationList } = this.props;
    if (params.data.supplierID) {
      requestAddSupplierLocationList(params.data.supplierID)
      this.setState({
        supplierId: params.data.supplierID
      })
    }

    this.closeSupplierListModal();
    change('supplierName', params.data.supplierName ? params.data.supplierName : '');
    change('supplierID', params.data.supplierID ? params.data.supplierID : '');
    change('supplierCompanyType', params.data.companyType ? params.data.companyType : '');
  };


  closeSupplierListModal = () => {
    this.setState({ isOpenSupplierList: false });
  };

  openSupplierListModal = () => {
    const { change } = this.props;
    change('supplierCompanyType', '');
    change('locationName', [{ id: '' }])
    change('address1', '');
    change('address2', '');
    change('country', '');
    change('city', '');
    this.setState({ isOpenSupplierList: true });
  };

  newSupplierAdd = (status) => {
    this.setState({ activeNewSupplierAdd: status, activeEditLocation: false });

  }
  editLocationButton = (status) => {
    this.setState({ activeNewSupplierAdd: false, activeEditLocation: status });
  }
  cancelAddSupplier = () => {

    history.push('/');
  };

  createNewUser = () => {
    this.setState({ showAddUserModal: true });
  }

  toggleCreateUserModal = () => {
   
    this.setState(prevState => ({ supplierModal: !prevState.supplierModal }))
  }

  supplierStatusChange = (id,status) => {
    const { setSupplierStatus } = this.props;

    setSupplierStatus({id:id,status:!(status)})

  }

  render() {

    const { isAddMaterialModalOpen, toggleAddMaterailModal,supplierData, suppliersData, addSupplierForm, errorStatusUpdateSupplier, totalPages, nextPage,loadFinish, isRslUser } = this.props;
    const { isOpenSupplierList, activeNewSupplierAdd, activeEditLocation, locationId, locationName, supplierId, supplierModal } = this.state;
    return (
      <div >
        {suppliersData && suppliersData.data  ? '' : (<Spinner large />)}
        <Container section className="p4-sm ml1-sm mr1-sm mt2-sm tab-class-container tab-class">
          <div className="ncss-col-sm-12 ncss-input-container">
            <span>
            {suppliersData&&suppliersData.count&&suppliersData.data&&
                            nextPage<=totalPages && !loadFinish?
                  <div className="ncss-col-sm-12 progress_bar">
                    <ProgressBar percent={(nextPage / totalPages) * 100}
                      message={"Loading"} />
                  </div>
                  : null
                }
            </span>
            <div className="ncss-col-sm-12 align-left">
              <div className="pb3-sm" >
              <div className="ncss-row">
                  <div className="ncss-col-sm-12">
                    <UserInfoGrid
                      gridData={suppliersData && suppliersData.data ? suppliersData.data : []}
                      useFor="EditSupplier"
                      overlayLoadingTemplate="<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                      isRslUser={isRslUser}
                      supplierStatusChange={this.supplierStatusChange}
                    />
                  </div>
                </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  </Container>
  {isAddMaterialModalOpen &&
    <AddLocation
      supplierData={supplierId}
      isModalOpen={isAddMaterialModalOpen}
      toggleModal={toggleAddMaterailModal}
    />
  }
  <ContentPopup
    title="Select Supplier:"
    isOpen={isOpenSupplierList}
    closeModal={this.closeSupplierListModal}
    content={
      <Grid rowData={supplierData} columnDefs={supplierGridConfig} onRowClick={this.onRowClick}
        customNoRowTemplete={
          supplierData === undefined
            ? "<span class='ag-overlay-loading-center'>No results found</span>"
            : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
        }
      />
    }
  />
  {activeNewSupplierAdd || activeEditLocation ?
    <div className="ncss-col-sm-12 ">
      {activeEditLocation ?
        <div className="bottom-section p2-top-sm">
          <Button className="ncss-btn-secondary-dark u-bold secondary-button add-supplier-location-buttton"
            onClick={this.cancelAddSupplier}>
            CANCEL
              </Button>
          <span className={`${(supplierData && addSupplierForm) && addSupplierForm.supplierID ? '' : 'cursor-not-allowed'}`}>
            <Button
              className={`ncss-btn-primary-dark u-bold submit-button-enable  new-material add-supplier-location-buttton ${(supplierData && addSupplierForm) && addSupplierForm.supplierID ? 'mr2-sm' : 'disabled'}`}
              onClick={() => toggleAddMaterailModal(true)}>
              ADD LOCATION
                </Button>
          </span>
          {locationId ?
            <span className={`${(typeof errorStatusUpdateSupplier === 'undefined' || errorStatusUpdateSupplier === true) ? 'cursor-not-allowed' : ''}`}>
              <Button className={`ncss-btn-primary-dark u-bold submit-button-enable u-bold new-material add-supplier-location-buttton ${(typeof errorStatusUpdateSupplier === 'undefined' || errorStatusUpdateSupplier === true) ? 'disabled' : ''}`}
                onClick={() => this.filterUpdateLocationInfo(addSupplierForm, locationId, locationName)}>
                UPDATE LOCATION
              </Button>
            </span>
            :
            null
          }
        </div>
        :
        <div className="ncss-col-sm-12 align-center">
          <Button className="ncss-btn-secondary-dark u-bold secondary-button add-supplier-location-buttton"
            onClick={this.cancelAddSupplier}>
            CANCEL
                </Button>
          <span>
            <Button className={`ncss-btn-grey u-bold new-material add-supplier-location-buttton btn btn-default dropdown-toggle `}
              onClick={() => this.openSupplierModal(true)}>
              ADD SUPPLIER LOCATION
                </Button>
          </span>
        </div>
      }
    </div>
    : null}
     <AddSupplierModal 
          isModalOpen={supplierModal}
          isAdd={true}
          toggleModal={this.toggleCreateUserModal} 
         // userType={userType}
        />
</div>
);
} 
}

AddSupplier.propTypes = {
  toggleAddMaterailModal: PropTypes.func,
  isAddMaterialModalOpen: PropTypes.bool,
  requestSupplierList: PropTypes.func,
  history: PropTypes.object
};
const mapStateToProps = state => ({
  allData: state,
  isAddMaterialModalOpen: state.trf.isAddMaterialModalOpen,
  supplierData: state.adminUser.supplierData,
  supplierLocationList: state.adminUser.supplierLocationList,
  locationDetails: state.adminUser.locationDetails,
  addSupplierForm: getFormValues('addSupplier')(state),
  errorStatusAddSupplier: state.adminUser.errorStatusAddSupplier,
  errorStatusUpdateSupplier: state.adminUser.errorStatusUpdateSupplier,
  isRslUser: isRslUser(state),
  suppliersData: getsuppliersData(state),
  totalPages:getTotalPages(state),
  nextPage: getSuppliersNextPage(state),
  loadFinish : supplierAllLoadFinish(state)
});

const mapDispatchToProps = dispatch => ({
  toggleAddMaterailModal: isOpen => {
    dispatch(toggleAddMaterailModal(isOpen));
  },

  requestAddSupplierLocationList: supplierId => {
    dispatch(createServiceAction('addSupplierLocationSearch', 'requested')(supplierId));
  },

  setSupplierStatus:(data) => {
    dispatch(createServiceAction('setSupplierStatus', 'submitted')(data));
  },

  clearEditLocationList: () => {
    dispatch(clearEditLocationList());
  },
  submitSupplierData: (data) => {
    dispatch(createServiceAction('addSupplier', 'submited')({ ...data }));
  },

  updateLocationData: (data) => {
    dispatch(createServiceAction('locationUpdate', 'submitted')({ ...data }));
  },

  requestLocationDetails: locationId => {
    dispatch(createServiceAction('locationDetails', 'requested')(locationId));
  },

  requestSupplierList: searchTerm => {
    dispatch(createServiceAction('addsupplierSearch', 'requested')(searchTerm));
  },

  requestAllSupplierList: () =>{
    dispatch(createServiceAction('listSuppliers','requested')())
  }
});

export default compose(reduxForm({ form: 'addSupplier' }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(AddSupplier);
