import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Container, PageSpinner, H1, Button } from '../../components/index.js';
import { requestUserLogin } from '../../actions/authActions.js';
import { userLoggedIn, hasAuthCheckFinished } from '../../reducers/authReducer.js';

const mapProps = state => ({
  isLoggedIn: userLoggedIn(state),
  loginFinished: hasAuthCheckFinished(state)
});

const LoginRequired = withRouter(
  connect(
    mapProps,
    { requestUserLogin }
  )(({ isLoggedIn, loginFinished, title, requestUserLogin, children }) => {
    if (!loginFinished) return <PageSpinner title={title} noHeader />;
    if (!isLoggedIn) {
      return (
        <div className="page-align-items">
          <Container fluid>
            <H1 className="u-sm-ib u-va-m">You must be logged in to view this page</H1>
            <Button extraClassName="m2-sm u-sm-ib u-va-m" onClick={requestUserLogin}>
              Login
            </Button>
          </Container>
        </div>
      );
    }

    return React.Children.only(children);
  })
);

const requireLogin = title => WrappedComponent => props => (
  <LoginRequired title={title}>
    <WrappedComponent {...props} />
  </LoginRequired>
);

export { LoginRequired };
export default requireLogin;
