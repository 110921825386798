import React from 'react';
import { arc as d3Arc, pie as d3Pie } from 'd3-shape';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';

import { H4 } from '../index.js';

const DonutChart = ({ chartData, title }) => {
  const width = 480;
  const height = 205;

  const radius = Math.min(width, height) / 2;

  const arc = d3Arc()
    .outerRadius(radius - 20)
    .innerRadius(radius - 40);

  const pie = d3Pie()
    .sort(null)
    .value(d => d.value)
    .padAngle(0.03);

  const data = pie(
    chartData.map(d => {
      d.value = +d.value;
      return d;
    })
  );

  // Check if all values are 0, no need to display graph if all values are 0
  const isAllEmpty = findIndex(chartData, d => d.value > 0);

  const Months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const date = new Date();
  const outputDate = `${date.getDate()} ${Months[date.getMonth()]} ${date.getFullYear()}`;

  return (
    <div className="donut">
      <H4 className="title">{title}</H4>
      {isAllEmpty !== -1 ? (
        <svg
          width="100%"
          height="100%"
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio="xMidYMid meet"
        >
          {/* Chart Legend */}
          <g transform={`translate(0, ${height / 3})`}>
            {data.map((d, i) => (
              <g transform={`translate(0,${i * 50})`} key={`l${d.data.label}`}>
                <rect width="15" height="15" fill={d.data.color} />
                <text x="30" y="15" className="legend-label">
                  {d.data.label}
                </text>
                <text x="160" y="17" className="legend-label-lg">
                  {d.data.value}
                </text>
              </g>
            ))}
          </g>
          {/* Chart */}
          <g transform={`translate(${width - 100}, ${height / 2})`}>
            {data.map(d => (
              <g key={`a${d.data.label}`}>
                <path d={arc(d)} fill={d.data.color} />
              </g>
            ))}
            <text textAnchor="middle" y="5" className="center-text">
              {outputDate}
            </text>
          </g>
        </svg>
      ) : (
        'No data to compare'
      )}
    </div>
  );
};

DonutChart.propTypes = {
  chartData: PropTypes.array,
  title: PropTypes.string
};

export default DonutChart;
