import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, reset } from 'redux-form';

import requireLogin from '../../auth/requireLogin.js';
import columnDefs from '../../../configs/gridConfigs/notifications/notificationConfig.js';
import {
    Button,
    Container,
    PageTitle,
    PageHeader,
    TextInput,
    MultiSelect,
    DatePicker,
    Collapsible,
    ConfirmationBox,
    H5
} from '../../../components/index.js';
import Grid from '../../../components/grid/Grid.js';
import Label from '../../../components/ncss/Label';
import usertypes from '../../../constants/json/usertypes/usertypes.json';
import { createServiceAction } from '../../../util/actionHelper.js';
import { convertDateToDBFormat } from '../../../util/index.js'
import EditNotification from '../editNotifications/editNotifications.js'


const style = {
    fontWeight: '800',
    fontSize: '1.8rem',
    lineHheight: '1.5',
    color: '#0078B9'
};


export class CreateNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            initialValue: {},
            submitModal: false,
            deleteId: null
        };
        this.hidden = {
            display: 'none'
        };

    }

    onCellClicked = (params) => {
        const { deleteNotification } = this.props;
        const colId = params.api.getFocusedCell().column.colId
        const statusValue = params.data.notificationID;
        if (colId === 'deleteNotify') {
            deleteNotification(statusValue);
        }
    }

    submitNotificationData = () => {
        const { notificationInfo, createNotification } = this.props;
        const reqObj = {};
        reqObj.startDate = convertDateToDBFormat(notificationInfo.startDate) || '';
        reqObj.endDate = convertDateToDBFormat(notificationInfo.dateOfCompletion) || '';
        reqObj.message = notificationInfo.message || '';
        // reqObj.isActive = true;
        if (notificationInfo.userTypes) {
            notificationInfo.userTypes = notificationInfo.userTypes
                ? notificationInfo.userTypes.map(item => {
                    return item.value;
                })
                : [];
        }
        reqObj.userGroups = notificationInfo.userTypes;
        createNotification(reqObj);
    }

    shouldEnableSubmit = () => {
        const { notificationInfo } = this.props;
        if (notificationInfo) {
            if (notificationInfo.startDate && notificationInfo.dateOfCompletion && notificationInfo.message && notificationInfo.userTypes) {
                return false;
            }
        }
        return true;
    }
    onCellClicked = (params) => {
        const colId = params.api.getFocusedCell().column.colId
        let defaultUserTypes = []
        let values = {}

        if (colId === 'editNotify') {
            if (params.data.userGroups) {
                defaultUserTypes = usertypes.filter(e => params.data.userGroups.includes(e.value))
                values = {
                    notificationId: params.data.id,
                    startDate: params.data.startDate,
                    endDate: params.data.endDate,
                    message: params.data.message,
                    userTypes: defaultUserTypes
                }
            }

            this.setState({ editModal: true, initialValue: values })
        }
        else if (colId === 'deleteNotify') {

            this.setState({ deleteId: params.data.id })
            this.confirmationBoxOpen(true);
        }
    }
    closeEditModal = () => {
        this.setState({ editModal: false });
    };

    confirmationBoxOpen = (modalopen) => {
        this.setState({ submitModal: modalopen });
    };

    closeSubmitModal = () => {
        this.setState({ submitModal: false });
    };

    render() {
        const { clearNotificationFields, history, notifications, deleteNotification } = this.props;
        const { editModal, initialValue, submitModal, deleteId } = this.state;
        return (
            <span>
                {notifications ?
                    <div className="notification-page pb2-sm">
                        <div className="page-align-items">
                            <PageTitle title="Create Notification" />
                            <PageHeader title="Create Notification">
                                <div className="user-page ie-icon-align">
                                    <i class="fas fa-bell"></i>
                                </div>
                            </PageHeader>
                            <Container section>


                                {notifications &&// notifications.length <= 10 ?
                                    <span>
                                        <div className="ncss-row p2-bottom-sm">
                                            <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size-notification align-center">Start Date: <span className="astericks">&#42;</span></Label>
                                            <div className='ncss-col-sm-3'>
                                                <Field
                                                    component={DatePicker}
                                                    defaultValue={null}
                                                    showTime={false}
                                                    name="startDate"
                                                    minDate={new Date()}
                                                />
                                            </div>
                                            <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size-notification text-size-notification-date align-center">End Date: <span className="astericks">&#42;</span></Label>
                                            <div className='ncss-col-sm-3'>
                                                <Field
                                                    component={DatePicker}
                                                    showTime={false}
                                                    defaultValue={null}
                                                    name="dateOfCompletion"
                                                    minDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                        <div className="ncss-row p2-bottom-sm">
                                            <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size-notification align-center">User Types: <span className="astericks">&#42;</span></Label>
                                            <div className="ncss-col-sm-4">
                                                <Field
                                                    component={MultiSelect}
                                                    name="userTypes"
                                                    type="text"
                                                    options={usertypes}
                                                    placeholder="Select user types"
                                                    containerClass="u-full-width"
                                                />
                                            </div>
                                            <div className="ncss-col-sm-12 ncss-form-group p2-bottom-sm p2-top-sm text-area-container-notification pl6-sm text-size-notification">
                                                <H5 className="u-bold description p2-top-sm pb2-sm add-supplier-text">Notification Text: <span className="astericks">*</span></H5>
                                                <Field
                                                    component={TextInput}
                                                    placeholder="Enter message"
                                                    type="textarea"
                                                    name="message"
                                                    className="ncss-col-sm-12 u-bold description p2-top-sm"
                                                />
                                            </div>

                                        </div>
                                    </span>
                                    // :
                                    // <H5 className="align-center u-bold error-text">Number of notifications morethan 10 .Please delete some notifications from Notification List</H5>
                                }
                                <div className="pl3-sm">
                                    <Collapsible
                                        trigger={
                                            <div className="ncss-col-sm-12 collapse-p0">
                                                <div className="ncss-col-sm-11 collapse-p0">NOTIFICATIONS LIST</div>
                                                <div className="ncss-col-sm-1 ">
                                                    <i className="fas fa-caret-down fa-pull-right" />
                                                </div>
                                            </div>
                                        }
                                        triggerWhenOpen={
                                            <div className="ncss-col-sm-12 collapse-p0">
                                                <div className="ncss-col-sm-11 collapse-p0">NOTIFICATIONS LIST</div>
                                                <div className="ncss-col-sm-1">
                                                    <i className="fas fa-caret-up fa-pull-right" />
                                                </div>
                                            </div>
                                        }
                                        triggerStyle={style}
                                        triggerClassName="collapsible"
                                    >
                                        <H5 className="u-bold description">List of all notifications </H5>
                                        <div className="ncss-row">
                                            <div className="ncss-col-sm-12">
                                                <Grid
                                                    columnDefs={columnDefs}
                                                    rowData={notifications}
                                                    showFilter={false}
                                                    onCellClicked={this.onCellClicked.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </Collapsible>
                                </div>

                            </Container>
                            {notifications &&// notifications.length <= 10 ?
                                <div className="ncss-col-sm-12 align-center">
                                    <Button onClick={() => { history.push('/'); }}
                                        className="ncss-btn-primary-dark u-bold secondary-button update-notification-buttton"

                                    >
                                        CANCEL
                            </Button>
                                    <Button
                                        // className="ncss-btn-grey u-bold new-material update-exception-buttton"
                                        className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected ${
                                            this.shouldEnableSubmit() ? 'disabled' : ''
                                            }`}
                                        onClick={() => { this.submitNotificationData(); clearNotificationFields(); }}
                                    >
                                        SUBMIT
                            </Button>
                                </div>
                                // :
                                // null
                            }
                        </div>
                        <ConfirmationBox
                            message={
                                'Are you sure you want to delete Notification?'

                            }
                            submitModal={submitModal}
                            submitValue={() => {
                                deleteNotification(deleteId);
                            }

                            }
                            closeModal={this.closeSubmitModal}
                        />
                        <EditNotification
                            editModal={editModal}
                            {...({ initialValues: initialValue })}
                            closeModal={this.closeEditModal} />
                    </div>
                    :
                    null}
            </span>
        );
    }
}

CreateNotification.propTypes = {

};

const mapStateToProps = state => ({
    notificationInfo: getFormValues('CreateNotification')(state),
    notifications: state.listNotification.notificationDetails
});

const mapDispatchToProps = dispatch => ({
    createNotification: data => {
        dispatch(createServiceAction('createNotification', 'requested')({ formValues: data }));
    },
    deleteNotification: id => {
        dispatch(createServiceAction('deleteNotification', 'requested')(id));
    },
    clearNotificationFields: () => {
        const forms = ['CreateNotification'];
        forms.map(form => dispatch(reset(form)));
    }
});

export default compose(
    requireLogin('User'),
    reduxForm({
        form: 'CreateNotification'
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CreateNotification);
