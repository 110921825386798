import { fromJS } from 'immutable';
import get from 'lodash/get';

import { startAuthCheck, finishAuthCheck, setUserLogin, setUserLogout, checkUserExist, checkValidGroup, setAuthCheckError, locationReinitilized, materialReinitilized } from '../actions/authActions.js';
import { clearFormValueTRF } from '../actions/trfActions.js';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  hasAuthCheckStarted: false,
  hasAuthCheckFinished: false,
  isLoading: false,
  loginError: null,
  userToken: null,
  count: 200,
  materialNextPage: 1,
  token: null,
  loginAuthFinish:false,
  logoutUser:false,
  userExist:true,
  validGroup:true

});

export default function auth(state = defaultState, action) {
  state = fromJS(state); // Force immutable in case of rehydration
  switch (action.type) {
    case startAuthCheck.type:
      return state.set('hasAuthCheckStarted', true).set('hasAuthCheckFinished', false);
    case setAuthCheckError.type:
      return state.set('hasAuthCheckFinished', true).set('loginError', action.payload);
    case finishAuthCheck.type:
      return state.set('hasAuthCheckFinished', true);
    case setUserLogin.type:

      return state.set('userToken', action.payload && action.payload.accessToken ?fromJS(action.payload) : null)
                  .set('loginAuthFinish', false)
                  //.set('logoutUser', action.payload.accessToken ? false:true);
    case setUserLogout.type:
      return state.set('logoutUser', action.payload);
    case checkUserExist.type:
      return state.set('userExist',action.payload);
    case checkValidGroup.type:
      return state.set('validGroup', action.payload)

    case locationReinitilized.type:
      return state.set('materialList', []).set('materialRequestCompleted', false);
    case materialReinitilized.type:
        return state.set('materialList', undefined).set('materialRequestCompleted', false);  
    case createServiceAction('getUserProfile', 'success').type:
      return state
        .setIn(['userToken', 'userId'], get(action.data, 'data.userId', ''))
        .setIn(['userToken', 'savedUserType'], get(action.data, 'data.userType', ''))
        .setIn(
          ['userToken', 'labId'],
          state.getIn(['userToken', 'group']) === 'Labs' ? get(action.data, 'data.labId', '') : ''
        )
        .setIn(
          ['userToken', 'labLocationId'],
          state.getIn(['userToken', 'group']) === 'Labs' ? get(action.data, 'data.labLocationId', '') : ''
        )
        .setIn(
          ['userToken', 'supplierId'],
          state.getIn(['userToken', 'group']) === 'Suppliers' ||
            state.getIn(['userToken', 'group']) === 'Factories'
            ? get(action.data, 'data.supplierId', '')
            : ''
        )
        .setIn(
          ['userToken', 'supplierName'],
          state.getIn(['userToken', 'group']) === 'Suppliers' ||
            state.getIn(['userToken', 'group']) === 'Factories'
            ? get(action.data, 'data.supplierName', '')
            : ''
        )
        .setIn(
          ['userToken', 'contact'],
          state.getIn(['userToken', 'group']) === 'Suppliers' ||
            state.getIn(['userToken', 'group']) === 'Factories' ||
            state.getIn(['userToken', 'group']) === 'Labs'
            ? get(action.data, 'data.contact', '')
            : ''
        )
        .setIn(['userToken', 'favourites'], get(action.data, 'data.favtrf', ''))
        .setIn(['userToken', 'userInactive'], get(action.data, 'data.userInactive', false))
        .setIn(['userToken', 'deactivated'], get(action.data, 'data.deactivated', false))
        .setIn(['userToken', 'profileUpdated'], get(action.data, 'data.profileUpdated', ''))
        .set('loginAuthFinish', true)
        .set('hasAuthCheckFinished', true)
        .setIn(['userToken', 'OSHId'], get(action.data, 'data.OSHId', ''))
        .setIn(['userToken', 'OSHStatus'], get(action.data, 'data.OSHStatus', ''))
        .setIn(['userToken', 'facilityName'], get(action.data, 'data.facilityName', ''))
        .setIn(['userToken', 'addr1'], get(action.data, 'data.addr1', ''))
        .setIn(['userToken', 'country'], get(action.data, 'data.country', ''))
        .setIn(['userToken', 'city'], get(action.data, 'data.city', ''))
        .setIn(['userToken', 'parentCompany'], get(action.data, 'data.parentCompany', ''))
        .setIn(['userToken', 'userType'], get(action.data, 'data.userType',''))
        .setIn(['userToken', 'OSIds'], get(action.data, 'data.OSIds',''))
        .setIn(['userToken', 'facilityId'], get(action.data, 'data.facilityId', ''))
        .setIn(['userToken', 'facilityIds'], get(action.data, 'data.facilityIds', ''))
        .setIn(['userToken', 'certificateId'], get(action.data, 'data.litmos.certificateId', ''))
        .setIn(['userToken', 'completion'], get(action.data, 'data.litmos.completion', ''))
        .setIn(['userToken', 'expiration'], get(action.data, 'data.litmos.expiration', ''))
        .setIn(['userToken', 'litmos'], get(action.data, 'data.litmos', ''))
        .setIn(['userToken', 'LitmosStatus'], get(action.data, 'data.LitmosStatus', ''));

       

    case createServiceAction('createUserProfile', 'success').type:
      return state.setIn(['userToken', 'userId'], get(action.data, 'data.userId', ''))
                  .set('loginAuthFinish', false)
                  .set('hasAuthCheckFinished', false);
    case createServiceAction('supplierSearch', 'requested').type:
      return state.set('supplierList', []);
    case createServiceAction('supplierSearch', 'error').type:
      return state.set('supplierList', undefined);
    case createServiceAction('supplierSearch', 'success').type:
      return state.set('supplierList', get(action.data, 'data', []));
    case createServiceAction('supplierDetail', 'requested').type:
      return state.set('supplierList', []);
    case createServiceAction('supplierDetail', 'success').type:
      return state.set('supplierList', get(action.data, 'data.supplierDetails', undefined));
    case clearFormValueTRF.type:
      return state.set('materialList', []).set('locationList', [])
        .set('materialTotalCount', 0)
        .set('materialTotalPages', 0)
        .set('materialNextPage', 1)
        .set('materialRemainingCount', 0)
        .set('materialRemainingPages', 0);
    case createServiceAction('locationSearch', 'requested').type:
      return state.set('materialList', []).set('locationList', []).set('isLoading', true);
    case createServiceAction('locationSearch', 'success').type:
      return state.set('locationList', get(action.data, 'data.locationDetails', []))
        .set('isLoading', false)
        .set('materialTotalCount', 0)
        .set('materialTotalPages', 0)
        .set('materialNextPage', 1)
        .set('materialRemainingCount', 0)
        .set('materialRemainingPages', 0);
    case createServiceAction('locationSearch', 'error').type:
      return state.set('isLoading', false);
    case createServiceAction('materialSearch', 'requested').type:
      return state.set('materialList', undefined)
        .set('materialRequestCompleted', false)
        .set('materialNextPage', 1);
    case createServiceAction('materialSearch', 'success').type:
      return state.set('materialList', get(action.data, 'data.materials', null))
        // materialTotalCount & materialTotalPages from API response
        .set('materialRequestCompleted', true)
        .set('materialTotalCount', get(action.data, 'data.totalCount', 0))
        .set('materialTotalPages', get(action.data, 'data.totalPages', 0))
        // materialNextPage = current page number form API response + 1
        .set('materialNextPage', parseInt(get(action.data, 'data.page', 0), 10) + 1)
        // materialRemainingCount = total count from API response - material list from current API response)
        .set('materialRemainingCount', parseInt(get(action.data, 'data.totalCount', 0), 10) - get(action.data, 'data.materials', []).length)
        // materialRemainingPages = total page count from API response - page number from API response 
        .set('materialRemainingPages', parseInt(get(action.data, 'data.totalPages', 0), 10) - parseInt(get(action.data, 'data.page', 0), 10));
    case createServiceAction('materialSearchLazyLoad', 'requested').type:
      return state.set('materialList', state.get('materialList'));
    case createServiceAction('materialSearchLazyLoad', 'success').type:
      // Add material list array from materail search response to existing materialList [As part of lazy load]
      return state.set('materialList', [...state.get('materialList'), ...get(action.data, 'data.materials', null)])
        // materialNextPage = current page number form API response + 1
        .set('materialNextPage', parseInt(get(action.data, 'data.page', 0), 10) + 1)
        // materialRemainingCount = total count from API response - (count of materials already added in store + material list from current API response)
        .set('materialRemainingCount', parseInt(get(action.data, 'data.totalCount', 0), 10) - [...state.get('materialList'), ...get(action.data, 'data.materials', [])].length)
        // materialRemainingPages = total page count from API response - page number from API response 
        .set('materialRemainingPages', parseInt(get(action.data, 'data.totalPages', 0), 10) - parseInt(get(action.data, 'data.page', 0), 10));
    case createServiceAction('set-favorite', 'success').type:
      return state.setIn(['userToken', 'favourites'], get(action, 'data', ''));
    default:
      return state;
  }
}
export const supplierName = state => state.auth.getIn(['userToken', 'supplierName']);
export const userLoggedIn = state => state.auth.get('userToken') !== null;
export const selectUserDetails = state =>
  state.auth.get('userToken') ? state.auth.get('userToken').toObject() : {};
export const selectUsername = state => state.auth.getIn(['userToken', 'username']);
export const selectUserId = state => state.auth.getIn(['userToken', 'userId']);
export const selectEmail = state => state.auth.getIn(['userToken', 'email']);
export const selectFullName = state => state.auth.getIn(['userToken', 'fullName']);
export const hasAuthCheckStarted = state => state.auth.get('hasAuthCheckStarted');
export const hasAuthCheckFinished = state => state.auth.get('hasAuthCheckFinished');
export const selectUserAccessToken = state => state.auth.getIn(['userToken', 'accessToken']);
export const selectLoginError = state => state.auth.get('loginError');
export const selectGroup = state => state.auth.getIn(['userToken', 'group']);
export const getSupplierList = state => state.auth.get('supplierList');
export const getLocationList = state => state.auth.get('locationList');
export const getMaterialList = state => state.auth.get('materialList');
export const getMaterialRequestCompleteStatus = state => state.auth.get('materialRequestCompleted');

export const getMaterialTotalPages = state => state.auth.get('materialTotalPages');
export const getMaterialNextPage = state => state.auth.get('materialNextPage');
export const getMaterialRemainingPages = state => state.auth.get('materialRemainingPages');
export const isLoading = state => state.auth.get('isLoading');
export const getFavourites = state => state.auth.getIn(['userToken', 'favourites']);

// identify groups(User group/type)
export const isSupplierUser = state => state.auth.getIn(['userToken', 'group']) === 'Suppliers';
export const isRslUser = state => state.auth.getIn(['userToken', 'group']) === 'Rsl';
export const isNloUser = state => state.auth.getIn(['userToken', 'group']) === 'Nlo';
export const isLabUser = state => state.auth.getIn(['userToken', 'group']) === 'Labs';
export const isFactoryUser = state => state.auth.getIn(['userToken', 'group']) === 'Factories';

export const loginAuthFinish = state => state.auth.get('loginAuthFinish') ;
export const logout = state => state.auth.get('logoutUser');
export const validUser = state => state.auth.get('userExist');
export const validGroup = state => state.auth.get('validGroup')


