import moment from 'moment';


const config = [
    {
        colId: 'startDate',
        headerName: 'Start Date',
        field: 'startDate',
        suppressFilter: true,
        suppressMovable: true,
        suppressMenu: true,
        width: 100,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        cellRenderer: data => {
            return moment(data.value).utc().format('DD-MMM-YYYY')
          }
    },
    {
        colId: 'endDate',
        headerName: 'End Date',
        field: 'endDate',
        suppressFilter: true,
        suppressMovable: true,
        suppressMenu: true,
        width: 100,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        cellRenderer: data => {
            return moment(data.value).utc().format('DD-MMM-YYYY');
          }
    },
    {
        colId: 'message',
        headerName: 'Notifications',
        field: 'message',
        suppressFilter: true,
        suppressMovable: true,
        suppressMenu: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'userGroups',
        headerName: 'User Groups',
        field: 'userGroups',
        suppressFilter: true,
        suppressMovable: true,
        suppressMenu: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        headerName: " ",
        field: "editNotify",
        colId: "editNotify",
        suppressToolPanel: true,
        suppressSizeToFit: true,
        width: 180,
        suppressFilter: true,
        suppressMenu: true,
        suppressMovable: true,
        cellRenderer: params => {

            return `<div class="test_button_proceed"><i class="fas fa-edit"></i> Edit Notification </div>`;

        }
    },
    {
        headerName: " ",
        field: "deleteNotify",
        colId: "deleteNotify",
        suppressToolPanel: true,
        suppressSizeToFit: true,
        width: 180,
        suppressFilter: true,
        suppressMenu: true,
        suppressMovable: true,
        cellRenderer: params => {

            return `<div class="test_button_proceed"><i class="fas fa-trash"></i> Delete Notification </div>`;

        }
    }
];
export default config;
