import React, { useState, useEffect, useRef } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  enter: 13,
};

const delimiters = [KeyCodes.enter];

const ReactTagsDemo = (props) => {
  const [tags, setTags] = useState([]);
  const [tagsValue, setTagsValue] = React.useState('');
  const inputText = useRef();

  useEffect(() => {
    setTags([]);
    setTagsValue('');
    var str =  document.getElementById("tags");
    if(str!== null){
      str.value = '';  
    }
  }, [props.clearTags])

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    setTagsValue([...tagsValue, tag.text])
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags].slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };
  const onRemove = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    setTagsValue(tagsValue.filter((tag, index) => index !== i));
    var test = tagsValue.filter((tag, index) => index !== i);
    if(test.length === 0){
      setTagsValue('');
    }
  }
  const preventFirstSpace =(e) =>{
    if(e === " "){
      inputText.current ='';
      var str =  document.getElementById("tags");
      str.value = '';
    }
  }
  return (
    <div className="app pt2-sm pr4-sm pb2-sm pl4-sm">
      <ReactTags
        handleDelete={onRemove}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        placeholder="Search..."
        readOnly={false}
        tags={tags}
        inputFieldPosition="inline"
        handleInputBlur={props.onChange(tagsValue)}
        handleInputChange={preventFirstSpace}
        name="searchTerm"
        id="tags"
        autofocus={true}
        ref = {inputText}
      />
    </div>
  );
};

const renderMultiTag = ({ input: { onChange }, clearTags}) => (
  <ReactTagsDemo
    onChange={onChange}
    clearTags={clearTags}
  />)
export default renderMultiTag;

