import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { Collapsible, Container, TextInput } from '../../../components/index.js';
import Label from '../../../components/ncss/Label';
import Grid from '../../../components/grid/Grid.js';
import UploadAttachment from '../../../components/uploadAttachment/UploadAttachment.js';
import testDetailConfig from '../../../configs/gridConfigs/labResults/testDetailConfig.js';
import TrfInfo from '../trfInfo/TrfInfo';

const style = {
  fontWeight: '800',
  fontSize: '1.8rem',
  lineHeight: '1.5',
  color: '#0078B9'
};

class LabReview extends Component {
  componentDidMount() {
    const {
      displayClearButtonLab,
      displaySubmitButtonLab,
      trfTestDetail,
      reviewData,
      submitLabResult,
      trfId,
      isTestValuesReady
    } = this.props;
    const sanitizedData = this.sanitizeDataForSubmit(trfTestDetail.data, reviewData);
    // Submit Lab results for getting Pass/Fail status in review page 
    // Invoke post lab result only if all test values validation is passed
    isTestValuesReady && submitLabResult({ testResult: sanitizedData, trfId: trfId });
    displayClearButtonLab(false);
    displaySubmitButtonLab(true);
  }

  sanitizeDataForSubmit = (testData, reviewData) => {
    if (testData.length === reviewData.length) {
      for (let i = 0; i < testData.length; i++) {
        for (let j = 0; j < reviewData.length; j++) {
          if (reviewData[j].chemicalName.trim() === testData[i].chemicalName.trim() &&
            reviewData[j].CAS === testData[i].CAS &&
            (reviewData[j].chemicalClass ? reviewData[j].chemicalClass.trim() === testData[i].testPackageId.trim() : reviewData[j].testPackageId === testData[i].testPackageId)
          ) {
            if (reviewData[j].testResultValue !== null) {
              if (
                typeof reviewData[j].testResultValue === 'string' &&
                reviewData[j].testResultValue.toLowerCase() === 'present'
              ) {
                testData[i].testValue = -2;
              } else if (
                typeof reviewData[j].testResultValue === 'string' &&
                reviewData[j].testResultValue.toLowerCase() === 'absent'
              ) {
                testData[i].testValue = -3;
              } else if (
                typeof reviewData[j].testResultValue === 'string' &&
                reviewData[j].testResultValue.toLowerCase() === 'nd'
              ) {
                testData[i].testValue = -1;
              } else if (
                typeof reviewData[j].testResultValue === 'string' &&
                reviewData[j].testResultValue.toLowerCase() === 'not tested'
              ) {
                testData[i].testValue = -4;
              } else if (reviewData[j].testResultValue >= 0) {
                testData[i].testValue = reviewData[j].testResultValue;
              }
            }
          }
        }
      }
    }
    return testData;
  };

  render() {
    const {
      displayClearButtonLab,
      displaySubmitButtonLab,
      testDetailData,
      isTestPending,
      addAttachmentLab,
      attachment,
      isInReview
    } = this.props;

    return (
      <div className="container-horizontal-scroll">
        <div className="ncss-row p2-bottom-sm"></div>
        <div className="ncss-row">
          <Label className="ncss-col-sm ncss-col-sm-2 p2-top-sm u-bold text-size">
            Lab Reference Number :
          </Label>
          <div className="ncss-col-sm-3 ncss-input-container inline-flex-display u-bold">
            <Field
              component={TextInput}
              type="text"
              name="labReferenceNumber"
              containerClass="u-full-width"
            />
          </div>
        </div>
        <div className="ncss-row p2-bottom-sm">
          <div className="ncss-col-sm-12 ncss-form-group p2-bottom-sm p2-top-sm text-area-container-sm">
            <Field
              component={TextInput}
              label="Comments :"
              type="textarea"
              name="labComment"
              className="ncss-col-sm-12 u-bold description p2-top-sm"
            />
          </div>
        </div>

        <UploadAttachment
          uploadAction={addAttachmentLab}
          attachment={attachment}
          disableUpload={isInReview}
        />

        <Collapsible
          trigger={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
              <div className="ncss-col-sm-1 ">
                <i className="fas fa-caret-down fa-pull-right" />
              </div>
            </div>
          }
          triggerWhenOpen={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
              <div className="ncss-col-sm-1">
                <i className="fas fa-caret-up fa-pull-right" />
              </div>
            </div>
          }
          triggerStyle={style}
          triggerClassName="collapsible"
        >
          <TrfInfo
            displayClearButtonLab={displayClearButtonLab}
            displaySubmitButtonLab={displaySubmitButtonLab}
            showTitle={false}
          />
        </Collapsible>
        <Collapsible
          trigger={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className={`ncss-col-sm-11 collapse-p0 ${isTestPending && 'loading-dots'}`}> TEST RESULTS {isTestPending && 'CALCULATING'}</div>
              <div className="ncss-col-sm-1">
                <i className="fas fa-caret-down fa-pull-right" />
              </div>
            </div>
          }
          triggerWhenOpen={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className={`ncss-col-sm-11 collapse-p0 ${isTestPending && 'loading-dots'}`}> TEST RESULTS {isTestPending && 'CALCULATING'}</div>
              <div className="ncss-col-sm-1">
                <i className="fas fa-caret-up fa-pull-right" />
              </div>
            </div>
          }
          triggerStyle={style}
          triggerClassName="collapsible"
        >
          <div className="dashboard">
            <Container section className="p0-top-sm m0-sm">
              <div className="ncss-row">
                <div className="ncss-col-sm-12">
                  <Grid
                    columnDefs={testDetailConfig}
                    suppressEdit={true}
                    rowData={testDetailData}
                    showFilter={false}
                  />
                </div>
              </div>
            </Container>
          </div>
        </Collapsible>

      </div>
    );
  }
}
LabReview.propTypes = {
  reviewData: PropTypes.array,
  displayClearButtonLab: PropTypes.func,
  displaySubmitButtonLab: PropTypes.func,
  trfTestDetail: PropTypes.object,
  testDetailData: PropTypes.object,
  isTestPending: PropTypes.bool,
  addAttachmentLab: PropTypes.func,
  attachment: PropTypes.array,
  isInReview: PropTypes.bool
};

export default reduxForm({
  form: 'LabReview',
  destroyOnUnmount: false,
  enableReinitialize: true
})(LabReview);

