import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ConfirmationBox } from '../../../components/index.js';
import Grid from '../../../components/grid/Grid.js';
import listGridConfig, { autoGroupColumnDefForList } from '../../../configs/gridConfigs/licencee/listLicenceeGridConfig.js';
import { toggleAddMaterailModal } from '../../../actions/trfActions.js';
import { clearEditLocationList } from '../../../actions/adminUserActions.js';
import { createServiceAction } from '../../../util/actionHelper.js';
import { history } from '../../../util/index.js';
import { getLicenseeList } from '../../../reducers/licenseeReducer.js';


class ListLicensees extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitModal: false,
            userName: null,
            userInfo: null
        };

        this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
        this.cancelAddLicensee = this.cancelAddLicensee.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);

    }
    componentDidMount() {
        const { clearEditLocationList, licenseeList } = this.props;
        clearEditLocationList();
        licenseeList();

    }
    componentWillUnmount() {
        const { licenseeListClear } = this.props;
        licenseeListClear();

    }
    newLicenseeAdd = status => this.setState({ activeNewLicenseeAdd: status, activelistLicensee: false });
    
    listLicenseeButton = status => this.setState({ activeNewLicenseeAdd: false, activelistLicensee: status });

    setLicensee = removedItems => {
    const { setLicenseeInfo } = this.props;
        setLicenseeInfo({ users: removedItems, listParams: { searchSup: '', isAll: false } })

    }


    confirmationBoxOpen = () => {
        this.setState({ submitModal: true });
    };

    closeSubmitModal = () => {
        this.setState({ submitModal: false });
    }

    onCellClicked = params => {
        const colId = params.api.getFocusedCell().column.colId
        if (colId === 'action') {
            this.confirmationBoxOpen(true, params.data)
            let removedItems = [{ userId: params.data.userId, supName: params.data.supplierName, isActive: false }]
            this.setState({ userName: params.data.name, userInfo: removedItems })
        }
    }

    cancelAddLicensee = () => {

        history.push('/');
    };
    onFilterTextBoxChanged = () => {
        if (this.params) {
            this.params.api.setQuickFilter(document.getElementById('filter-text-box').value);
        }
    }

    setGridParams = params => this.params = params;

    render() {

        const { licenceeList } = this.props;
        const { submitModal, userName, userInfo } = this.state;
        return (
            <div >
                <div section className="ml1-sm mr1-sm mt2-sm tab-class-container tab-class">

                    <span>
                        <div className="pl0-sm grid-filter-p-right-10 ncss-col-sm-offset-2 ncss-col-sm-11  pt3-sm">
                            <input
                                type="text"
                                id="filter-text-box"
                                className="grid-filter-textbox-licensee"
                                placeholder="Search Here..."
                                onChange={this.onFilterTextBoxChanged}
                            />
                        </div>
                        <div className="pt2-sm ncss-col-sm-offset-2 ncss-col-sm-11 pl0-sm">
                            <Grid rowData={licenceeList}
                                columnDefs={listGridConfig}
                                autoGroupColumnDef={autoGroupColumnDefForList}
                                onCellClicked={this.onCellClicked}
                                setParams={this.setGridParams}
                            />
                        </div>
                    </span>

                </div>
                <ConfirmationBox
                    message={
                        `Are you sure you want to remove ${userName} from  licensee list?`

                    }
                    submitModal={submitModal}
                    submitValue={
                        () => {
                            this.setLicensee(userInfo);
                        }

                    }
                    closeModal={this.closeSubmitModal}
                />


            </div>
        );
    }
}

ListLicensees.propTypes = {
    toggleAddMaterailModal: PropTypes.func,
    isAddMaterialModalOpen: PropTypes.bool,
    requestSupplierList: PropTypes.func,
    history: PropTypes.object
};
const mapStateToProps = state => ({
    allData: state,
    isAddMaterialModalOpen: state.trf.isAddMaterialModalOpen,
    supplierData: state.adminUser.supplierData,
    licenseeData: state.licensee.licenseeDetails,
    supplierLocationList: state.adminUser.supplierLocationList,
    locationDetails: state.adminUser.locationDetails,
    listLicenseeForm: getFormValues('listLicensee')(state),
    errorStatusAddLicensee: state.adminUser.errorStatusAddLicensee,
    errorStatusUpdateSupplier: state.adminUser.errorStatusUpdateSupplier,
    licenceeList: getLicenseeList(state)
});

const mapDispatchToProps = dispatch => ({
    toggleAddMaterailModal: isOpen => {
        dispatch(toggleAddMaterailModal(isOpen));
    },

    clearEditLocationList: () => {
        dispatch(clearEditLocationList());
    },
    submitSupplierData: (data) => {
        dispatch(createServiceAction('addLicensee', 'submited')({ ...data }));
    },

    setLicenseeInfo: (data) => {
        dispatch(createServiceAction('setLicensee', 'requested')({ ...data }));
    },

    requestLicenseeList: searchTerm => {
        dispatch(createServiceAction('addLicenseeSearch', 'requested')(searchTerm));
    },

    licenseeList: () => {
        dispatch(createServiceAction("listLicensee", 'requested')({ searchSup: '', isAll: false }))
    },

    licenseeListClear: () => {
        dispatch(createServiceAction("listLicensee", 'clear')())
    }
});

export default compose(reduxForm({ form: 'listLicensee' }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ListLicensees);
