const config = [
    {
      colId: 'OSHId',
      headerName: 'OS ID',
      field: 'OSHId',
      suppressFilter: true,
      suppressMovable: true,
      suppressMenu: true,
      icons: {
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      }
    },
    {
      colId: 'supplierName',
      headerName: 'Supplier Name',
      field: 'supplierName',
      suppressFilter: true,
      suppressMovable: true,
      suppressMenu: true,
      icons: {
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      }
    },
    {
      colId: 'locId',
      headerName: 'locId',
      field: 'locId',
      suppressFilter: true,
      suppressMovable: true,
      suppressMenu: true,
      icons: {
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      }
    }
  ];
  export default config;
  