import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { getDetailsNextPage, getRemainingPages} from '../reducers/adminUserReducer.js';
import { put, takeLatest,call, select } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const options = {
      page: yield select(getDetailsNextPage),// Temporarily setting static page number till server side pagination in ag grid is implemented
      pageCount:500
    };

    const res = yield call(getRequest, apiConfig.userData.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){ 
      const response = yield res.json();
      //Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const remainingPages = yield select(getRemainingPages);
        // Trigger call again for next set of records till there are pages remaining
        if(remainingPages > 0){
          yield put(createServiceAction(`userDetailsLazyLoad`, 'requested')(action.data));
        }
      }
    }
    
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* userDetailsSagas() {
  
  yield takeLatest(createServiceAction('userDetails', 'requested').type, listener, 'userDetails');
  yield takeLatest(createServiceAction('userDetailsLazyLoad', 'requested').type, listener, 'userDetailsLazyLoad');

}

export default userDetailsSagas;
