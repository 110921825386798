import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
//import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getSuppliersNextPage, getSuppliersRemainingPages } from '../reducers/listAllSupplierReducer';

function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());
        const options = {
            "page": yield select(getSuppliersNextPage),
            "limit": 100
        };

        const res = yield call(getRequest, apiConfig.listSuppliers.endPointUrl, options);
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
        if (!statusErrorMessage) {
            const response = yield res.json();
            //const response = sampleData
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response);
            if (!serverErrorMessage && response.errorCode === 0) {
                yield put(createServiceAction(serviceType, 'success')(response.data));
                const remainingPages = yield select(getSuppliersRemainingPages);
                // Trigger call again for next set of records till there are pages remaining
                if (remainingPages > 0) {
                    yield put(createServiceAction('listSuppliersLazyLoad', 'requested')());
                } else {
                    yield put(createServiceAction('listSuppliersLazyLoad', 'completed')());
                }


            }
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));

    }
}

function* listSuppliers() {
    const serviceType = 'listSuppliers';
    const serviceTypeLazyLoad = 'listSuppliersLazyLoad';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
    yield takeLatest(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);

}

export default listSuppliers;