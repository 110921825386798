import { fromJS } from 'immutable';
import get from 'lodash/get';

import { clearArchive } from '../actions/archiveActions.js'
import { createServiceAction } from '../util/actionHelper.js';


const defaultState = fromJS({
    getArchiveNextPage: 1,
    archiveLoading: false,
    searchAllLoadFinish: false
});

export default function dashboard(state = defaultState, action) {
    state = fromJS(state);
    const pageCount = 500;
    switch (action.type) {

        case createServiceAction('getArchive', 'loading').type:
            sessionStorage.setItem("searchLoadFinish",false);
            return state.set('archiveLoading', true).set('searchAllLoadFinish', false)
        case createServiceAction('getArchiveLazyLoad', 'completed').type:
            sessionStorage.setItem("searchLoadFinish",'done');
            return state.set('searchAllLoadFinish', true) 
        case createServiceAction('getArchive', 'server-error').type:
                return state.set('archiveLoading', false) 
        case clearArchive.type:
                return state.set('archiveData', action.payload);             
        case createServiceAction('getArchive', 'success').type:
            let nextPage = 1
            let totalPage_success = 0
            sessionStorage.setItem("searchLoadFinish",false);
            if (get(action.data, 'data.count') > pageCount) {
                nextPage = state.get('getArchiveNextPage') + 1
                totalPage_success = Math.ceil(parseInt(get(action.data, 'data.count'), 10) / pageCount)
            }
            let tempForAll = action.data
            let updateTempForAll = fromJS(tempForAll)


            return state.set('archiveData', updateTempForAll.toJS())
                .set('archiveTotalCount', get(action.data, 'data.count'))
                .set('getArchiveTotalPage', totalPage_success)
                .set('getArchiveNextPage', nextPage)
                .set('getArchiveRemainingPages', totalPage_success)
                .set('archiveLoading', false)


        case createServiceAction('getArchiveLazyLoad', 'success').type:


            let newData = action.data.data.rows



            let temp = state.get('archiveData')
            let updateTemp = fromJS(temp)

            let InitialData = temp.data.rows;

            InitialData = [...InitialData, ...newData];

            sessionStorage.setItem("searchLoadFinish",false);
            //updateTemp = updateTemp.updateIn(['data', 'currentPage'], data => action.data.data.currentPage)
            updateTemp = updateTemp.updateIn(['data', 'rows'], index => InitialData)
            let nextSummaryPage = state.get('getArchiveNextPage') + 1;
            let totalPage = Math.ceil(parseInt(get(action.data, 'data.count'), 10) / pageCount)
            if (nextSummaryPage > state.get('getArchiveTotalPage')) {
                nextSummaryPage = 1
                totalPage = 0
            }
            return state.setIn(['archiveData'], updateTemp.toJS())
                .set('getArchiveTotalPage', totalPage)
                .set('getArchiveRemainingPages', Math.ceil(parseInt(get(action.data, 'data.count'), 10) / pageCount) - (state.get('getArchiveNextPage')), 10)
                .set('getArchiveNextPage', nextSummaryPage)


        default:
            return state;
    }
}



export const getarchiveData = state => state.archive.get('archiveData');
export const getArchiveNextPage = state => state.archive.get('getArchiveNextPage');
export const getArchiveRemainingPages = state => state.archive.get('getArchiveRemainingPages');
export const isArchiveLoading = state => state.archive.get('archiveLoading');
export const getArchiveLoadStatus = state => state.archive.get('searchAllLoadFinish');


