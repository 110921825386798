import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import { put, takeEvery, call, select, takeLatest } from 'redux-saga/effects';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { getTestDetailsRemainingPages, getTestDetailsTotalPage, getTestDetailsNextPage } from '../reducers/testDetailReducer';

function* listener(serviceType, action) {
  try {
    // Dispatch TESTDETAIL_LOADING action
    yield put(createServiceAction(serviceType, 'loading')());

    const options = {
      trfId: action.data.trfId,
      page: yield select(getTestDetailsNextPage),
      pageCount: action.data.pageCount
    };

    // Trigger Call to API endpoint
    const res = yield call(getRequest, apiConfig.testDetails.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();

      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        // Dispatch TESTDETAIL_SUCCESS action with response object
        yield put(createServiceAction(serviceType, 'success')(response));
        const remainingPages = yield select(getTestDetailsRemainingPages);
        const TotalPages = yield select(getTestDetailsTotalPage);
        // Trigger call again for next set of records till there are pages remaining
        if (remainingPages > 0 && remainingPages <= TotalPages) {
          yield put(createServiceAction('testDetailsInfiniteLoad', 'requested')(action.data));
        }
      }
    }
  } catch (e) {
    // Dispatch TESTDETAIL_ERROR action with captured error data

    yield put(createServiceAction(serviceType, 'error')(e));
  }
}
// Will Remove the function once check with team
function* submitlistener(serviceType, action) {
  try {
    const trfReq = {
      testResult: null,
      trfId: null,
      qrfComment: null,
      frfComment: null,
      frfId: null
    };
    const requestParam = pick(action.data, keys(trfReq));
    const options = {
      user_type: 'Rsl'
    };
    const infoPopDuration = 1000;

    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, apiConfig.approveorReject_TD.endPointUrl, requestParam, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {

        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

//console.log(submitlistener());
function* testDetailSagas() {
  const serviceType = 'testDetails';
  const serviceTypeInfiniteLoad = 'testDetailsInfiniteLoad'
  // Listen to TESTDETAILS_REQUESTED actions
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  // Listen to TESTDETAILSFORLAB_REQUESTED actions
  yield takeEvery(createServiceAction('testDetailsForLab', 'requested').type, listener, 'testDetailsForLab');
  yield takeEvery(createServiceAction('testDetailsSubmit', 'submitted').type, submitlistener, 'testDetailsSubmit');
  yield takeLatest(createServiceAction(serviceTypeInfiniteLoad, 'requested').type, listener, serviceTypeInfiniteLoad);
}

export default testDetailSagas;
