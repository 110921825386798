import { createAction } from '../util/index.js';

export const displayClearButtonLab = createAction('DISPLAY_CLEAR_BUTTON_LAB', requestObject => requestObject);

export const displaySubmitButtonLab = createAction(
  'DISPLAY_SUBMIT_BUTTON_LAB',
  requestObject => requestObject
);

export const validateData = createAction('VALIDATE_DATA', requestObject => requestObject);

export const clearData = createAction('CLEAR_DATA', requestObject => requestObject);

export const getErrorClass = createAction('ERROR_CLASS', requestObject => requestObject);

export const addAttachmentLab = createAction('ADD_ATTACHMENT_LAB', requestObject => requestObject);

export const clearFormValueLAB = createAction('CLEAR_FORM_VALUE_LAB', requestObject => requestObject);