const config = [
  {
    colId: 'materialNumber',
    headerName: 'Material Number',
    field: 'materialNumber',
    suppressMenu: true,
    width: 100,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'PDMNumber',
    headerName: 'PDM Number',
    field: 'PDMNumber',
    suppressMenu: true,
    width: 100,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'materialName',
    headerName: 'Material Name',
    field: 'materialName',
    suppressMenu: true,
    tooltipField: 'materialName',
    suppressMovable: true,
    cellStyle: { color: '#3498db' },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'refId',
    headerName: 'Material ID',
    field: 'refId',
    suppressMenu: true,
    width: 100,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  }
];
export default config;
