import isEmpty from 'lodash/isEmpty';

import globalHeader from '../services/globalHeader.js';

const headers = {
  'Accept': 'application/json',
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/x-www-form-urlencoded'
}

export const postRequest = (url, payload) =>
  fetch(url, {
    method: 'POST',
    ...{ headers: globalHeader() },
    body: JSON.stringify(payload)
  });

  export async function authPostRequest(url,params){
    //export async const authPostRequest = (url,params) =>{
      try{
      const response = await fetch(url, {
        method: 'POST',
        ...{ headers: headers },
        body: params
    
    })
    
    const body = await response.json()
    return body
      } catch (e){
        return e
      }
    
    };

export const getRequest = (url, queryParams) => {
  if (!isEmpty(queryParams)) {
    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
    url = `${url}?${queryString}`;
  }
  return fetch(url, {
    method: 'GET',
    ...{ headers: globalHeader() }
  });
};

export const deleteRequest = (url) =>
  fetch(url, {
    method: 'DELETE',
    ...{ headers: globalHeader() }
  });

export const putRequest = (url, payload) => {
  return fetch(url, {
    method: 'PUT',
    ...{ headers: globalHeader() },
    body: JSON.stringify(payload)
  });
}