import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { IconButton, H4, H3 } from '../../components/index.js';
import { getSelectedButton } from '../../reducers/dashboardReducer.js';
import { isLabUser } from '../../reducers/authReducer.js';


const StatusArea = ({ selectedButton, status, supplierName, ActionRequiredButtonStatus, requestTestSummary, requestSearchAll, isLabUser }) => (
  <div>
    {status.map((info, indx) => (
      <div key={info.value} className={`${isLabUser ? 'ncss-col-sm-4 ' : 'ncss-col-sm-3 '} align-center ${indx === 0 && !isLabUser ? '' : ''}`}>
        <div className = 'background-status'  onClick={() => {
            // get test summary for each status
            info.value === 'All'?
            requestSearchAll('/archive')
            :
            requestTestSummary(info.value, supplierName, ActionRequiredButtonStatus);
          }}>
        <IconButton
          icon={`${info.icon} icon-button-margin`}
          color={`${selectedButton === info.value ? 'offwhite' : 'medium-grey'}`}
          className={`circle ${selectedButton === info.value ? 'big-button-selected' : 'big-button'}`}
        />
        
        <H4 className={`u-bold ${selectedButton === info.value ? 'text-color-accent' : 'text-color-grey'}`}>
          {info.name}
        </H4>
        <H3 className={`u-bold ${selectedButton === info.value ? 'text-color-accent' : ''}`}>{info.count}</H3>

      </div>
      </div>
    ))}
  </div>
);

StatusArea.propTypes = {
  status: PropTypes.array,
  selectedButton: PropTypes.string,
  requestTestSummary: PropTypes.func,
  requestSearchAll: PropTypes.func
};

export { StatusArea };

const mapStateToProps = state => ({
  selectedButton: getSelectedButton(state),
  isLabUser: isLabUser(state)
});

export default connect(mapStateToProps)(StatusArea);