import moment from 'moment';

export function convertDateToDBFormat(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth()+1)).slice(-2),
        day  = ("0" + date.getDate()).slice(-2);
    return [ date.getFullYear(), mnth, day ].join("-");
  }

export function convertDateReview(str){
  var date = new Date(str),
        mnth = ("0" + (date.getMonth()+1)).slice(-2),
        day  = ("0" + date.getDate()).slice(-2);
  return [day,mnth,date.getFullYear()].join("-");
}
  
export function convertDate(str) {
    if(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)){
      return str.substring(0, str.indexOf("T")).split('-').reverse().join('-');
    }
    return '';
}

export function formatDate(updatedDate) {
  let isIE = /*@cc_on!@*/false || !!document.documentMode;
  let isEdge = !isIE && !!window.StyleMedia;
  let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const formatStr = 'DD-MMM-YYYY';
  const month = { Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12 };
  let localDate;
  if (isEdge || isSafari) {
    const dateAndTime = updatedDate.split(' ');
    const dateUpdated = dateAndTime[0].split('/');
    const timeUpdated = dateAndTime[1].split(':');
    const monthInChar = dateUpdated[1]
    const fullDate = month[monthInChar] + "/" + dateUpdated[2] + "/" + dateUpdated[0] + " " + timeUpdated[0] + ":" + timeUpdated[1] + ":" + timeUpdated[2];
    localDate = moment.utc(fullDate).local().format(formatStr);
  }

  else {

    localDate = moment.utc(updatedDate).local().format(formatStr);
  }

  
  return localDate;
}

  
  