import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getFormValues } from 'redux-form';

import { createServiceAction } from '../../util/actionHelper.js';
import { H4, Button, ProgressTracker, Container, ConfirmationBox } from '../../components/index.js';
import {
  displayClearButtonLab,
  displaySubmitButtonLab,
  validateData,
  clearData,
  getErrorClass,
  addAttachmentLab,
  clearFormValueLAB
} from '../../actions/labActions';
import { selectTestDetailData } from '../../reducers/labReducer.js';
import { selectUserDetails } from '../../reducers/authReducer.js';

import TrfInfo from './trfInfo/TrfInfo';
import TestValues from './testValues/TestValues';
import ReviewForCompleted from './review/ReviewForCompleted';
import LabReview from './review/Review';
import { PDFExport } from '@progress/kendo-react-pdf';
import PageTemplate from "../test_details/pageTemplate.js";
import TestDetailsPDF from "../test_details/testDetailsPdf.js";
import { getTestDetailData } from '../../reducers/testDetailReducer.js';
import { getTestDetailsByClassLab } from '../../reducers/labReducer.js';

import { isRslUser, isLabUser } from '../../reducers/authReducer.js';

class LabResults extends Component {
  constructor() {
    super();
    this.state = {
      reviewData: [],
      submitModal: false,
      status: null,
      stepState: 0,
      progressFlag: false,
      completedFlag: false
    };
    this.hidden = {
      display: 'none'
    };
    this.currentStep = this.currentStep.bind(this);
  }

  componentDidMount() {
    const { location, getTrfDetails, getErrorClass, clearFormValueLAB } = this.props;
    getErrorClass();
    getTrfDetails(location.state && location.state.trfId);
    clearFormValueLAB();


  }

  componentDidUpdate(prevProps) {
    const { trfTestDetail, trfDetail, location, getTrfTestDetails, getTestDetails } = this.props;
    if (prevProps.trfDetail !== trfDetail && !isEmpty(trfDetail.data)) {
      if (trfDetail.data.trfDetails.status === 'InProgress') {
        this.updateFlag(true, false);
        getTrfTestDetails(location.state && location.state.trfId);
      } else if (
        trfDetail.data.trfDetails.status === 'ActionRequired' ||
        trfDetail.data.trfDetails.status === 'Completed'
      ) {
        this.updateFlag(false, true);
        const info = { trfId: location.state && location.state.trfId, pageCount: 1000 }
        getTestDetails(info);
      }
    }
    if (prevProps.trfTestDetail !== trfTestDetail && !isEmpty(trfTestDetail.data)) {
      this.getReviewInfo(trfTestDetail.data);
    }
  }

  onClickNext = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState + 1
    }));
    this.clickNext();
  };

  onClickPrevious = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState - 1
    }));
    this.clickPrevious();
  };

  getReviewInfo = val => {
    this.setState({
      reviewData: val
    });
  };

  updateFlag = (data1, data2) => {
    this.setState({ progressFlag: data1 });
    this.setState({ completedFlag: data2 });
  };

  clearValue = () => {
    const { trfTestDetail, clearData, history } = this.props;
    for (let i = 0; i < trfTestDetail.data.length; i++) {
      trfTestDetail.data[i].testResultValue = '';
    }
    this.getReviewInfo(trfTestDetail.data);
    clearData();
    history.push('/');
  };

  confirmationBoxOpen = (modalopen, status) => {
    this.setState({ submitModal: true, status });
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  currentStep = step => {
    this.setState({ stepState: step });
  };
  exportLabPDF = () => {
    sessionStorage.setItem("reportId",this.props.trfDetail.data.trfDetails.RSLNo);
    this.testLabPdf.save();
  }
  ExtendedPageTemplate = props => (
    <PageTemplate header="Nike RSL Test Report" isLabUser="true" {...props}  />
  );

  render() {
    const { reviewData, submitModal, status, stepState, progressFlag, completedFlag } = this.state;
    const {
      errorClassName,
      shouldDisplayClear,
      displayClearButtonLab,
      displaySubmitButtonLab,
      shouldDisplaySubmit,
      validateData,
      trfTestDetail,
      submitLabResultFromReview,
      submitLabResult,
      location,
      history,
      testDetailData,
      trfDetail,
      getTestDetails,
      isTestPending,
      overAllTestResult,
      isTestValuesReady,
      reviewFormValues,
      isInReview,
      addAttachmentLab,
      attachment,
      isLabUser,
      isRslUser,
      testDetailsByClass
      
    } = this.props;
    const labSubmitData = {
      trfId: location.state.trfId,
      result: overAllTestResult,
      ...({ attachments: attachment }),
      ...reviewFormValues
    };

    const steps = [
      {
        name: 'TRF INFORMATION',
        component: (
          <TrfInfo
            displayClearButtonLab={displayClearButtonLab}
            displaySubmitButtonLab={displaySubmitButtonLab}
          />
        )
      },
      {
        name: 'UPLOAD TEST RESULTS',
        component: (
          <TestValues
            getReviewInfo={this.getReviewInfo}
            data={reviewData}
            displayClearButtonLab={displayClearButtonLab}
            displaySubmitButtonLab={displaySubmitButtonLab}
            validateData={validateData}
            rslNo={get(trfDetail, 'data.trfDetails.RSLNo', '')}
            isInReview={isInReview}
          />
        )
      },
      {
        name: 'REVIEW',
        component: (
          <LabReview
            reviewData={reviewData}
            trfTestDetail={trfTestDetail}
            displayClearButtonLab={displayClearButtonLab}
            displaySubmitButtonLab={displaySubmitButtonLab}
            submitLabResult={submitLabResultFromReview}
            trfId={location.state.trfId}
            getTestDetails={getTestDetails}
            testDetailData={testDetailData}
            isTestValuesReady={isTestValuesReady}
            isTestPending={isTestPending}
            formData={reviewFormValues}
            isInReview={isInReview}
            addAttachmentLab={addAttachmentLab}
            attachment={attachment}
          />
        )
      }
    ];

    return (
      <div className="page-align-fixed-items">
        <div section className="prevent-scroll top-scroll-fix">
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading p2-bottom-sm">
            LAB - UPLOAD RESULTS
            <span className="u-bold p1-top-sm p1-bottom-sm sub-header pl3-sm">
              {progressFlag ? '3 Steps to fill out this form.' : null}
            </span>
          </H4>
          {trfDetail && !progressFlag &&
            <div className="ncss-col-sm-6 download-print-section">
              {testDetailData ?
                <span>
                  <i className="fa fas fa-download" />
                  <Button onClick={this.exportLabPDF} className="download-print-button text-color-accent">
                    Nike RSL Test Report
                  </Button>
                </span>
                :
                <span>
                  <span className="text-color-accent">Loading Reports</span>
                  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                </span>
              }
            </div>
          }
        </div>
        {progressFlag ? (
          <div>
            <div section className="clear-margin progress-tracker-align">
              <ProgressTracker
                steps={steps}
                flat="true"
                errorClassName={errorClassName}
                clickNext={clickNext => (this.clickNext = clickNext)}
                clickPrevious={clickPrevious => (this.clickPrevious = clickPrevious)}
                currentStepNumber={this.currentStep}
              />
            </div>
            <Container
              section
              className="prevent-scroll bottom-scroll-fix bottom-section remove-margin sumbmit-area"
            >
              {shouldDisplaySubmit && (
                <span>
                  {(errorClassName.every(element => element === false) && !isTestPending) ? (
                    <Button
                      className="ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected"
                      onClick={() => this.confirmationBoxOpen(true, 'submit')}
                    >
                      SUBMIT
                    </Button>
                  ) : (
                      <Button className="ncss-btn-primary-dark disabled u-bold submit-button trf-button-selected">
                        SUBMIT
                    </Button>
                    )}
                </span>
              )}
              <Button
                style={stepState === 0 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-prevoius-button trf-button-selected"
                onClick={() => this.onClickPrevious('previous')}
              >
                Previous
              </Button>
              {shouldDisplayClear && (
                <Button
                  className="ncss-btn-secondary-dark u-bold secondary-button trf-button-selected"
                  onClick={() => this.confirmationBoxOpen(true, 'clear')}
                  background="bg-dark-grey"
                >
                  CLEAR
                </Button>
              )}
              <Button
                style={stepState === 2 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-next-button trf-button-selected"
                onClick={() => this.onClickNext('next')}
              >
                Next
              </Button>
            </Container>
            <ConfirmationBox
              message={
                status === 'submit'
                  ? 'Are you sure you want to submit the Test results?'
                  : 'Are you sure you want to clear the Test results? '
              }
              submitModal={submitModal}
              submitValue={
                status === 'submit'
                  ? () => {
                    submitLabResult(labSubmitData, history);
                  }
                  : () => {
                    this.clearValue();
                  }
              }
              closeModal={this.closeSubmitModal}
            />
          </div>
        ) : null}
        {completedFlag ? (
          <ReviewForCompleted
            testData={testDetailData}
            displayClearButtonLab={displayClearButtonLab}
            displaySubmitButtonLab={displaySubmitButtonLab}
            trfDetail={trfDetail}
          />
        ) : null}
        <span>


        </span>
        {trfDetail && trfDetail.data && testDetailData && completedFlag && !isEmpty(testDetailsByClass) &&
          <div className="testDetailsPDF">
            <span>

              <PDFExport paperSize="A4"
                pageTemplate={this.ExtendedPageTemplate}
                scale={0.8}
                margin={{ top: 50, left: 20, right: 20, bottom: 50 }}
                multiPage
                forcePageBreak=".page-break"
                fileName={`Nike RSLReport - ${trfDetail.data.trfDetails.RSLNo}.pdf`}
                repeatHeaders
                title=""
                subject=""
                keywords=""
                landscape="true"
                keepTogether=".prevent-split"
                ref={(r) => this.testLabPdf = r}>
                <div className="ncss-col-sm-12">
                  <TestDetailsPDF trfDetails={trfDetail} isLabUser={isLabUser} isRslUser={isRslUser} testDetails={testDetailData} testDetailsByClass={testDetailsByClass} />
                </div>
              </PDFExport>
            </span>

          </div>
        }
      </div>
    );
  }
}
LabResults.propTypes = {
  getTrfDetails: PropTypes.func,
  getTrfTestDetails: PropTypes.func,
  getTestDetails: PropTypes.func,
  submitLabResultFromReview: PropTypes.func,
  submitLabResult: PropTypes.func,
  errorClassName: PropTypes.array,
  location: PropTypes.object,
  displayClearButtonLab: PropTypes.func,
  shouldDisplaySubmit: PropTypes.bool,
  shouldDisplayClear: PropTypes.bool,
  displaySubmitButtonLab: PropTypes.func,
  trfTestDetail: PropTypes.object,
  trfDetail: PropTypes.object,
  history: PropTypes.object,
  validateData: PropTypes.func,
  clearData: PropTypes.func,
  getErrorClass: PropTypes.func,
  testDetailData: PropTypes.object,
  isTestPending: PropTypes.bool,
  overAllTestResult: PropTypes.string,
  isTestValuesReady: PropTypes.bool,
  reviewFormValues: PropTypes.object,
  isInReview: PropTypes.bool,
  attachment: PropTypes.array
};

function mapStateToProps(state) {
  //console.log(selectTestDetailData(state));
  return {
    errorClassName: state.lab.errorClassName,
    shouldDisplaySubmit: state.lab.shouldDisplaySubmit,
    shouldDisplayClear: state.lab.shouldDisplayClear,
    trfTestDetail: state.lab.trfTestDetail,
    trfDetail: state.lab.trfDetail,
    // Lab section will be isInReview (non editable state) for the below mentioned 2 scenarios
    // 1) If status of TRF is Review 
    // 2) If Lab user doesn't have access to location in which the TRF is requested
    isInReview: (
      get(state.lab.trfDetail, 'data.trfDetails.statusValue', '') === 'Review' ||
      get(state.lab.trfDetail, 'data.trfDetails.labLocationId', '') !== parseInt(selectUserDetails(state).labLocationId, 10)
    ),
    isTestPending: state.lab.isTestPending,
    testDetailData: selectTestDetailData(state),
    testDetailsData: getTestDetailData(state),
    overAllTestResult: get(state.lab.labResultsSubmitResponse, 'data.result', ''),
    reviewFormValues: getFormValues('LabReview')(state),
    // isTestValuesReady checks if all validations are passed
    // isTestValuesReady key controls whether or not to invoke post lab result from review page
    isTestValuesReady: !state.lab.errorClassName[1],
    attachment: state.lab.attachment,
    isRslUser: isRslUser(state),
    isLabUser: isLabUser(state),
    testDetailsByClass: getTestDetailsByClassLab(state)

  };
}

const mapDispatchToProps = dispatch => ({
  getTrfDetails: data => {
    dispatch(createServiceAction('trfDetailsForLab', 'requested')(data));
  },
  getTestDetails: data => {
    dispatch(createServiceAction('testDetailsForLab', 'requested')(data));
  },
  getTrfTestDetails: data => {
    dispatch(createServiceAction('trfTestDetailsForLab', 'requested')(data));
  },
  submitLabResultFromReview: (data, history) => {
    dispatch(createServiceAction('labResultsSubmit', 'submitted')(data));
  },
  submitLabResult: (labSubmitData, history) => {
    dispatch(createServiceAction('labSubmit', 'submitted')({ labSubmitData, history }));
  },
  displaySubmitButtonLab: isOpen => {
    dispatch(displaySubmitButtonLab(isOpen));
  },
  displayClearButtonLab: isOpen => {
    dispatch(displayClearButtonLab(isOpen));
  },
  validateData: data => {
    dispatch(validateData(data));
  },
  clearData: () => {
    dispatch(clearData());
  },
  getErrorClass: () => {
    dispatch(getErrorClass());
  },
  addAttachmentLab: data => {
    dispatch(addAttachmentLab(data));
  },
  clearFormValueLAB: () => {
    dispatch(clearFormValueLAB());
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LabResults)
);