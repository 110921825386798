import { createServiceAction } from '../util/actionHelper.js';
import {
  displayClearButtonLab,
  displaySubmitButtonLab,
  validateData,
  clearData,
  getErrorClass,
  addAttachmentLab
} from '../actions/labActions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
  errorClassName: [false, true, true],
  testDetailData: [],
  isTestPending: false,
  labResultsSubmitResponse: {},
  attachment:[],
  testDetailByClass:[]
};

let isError = true;

function isValidated(data) {
  const arr = [];
  isError = true;
  for (let i = 0; i < data.length; i++) {
    if (data[i].testResultValue !== null && data[i].testResultValue !== undefined)
    {
      if(typeof data[i].testResultValue === 'string' && (data[i].testResultValue.toLowerCase() === 'present' ||
      data[i].testResultValue.toLowerCase() === 'absent')&& ( data[i].chemicalClass ? (
        data[i].chemicalClass.trim() === "Asbestos" ||
        data[i].chemicalClass.trim() === "Polyvinyl Chloride (PVC)") : (data[i].testPackageId === 532 ||
          data[i].testPackageId === 504)
         )
    ) {
      arr.push(data[i]);
    }
    else if (data[i].testResultValue >= 0 && (data[i].chemicalClass ? (data[i].chemicalClass.trim() !== "Asbestos" 
      && data[i].chemicalClass.trim() !== "Polyvinyl Chloride (PVC)") : (data[i].testPackageId !== 532 &&
      data[i].testPackageId !== 504))
    ) {
      arr.push(data[i]);
    }
    else if(data[i].testResultValue.toString().length >0 &&
      (data[i].chemicalClass ? (data[i].chemicalClass.trim() !== "Asbestos" &&
      data[i].chemicalClass.trim() !== "Polyvinyl Chloride (PVC)") : (data[i].testPackageId !== 532 &&
      data[i].testPackageId !== 504)) &&
      ((typeof data[i].testResultValue === 'string' &&
        (data[i].testResultValue.toLowerCase() === 'present' ||
        data[i].testResultValue.toLowerCase() === 'absent' ||
        data[i].testResultValue.toLowerCase() === 'nd' ||
        data[i].testResultValue.toLowerCase() === 'not tested')) ||
        data[i].testResultValue >= 0) 
    ) {
      arr.push(data[i]);
    }
  }
  }
  if (arr.length === data.length) {
    isError = false;
  }
  return [false, isError, isError];
}

function isClearData() {
  return defaultState.errorClassName;
}

export default function lab(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('trfDetailsForLab', 'requested').type:
      return { ...state, trfDetail: {} };
    case createServiceAction('trfDetailsForLab', 'success').type:
      return { ...state, trfDetail: action.data, attachment:[] };
    case createServiceAction('trfTestDetailsForLab', 'success').type:
      return { ...state, trfTestDetail: action.data, testDetailData: [] };
    case createServiceAction('labResultsSubmit', 'submitted').type:
      return { ...state, testDetailData: [], isTestPending: true };
    case createServiceAction('labResultsSubmit', 'success').type:
      return { ...state, labResultsSubmitResponse: action.data };
    case createServiceAction('labSubmit', 'success').type:
      return { ...state, result: action.data };
    case createServiceAction('labSubmit', 'error').type:
      return { ...state, err: action.data };
    case createServiceAction('testDetailsForLab', 'success').type:
      return {
        ...state,
        testDetailByClass: action.data.data.classStatus,
        testDetailData: action.data.data,
        isTestPending: isEmpty(get(action.data,'data.testDetails',[])) 
      };
    case displayClearButtonLab.type:
      return { ...state, shouldDisplayClear: action.payload };
    case displaySubmitButtonLab.type:
      return { ...state, shouldDisplaySubmit: action.payload };
    case validateData.type:
      return { ...state, errorClassName: isValidated(action.payload) };
    case clearData.type:
      return { ...state, errorClassName: isClearData() };
    case getErrorClass.type:
      return { ...state, errorClassName: [false, true, true] };
    case addAttachmentLab.type:
      return { ...state, attachment: [...state.attachment, action.payload] }; 
    default:
      return state;
  }
}

export const selectTestDetailData = state => get(state.lab.testDetailData.testDetails,'data',[]);

export const isTestPending = state => state.lab.isTestPending;
export const getTestDetailsByClassLab = state => state.lab.testDetailByClass;



