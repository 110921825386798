import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise';

export default class StepZilla extends Component {
  constructor(props) {
    super(props);
    const { startAtStep, steps } = this.props;
    this.state = {
      compState: startAtStep,
      navState: this.getNavStates(startAtStep, steps.length)
    };

    this.hidden = {
      display: 'none'
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    const { clickNext, clickPrevious } = this.props;
    clickNext(this.next);
    clickPrevious(this.previous);
  }

  // get the classmame of steps
  getClassName = (className, i, errorClassName) => {
    let liClassName;
    const { navState } = this.state;
    const { stepsNavigation } = this.props;
    if (typeof errorClassName === 'undefined' && navState.styles[i] === `done step${i}`) {
      liClassName = `${className}-${navState.styles[i]} stepper-error-done`;
    } else if (typeof errorClassName === 'undefined' && navState.styles[i] === `doing step${i}`) {
      liClassName = `${className}-${navState.styles[i]} stepper-error-doing`;
    } else if (typeof errorClassName === 'undefined' && navState.styles[i] === `todo step${i}`) {
      liClassName = `${className}-${navState.styles[i]}`;
    } else if (errorClassName[i] && navState.styles[i] === `doing step${i}`) {
      liClassName = `${className}-${navState.styles[i]} stepper-error-doing`;
    } else if (errorClassName[i] && navState.styles[i] === `done step${i}`) {
      liClassName = `${className}-${navState.styles[i]} stepper-error-done`;
    } else if (!errorClassName[i] && navState.styles[i] === `todo step${i}`) {
      liClassName = `${className}-${navState.styles[i]} stepper-completed`;
    } else {
      liClassName = `${className}-${navState.styles[i]}`;
    }
    if (!stepsNavigation) {
      liClassName += ' no-hl';
    }

    return liClassName;
  }

  // set the nav state
  setNavState = next => {
    const { steps } = this.props;
    this.setState({ navState: this.getNavStates(next, steps.length) });

    if (next < steps.length) {
      this.setState({ compState: next });
    }

    this.checkNavState(next);
  }

  // update the header nav states via classes so they can be styled via css
  getNavStates = (indx, length) => {
    const styles = [];
    for (let i = 0; i < length; i++) {
      if (i < indx) {
        styles.push(`done step${i}`);
      } else if (i === indx) {
        styles.push(`doing step${i}`);
      } else {
        styles.push(`todo step${i}`);
      }
    }

    return { current: indx, styles };
  };

  getPrevNextBtnLayout = currentStep => {
    const { steps } = this.props;
    // first set default values
    let showPreviousBtn = true;
    let showNextBtn = true;

    // first step hide previous btn
    if (currentStep === 0) {
      showPreviousBtn = false;
    }

    // last step hide next btn, hide previous btn if supplied as props
    if (currentStep >= steps.length - 1) {
      showNextBtn = false;
    }

    return {
      showPreviousBtn,
      showNextBtn
    };
  }

  // which step are we in?
  checkNavState = nextStep => {
    const { onStepChange } = this.props;
    if (onStepChange) {
      onStepChange(nextStep);
    }
  }

  // handles keydown on enter being pressed in any Child component input area. in this case it goes to the next (ignore textareas as they should allow line breaks)
  handleKeyDown = evt => {
    const { preventEnterSubmission } = this.props;
    if (evt.which === 13) {
      if (!preventEnterSubmission && evt.target.type !== 'textarea') {
        this.next();
      } else if (evt.target.type !== 'textarea') {
        evt.preventDefault();
      }
    }
  }

  // this utility method lets Child components invoke a direct jump to another step
  jumpToStep = evt => {
    const { compState } = this.state;
    const { stepsNavigation, steps, getInput } = this.props;
    if (typeof evt.target === 'undefined') {
      // a child step wants to invoke a jump between steps. in this case 'evt' is the numeric step number and not the JS event
      this.setNavState(evt);
    } else {
      getInput(evt.target.value);
      // the main navigation step ui is invoking a jump between steps
      // if stepsNavigation is turned off or user clicked on existing step again (on step 2 and clicked on 2 again) then ignore
      if (!stepsNavigation || evt.target.value === compState) {
        evt.preventDefault();
        evt.stopPropagation();

        return;
      }

      // evt is a react event so we need to persist it as we deal with aync promises which nullifies these events (https://facebook.github.io/react/docs/events.html#event-pooling)
      evt.persist();

      const movingBack = evt.target.value < compState; // are we trying to move back or front?
      let passThroughStepsNotValid = false; // if we are jumping forward, only allow that if inbetween steps are all validated. This flag informs the logic...
      let proceed = false; // flag on if we should move on

      this.abstractStepMoveAllowedToPromise(movingBack)
        .then((valid = true) => {
          // validation was a success (promise or sync validation). In it was a Promise's resolve()
          // ... then proceed will be undefined, so make it true. Or else 'proceed' will carry the true/false value from sync
          proceed = valid;

          if (!movingBack) {
            this.updateStepValidationFlag(proceed);
          }

          if (proceed) {
            if (!movingBack) {
              // looks like we are moving forward, 'reduce' a new array of step>validated values we need to check and
              // ... 'some' that to get a decision on if we should allow moving forward
              passThroughStepsNotValid = steps
                .reduce((a, c, i) => {
                  if (i >= compState && i < evt.target.value) {
                    a.push(c.validated);
                  }
                  return a;
                }, [])
                .some(c => c === false);
            }
          }
        })
        .catch(() => {
          // Promise based validation was a fail (i.e reject())
          if (!movingBack) {
            this.updateStepValidationFlag(false);
          }
        })
        .then(() => {
          // this is like finally(), executes if error no no error
          if (proceed && !passThroughStepsNotValid) {
            if (evt.target.value === steps.length - 1 && compState === steps.length - 1) {
              this.setNavState(steps.length);
            } else {
              this.setNavState(evt.target.value);
            }
          }
        })
        .catch(e => {
          if (e) {
            // see note below called "CatchRethrowing"
            // ... plus the finally then() above is what throws the JS Error so we need to catch that here specifically
            setTimeout(() => {
              throw e;
            });
          }
        });
    }
  }

  next() {
    const { compState } = this.state;
    this.abstractStepMoveAllowedToPromise()
      .then((proceed = true) => {
        // validation was a success (promise or sync validation). In it was a Promise's resolve() then proceed will be undefined,
        // ... so make it true. Or else 'proceed' will carry the true/false value from sync validation
        this.updateStepValidationFlag(proceed);

        if (proceed) {
          this.setNavState(compState + 1);
        }
      })
      .catch(e => {
        if (e) {
          // CatchRethrowing: as we wrap StepMoveAllowed() to resolve as a Promise, the then() is invoked and the next React Component is loaded.
          // ... during the render, if there are JS errors thrown (e.g. ReferenceError) it gets swallowed by the Promise library and comes in here (catch)
          // ... so we need to rethrow it outside the execution stack so it behaves like a notmal JS error (i.e. halts and prints to console)
          //
          setTimeout(() => {
            throw e;
          });
        }

        // Promise based validation was a fail (i.e reject())
        this.updateStepValidationFlag(false);
      });
  }

  // move behind via previous button
  previous = () => {
    const { compState } = this.state;
    if (compState > 0) {
      this.setNavState(compState - 1);
    }
  }

  // update step's validation flag
  updateStepValidationFlag = (val = true) => {
    const { compState } = this.state;
    const { steps } = this.props;
    steps[compState].validated = val; // note: if a step component returns 'underfined' then treat as "true".
  }

  // are we allowed to move forward? via the next button or via jumpToStep?
  stepMoveAllowed = (skipValidationExecution = false) => {
    let proceed = true;
    const { dontValidate } = this.props;

    if (dontValidate) {
      proceed = true;
    }
    if (skipValidationExecution) {
      proceed = true;
    }

    return proceed;
  }

  abstractStepMoveAllowedToPromise = movingBack => Promise.resolve(this.stepMoveAllowed(movingBack));

  // render the steps as stepsNavigation
  renderSteps() {
    const { errorClassName, steps } = this.props;
    return steps.map((s, i) => (
      <li
        className={this.getClassName('progtrckr', i, errorClassName)}
        onClick={evt => {
          this.jumpToStep(evt);
        }}
        key={s.name}
        value={i}
        data-id={i}
      >
        <em>{i + 1}</em>
        <span> {steps[i].name} </span>
      </li>
    ));
  }

  // main render of stepzilla container
  render() {
    const { steps, showSteps } = this.props;
    const { compState } = this.state;
    const cloneExtensions = {
      jumpToStep: t => {
        this.jumpToStep(t);
      }
    };

    const componentPointer = steps[compState].component;

    if (
      componentPointer instanceof Component ||
      (componentPointer.type && componentPointer.type.prototype instanceof Component)
    ) {
      cloneExtensions.ref = 'activeComponent';
    }

    const compToRender = React.cloneElement(componentPointer, cloneExtensions);

    return (
      <div className="stepper">
        <div
          className="multi-step"
          role="presentation"
          onKeyDown={evt => {
            this.handleKeyDown(evt);
          }}
        >
          <div className="progtrckr-steps">
            {showSteps ? <ol className="progtrckr">{this.renderSteps()}</ol> : <span />}
          </div>
        </div>
        <div className="progtrckr-component">{compToRender}</div>
      </div>
    );
  }
}

StepZilla.defaultProps = {
  showSteps: true,
  stepsNavigation: true,
  dontValidate: false,
  preventEnterSubmission: false,
  startAtStep: 0
};

StepZilla.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      component: PropTypes.element.isRequired
    })
  ).isRequired,
  showSteps: PropTypes.bool,
  stepsNavigation: PropTypes.bool,
  dontValidate: PropTypes.bool,
  preventEnterSubmission: PropTypes.bool,
  startAtStep: PropTypes.number,
  onStepChange: PropTypes.func,
  errorClassName: PropTypes.array,
  clickNext: PropTypes.func,
  clickPrevious: PropTypes.func,
  getInput: PropTypes.func
};
