/* eslint-disable complexity */
import React, { Component, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import union from 'lodash/union';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import Modal from 'react-modal';
import { PDFExport } from '@progress/kendo-react-pdf';

import { selectUserDetails } from '../../../reducers/authReducer.js';
import brandInfo from '../../../constants/json/checkboxes/brandInfo.json';
import productInfo from '../../../constants/json/checkboxes/productInfo.json';
import Season from '../../../constants/json/checkboxes/season.json';
import MaterialTypes from '../../../constants/json/radiobuttons/materialTypes.json';
import individualTest from '../../../constants/json/checkboxes/individualTestPackages.json';
import location from '../../../constants/json/radiobuttons/location.json';
import { H4, H5, H6, Button, IconButton } from '../../../components/index.js';
import TrfPrintable from '../../trf_review/Trf/TrfPrintable.js';
import PageTemplate from '../../test_details/pageTemplate.js';
import { formatDate, strUpperCase, trfSampleTypeName, trfAgeGroupName, trfCertificationName } from '../../../util/index.js';
import previewDetailsJson from './previewDetailsJson.js';
import { trim } from 'lodash';

const PreviewPageTemplate = props => (
  <PageTemplate header="NIKE TEST REQUEST FORM (TRF)" isTRFReport={true} {...props} />
);
const flattenItems = (items, key) =>
  items.reduce((flattenedItems, item) => {
    flattenedItems.push(item);
    if (Array.isArray(item[key])) {
      flattenedItems = flattenedItems.concat(flattenItems(item[key], key));
    }
    return flattenedItems;
  }, []);

const displayCertification = (testingInfoStates) => {
  const certificationArry = testingInfoStates.certification
  const filterCertification = certificationArry.filter(data => data.name !=="" && data.number !=="") 
  
  return (
    <div>
      {filterCertification && filterCertification.length > 0 && typeof filterCertification !== 'undefined'
      ? (
      filterCertification.map((data, index) => {
       return (
        <Fragment>
        <div class="ncss-col-sm-3 description u-bold p2-sm  flex-head-center">{index === 0 ? "Certificate" : '' } </div>
        <div class="ncss-col-sm-9 p2-sm inline-flex-display material_description">
          <div class="ncss-col-sm-12 h-20">
            <h6 class=" ncss-col-sm-5 tab-class u-bold ">{trfCertificationName(data.name) === 'Other' ? strUpperCase(data.name) : trfCertificationName(data.name)}</h6>
            <div class="ncss-col-sm-2 description u-bold p2-sm ">Number</div>
            <div class="ncss-col-sm-5 p2-sm inline-flex-display  ">
                <h6 class="ncss-col-sm-5 tab-class u-bold ">{data.number}</h6>
            </div>
          </div>
        </div></Fragment>
       )
      })
      ) : ''}
    </div>
  )
}
const label597 = find(individualTest[0].common, ['id', 597]);
const returnMaterialTypeSection = (testingInfoStates) => {
  return (<div className="ncss-col-sm-9 p2-sm inline-flex-display material_description">
    {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.materialType ? (
      <div className="ncss-col-sm-12">
        {Object.prototype.hasOwnProperty.call(testingInfoStates, 'materialType') &&
          typeof testingInfoStates.materialType !== 'undefined' &&
          !isEmpty(testingInfoStates.materialType) &&
          !testingInfoStates.materialType.every(function (v) { return v === null; }) &&
          testingInfoStates.materialType !== null ? (
          testingInfoStates.materialType.filter(element => element != null)
            .map((data, index) =>
              <span>
                <H6 className="ncss-col-sm-5 tab-class u-bold ">
                  {find(MaterialTypes, ['id', data]).label}
                </H6>
                <div className="ncss-col-sm-2 description u-bold p2-sm ">Variants</div>
                <div className="ncss-col-sm-5 p2-sm inline-flex-display  ">
                  {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.materialType ? (
                    <span className="ncss-col-sm-12 pl0-sm">
                      {Object.prototype.hasOwnProperty.call(testingInfoStates, 'materialType') &&
                        Object.prototype.hasOwnProperty.call(
                          testingInfoStates,
                          `variants${data}`
                        ) &&
                        testingInfoStates[`variants${data}`] && data !== 400 && data !== 415 ? (
                        <H6 className=" u-bold ">
                          {' '}
                          {
                            find(flattenItems(MaterialTypes, 'variants'), [
                              'id',
                              testingInfoStates[`variants${data}`]
                            ]).label
                          }
                          {testingInfoStates[`variants${data}`] === 4052 ?
                            (testingInfoStates.subVariants === 4050 ? ' - Not White' : ' - White')
                            : ''}
                        </H6>
                      ) : (data === 400 || data === 415) ?
                        (
                          <div className=" u-bold">
                            {testingInfoStates[`variants${data}`] && testingInfoStates[`variants${data}`].filter(element => element != null).map(data => (
                              <H6>
                                {find(flattenItems(MaterialTypes, 'variants'), [
                                  'id',
                                  data
                                ]).label}
                              </H6>
                            ))}
                          </div>
                        )
                        : (
                          <div className="  u-bold">
                            <H6 className=" error-text-not text-left ">None </H6>
                          </div>
                        )}
                    </span>
                  ) : (
                    <div className="ncss-col-sm-4  u-bold">
                      <H6 className="error-text-not text-left ">None</H6>
                    </div>
                  )}
                </div>
              </span>
            )
        ) :
          (
            <div className="u-bold">
              <H6 className="error-text-not text-left">Not Added</H6>
            </div>
          )
        }
      </div>
    ) : (
      <div className="ncss-col-sm-8  u-bold">
        <H6 className="error-text-not text-left ">Not Added</H6>
      </div>
    )}
  </div>)
}

const returnInkSection = (testingInfoStates) => {
  return (
    <div>
      {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.materialType &&
        (
          //testingInfoStates.materialType.includes(404) ||
          testingInfoStates.materialType.includes(408) ||
          testingInfoStates.materialType.includes(405) ||
          testingInfoStates.materialType.includes(418)) ? (
        <div>
          {Object.prototype.hasOwnProperty.call(testingInfoStates, 'inkSystemName') && testingInfoStates.inkSystemName ? (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center ">
                Ink System Name
              </H6>
              <H6 className=" ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                {testingInfoStates.inkSystemName}
              </H6>
            </span>
          ) : (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Ink System Name
              </H6>
              <div className="ncss-col-sm-6  u-bold">
                <H6 className="error-text-not-ink text-left ">Not Added</H6>
              </div>
            </span>
          )}
          {Object.prototype.hasOwnProperty.call(testingInfoStates, 'baseName') && testingInfoStates.baseName ? (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Base Name
              </H6>
              <H6 className="  ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                {testingInfoStates.baseName}
              </H6>
            </span>
          ) : (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Base Name
              </H6>
              <div className="ncss-col-sm-6  u-bold">
                <H6 className="error-text-not-ink text-left ">Not Added</H6>
              </div>
            </span>
          )}
          {Object.prototype.hasOwnProperty.call(testingInfoStates, 'pigmentColor') && testingInfoStates.pigmentColor ? (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Pigment Color
              </H6>
              <H6 className="  ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                {testingInfoStates.pigmentColor}
              </H6>
            </span>
          ) : (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Pigment Color
              </H6>
              <div className="ncss-col-sm-6  u-bold">
                <H6 className="error-text text-left ">
                  Please Select Pigment Color from TESTING INFORMATION (mandatory)
                </H6>
              </div>
            </span>
          )}
          {Object.prototype.hasOwnProperty.call(testingInfoStates, 'additive1Name') && testingInfoStates.additive1Name ? (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Additive 1 Name
              </H6>
              <H6 className="  ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                {testingInfoStates.additive1Name}
              </H6>
            </span>
          ) : (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Additive 1 Name
              </H6>
              <div className="ncss-col-sm-6  u-bold">
                <H6 className="error-text-not-ink text-left ">Not Added</H6>
              </div>
            </span>
          )}
          {Object.prototype.hasOwnProperty.call(testingInfoStates, 'additive2Name') && testingInfoStates.additive2Name ? (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Additive 2 Name
              </H6>
              <H6 className="  ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                {testingInfoStates.additive2Name}
              </H6>
            </span>
          ) : (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Additive 2 Name
              </H6>
              <div className="ncss-col-sm-6  u-bold">
                <H6 className="error-text-not-ink text-left ">Not Added</H6>
              </div>
            </span>
          )}
          {Object.prototype.hasOwnProperty.call(testingInfoStates, 'additive3Name') && testingInfoStates.additive3Name ? (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Additive 3 Name
              </H6>
              <H6 className="  ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                {testingInfoStates.additive3Name}
              </H6>
            </span>
          ) : (
            <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
              <H6 className="  ncss-col-sm-3 u-bold description inline-flex-display flex-head-center">
                Additive 3 Name
              </H6>
              <div className="ncss-col-sm-6  u-bold">
                <H6 className="error-text-not-ink text-left ">Not Added</H6>
              </div>
            </span>
          )}
        </div>
      ) : null}
    </div>
  )
}
export const ReviewData = ({ brandInfoStates, testingInfoStates, labLocationStates, packagingTestIds, rslOtherTestsIds, selectedMaterialTypes,getRecycledContentValue }) => (
  <div>
    <div className="p2-sm">
      <H4 className="text-color-accent u-bold page-title-has-back sub-heading">1. MATERIAL SELECTION</H4>
      <H4 className="u-bold p2-sm">Brand Information</H4>
      <div>
        <div className="ncss-col-sm-3 description p2-sm u-bold p2-sm">Brand tested for</div>
        <div className="ncss-col-sm-8 p2-sm u-bold">
          {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.brandId ? (
            <div>
              {Object.prototype.hasOwnProperty.call(brandInfoStates, 'brandId') ? (
                <div key={find(brandInfo, ['id', brandInfoStates.brandId]).icon} className="ncss-col-sm-2">
                  <i
                    className={`${find(brandInfo, ['id', brandInfoStates.brandId]).icon} ${brandInfoStates.brandId === 100 ? 'review-icon-nike' : 'review-icon'
                      } fs32-sm pr2-sm d-sm-ib va-sm-m`}
                  />
                </div>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className="error-text text-left ">
                    Please Select Brand from MATERIAL SELECTION (mandatory)
                  </H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className="error-text text-left ">
                Please Select Brand from MATERIAL SELECTION (mandatory)
              </H6>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Product Type</div>
        <div className="ncss-col-sm-8 p2-sm u-bold">
          {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.productId ? (
            <div>
              {Object.prototype.hasOwnProperty.call(brandInfoStates, 'productId') ? (
                <H6 className="ncss-col-sm-2">
                  {find(productInfo, ['id', parseInt(brandInfoStates.productId)]).label}
                </H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className="error-text text-left ">
                    Please Select Product Type from MATERIAL SELECTION (mandatory)
                  </H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className="error-text text-left ">
                Please Select Product Type from MATERIAL SELECTION (mandatory)
              </H6>
            </div>
          )}
        </div>
      </div>
      {/* <H4 className="u-bold p2-sm">Licensee Information</H4> */}
      <div className="ncss-col-sm-3 description u-bold p2-sm">Licensee Name</div>
      <div className="ncss-col-sm-8 p2-sm u-bold">
        {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.isLicensee === "Yes" ?
          <div>
            {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.licensee ? (
              <div>
                {Object.prototype.hasOwnProperty.call(brandInfoStates, 'licensee') ? (
                  <H6 className="ncss-col-sm-12">
                    {brandInfoStates.licensee}
                  </H6>
                ) : (
                  <div className="ncss-col-sm-8  u-bold">
                    <H6 className="error-text text-left ">
                      Please Select  Licensee Name from the Licensee Selection (mandatory)
                    </H6>
                  </div>
                )}
              </div>
            ) : (
              <div className="ncss-col-sm-8  u-bold">
                <H6 className="error-text text-left ">
                  Please Select Licensee Name from the Licensee Selection (mandatory)
                </H6>
              </div>
            )}

          </div>
          : <div className="ncss-col-sm-8  u-bold">
            <H6 className="text-left ">
              Not added
            </H6>
          </div>}
      </div>
      <div className="ncss-col-sm-3 description u-bold p2-sm">Link TRF</div>
      <div className="ncss-col-sm-8 p2-sm u-bold">
        {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.isRetest === "Yes" ?
          <div>
            {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.originalTRF ? (
              <div>
                {Object.prototype.hasOwnProperty.call(brandInfoStates, 'originalTRF') ? (
                  <H6 className="ncss-col-sm-12">
                    {brandInfoStates.originalTRF}
                  </H6>
                ) : (
                  <div className="ncss-col-sm-8  u-bold">
                    <H6 className="error-text text-left ">
                      Please Select Re-test TRF (mandatory)
                    </H6>
                  </div>
                )}
              </div>
            ) : (
              <div className="ncss-col-sm-8  u-bold">
                <H6 className="error-text text-left ">
                Please Select Re-test TRF (mandatory)
                </H6>
              </div>
            )}

          </div>
          : <div className="ncss-col-sm-8  u-bold">
            <H6 className="text-left ">
              Not added
            </H6>
          </div>}
      </div>

      <H4 className="u-bold p2-sm">Material Information</H4>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Material Name</div>
        <div className="ncss-col-sm-9 p2-sm u-bold">
          {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.materialName ? (
            <div>
              {Object.prototype.hasOwnProperty.call(brandInfoStates, 'materialName') ? (
                <H6 className="text-color-accent ncss-col-sm-12">{brandInfoStates.materialName}</H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className="error-text text-left ">
                    Please Select material from MATERIAL SELECTION (mandatory)
                  </H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className="error-text text-left ">
                Please Select material from MATERIAL SELECTION (mandatory)
              </H6>
            </div>
          )}
        </div>
      </div>
      <H4 className="text-color-accent u-bold page-title-has-back sub-heading">2. MATERIAL INFORMATION</H4>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm inline-flex-display">Supplier Name</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {(testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.supplierName) ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'supplierName') ? (
                <H6 className="ncss-col-sm-6 u-bold inline-flex-display">{testingInfoStates.supplierName}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm inline-flex-display">Location Name</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {(testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.supLocName) ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'supLocName') ? (
                <H6 className="ncss-col-sm-6 u-bold inline-flex-display">{testingInfoStates.supLocName}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm inline-flex-display">Material Number</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.materialNumber ? (
            <div>
              {Object.prototype.hasOwnProperty.call(brandInfoStates, 'materialNumber') ? (
                <H6 className="ncss-col-sm-6 u-bold inline-flex-display">{brandInfoStates.materialNumber}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm inline-flex-display">PDM Number</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.pdmNumber ? (
            <div>
              {Object.prototype.hasOwnProperty.call(brandInfoStates, 'pdmNumber') ? (
                <H6 className="ncss-col-sm-6 u-bold inline-flex-display">{brandInfoStates.pdmNumber}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm inline-flex-display">Material ID</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {brandInfoStates && typeof brandInfoStates !== 'undefined' && brandInfoStates.refId ? (
            <div>
              {Object.prototype.hasOwnProperty.call(brandInfoStates, 'refId') ? (
                <H6 className="ncss-col-sm-6 u-bold inline-flex-display">{brandInfoStates.refId}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm inline-flex-display">Sample Type</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.sampleType ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'sampleType') ? (
                <H6 className="ncss-col-sm-8 u-bold inline-flex-display">{trfSampleTypeName(testingInfoStates.sampleType)}</H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className=" error-text text-left "> Please Select Sample Type from MATERIAL INFORMATION (mandatory)</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className=" error-text text-left ">Please Select Sample Type from MATERIAL INFORMATION (mandatory)</H6>
            </div>
          )}
        </div>
      </div>
      <H4 className="u-bold p2-sm">Color Information</H4>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Color Code</div>
        <div className="ncss-col-sm-8 p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.colorCode ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'colorCode') ? (
                <H6 className="ncss-col-sm-2 u-bold">{testingInfoStates.colorCode}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Color Name</div>
        <div className="ncss-col-sm-8 p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.colorName ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'colorName') ? (
                <H6 className="ncss-col-sm-2 u-bold">{testingInfoStates.colorName}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Colorway</div>
        <div className="ncss-col-sm-8 p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.colorway ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'colorway') ? (
                <H6 className="ncss-col-sm-2 u-bold">{testingInfoStates.colorway}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="error-text-not text-left ">Not Added</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Age Group</div>
        <div className="ncss-col-sm-8 p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.ageGroup ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'ageGroup') ? (
                <H6 className="ncss-col-sm-8 u-bold">{trfAgeGroupName(testingInfoStates.ageGroup)}</H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className=" error-text text-left ">Please Select Age Group from MATERIAL INFORMATION (mandatory)</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className=" error-text text-left ">Please Select Age Group from MATERIAL INFORMATION (mandatory)</H6>
            </div>
          )}
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Sample Color</div>
        <div className="ncss-col-sm-8 p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.sampleColor ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'sampleColor') ? (
                <H6 className="ncss-col-sm-2 u-bold">{testingInfoStates.sampleColor !== '' ? strUpperCase(testingInfoStates.sampleColor) : ''}</H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className=" error-text text-left ">Please Select Sample Color from MATERIAL INFORMATION (mandatory)</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className=" error-text text-left ">Please Select Sample Color from MATERIAL INFORMATION (mandatory)</H6>
            </div>
          )}
        </div>
      </div>
      <H4 className="u-bold p2-sm">Sample Description</H4>
      <div className="ncss-col-sm-3 description u-bold p2-sm">Description</div>
      <div className="ncss-col-sm-8 p2-sm">
        {testingInfoStates && typeof testingInfoStates !== 'undefined' && trim(testingInfoStates['sampleDescription']) !== '' && testingInfoStates['sampleDescription'] !== 'N/A' ? (
          <div>
            {Object.prototype.hasOwnProperty.call(testingInfoStates, 'sampleDescription') ? (
              <H6 className="ncss-col-sm-2 u-bold">{testingInfoStates['sampleDescription']}</H6>
            ) : (
              <div className="ncss-col-sm-2  u-bold">
                <H6 className="error-text-not text-left ">Not Added</H6>
              </div>
            )}
          </div>
        ) : (
          <div className="ncss-col-sm-8  u-bold">
            <H6 className="error-text  text-left ">
              Please Enter sample description from TESTING INFORMATION (mandatory)
            </H6>
          </div>
        )}
      </div>
      <div />
      {testingInfoStates && typeof testingInfoStates !== 'undefined' && typeof testingInfoStates.certification !== 'undefined' && testingInfoStates.certification.length > 0 ?
       displayCertification(testingInfoStates) : ''}
      <H4 className="text-color-accent u-bold page-title-has-back sub-heading">3. SEASON</H4>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Season</div>
        <div className="ncss-col-sm-8 p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.season ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'season') ? (
                <H6 className="ncss-col-sm-2 u-bold">
                  {find(Season, ['id', testingInfoStates.season]).label}
                </H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className=" error-text text-left ">
                    Please Select season from TESTING INFORMATION (mandatory)
                  </H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className="error-text text-left ">
                Please Select season from TESTING INFORMATION (mandatory)
              </H6>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Year</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.Year ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'Year') ? (
                <H6 className="ncss-col-sm-2">{(testingInfoStates.Year).year()}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="u-bold text-left ">{new Date().getFullYear()}</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="u-bold text-left ">{new Date().getFullYear()}</H6>
            </div>
          )}
        </div>
      </div>
      <H4 className="text-color-accent u-bold page-title-has-back sub-heading">4. TESTING INFORMATION</H4>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Testing Type</div>
        <div className="ncss-col-sm-8  u-bold ">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'testingType') ? (
                <H6 className="ncss-col-sm-3 ">
                  {testingInfoStates.testingType === 'RD'
                    ? 'R&D'
                    : 'PROD'}
                </H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className="error-text text-left ">
                    Please Select Testing Type from TESTING INFORMATION (mandatory)
                  </H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className="error-text text-left ">
                Please Select Testing Type from TESTING INFORMATION (mandatory)
              </H6>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Does material have repellent finish?</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'repellent') ? (
                <H6 className="ncss-col-sm-2">{testingInfoStates.repellent}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="u-bold text-left ">No</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="u-bold text-left ">No</H6>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Is the material intended to come into contact with food or mouth?</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'foodOrMouth') ? (
                <H6 className="ncss-col-sm-2">{testingInfoStates.foodOrMouth}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="u-bold text-left ">No</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="u-bold text-left ">No</H6>
            </div>
          )}
        </div>
      </div>
      {brandInfoStates && typeof brandInfoStates !== 'undefined' && parseInt(brandInfoStates.productId) === 202 ? (
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Is this material intended for eye-wear frames?</div>
          <div className="ncss-col-sm-8  u-bold p2-sm">
            {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
              <div>
                {Object.prototype.hasOwnProperty.call(testingInfoStates, 'eyeWear') ? (
                  <H6 className="ncss-col-sm-2">{testingInfoStates.eyeWear}</H6>
                ) : (
                  <div className="ncss-col-sm-2  u-bold">
                    <H6 className="u-bold text-left ">No</H6>
                  </div>
                )}
              </div>
            ) : (
              <div className="ncss-col-sm-2  u-bold">
                <H6 className="u-bold text-left ">No</H6>
              </div>
            )}
          </div>
        </div>) : ''}
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm"> Does this material sample contain recycled content?</div>
          <div className="ncss-col-sm-8  u-bold p2-sm">
            {testingInfoStates && typeof testingInfoStates !== 'undefined' &&  testingInfoStates !== null ? (
              <div>
                {Object.prototype.hasOwnProperty.call(testingInfoStates, 'recycle') ? (
                  <H6 className="ncss-col-sm-2">{testingInfoStates.recycle}</H6>
                ) : (
                  <div className="ncss-col-sm-8  u-bold">
                  <H6 className="error-text text-left ">
                    Please Select recycled content from MATERIAL INFORMATION (mandatory)
                  </H6>
                </div>
                )}
              </div>
            ) : (
              <div className="ncss-col-sm-2  u-bold">
                <H6 className="u-bold text-left ">No</H6>
              </div>
            )}
          </div>
        </div>
        {testingInfoStates && testingInfoStates.recycle === 'Yes' ? (
          <Fragment>
          <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm"> 
          List the samples recycled material source (e.g. post industrial, post consumer, pre consumer, etc.) </div>
          <div className="ncss-col-sm-8  u-bold p2-sm">
            {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
              <div>
                {Object.prototype.hasOwnProperty.call(testingInfoStates, 'listRecycle') ? (
                  <H6 className="ncss-col-sm-2">{testingInfoStates.listRecycle}</H6>
                ) : (
                  <div className="ncss-col-sm-8  u-bold">
                  <H6 className="error-text text-left ">
                    Please Enter samples recycled material source from MATERIAL INFORMATION (mandatory)
                  </H6>
                </div>
                )}
              </div>
            ) : (
              <div className="ncss-col-sm-2  u-bold">
                <H6 className="u-bold text-left ">No</H6>
              </div>
            )}
          </div>
        </div>
        <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm"> 
        Describe the recycled content in the material sample (e.g. 20% recycled PET, 10% recycled TPU, etc.)</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'describeRecycle') ? (
                <H6 className="ncss-col-sm-2">{testingInfoStates.describeRecycle}</H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                <H6 className="error-text text-left ">
                  Please Enter recycled content in the material sample from MATERIAL INFORMATION (mandatory)
                </H6>
              </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="u-bold text-left ">No</H6>
            </div>
          )}
        </div>
      </div></Fragment>
        ) : ""}

      {brandInfoStates && typeof brandInfoStates !== 'undefined' && (parseInt(brandInfoStates.productId) === 204 || parseInt(brandInfoStates.productId) === 206) ? (
        <Fragment>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">
            Will this electonic material be embedded into a finished product (e.g. Footwear)?</div>
          <div className="ncss-col-sm-8  u-bold p2-sm">
            {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
              <div>
                {Object.prototype.hasOwnProperty.call(testingInfoStates, 'isElectEmbedded') ? (
                  <H6 className="ncss-col-sm-2">{testingInfoStates.isElectEmbedded}</H6>
                ) : (
                  <div className="ncss-col-sm-2  u-bold">
                    <H6 className="u-bold text-left ">No</H6>
                  </div>
                )}
              </div>
            ) : (
              <div className="ncss-col-sm-2  u-bold">
                <H6 className="u-bold text-left ">No</H6>
              </div>
            )}
          </div>
        </div>
        <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">
        Is this electronic material intended to come in contact with user's skin?</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
            <div>
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'skinContact') ? (
                <H6 className="ncss-col-sm-2">{testingInfoStates.skinContact}</H6>
              ) : (
                <div className="ncss-col-sm-2  u-bold">
                  <H6 className="u-bold text-left ">No</H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="u-bold text-left ">No</H6>
            </div>
          )}
        </div>
      </div></Fragment>
        ) : ''}
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Enable TP2</div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' && testingInfoStates.enableTP2 ? (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="u-bold text-left ">Yes</H6>
            </div>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="u-bold text-left ">No</H6>
            </div>
          )}
        </div>
      </div>
      {/* <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Does this material contain recycled content? 
        </div>
        <div className="ncss-col-sm-8  u-bold p2-sm">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
            <div>
              {
              Object.prototype.hasOwnProperty.call(testingInfoStates, 'subRecycledContent') === 'Post Consumer' ||
              Object.prototype.hasOwnProperty.call(testingInfoStates, 'subRecycledContent') === 'Post-Industrial' ||
              Object.prototype.hasOwnProperty.call(testingInfoStates, 'subRecycledContent') === 'Post-Industrial' ||
              Object.prototype.hasOwnProperty.call(testingInfoStates, 'subRecycledContent') !== null  ||
              Object.prototype.hasOwnProperty.call(testingInfoStates, 'subRecycledContent') !== ''
              ? (
                <H6 className="ncss-col-sm-2">{testingInfoStates.subRecycledContent !== null ? testingInfoStates.subRecycledContent  : 'No'}</H6>
              ) : (
                  <div className="ncss-col-sm-2  u-bold">
                    <H6 className="u-bold text-left ">No</H6>
                  </div>
                )}
            </div>
          ) : (
              <div className="ncss-col-sm-2  u-bold">
                <H6 className="u-bold text-left ">No</H6>
              </div>
            )}
        </div>
      </div> */}
      {brandInfoStates && (parseInt(brandInfoStates.productId) !== 204 && parseInt(brandInfoStates.productId) !== 206) ? (
        <Fragment>
          <div>
            <div className="ncss-col-sm-3 description u-bold p2-sm  flex-head-center">
              Material Types 
            </div>
            {returnMaterialTypeSection(testingInfoStates)}
          </div>
          {returnInkSection(testingInfoStates)}
        </Fragment>) : ''}
      {brandInfoStates && (parseInt(brandInfoStates.productId) === 204 || parseInt(brandInfoStates.productId) === 206) ? (
        <Fragment>
          <div>
            <div class="ncss-col-sm-3 description u-bold p2-sm  flex-head-center"> Material Types     
            </div>
            {testingInfoStates.materialType.filter(element => element !== null).length > 0 ? (
              <Fragment>
                {returnMaterialTypeSection(testingInfoStates)}
                {returnInkSection(testingInfoStates)}
                </Fragment>
            ) :
              (
                <div class="ncss-col-sm-8  u-bold p2-sm">
                  <div class="ncss-col-sm-2  u-bold">
                    <h6 class=" error-text-not text-left ">Not Added</h6>
                  </div>
                </div>
              )}
          </div>
        </Fragment>
      ) : (
        ''
      )}
      <div>

        <div className="ncss-col-sm-3 description u-bold p2-sm inline-flex-display">Individual Packages</div>
        <div className="ncss-col-sm-8 p2-sm inline-flex-display">
          {testingInfoStates && typeof testingInfoStates !== 'undefined' ? (
            <span className="ncss-col-sm-12 pl0-sm">
              {Object.prototype.hasOwnProperty.call(testingInfoStates, 'testPackages') ||
                Object.prototype.hasOwnProperty.call(testingInfoStates, 'testPackagesToys') ||
                Object.prototype.hasOwnProperty.call(testingInfoStates, 'testPackagesElectronic') ||
                Object.prototype.hasOwnProperty.call(testingInfoStates, 'testPackagesPackaging') ||
                Object.prototype.hasOwnProperty.call(testingInfoStates, 'testPackagesJewelry') ? (
                                    union(
                      testingInfoStates.testPackages,
                      testingInfoStates.testPackagesToys,
                      testingInfoStates.testPackagesElectronic,
                      testingInfoStates.testPackagesPackaging,
                      testingInfoStates.testPackagesJewelry
                    )
                      .filter(element => element != null)
                      .map((data, index) =>
                        data !== null || data !== '' ? (
                          <span>
                            <div key={data} className="ncss-col-sm-12  u-bold mt1-sm mb1-sm">
                              <H6>
                                {' '}
                                {
                                  find(
                                    union(
                                      individualTest[0].common,
                                      individualTest[0].toys,
                                      individualTest[0].electronic,
                                      individualTest[0].packaging,
                                      individualTest[0].jewelry
                                    ),
                                    ['id', data]
                                  ).label
                                }
                                {includes(Object.keys(testingInfoStates), `tp-variants${data}`)
                                  ? testingInfoStates[`tp-variants${data}`] !== 506 && testingInfoStates[`tp-variants${data}`] !== 594 ? ` - ${find(
                                    flattenItems(
                                      union(
                                        individualTest[0].common,
                                        individualTest[0].toys,
                                        individualTest[0].electronic,
                                        individualTest[0].packaging,
                                        individualTest[0].jewelry
                                      ),
                                      'variants'
                                    ),
                                    ['id', testingInfoStates[`tp-variants${data}`]]
                                  ).label
                                    }` : ''
                                  : ''}
                                

                              </H6>

                            </div>
                          </span>
                        ) : null
                      )
              ) :
                Object.prototype.hasOwnProperty.call(testingInfoStates, 'repellent') && testingInfoStates.repellent === 'Yes' ?
                  <H6 className="ncss-col-sm-12  u-bold mt1-sm mb1-sm" >
                    <span>
                      {label597 ? label597.label : ''}
                    </span>
                  </H6> :
                  (
                    <div className="ncss-col-sm-2  u-bold">
                      <H6 className="error-text-not text-left ">Not Added</H6>
                    </div>
                  )
              }
            </span>
          ) : (
            <div className="ncss-col-sm-2  u-bold">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        </div>
      </div>
      <H4 className="text-color-accent u-bold page-title-has-back sub-heading">5. LAB SELECTION</H4>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Select Location</div>
        <div className="ncss-col-sm-8 p2-sm">
          {labLocationStates && typeof labLocationStates !== 'undefined' && labLocationStates.lab ? (
            <div>
              {Object.prototype.hasOwnProperty.call(labLocationStates, 'lab') &&
                Object.prototype.hasOwnProperty.call(labLocationStates, `location${labLocationStates.lab}`) ? (
                <H6 className="ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                  {find(location, ['id', labLocationStates.lab]).lab} {' - '}
                  {
                    find(flattenItems(location, 'location'), [
                      'id',
                      labLocationStates[`location${labLocationStates.lab}`]
                    ]).area
                  }
                  {','}
                  {
                    find(flattenItems(location, 'location'), [
                      'id',
                      labLocationStates[`location${labLocationStates.lab}`]
                    ]).country
                  }
                </H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className=" error-text text-left ">
                    Please Select Location from LOCATION SELECTION (mandatory)
                  </H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className="error-text text-left ">
                Please Select Location from LOCATION SELECTION (mandatory)
              </H6>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Service Requested</div>
        <div className="ncss-col-sm-8 p2-sm">
          {labLocationStates && typeof labLocationStates !== 'undefined' ? (
            <div>
              {Object.prototype.hasOwnProperty.call(labLocationStates, 'serviceRequested') ? (
                <H6 className="ncss-col-sm-6 u-bold inline-flex-display flex-head-center">
                  {labLocationStates.serviceRequested}
                </H6>
              ) : (
                <div className="ncss-col-sm-8  u-bold">
                  <H6 className="error-text text-left ">
                    Please Select Service Requested from LOCATION SELECTION (mandatory)
                  </H6>
                </div>
              )}
            </div>
          ) : (
            <div className="ncss-col-sm-8  u-bold">
              <H6 className="error-text text-left ">
                Please Select Service Requested from LOCATION SELECTION (mandatory)
              </H6>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trfReportModal: false,
      getTrfDataToModal: [],
      paramsForExcel: {},
      previewData: []
    };
  }

  exportPreviewPDF = () => {

    this.previewPdf.save();

  }

  openTRFPreview = (data) => {
    this.setState({ trfReportModal: true });
    let previewDetailsJsonData = previewDetailsJson(data);
    this.setState({ previewData: previewDetailsJsonData })
  }

  closeTrfReport = () => { this.setState({ trfReportModal: false }) }

  componentDidMount() {
    const { displaySubmitButtonTrf, displayClearButtonTrf } = this.props;
    displaySubmitButtonTrf(true);
    displayClearButtonTrf(false);
  }
  setParams = params => {
    const { paramsForExcel } = this.state;
    if (paramsForExcel) {
      this.setState({ paramsForExcel: params });
    }
  };

  render() {
    const { data, userDetails } = this.props;
    const { previewData, trfReportModal } = this.state;
    //var returnTrfArry = { qrfDetails: '', frfDetails: '', trfDetails: data.trfDataForEdit }
    var returnDownloadObj = { trfDetails: previewData }
    return (
      <div className="form review_page ">
        <div className="p2-sm ncss-col-sm-12 download-button-review">
          {/* {Object.keys(returnTrfArry.trfDetails).length !== 0  && 
          <span style={{ float: 'right' }}>
            <i className="fa fas fa-download " />
            <Button className="btn-text download-print-button text-color-accent  mr5-sm" onClick={() => this.openTRFPreview(data)}>
              Preview / Download TRF
            </Button>
          </span> } */}
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">REVIEW</H4>
          <H5 className="u-bold description ncss-col-sm-12 pt5-sm">
            Please review all details. If corrections are needed, click on respective step to update. When ready to proceed, click SUBMIT.
          </H5>

        </div>
        <div className="ncss-col-sm-12 pt3-sm pb3-sm inline-flex-display material_description">
          <div className="ncss-col-sm-4">
            <span className="small-text description u-bold pt2-sm pb2-sm pr2-sm">Full Name :</span>
            <span className=" u-bold pl0-sm">
              {data.trfId ?
                data.trfDataForEdit.name ? (
                  <H6 className="ncss-col-sm-2 small-text pl0-sm">{data.trfDataForEdit.name}</H6>
                ) :

                  (
                    <H6 className="error-text-not small-text text-left ">Not Added</H6>
                  )
                :
                userDetails.fullName ? (
                  <H6 className="ncss-col-sm-2 small-text pl0-sm">{userDetails.fullName}</H6>
                )
                  :
                  <H6 className="error-text-not small-text text-left ">Not Added</H6>
              }

            </span>
          </div>
          <div className="ncss-col-sm-5">
            <span className="small-text description u-bold pt2-sm pb2-sm pr2-sm">E-mail ID:</span>
            <span className="small-text  u-bold pl0-sm ">
              {data.trfId ?
                data.trfDataForEdit.userEmail ? (
                  <H6 className="ncss-col-sm-2 small-text pl0-sm">{data.trfDataForEdit.userEmail}</H6>
                ) :

                  (
                    <H6 className="error-text-not small-text text-left ">Not Added</H6>
                  )
                :
                userDetails.email ? (
                  <H6 className="ncss-col-sm-2 small-text pl0-sm">{userDetails.email}</H6>
                )
                  :
                  <H6 className="error-text-not small-text text-left ">Not Added</H6>
              }
            </span>
          </div>
          <div className="ncss-col-sm-3">
            <span className="small-text description u-bold pt2-sm pb2-sm pr2-sm">Contact No:</span>
            <span className=" small-text u-bold pl0-sm">

              {data.trfId ?
                data.trfDataForEdit.contactNumber ? (
                  <H6 className="ncss-col-sm-2 small-text pl0-sm">{data.trfDataForEdit.contactNumber}</H6>
                ) :

                  (
                    <H6 className="error-text-not small-text text-left ">Not Added</H6>
                  )
                :
                userDetails.contact ? (
                  <H6 className="ncss-col-sm-2 small-text pl0-sm">{userDetails.contact}</H6>
                )
                  :
                  <H6 className="error-text-not small-text text-left ">Not Added</H6>
              }
            </span>
          </div>
        </div>
        <ReviewData {...data} />
        <Modal isOpen={trfReportModal} className="modal-container trf-modal-container trf-modal-width" overlayClassName="modal-overlay">
          <div className='modal-report-section'>
            <IconButton icon="times" className="modal-close-btn" onClick={() => { this.closeTrfReport() }} />
          </div>
          <div className="p2-sm">

          </div>

          <div className="trf-modal-scroll">
            <div className="ncss-col-sm-12" style={{ paddingBottom: '45px' }}>
              <div className="test_details">
                <div className="header" >
                  <div className="ncss-col-sm-12 mt1-sm pdf_header">
                    <div className="ncss-col-sm-5">
                      <h3 className="fs20-sm lh20-sm fs24-md lh24-md  fs28-lg lh28-lg inline-display relative-position">
                        <div className="ncss-brand header-brand-pdf preview-title" >NIKE TEST REQUEST FORM (TRF)</div>
                      </h3>
                    </div>
                    <div className="ncss-col-sm-5">
                      <div className="ncss-brand header-brand-pdf">
                        {formatDate(data.trfDataForEdit.createdAt)}
                      </div>
                    </div>
                    <div className="ncss-col-sm-2 padding-logo">
                      <i className="adjust-icon g72-swoosh nav-brand fs18-sm" style={{ paddingLeft: '67%' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ncss-col-sm-12">
              <TrfPrintable trfDetails={returnDownloadObj.trfDetails} showBilling={false} />
            </div>
            <div className="ncss-col-sm-12">
              <Button
                onClick={this.exportPreviewPDF}
                className="ncss-btn-secondary-dark u-bold secondary-button trf-button-selected download-btn"
              >Download</Button>
            </div>
          </div>
          {returnDownloadObj.trfDetails !== undefined && <div className="testDetailsPDF">
            <span>
              <PDFExport paperSize="A4"
                pageTemplate={PreviewPageTemplate}
                scale={0.8}
                margin={{ top: 50, left: 20, right: 20, bottom: 50 }}
                multiPage
                forcePageBreak=".page-break"
                fileName={`TRF Report - R${data.trfDataForEdit.trfId}.pdf`}
                repeatHeaders
                title=""
                subject=""
                keywords=""
                landscape="true"
                keepTogether=".prevent-split"
                ref={(r) => this.previewPdf = r}>
                <div className="ncss-col-sm-12">
                  <TrfPrintable trfDetails={returnDownloadObj.trfDetails} showBilling={false} />
                </div>
              </PDFExport>

            </span>
          </div>}
        </Modal>
      </div>

    );
  }
}
Review.propTypes = {
  data: PropTypes.object,
  displaySubmitButtonTrf: PropTypes.func,
  displayClearButtonTrf: PropTypes.func
};

ReviewData.propTypes = {
  brandInfoStates: PropTypes.object,
  testingInfoStates: PropTypes.object,
  labLocationStates: PropTypes.object
};

const mapStateToProps = state => ({
  userDetails: selectUserDetails(state)

});

export default compose(
  reduxForm({ form: 'review', destroyOnUnmount: false }),
  connect(
    mapStateToProps,
    null
  )
)(Review);
