import React, { Component, Fragment } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import {
  H4,
  H5,
  H6,
  Checkbox,
  normalizeBoolean,
  Radiobutton,
  IconButton,
  Collapsible,
  YearSelector,
  TextInput,
  PopOver,
  ConfirmationBox,
  ToggleSwitch,
  DropDown,
  Button
} from '../../../components/index.js';
import { strUpperCase } from '../../../util/index.js'
import SampleInfo from '../../../constants/json/radiobuttons/sampleInfo.json';
import MaterialTypes from '../../../constants/json/radiobuttons/materialTypes.json';
import individualTest from '../../../constants/json/checkboxes/individualTestPackages.json';
import Season from '../../../constants/json/checkboxes/season.json';
import repellent from '../../../constants/json/radiobuttons/repellent.json';
import foodOrMouth from '../../../constants/json/radiobuttons/foodOrMouth.json';
import eyeWear from '../../../constants/json/radiobuttons/eyeWear.json';
import embededProduct from '../../../constants/json/radiobuttons/embededProduct.json';
import sampleTypeInfo from '../../../constants/json/radiobuttons/sampleTypeInfo.json';
import ageGroup from '../../../constants/json/radiobuttons/ageGroup.json';
import sampleColor from '../../../constants/json/radiobuttons/sampleColor.json';
import certification from '../../../constants/json/radiobuttons/certification.json';
import skinContact from '../../../constants/json/radiobuttons/skinContact.json';
import recycledContent from '../../../constants/json/radiobuttons/recycledContent.json';
/*import recycledContentDesc from '../../../constants/json/radiobuttons/recycledContentDesc.json'; */
import {
  InitialProductTypeStore, currentProductTypeStore, onChangeProductType,
  onChangeScreeningInkVarient, getProductIdEdit, initialCertificationArry, recycledContentValue
} from '../../../actions/trfActions';
import { propTypes } from 'react-widgets/lib/DateTimePicker.js';

const titleStyles = {
  'font-weight': '700',
  'font-size': '16px',
  'line-height': '1.5'
};

function getSampleTypeInfo() {
  return sampleTypeInfo.map(productType => ({
    id: productType.id,
    value: productType.value,
    label: productType.label
  }));
}

function getAgeGroup() {
  return ageGroup.map(productType => ({
    id: productType.id,
    value: productType.value,
    label: productType.label
  }));
}

function getSampleColor() {
  return sampleColor.map(productType => ({
    id: productType.id,
    value: strUpperCase(productType.value),
    label: productType.label
  }));
}

export class TestingInfo extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      submitModal: false,
      message: '',
      resetMaterial: false,
      jewelryClearbtn: true,
      electronicClearbtn: true,
      certificationArry: [{ name: "", number: "", other: "" }]
    };
    this.resetTestingType = this.resetTestingType.bind(this);
    this.toysValidationMessage = this.toysValidationMessage.bind(this);
  }
  componentDidMount() {
    const { change, displaySubmitButtonTrf, displayClearButtonTrf, updateMaterialField, productId, prevProductId, InitialProductTypeStore
      , currentProductTypeStore, getProductTypeObj, onChangeProductType, onChangeScreeningInkVarient, testingInfoStates, getProductIdEdit
      , initialCertificationArry, recycledContentValue } = this.props;
    //Edit form to get productId
    if (isNaN(productId) === false) {
      getProductIdEdit(productId)
    }
    else {
      getProductIdEdit('')
    }
    if (testingInfoStates.electCategory === '') {
      this.setState({ electronicClearbtn: true })
    } else {
      this.setState({ electronicClearbtn: false })
    }
    if (testingInfoStates.jeweleryMaterialType === '') {
      this.setState({ jewelryClearbtn: true })
    } else {
      this.setState({ jewelryClearbtn: false })
    }
    if (testingInfoStates.certification !== '' && testingInfoStates.testingInfoStatus === 'Edit') {
      initialCertificationArry(testingInfoStates.certification)
      const { getInitialCertificationArry } = this.props
      const editCertificationArry = getInitialCertificationArry
      editCertificationArry.map(item => {
        if (item.name !== 'oeko' && item.name !== 'bluesign' && item.name !== 'eco') {
          item.other = 'sample'
        }
        return item
      })
    }
    if (testingInfoStates.certification && testingInfoStates.certification.length === 0 && testingInfoStates.testingInfoStatus === 'Edit') {
      initialCertificationArry([{ name: "", number: "", other: "" }])
    }
    if (testingInfoStates.recycle && testingInfoStates.recycle === true && testingInfoStates.testingInfoStatus === 'Edit') {
      recycledContentValue('Yes')
    }
    if (updateMaterialField.length > 0) {
      change(
        'Material Product Information',
        updateMaterialField.length > 0 ? updateMaterialField.productInformation : ''
      );
      change('supplierName', updateMaterialField.length > 0 ? updateMaterialField[1].supplierName : '');
      change('supLocName', updateMaterialField.length > 0 ? updateMaterialField[1].supLocName : '');
      change('testmaterialName', updateMaterialField.length > 0 ? updateMaterialField[0].materialName : '');
      change('testmaterialNumber', updateMaterialField.length > 0 ? updateMaterialField[0].materialNumber : '');
      change('refId', updateMaterialField.length > 0 ? updateMaterialField[0].refId : '');
      change('refId', updateMaterialField.length > 0 ? updateMaterialField[0].refId : '');
    }
    const getVarientId = testingInfoStates.variants405
    onChangeScreeningInkVarient(getVarientId)
    displaySubmitButtonTrf(false);
    displayClearButtonTrf(true);
    const getUpdatedProductType = getProductTypeObj
    if (getUpdatedProductType && getUpdatedProductType.isChanged === true) {
      InitialProductTypeStore(prevProductId)
      currentProductTypeStore(productId)
       const packagingTestPackageNullArry = new Array(individualTest[0].packaging.filter(data => data.isTestAvailable === true).length).fill(null);
       const testPackagesNullArry = new Array(individualTest[0].common.filter(data => data.isTestAvailable === true).length).fill(null);
      const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
      const testPackagesJewelryNullArry = new Array(individualTest[0].jewelry.filter(data => data.isTestAvailable === true).length).fill(null);
      testingInfoStates.materialType && testingInfoStates.materialType.filter(element => element != null).map(id => (
        change(`subVariants`, null)
      ));
      testingInfoStates.materialType && testingInfoStates.materialType.filter(element => element != null).map(id => (
        change(`variants${id}`, null)
      ));
      change('materialType', [null])
      change('jeweleryMaterialType', '')
      change('electCategory', '')
      change('testPackages', testPackagesNullArry)
      change('testPackagesPackaging', packagingTestPackageNullArry)
      change('testPackagesJewelry', testPackagesJewelryNullArry)
      change('testPackagesElectronic', testPackagesElectronicNullArry)
      change('currentProductType', productId)
      change('InitialProductType', prevProductId)
      change('repellent', 'No')
      change('foodOrMouth', 'No')
      const storeProductTypeFalse = { isChanged: false, CurrentProductType: 0 }
      onChangeProductType(storeProductTypeFalse)
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { testingInfoStates, initialValues, initialMaterialTypes, productId, prevProductId } = this.props;
    if (testingInfoStates.recycle === "Yes") {
      recycledContentValue('Yes')
    } else {
      recycledContentValue('No')
    }
    //Edit form to get productId
    if (isNaN(productId) === false) {
      getProductIdEdit(productId)
    }
    else {
      getProductIdEdit('')
    }

    const { resetMaterial } = this.props;
    let packageDiffernce = [], materialDiffernce = [], lastPopMaterial = [], lastPopForFirsttime = [], newMaterialLength = 0;
    let newMaterialList;
    let oldMaterialList;
    let newPackageList;
    let oldPackageList;
    oldPackageList = (testingInfoStates?.testPackages?.length > 0) ? testingInfoStates?.testPackages.filter(function (el) {
      return el != null;
    }) : [];
    newPackageList = (nextProps?.testingInfoStates?.testPackages?.length > 0) ? nextProps?.testingInfoStates?.testPackages.filter(function (el) {
      return el != null;
    }) : [];
    packageDiffernce = (newPackageList?.length > 0) ? newPackageList.filter(x => !oldPackageList.includes(x)) : [];
    if (
      (testingInfoStates.materialType !== nextProps.testingInfoStates.materialType ||
        testingInfoStates[`variants${nextProps.testingInfoStates.materialType}`] !==
        nextProps.testingInfoStates[`variants${nextProps.testingInfoStates.materialType}`]) &&
      nextProps.testingInfoStates.materialType !== null
    ) {
      // isEqualArray will check if the materialtype array has changed.
      // There is one more check added especially for intial value as materialType nextprops and props are same on intial loading
      let isEqualArray = isEqual(nextProps.testingInfoStates.materialType, testingInfoStates.materialType)
      // Will remove in next PR issues got resolved  
      //isEqual(initialMaterialTypes,testingInfoStates.materialType);
      if ((nextProps.testingInfoStates.materialType.filter(element => element !== undefined)).length > 0 && testingInfoStates.materialType) {
        oldMaterialList = testingInfoStates.materialType.filter(function (el) {
          return el != null;
        });
        newMaterialList = nextProps.testingInfoStates.materialType.filter(function (el) {
          return el != null;
        });
        newMaterialLength = nextProps.testingInfoStates.materialType.filter(element => element !== undefined).length;
        materialDiffernce = newMaterialList.filter(x => !oldMaterialList.includes(x));
        lastPopMaterial = oldMaterialList.filter(x => !newMaterialList.includes(x));
        lastPopForFirsttime = (prevProductId !== productId && prevProductId !== 0 && prevProductId !== '')
          ? [] : initialMaterialTypes && initialMaterialTypes.filter(x => !newMaterialList.includes(x))
      }
      //Not using materials -  Need to remove // 
      if ((materialDiffernce.length > 0 && materialDiffernce.includes(417)) ||
        (materialDiffernce.length === 0 && newMaterialLength <= 1 && nextProps.testingInfoStates.materialType.filter(element => element !== undefined).includes(417)) ||
        (isEqualArray && testingInfoStates.materialType.includes(417))
      ) {
        this.toysValidationMessage(
          'If this item is for an Electronic component, the appropriate RSL test package and/or additional test(s) must also be indicated.'
        );
      } else if ((materialDiffernce.length > 0 && materialDiffernce.includes(420)) ||
        (materialDiffernce.length === 0 && newMaterialLength <= 1 && nextProps.testingInfoStates.materialType.filter(element => element !== undefined).includes(420)) ||
        (isEqualArray && testingInfoStates.materialType.includes(420))
      ) {
        this.toysValidationMessage(
          'For the selection Packaging, the appropriate RSL test package and/or additional tests must also be indicated.'
        );
      } else if ((materialDiffernce.length > 0 && materialDiffernce.includes(410)) ||
        (materialDiffernce.length === 0 && newMaterialLength <= 1 && nextProps.testingInfoStates.materialType.filter(element => element !== undefined).includes(410)) ||
        (isEqualArray && testingInfoStates.materialType.includes(410))
      ) {
        this.toysValidationMessage(
          'For the selection OTHER Rhinestones, sequins, etc. , Please select the appropriate RSL Individual Test package from Metals (Nickel Release).'
        );
      }
      // for deselecting the test packages for both edit and create trf
      if ((isEqualArray && lastPopForFirsttime.includes(417)) ||
        (lastPopMaterial.includes(417) && nextProps.testingInfoStates.testPackagesElectronic && nextProps.testingInfoStates.testPackagesElectronic.filter(element => element !== null).length > 0
        )) {
        this.toysValidationMessage(
          'You have an individual test package selected for the Material type - Electronics'
        );
      }
      else if ((isEqualArray && lastPopForFirsttime.includes(420)) ||
        (lastPopMaterial.includes(420) && nextProps.testingInfoStates.testPackagesPackaging && nextProps.testingInfoStates.testPackagesPackaging.filter(element => element !== null).length > 0)) {
        this.toysValidationMessage(
          'You have an individual test package selected for the Material type - Packaging'
        );
      } //Not using materials -  Need to remove till here// 
    }

    if (nextProps.testingInfoStates.materialType && testingInfoStates[`variants409`] !== nextProps.testingInfoStates[`variants409`]) {
      if (
        (nextProps.testingInfoStates.materialType.filter(element => element !== undefined).includes(409)) &&
        nextProps.testingInfoStates[`variants409`] === 4091
      ) {
        this.toysValidationMessage(
          'For the selection Metal items(Not coated or painted items;  direct skin contact), Please select the appropriate RSL Individual Test package from Metals (Nickel Release).'
        );
      } else if (
        (nextProps.testingInfoStates.materialType.filter(element => element !== undefined).includes(409)) &&
        nextProps.testingInfoStates[`variants409`] === 4093
      ) {
        this.toysValidationMessage(
          'For the selection Metal items(Coated or Painted Items; direct skin contact) is checked, Please select the appropriate RSL Individual Test package from Metals (Nickel Release).'
        );
      }
    }

    if (nextProps.testingInfoStates.testPackages && JSON.stringify(testingInfoStates.testPackages) !== JSON.stringify(nextProps.testingInfoStates.testPackages)) {
      if ((nextProps.testingInfoStates.testPackages.filter(element => element !== undefined).includes(603) && packageDiffernce.includes(603)) || (nextProps.testingInfoStates.testPackages.filter(element => element !== undefined).includes(604) && packageDiffernce.includes(604)) || (nextProps.testingInfoStates.testPackages.filter(element => element !== undefined).includes(529) && packageDiffernce.includes(529))) {
        this.toysValidationMessage(
          'Please work with RSL Support Team to discuss this testing further: RSLSupport@nike.com'
        );
      }
    }

    if (testingInfoStates.repellent !== nextProps.testingInfoStates.repellent && nextProps.testingInfoStates.repellent === 'No' && nextProps.testingInfoStates.testingInfoStatus === 'Edit') {
      nextProps.testingInfoStates.testPackages[26] = null;
    }
    if (testingInfoStates.materialType !== nextProps.testingInfoStates.materialType && resetMaterial && nextProps.testingInfoStates.testingInfoStatus === 'Edit') {

      const { change } = this.props;
      nextProps.testingInfoStates.materialType.filter(element => element != null).map(id => (
        change(`variants${id}`, null)
      ));
      change('materialType', [null]);
      this.setState({ resetMaterial: false })

    }
    if (testingInfoStates.testPackages !== nextProps.testingInfoStates.testPackages && nextProps.testingInfoStates.testingInfoStatus === 'Edit') {
    } if (initialValues.refId !== nextProps.initialValues.refId && nextProps.initialValues.testingInfoStatus === 'Edit') {
      const { change } = this.props;
      change(`refId`, nextProps.initialValues.refId);

    }
    else if (testingInfoStates.testmaterialName !== nextProps.testingInfoStates.testmaterialName && testingInfoStates.testingInfoStatus === 'Edit') {
      const { change } = this.props;
      change(`refId`, nextProps.initialValues.refId);

    }
  }
  resetTestingType = () => {
    const { change, testingInfoStates } = this.props;
    const materialNullArry = new Array(MaterialTypes.length).fill(null);
    const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
    testingInfoStates.materialType.filter(element => element != null).map(id => (
      change(`variants${id}`, null)
    ));
    change('materialType', materialNullArry);
    change('testPackagesElectronic', testPackagesElectronicNullArry)
    change('jeweleryMaterialType', '')
    change('electCategory', '')
    if (testingInfoStates.testingInfoStatus === 'Edit') {
      this.setState({ resetMaterial: true })
    }
  };

  closeSubmitModal = status => {
    this.setState({ submitModal: status });
  };

  toysValidationMessage = message => {
    this.setState({ submitModal: true, message });
  };
  sortDynamic = (key, order = 'asc') => {
    const sortOrder = order === 'asc' ? 1 : -1
    return (a, b) => {
      const A = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
      const B = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
      if (A < B) {
        return sortOrder * -1
      } else if (A > B) {
        return sortOrder * 1
      } else {
        return 0
      }
    }
  }

  modifyData = data => {
    if (data.length >= 3) {
      var mod = data.length % 3;
      var colLength = data.length / 3;
      var tableArr = [];
      var _ndEle = mod === 2 ? Math.trunc(colLength) + 1 : Math.trunc(colLength) + mod;
      var rowLength = Math.trunc(colLength);
      var roundoff = Math.round(colLength);
      for (var i = 0; i < _ndEle; i++) {
        if (i === 0) {
          tableArr.push(data[0]);
          tableArr.push(data[_ndEle]);
          mod === 2 ? tableArr.push(data[_ndEle + roundoff]) : tableArr.push(data[_ndEle + rowLength]);
        } else {
          if (mod === 2) {
            tableArr.length < data.length && tableArr.push(data[i]);
            tableArr.length < data.length && tableArr.push(data[i + roundoff]);
            tableArr.length < data.length && tableArr.push(data[i + roundoff + roundoff]);
          }
          else if ((i + _ndEle + rowLength - 1) < data.length && mod !== 2) {
            tableArr.push(data[i]);
            if (mod === 1) {
              (i + _ndEle + rowLength) !== tableArr.length && tableArr.push(data[i + _ndEle]);
              (i + _ndEle + rowLength) !== tableArr.length && tableArr.push(data[i + _ndEle + rowLength]);
            }
            else if ((i + _ndEle + rowLength) !== data.length) {
              tableArr.push(data[i + _ndEle])
              tableArr.push(data[i + _ndEle + rowLength])
            }
          }
        }
      }
      return tableArr;
    }
    return data
  }
  handleChange = (event) => {
    const { onChangeScreeningInkVarient } = this.props;
    if (event.target.value === '4040') {
      onChangeScreeningInkVarient(0)
    } else if (event.target.value === '4052') {
      onChangeScreeningInkVarient(parseInt(event.target.value))
    }
  }
  skinContactHandleChange = (event) => {
    const { change, testingInfoStates } = this.props
    if (event.target.value === 'Yes' && (testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD')) {
      this.toysValidationMessage(
        'Select from the Material Types under the PRODUCT RSL TESTS section'
      );
    } else if (event.target.value === 'No' && testingInfoStates.testingType === 'PR') {
      const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
      change('electCategory', '')
      this.radioButtonMaterialValues('clear', "electronic-test")
      change('testPackagesElectronic', testPackagesElectronicNullArry)
    }
    else if (event.target.value === 'Yes' && testingInfoStates.testingType === 'RD') {
      const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
      change('electCategory', '')
      this.radioButtonMaterialValues('clear', "electronic-test")
      change('testPackagesElectronic', testPackagesElectronicNullArry)
    }
  }
  radioButtonMaterialValues = ( materialId, materialCategory) => {
    const {change, testingInfoStates} = this.props
    var rslTests = MaterialTypes.filter(data => data.category === "rsl-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    rslTests = this.modifyData(rslTests);
    var otherTest = MaterialTypes.filter(data => data.category === "other-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    otherTest = this.modifyData(otherTest);
    const packagingTests = MaterialTypes.filter(data => data.category === "packaging-test" && data.isTestAvailable === true);
    var packagingTestSort = packagingTests.sort(this.sortDynamic('label'));
    packagingTestSort = this.modifyData(packagingTestSort);
    var jewelryTest = MaterialTypes.filter(data => data.category === "jewelry-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    var electronicTest = MaterialTypes.filter(data => data.category === "electronic-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    electronicTest = this.modifyData(electronicTest);
    var allMaterialTypes = rslTests.concat(otherTest).concat(packagingTestSort).concat(jewelryTest).concat(electronicTest);
    var materialIdInt = materialId
    var materialTypeData = testingInfoStates.materialType
    if(materialId === 'clear'){
      allMaterialTypes.map((value, index) => {
        if(value.category === materialCategory){
          materialTypeData[index] = null
        }
        return materialTypeData
      })
    change('materialType', materialTypeData)
    }else{
      allMaterialTypes.map((value, index) => {
        if(value.id === materialIdInt && value.category === materialCategory){
          materialTypeData[index] = materialIdInt
        }
        else if(value.category === materialCategory){
          materialTypeData[index] = null
        }
        return materialTypeData
      })
      change('materialType', materialTypeData)
    }
  }
  electCategoryHandleChange = (event) => {
    const { change, testingInfoStates, productId } = this.props
    if (event === 4150 && (testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD')) {
      const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
      testPackagesElectronicNullArry.splice(0, 0, 555)
      change('testPackagesElectronic', testPackagesElectronicNullArry)
      this.setState({ electronicClearbtn: false })
      this.radioButtonMaterialValues(event, "electronic-test")
      change("electCategory", "electrical")
    } else if (event === 4152 && (testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD')) {
       const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
      testPackagesElectronicNullArry.splice(1, 0, 556)
      change('testPackagesElectronic', testPackagesElectronicNullArry)
      this.setState({ electronicClearbtn: false })
      this.radioButtonMaterialValues(event, "electronic-test")
      change("electCategory", "battery")
    }
    else if (event === 4150 && testingInfoStates.testingType === 'RD' && productId === 206) {
      const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
      testPackagesElectronicNullArry.splice(0, 0, 555)
      change('testPackagesElectronic', testPackagesElectronicNullArry)
      this.setState({ electronicClearbtn: false })
    } else if (event === 4152 && testingInfoStates.testingType === 'RD' && productId === 206) {
      const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
      testPackagesElectronicNullArry.splice(1, 0, 556)
      change('testPackagesElectronic', testPackagesElectronicNullArry)
      this.setState({ electronicClearbtn: false })
    }
  }
  clearJewelryRadioButtons = () => {
    const { change } = this.props
    change("jeweleryMaterialType", " ")
    this.radioButtonMaterialValues('clear', "jewelry-test")
    this.setState({ jewelryClearbtn: true })
  }

  clearElectronicRadioButtons = () => {
    const { change } = this.props
    const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
    change('electCategory', '')
    change('testPackagesElectronic', testPackagesElectronicNullArry)
    this.radioButtonMaterialValues('clear', "electronic-test")
    this.setState({ electronicClearbtn: true })
  }

  jewelryHandleChange = (event) => { 
    const { change } = this.props
    change("jeweleryMaterialType", event)
    if (event === 4148) {
      this.setState({ jewelryClearbtn: false })
      this.radioButtonMaterialValues(event, "jewelry-test")
    }else if (event === 4144) {
      this.setState({ jewelryClearbtn: false })
      this.radioButtonMaterialValues(event, "jewelry-test")
    }else if (event === 4147) {
      this.setState({ jewelryClearbtn: false })
      this.radioButtonMaterialValues(event, "jewelry-test")
    }else if (event === 4143) {
      this.setState({ jewelryClearbtn: false })
      this.radioButtonMaterialValues(event, "jewelry-test")
    }
  }

  onToggleSwitchChange = (checked) => {
    const { change } = this.props
    change(`enableTP2`, checked);
  }
  // handle input change
  handleInputChange = (e, index) => {
    const { change } = this.props
    const { getInitialCertificationArry, initialCertificationArry } = this.props
    const { name, value } = e.target;
    const list = getInitialCertificationArry;
    list[index][name] = value;
    if (value === 'other') {
      const otherKay = "other";
      list[index][otherKay] = "sample"
      list[index][name] = ""
    }
    initialCertificationArry(list)
    change('certification', getInitialCertificationArry)
    if (getInitialCertificationArry) {
      const { getInitialCertificationArry } = this.props
      const editCertificationArry = getInitialCertificationArry
      editCertificationArry.map(item => {
        if (item.name === 'oeko' || item.name === 'bluesign' || item.name === 'eco') {
          delete item.other
        }
        return item
      })
    }
  };
  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const { getInitialCertificationArry, initialCertificationArry } = this.props
    const list = getInitialCertificationArry;
    list.splice(index, 1);
    initialCertificationArry(list)
  };

  // handle click event of the Add button
  handleAddClick = (e) => {
    e.preventDefault();
    const { getInitialCertificationArry, initialCertificationArry } = this.props
    const cerObj = { name: '', number: '' }
    if (getInitialCertificationArry.length <= 5) {
      initialCertificationArry([...getInitialCertificationArry, cerObj])
    }
  };
  certificationAddMore = () => {
    const { getInitialCertificationArry } = this.props
    return (
      getInitialCertificationArry && getInitialCertificationArry.map((x, i) => {
        return (
          <Fragment>
            <div className="ncss-col-sm-12 ncss-input-container">
              <div className="ncss-col-sm-3 ncss-input-container">
                <select id="select" name="name" onChange={(e) => this.handleInputChange(e, i)}
                  className="Select-input select-input-font ncss-input pt2-sm pr4-sm pb2-sm pl4-sm dropdown-border-location">
                  <option value="" selected="selected">Select Certificate</option>
                  {certification.map(data => (
                    <option key={data.label} value={data.value} selected={(x.name === data.value) ? true
                      : (x.name !== 'oeko' && x.name !== 'bluesign' && x.name !== 'eco' && x.name !== '') ? true
                        : false ? 'selected' : ''}>
                      {data.label}
                    </option>
                  ))}
                </select></div>
              {x.other !== '' && typeof x.other !== 'undefined' ? (
                <div className="ncss-col-sm-3 ncss-input-container">
                  <input
                    className="ncss-input pt2-sm pr4-sm pb2-sm pl4-sm"
                    placeholder="Certificate Name"
                    value={x.name}
                    name="name"
                    onChange={(e) => this.handleInputChange(e, i)}
                  /></div>
              ) : ''
              }
              <div className="ncss-col-sm-3 ncss-input-container">
                <input
                  className="ncss-input pt2-sm pr4-sm pb2-sm pl4-sm"
                  placeholder="Certificate Number"
                  value={x.number}
                  name="number"
                  onChange={(e) => this.handleInputChange(e, i)}
                /></div>
              <div className="ncss-col-sm-3 ncss-input-container">
                {getInitialCertificationArry.length - 1 === i && (
                  <Button className="ncss-col-sm-2 download-print-button-FRF u-bold pb1-sm text-color-accent" onClick={(e) => this.handleAddClick(e)}>Add</Button>
                )}
                {getInitialCertificationArry.length !== 1 && (
                  <Button className=" ncss-col-sm-2 download-print-button-FRF u-bold pb1-sm addmore-error" onClick={() => this.handleRemoveClick(i)}>
                    Remove
                  </Button>
                )}
              </div>
            </div>
          </Fragment>
        );
      }))
  }

  testPackageForPFAS = (event) => {
    const { testingInfoStates, change, productId } = this.props
    var containsTestClass = testingInfoStates.testPackages.every(i => [597,607].includes(i))
    var containsTestClassPackage = testingInfoStates.testPackages.every(i => [586,608].includes(i))
    if (event.target.value === 'PR' || event.target.value === 'RD') {
      if (!containsTestClass) {
        if (testingInfoStates.repellent === 'Yes' && (productId === 200 || productId === 201 || productId === 202 || productId === 206)) {
          const testPackagePFAS = testingInfoStates.testPackages;
          testPackagePFAS.splice(8, 0, 597)
          testPackagePFAS.splice(29, 0, 607)
          change('testPackages', testPackagePFAS)
        }
      }
    } else if (event.target.value === 'Yes') {
      if ((testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD')
        && (productId === 200 || productId === 201 || productId === 202 || productId === 206)) {//Repellent Value as Yes & Product Type as AP/FW/EQ/Accessories
        if (!containsTestClass) {
          const testPackagePFAS = testingInfoStates.testPackages;
          if(event.target.name === 'repellent'){//Repellent value yes
            testPackagePFAS.splice(8, 0, 597)
            testPackagePFAS.splice(29, 0, 607) // enable default Toatl Organic Fluorine
          change('testPackages', testingInfoStates.testPackages)
          }
          if(event.target.name === 'foodOrMouth' && testingInfoStates.repellent !== 'Yes' ){
            testPackagePFAS.splice(8, 0, 597)// enable PFAS default
          change('testPackages', testPackagePFAS)
          }
          
        }
      } else if ((testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD')
        && (productId === 203)) { //Repellent Value as Yes & Product Type as PK
        if (!containsTestClassPackage) {
          const testPackagesPackagingPFAS = testingInfoStates.testPackagesPackaging;
          if(event.target.name === 'repellent'){
          testPackagesPackagingPFAS.splice(5, 0, 586)
          testPackagesPackagingPFAS.splice(11, 0, 608)
          }
          if(event.target.name === 'foodOrMouth'&& testingInfoStates.repellent !== 'Yes'){
            testPackagesPackagingPFAS.splice(5, 0, 586)
          }
          change('testPackagesPackaging', testPackagesPackagingPFAS)
        }
      }
    } else if (event.target.value === 'No') {
      if ((testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD')
        && (productId === 200 || productId === 201 || productId === 202 || productId === 206)) {//Repellent Value as Yes & Product Type as AP/FW/EQ/Accessories     
          if( testingInfoStates.testPackages.includes(597) === true && event.target.name === 'repellent' && testingInfoStates.foodOrMouth !== 'Yes'){
            const testPackagePFAS = testingInfoStates.testPackages;
            const index =testPackagePFAS.indexOf(597)
            index > -1 && testPackagePFAS.splice(index, 1);
            change('testPackages', testPackagePFAS)
          }
          if(testingInfoStates.testPackages.includes(597) === true && event.target.name === 'foodOrMouth' && testingInfoStates.repellent !== 'Yes'){
            const testPackagePFAS = testingInfoStates.testPackages;
            const index =testPackagePFAS.indexOf(597)
            index > -1 && testPackagePFAS.splice(index, 1);
            change('testPackages', testPackagePFAS)
          }
          if (testingInfoStates.testPackages.includes(607) === true && event.target.name === 'repellent') {
          const testPackagePFAS = testingInfoStates.testPackages;
          const index = testPackagePFAS.indexOf(607);
          if (index > -1) {
            testPackagePFAS.splice(index, 1);
          }
          change('testPackages', testPackagePFAS)
        }
      } else if ((testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD')
        && (productId === 203)) { //Repellent Value as Yes & Product Type as PK
        if (testingInfoStates.testPackagesPackaging.includes(586) === true && event.target.name === 'repellent' && testingInfoStates.foodOrMouth !== 'Yes') {
          const testPackagePFAS = testingInfoStates.testPackages;
          const index = testPackagePFAS.indexOf(586);
          if (index > -1) {
            testPackagePFAS.splice(index, 1);
          }
          change('testPackagesPackaging', testPackagePFAS)
        }
        if(testingInfoStates.testPackagesPackaging.includes(586) === true && event.target.name === 'foodOrMouth' && testingInfoStates.repellent !== 'Yes'){
          const testPackagePFAS = testingInfoStates.testPackages;
          const index =testPackagePFAS.indexOf(586)
          index > -1 && testPackagePFAS.splice(index, 1);
          change('testPackagesPackaging', testPackagePFAS)
        }
        if (testingInfoStates.testPackagesPackaging.includes(608) === true  && event.target.name === 'repellent') {
          const testPackagePFAS = testingInfoStates.testPackages;
          const index = testPackagePFAS.indexOf(608);
          if (index > -1) {
            testPackagePFAS.splice(index, 1);
          }
          change('testPackagesPackaging', testPackagePFAS)
        }
      }
    }
  }

  recycledContentHandleChange = (event) => {
    const { recycledContentValue } = this.props
    if (event.target.value === 'Yes') {
      recycledContentValue('Yes')
    } else {
      recycledContentValue('No')
    }
  }

  render() {
    const { sucessFill, testingInfoStates, isPartialEdit, productId, getScreeningInkVarient, getInitialCertificationArry, getRecycledContentValue } = this.props;
    const { submitModal, message, electronicClearbtn, jewelryClearbtn } = this.state;
    const packagingTests = MaterialTypes.filter(data => data.category === "packaging-test" && data.isTestAvailable === true);
    const packagingTestIds = packagingTests.map(data => data.id)
    const packagingTestVariantIds = packagingTests.map(data => data.variant ? data.variants.map(data => data.id) : null)
    packagingTestVariantIds.push(packagingTestIds)
    var common = individualTest[0].common.filter(data => data.isTestAvailable === true);
    common.sort(this.sortDynamic('label'));
    common = this.modifyData(common);
    var toys = individualTest[0].toys.filter(data => data.isTestAvailable === true);
    toys.sort(this.sortDynamic('label'));
    toys = this.modifyData(toys);
    var packaging = individualTest[0].packaging.filter(data => data.isTestAvailable === true);
    packaging.sort(this.sortDynamic('label'));
    packaging = this.modifyData(packaging);
    var jewelry = individualTest[0].jewelry.filter(data => data.isTestAvailable === true);
    jewelry.sort(this.sortDynamic('label'));
    jewelry = this.modifyData(jewelry);
    var electronic = individualTest[0].electronic.sort(this.sortDynamic('label'));
    electronic = this.modifyData(electronic);
    var rslTests = MaterialTypes.filter(data => data.category === "rsl-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    rslTests = this.modifyData(rslTests);
    var otherTest = MaterialTypes.filter(data => data.category === "other-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    otherTest = this.modifyData(otherTest);
    var packagingTestSort = packagingTests.sort(this.sortDynamic('label'));
    packagingTestSort = this.modifyData(packagingTestSort);
    var jewelryTest = MaterialTypes.filter(data => data.category === "jewelry-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    var electronicTest = MaterialTypes.filter(data => data.category === "electronic-test" && data.isTestAvailable === true).sort(this.sortDynamic('label'));
    electronicTest = this.modifyData(electronicTest);
    const materialTypesSorted = rslTests.concat(otherTest).concat(packagingTestSort).concat(jewelryTest).concat(electronicTest);

    return (
      <form className="form">
        <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
          Sample Type, Age Group, Sample Color, Season, Year, Testing Type Fields, Sample Description are Mandatory
          <span className="astericks ml-pos1">&#42;</span>
        </H4>
        <div className="p2-sm">
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">MATERIAL INFORMATION</H4>
        </div>
        <div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Supplier Name</H5>
            <Field component={TextInput} name="supplierName" type="text" disabled />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Location Name</H5>
            <Field component={TextInput} name="supLocName" type="text" disabled />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Material Name</H5>
            <Field component={TextInput} name="testmaterialName" type="text" disabled />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Material Number</H5>
            <Field component={TextInput} name="testmaterialNumber" type="text" disabled />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Material ID</H5>
            <Field component={TextInput} name="refId" type="text" disabled />
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Sample Type
              <span className="astericks" >&#42;</span></H5>
            <Field
              component={DropDown}
              name="sampleType"
              placeholder="Select Sample Type"
              options={getSampleTypeInfo()}
              className="dropdown-border-location"
            />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Sample Color<span className="astericks" >&#42;</span></H5>
            <Field
              component={DropDown}
              name="sampleColor"
              placeholder="Select Sample Color"
              options={getSampleColor()}
              className="dropdown-border-location"
            />
          </div>
        </div>
        <div className="mb3-sm mt2-sm">
          <div className="p2-sm ncss-col-sm-12">
            <H5 className="u-bold">Color Information</H5>
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Color Code</H5>
            <Field component={TextInput} name="colorCode" type="text" />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Color Group Name</H5>
            <Field component={TextInput} name="colorName" type="text" />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Colorway (only for finished product)</H5>
            <Field component={TextInput} name="colorway" type="text" />
          </div>
          <div className="ncss-col-sm-4 ncss-input-container ">
            <H5 className="u-bold description p2-top-sm p2-bottom-sm">Age Group<span className="astericks" >&#42;</span></H5>
            <Field
              component={DropDown}
              name="ageGroup"
              placeholder="Select Age Group"
              options={getAgeGroup()}
              className="dropdown-border-location"
            />
          </div>
        </div>
        <div className="p2-sm">
          <H4 className="ncss-label ncss-label pr3-sm u-bold desc-h4">Sample Description
            <span className="astericks" >&#42;</span></H4>
          <Field
            component={TextInput}
            name="sampleDescription"
            type="textarea"
            className="ncss-col-sm-12 description u-bold trf-data-text"
          />
        </div>
        <div className="pt2-sm pb2-sm">
          <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
            Does material have repellent finish?<span className="astericks ml-pos1">&#42;</span>
          </div>
          <div className="ncss-col-sm-7 ncss-radio-container" onChange={this.testPackageForPFAS.bind(this)}>
            {repellent.map(data => (
              <div key={data.label} className="ncss-col-sm-6 u-bold trf-data-text">
                <Field
                  type="radio"
                  component={Radiobutton}
                  name="repellent"
                  value={data.value}
                  checked={false}
                  label={data.label}
                  id={data.id}
                  isIcon={false}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="pt2-sm pb2-sm">
          <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
            Is the material intended to come into contact with food or mouth?  <span className="astericks ml-neg3">&#42;</span>
          </div>
          <div className="ncss-col-sm-7 ncss-radio-container" onChange={this.testPackageForPFAS.bind(this)}>
            {foodOrMouth.map(data => (
              <div key={data.label} className="ncss-col-sm-6 u-bold trf-data-text">
                <Field
                  type="radio"
                  component={Radiobutton}
                  name="foodOrMouth"
                  value={data.value}
                  checked={false}
                  label={data.label}
                  id={data.id}
                  isIcon={false}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={productId === 202 ? "pt2-sm pb2-sm div-block" : "pt2-sm pb2-sm div-none"}>
          <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
            Is this material intended for eye-wear frames? <span className="astericks ml-neg3">&#42;</span>
          </div>
          <div className="ncss-col-sm-7 ncss-radio-container">
            {eyeWear.map(data => (
              <div key={data.label} className="ncss-col-sm-6 u-bold trf-data-text">
                <Field
                  type="radio"
                  component={Radiobutton}
                  name="eyeWear"
                  value={data.value}
                  checked={false}
                  label={data.label}
                  id={data.id}
                  isIcon={false}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="pt2-sm pb2-sm">
          <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
            Does this material sample contain recycled content?  <span className="astericks ml-neg3">&#42;</span>
          </div>
          <div className="ncss-col-sm-7 ncss-radio-container">
            {recycledContent.map(data => (
              <div key={data.label} className="ncss-col-sm-6 u-bold trf-data-text" onChange={this.recycledContentHandleChange.bind(this)}>
                <Field
                  type="radio"
                  component={Radiobutton}
                  name="recycle"
                  value={data.value}
                  checked={false}
                  label={data.label}
                  id={data.id}
                  isIcon={false}
                />
              </div>
            ))}
          </div>
        </div>
        {getRecycledContentValue === 'Yes' || testingInfoStates.recycle === 'Yes' ?
          (<Fragment><div className="pt2-sm pb2-sm">
            <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
              List the samples recycled material source (e.g. post industrial, post consumer, pre consumer, etc.)  <span className="astericks ml-neg3">&#42;</span>
            </div>
            <div className="ncss-col-sm-4 ncss-input-container">
              <Field component={TextInput} name="listRecycle" type="text" />
            </div>
          </div>
            <div className="pt2-sm pb2-sm">
              <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
                Describe the recycled content in the material sample (e.g. 20% recycled PET, 10% recycled TPU, etc.) <span className="astericks ml-neg3">&#42;</span>
              </div>
              <div className="ncss-col-sm-4 ncss-input-container">
                <Field component={TextInput} name="describeRecycle" type="text" />
              </div>
            </div></Fragment>) : ""}

        <div className="mb3-sm mt2-sm">
          <div className="p2-sm ncss-col-sm-12"><H5 className="u-bold">Certificate</H5></div>
          {getInitialCertificationArry.length > 0 ? this.certificationAddMore() : ''}
        </div>
        <div className="p2-sm">
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">SEASON</H4>
        </div>
        <div className="mb3-sm mt2-sm">
          <div className=" ncss-col-sm-7 inline-flex-display p2-sm u-bold ">
            <div className="ncss-col-sm-2 description u-bold flex-head-center trf-data-text">
              Season <span className="astericks ml-neg4">&#42;</span>
            </div>
            <div className="ncss-col-sm-8  ncss-radio-container">
              {Season.map(data => (
                <div key={data.label} className="ncss-col-sm-3 ncss-radio-container">
                  <Field
                    type="radio"
                    component={Radiobutton}
                    name="season"
                    value={data.id}
                    label={data.label}
                    checked={false}
                    id={data.id}
                    isIcon={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mb3-sm mt2-sm">
          <div className=" ncss-col-sm-7 inline-flex-display p2-sm u-bold ">
            <div className="ncss-col-sm-2 description u-bold trf-data-text">
              Year
              <span className="astericks">&#42;</span>
            </div>
            <div className="ncss-col-sm-12 grid-align-fix ncss-input-container">
              <div className="ncss-col-sm-12">
                <Field component={YearSelector} name="Year" minDate={new Date().getTime()} />
              </div>
            </div>
          </div>
        </div>
        <div className="p2-sm">
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">TESTING INFORMATION</H4>
          <H5 className="u-bold description">Description for Testing Information</H5>
        </div>
        <div className="mb3-sm mt2-sm">
          <div className="p2-sm ncss-col-sm-4">
            <H5 className="u-bold description">
              Testing Type <span className="astericks ml-neg3">&#42;</span>
            </H5>

            <H6 className="sample p2-sm">
              (R&D sample tests cannot be converted to Production-ready material samples after testing.)
            </H6>
          </div>
          <div className="ncss-col-sm-7 ncss-form-group p4-bottom-sm p4-top-sm">
            {SampleInfo.map(data => (
              <div key={data.label} className="ncss-col-sm-6 ncss-radio-container" onChange={this.testPackageForPFAS.bind(this)}>
                <Field
                  type="radio"
                  component={Radiobutton}
                  name="testingType"
                  value={data.value}
                  checked={false}
                  label={data.label}
                  id={data.id}
                  isIcon={false}
                />
                <span className="pl6-sm radio-sub-info">{data.sub}</span>
              </div>
            ))}
          </div>
        </div>
        {(productId === 204) ? (
          <Fragment>
            <div className="pt2-sm pb2-sm">
              <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
                Will this electonic material be embedded into a finished product (e.g. Footwear)?  <span className="astericks ml-neg3">&#42;</span>
              </div>
              <div className="ncss-col-sm-7 ncss-radio-container">
                {embededProduct.map(data => (
                  <div key={data.label} className="ncss-col-sm-6 u-bold trf-data-text">
                    <Field
                      type="radio"
                      component={Radiobutton}
                      name="isElectEmbedded"
                      value={data.value}
                      checked={false}
                      label={data.label}
                      id={data.id}
                      isIcon={false}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="pt2-sm pb2-sm">
              <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
                Is this electronic material intended to come in contact with user's skin?
                <span className="astericks ml-neg3">&#42;</span>
              </div>
              <div className="ncss-col-sm-7 ncss-radio-container">
                {skinContact.map(data => (
                  <div key={data.label} className="ncss-col-sm-6 u-bold trf-data-text"
                    onChange={this.skinContactHandleChange.bind(this)}>
                    <Field
                      type="radio"
                      component={Radiobutton}
                      name="skinContact"
                      value={data.value}
                      checked={false}
                      label={data.label}
                      id={data.id}
                      isIcon={false}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        ) : ''}
        <div className="pt2-sm pb2-sm">
          <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
            Enable TP2 {testingInfoStates.enableTP2}
          </div>
          <div className="ncss-col-sm-7 ncss-radio-container ml-6">
            <ToggleSwitch
              id="enableTP2"
              name="enableTP2"
              checked={testingInfoStates.enableTP2}
              onChange={this.onToggleSwitchChange}
            />
          </div>
        </div>
        <div className="p2-sm">
          {testingInfoStates && testingInfoStates.testingType === 'PR' && !isNaN(productId) ? (
            <H4 className={sucessFill ? 'error-text pb3-sm' : 'error-hide'}>
              Material Type selection is mandatory for Testing Type - Production Ready
              <span className="astericks">&#42;</span>
            </H4>
          ) : null}
          {testingInfoStates && testingInfoStates.testingType === 'RD' && !isNaN(productId) ? (
            <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
              Either Material Type selection Or Individual Test selection is mandatory for Testing Type - Research &amp; Development(R&amp;D)
              <span className="astericks">&#42;</span>
            </H4>
          ) : null}
          {testingInfoStates && (testingInfoStates.testingType === 'PR' || testingInfoStates.testingType === 'RD') && testingInfoStates.currentProductType
            === null ? (
            <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
              Product Type is Mandatory in Step 1
              <span className="astericks">&#42;</span>
            </H4>
          ) : null}
          <H5 className="u-bold ">
            <div className="ncss-col-sm-10 header-text-mat">
              SELECT MATERIAL TYPE<span className="astericks material-type">&#42;</span>
            </div>
            <div className="ncss-col-sm-2">
              {!isPartialEdit ?
                <IconButton
                  className="clear-button"
                  icon="trash-alt icon-button-margin"
                  onClick={this.resetTestingType}
                  title="Clear Material Selection"
                />
                :
                null
              }
            </div>
          </H5>
          <H6 className="sample">
            Select Test Package (If sample is production-ready material, you must select a Test Package.)
          </H6>
        </div>

        <div className={"ncss-col-sm-12 mb1-sm material-type-section"}>
          {(productId !== 203 && productId !== 205) ? (<div className='mat-data-text mb1-sm'>PRODUCT RSL TESTS (AP, FW & EQ)</div>) : ''}
          {materialTypesSorted.map((materialData, index) => (
            materialData.isTestAvailable !== false ?
              (<span key={materialData.label}>
                {materialData.id === 417 ? <br /> : null}
                {materialData.id === 6010 && (productId === 203 || productId === 206) ?
                  <span>
                    <div className="ncss-col-sm-12 pl0-sm pr0-sm mb1-sm top-border"><div className='mat-data-text'>PACKAGING RSL TESTS</div></div>
                  </span>
                  : materialData.id === 4150 && materialData.category === "electronic-test" && (productId === 204 || productId === 206) ?
                    <span>
                      <div className="ncss-col-sm-12 pl0-sm pr0-sm mb1-sm top-border">
                        <div className='mat-data-text'>ELECTRONIC RSL TESTS </div></div>
                    </span>
                    : materialData.category === "jewelry-test" && index === 22
                      && (productId === 205 || productId === 206) ?
                      <span>
                        <div className="ncss-col-sm-12 pl0-sm pr0-sm mb1-sm top-border"><div className='mat-data-text'>JEWELRY RSL TESTS</div></div>
                      </span>
                      : null}
                {/* RSL TESTS Container start here */}
                {materialData.id !== 4143 && materialData.id !== 4142 &&
                  ((productId === 200 || productId === 201 || productId === 202 || productId === 204 || productId === 206 || isNaN(productId))
                    && materialData.category === 'rsl-test') ?
                  (<div key={materialData.label} className={`ncss-col-sm-4 u-bold pb1-sm`}>
                    <span className="ncss-checkbox-container">
                      <Field
                        type="checkbox"
                        component={Checkbox}
                        name={`materialType[${index}]`}
                        value={materialData.id}
                        normalize={normalizeBoolean(materialData.id)}
                        label={materialData.label}
                        variant={materialData.variant}
                        variants={materialData.variants}
                        id={materialData.id}
                        mandatory={materialData.mandatory}
                        isIcon={false}
                        disabled={isNaN(productId) ? true : false}
                      />
                    </span>
                    {testingInfoStates.materialType && (materialData.variant !== false)
                      ? testingInfoStates.materialType
                        .filter(element => element != null)
                        .map(id =>
                          id === materialData.id && (materialData.isTestAvailable !== false) && find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variant ? (
                            (id === 400 || id === 415) ?
                              <PopOver>
                                <span>
                                  <H4 className={sucessFill && id !== 415 ? 'error-text' : 'error-hide'}>
                                    Select one (mandatory)<span className="astericks">&#42;</span>
                                  </H4>
                                  {find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variants.sort(this.sortDynamic('label')).map((variant, varientIndex) => (
                                    <div key={materialData.label} className=" ncss-checkbox-container">
                                      <Field
                                        type="checkbox"
                                        component={Checkbox}
                                        name={`variants${id}[${varientIndex}]`}
                                        normalize={normalizeBoolean(variant.id)}
                                        label={variant.label}
                                        value={variant.id}
                                        id={variant.id}
                                        isIcon={false}
                                      />
                                    </div>
                                  ))}
                                </span>
                              </PopOver>
                              :
                              <span>
                                <PopOver>
                                  <span>
                                    <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
                                      Select one (mandatory)<span className="astericks">&#42;</span>
                                    </H4>
                                    {find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variants.map(variant => (
                                      <Fragment>
                                        <div key={materialData.label} className=" ncss-radio-container" onChange={this.handleChange.bind(this)}>
                                          <Field
                                            type="radio"
                                            component={Radiobutton}
                                            name={`variants${materialData.id}`}
                                            label={variant.label}
                                            value={variant.id}
                                            id={variant.id}
                                            checked={false}
                                            isIcon={false} />
                                        </div>
                                        <div className={(getScreeningInkVarient === 4052 &&
                                          getScreeningInkVarient !== 0) || (getScreeningInkVarient === 4052 && testingInfoStates.testingInfoStatus === 'Edit'
                                          ) ? 'div-block sub-variant-div' : 'div-none sub-variant-div'}>
                                          {variant.subVariant === true ? (
                                            variant.subVariants.map(subvariant => (
                                              <div key={materialData.label} className="ncss-radio-container">
                                                <Field
                                                  type="radio"
                                                  component={Radiobutton}
                                                  name={`subVariants`}
                                                  label={subvariant.label}
                                                  value={subvariant.id}
                                                  id={subvariant.id}
                                                  checked={false}
                                                  isIcon={false}
                                                />
                                              </div>
                                            )))
                                            : ''}</div>
                                      </Fragment>
                                    ))}
                                  </span>
                                </PopOver>
                              </span>
                          ) : null
                        )
                      : null}
                  </div>) : ''
                }
                {/*
                {/* RSL TESTS Container end here */}
                {/* PACKAGING TESTS Container start here */}
                {materialData.id !== 4143 && materialData.id !== 4142 &&
                  ((productId === 203 || productId === 206) && materialData.category === 'packaging-test') ?
                  (<div key={materialData.label} className={`ncss-col-sm-4 u-bold pb1-sm`}>
                    <span className="ncss-checkbox-container">
                      <Field
                        type="checkbox"
                        component={Checkbox}
                        name={`materialType[${index}]`}
                        value={materialData.id}
                        normalize={normalizeBoolean(materialData.id)}
                        label={materialData.label}
                        variant={materialData.variant}
                        variants={materialData.variants}
                        id={materialData.id}
                        mandatory={materialData.mandatory}
                        isIcon={false}
                      />
                    </span>
                    {testingInfoStates.materialType && (materialData.variant !== false)
                      ? testingInfoStates.materialType
                        .filter(element => element != null)
                        .map(id =>
                          id === materialData.id && (materialData.isTestAvailable !== false) && find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variant ? (
                            (id === 400 || id === 415) ?
                              <PopOver>
                                <span>
                                  <H4 className={sucessFill && id !== 415 ? 'error-text' : 'error-hide'}>
                                    Select one (mandatory)<span className="astericks">&#42;</span>
                                  </H4>
                                  {find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variants.sort(this.sortDynamic('label')).map((variant, varientIndex) => (
                                    <div key={materialData.label} className=" ncss-checkbox-container">
                                      <Field
                                        type="checkbox"
                                        component={Checkbox}
                                        name={`variants${id}[${varientIndex}]`}
                                        normalize={normalizeBoolean(variant.id)}
                                        label={variant.label}
                                        value={variant.id}
                                        id={variant.id}
                                        isIcon={false}
                                      />
                                    </div>
                                  ))}
                                </span>
                              </PopOver>
                              :
                              <span>
                                <PopOver>
                                  <span>
                                    <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
                                      Select one (mandatory)<span className="astericks">&#42;</span>
                                    </H4>
                                    {find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variants.map(variant => (
                                      <Fragment>
                                        <div key={materialData.label} className=" ncss-radio-container" onChange={this.handleChange.bind(this)}>
                                          <Field
                                            type="radio"
                                            component={Radiobutton}
                                            name={`variants${materialData.id}`}
                                            label={variant.label}
                                            value={variant.id}
                                            id={variant.id}
                                            checked={false}
                                            isIcon={false} />
                                        </div>
                                        <div className={(getScreeningInkVarient === 4052 &&
                                          getScreeningInkVarient !== 0) || (getScreeningInkVarient === 4052 && testingInfoStates.testingInfoStatus === 'Edit'
                                          ) ? 'div-block sub-variant-div' : 'div-none sub-variant-div'}>
                                          {variant.subVariant === true ? (
                                            variant.subVariants.map(subvariant => (
                                              <div key={materialData.label} className="ncss-radio-container">
                                                <Field
                                                  type="radio"
                                                  component={Radiobutton}
                                                  name={`subVariants`}
                                                  label={subvariant.label}
                                                  value={subvariant.id}
                                                  id={subvariant.id}
                                                  checked={false}
                                                  isIcon={false}
                                                />
                                              </div>
                                            )))
                                            : ''}</div>
                                      </Fragment>
                                    ))}
                                  </span>
                                </PopOver>
                              </span>
                          ) : null
                        )
                      : null}
                  </div>) : ''
                }
                {/* PACKAGING TESTS Container end here */}
                {/* ELECTRONIC TESTS Container end here */}
                {
                  ((materialData.category === "electronic-test" && materialData.id !== 4143 && materialData.id !== 6021 && materialData.id !== 4144 && materialData.id !== 6023) && (productId === 204 || productId === 206)) ? (
                    <Fragment>
                      <div key={materialData.label} className="ncss-col-sm-4 u-bold pb1-sm " >
                        <span className="ncss-radio-container">
                        <Field
                        type="radio"
                        component={Radiobutton}
                        name={`materialType[${index}]`}
                        value={materialData.id}
                        normalize={normalizeBoolean(materialData.id)}
                        label={materialData.label}
                        variant={materialData.variant}
                        variants={materialData.variants}
                        id={materialData.id}
                        onChange={() => this.electCategoryHandleChange(materialData.id)}
                        isIcon={false}
                      />
                        </span>
                      </div>
                      {materialData.id === 4152 &&
                        (<Button className="download-print-button-FRF u-bold pb1-sm text-color-accent" disabled={electronicClearbtn}
                          onClick={this.clearElectronicRadioButtons} title='Clear the Radio buttons' style={{
                            fontSize: 'initial', marginLeft: '30px'
                          }}>clear</Button>)}
                    </Fragment>
                  ) : ''
                }
                {/* ELECTRONIC TESTS Container end here */}
                {/* JEWELRY TESTS Container start here */}
                {
                  materialData.category === "jewelry-test" && (index >= 22 && index <= 25)
                    && (productId === 205 || productId === 206) ? (
                    <Fragment>
                      <div key={materialData.label} className="ncss-col-sm-4 u-bold pb1-sm ">
                        <span className="ncss-radio-container">
                        <Field
                            type="radio"
                            component={Radiobutton}
                            name={`materialType[${index}]`}
                            value={materialData.id}
                            normalize={normalizeBoolean(materialData.id)}
                            label={materialData.label}
                            variant={materialData.variant}
                            variants={materialData.variants}
                            id={materialData.id}
                            isIcon={false} 
                            onChange={() => this.jewelryHandleChange(materialData.id)}
                            />
                        </span>
                        {testingInfoStates.materialType && (materialData.variants !== false)
                          ? testingInfoStates.materialType
                            .filter(element => element != null)
                            .map(id =>
                              id === materialData.id && (materialData.isTestAvailable !== false) && find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variants ? (
                                <span>
                                  <PopOver>
                                    <span>
                                      <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
                                        Select one (mandatory)<span className="astericks">&#42;</span>
                                      </H4>
                                      {find(materialTypesSorted, ['id', testingInfoStates.materialType[index]]).variants.map(variant => (
                                        <Fragment>
                                          <div key={materialData.label} className=" ncss-radio-container" onChange={this.handleChange.bind(this)}>
                                            <Field
                                              type="radio"
                                              component={Radiobutton}
                                              name={`variants${materialData.id}`}
                                              label={variant.label}
                                              value={variant.id}
                                              id={variant.id}
                                              checked={false}
                                              isIcon={false} />
                                          </div>

                                        </Fragment>
                                      ))}
                                    </span>
                                  </PopOver>
                                </span>
                              ) : null
                            )
                          : null}
                      </div>
                    {materialData.category === "jewelry-test" && (index === 25)
                     && (
                        <div className="ncss-col-sm-4 u-bold pb1-sm ">
                          <Button className="download-print-button-FRF ncss-col-sm-4 u-bold pb1-sm " disabled={jewelryClearbtn}
                            onClick={this.clearJewelryRadioButtons} class="u-bold pb1-sm text-color-accent" title='Clear the Radio buttons' style={{
                              fontSize: 'initial', marginLeft:'-49px'
                            }}>clear</Button></div>
                            )}
                    </Fragment>
                  ) : ''
                }
              </span>) : ''
          ))}
        </div>
        {testingInfoStates && testingInfoStates.materialType &&
          (
            (testingInfoStates.materialType.includes(418)) ||
            (testingInfoStates.materialType.includes(405)) ||
            (testingInfoStates.materialType.includes(408))) ? (
          <div className="pt2-sm pb2-sm">
            <div className="ncss-col-sm-4 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Ink System Name</H5>
              <Field component={TextInput} name="inkSystemName" type="text"
              />
            </div>
            <div className="ncss-col-sm-4 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Base Name</H5>
              <Field component={TextInput} name="baseName" type="text" />
            </div>
            <div className="ncss-col-sm-4 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">
                Pigment Color<span className="astericks ml-pos">&#42;</span>
              </H5>
              <Field component={TextInput} name="pigmentColor" type="text" />
            </div>
            <div className="ncss-col-sm-4 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Additive 1 Name</H5>
              <Field component={TextInput} name="additive1Name" type="text" />
            </div>
            <div className="ncss-col-sm-4 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Additive 2 Name</H5>
              <Field component={TextInput} name="additive2Name" type="text" />
            </div>
            <div className="ncss-col-sm-4 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Additive 3 Name</H5>
              <Field component={TextInput} name="additive3Name" type="text" />
            </div>{' '}
          </div>
        ) : null}
        <div className={"collapsible_IndividualTest_container"}>
          <Collapsible
            trigger={
              <div className="ncss-col-sm-12 collapse-p0 ">
                <H5 className="ncss-col-sm-11 collapse-p0 u-bold testpackage-font-adjust">
                  Select Individual Test
                </H5>
                <div className="ncss-col-sm-1 collapse-p0">
                  <i className="fas fa-caret-down fa-pull-right" />
                </div>
              </div>
            }
            triggerWhenOpen={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0 testpackage-font-adjust">Select Individual Test</div>
                <div className="ncss-col-sm-1 collapse-p0">
                  <i className="fas fa-caret-up fa-pull-right" />
                </div>
              </div>
            }
            triggerStyle={titleStyles}
            triggerClassName="collapsible mt3-sm"
          >
            <div className="p2-sm">
              <H6 className="sample">
                Please select a Test Package in the box above. You can then select the Individual Tests you want below.
                If this sample is for Research & Development (R&D), then it does not affect annual testing requirements
                for Production-ready material.
              </H6>
            </div>
            <div className="ncss-col-sm-12  ncss-checkbox-container ">
              <div className={productId !== 203 && productId !== 205 ? 'div-block' : 'div-none'}>
                {common.map((data, index) => (
                  data.isTestAvailable !== false ?
                    <div key={data.label} className="ncss-col-sm-4  description u-bold trf-data-text pb1-sm">
                      <span className="ncss-checkbox-container">
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          normalize={normalizeBoolean(data.id)}
                          name={`testPackages[${index}]`}
                          label={data.label}
                          checked={data.checked}
                          disabled={(productId === 203 && (isPartialEdit === false || isPartialEdit === true)) ? true :  ((testingInfoStates.repellent === 'Yes'  &&
                           (index === 8 || index === 29))||(testingInfoStates.foodOrMouth === 'Yes' && index === 8)) && (productId !== 204 && productId !== 205) ?true :
                            isNaN(productId) ? true :
                              (productId !== 203 && (isPartialEdit === false || isPartialEdit === true)) ? false : true}
                          isIcon={false}
                        />
                      </span>
                      {testingInfoStates.testPackages && data.variant
                        ? testingInfoStates.testPackages
                          .filter(element => element != null)
                          .map(id =>
                            id === data.id && find(individualTest[0].common, ['id', id]).variant ? (
                              <PopOver>
                                <span>
                                  <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
                                    Select one (mandatory)<span className="astericks">&#42;</span>
                                  </H4>
                                  {find(individualTest[0].common, ['id', id]).variants.sort(this.sortDynamic('label')).map(variant => (
                                    <div key={data.label} className=" ncss-radio-container">
                                      <Field
                                        type="radio"
                                        component={Radiobutton}
                                        name={`tp-variants${data.id}`}
                                        label={variant.label}
                                        value={variant.id}
                                        id={variant.id}
                                        checked={false}
                                        isIcon={false}
                                      />
                                    </div>
                                  ))}
                                </span>
                              </PopOver>
                            ) : null
                          )
                        : null}
                    </div>
                    : null
                ))}</div>
              <div className={productId === 204 || productId === 206 ? 'div-block' : 'div-none'}>
                <div className="astericks u-bold ncss-col-sm-12">Electronic</div>
                {electronic.map((data, index) => (
                  data.isTestAvailable !== false ?
                    <div
                      key={data.label}
                      className="ncss-col-sm-4 ncss-checkbox-container description u-bold trf-data-text"
                    >
                      <Field
                        type="checkbox"
                        component={Checkbox}
                        normalize={normalizeBoolean(data.id)}
                        name={`testPackagesElectronic[${index}]`}
                        label={data.label}
                        disabled={((productId === 203 || productId === 204 || productId === 206) && testingInfoStates.testingType === 'PR') ? true :
                          (productId === 204 && testingInfoStates.testingType === 'RD') ? false :
                            ((productId !== 203 || productId !== 204 || productId !== 206) && (isPartialEdit === false || isPartialEdit === true)) ? false : true}
                        checked={data.checked}
                        className="p1-sm"
                        isIcon={false}
                      />
                    </div>
                    : null
                ))}</div>
              <div className={productId === 203 || productId === 206 ? 'div-block' : 'div-none'}>
                <div className="astericks u-bold ncss-col-sm-12">Packaging</div>
                {packaging.map((data, index) => (
                  data.isTestAvailable !== false ?
                    <div
                      key={data.label}
                      className="ncss-col-sm-4 ncss-checkbox-container description u-bold trf-data-text"
                    >
                      <Field
                        type="checkbox"
                        component={Checkbox}
                        normalize={normalizeBoolean(data.id)}
                        name={`testPackagesPackaging[${index}]`}
                        label={data.label}
                        disabled={((testingInfoStates.repellent === 'Yes'  && (index === 5 || index === 11))||(index === 5 && testingInfoStates.foodOrMouth === 'Yes')) ? true:((productId === 203 || productId === '' || productId === 206) && (isPartialEdit === false || isPartialEdit === true)) ? false :
                          (productId && productId !== 203 && (isPartialEdit === false || isPartialEdit === true)) ? true : false}
                        checked={data.checked}
                        isIcon={false}
                      />
                    </div>
                    : null
                ))}</div>
              <div className={productId === 205 || productId === 206 ? 'div-block' : 'div-none'}>
                <div className="astericks u-bold ncss-col-sm-12">Jewelry</div>
                {jewelry.map((data, index) => (
                  data.isTestAvailable !== false ?
                    <div
                      key={data.label}
                      className="ncss-col-sm-4 ncss-checkbox-container description u-bold trf-data-text"
                    >
                      <span>
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          normalize={normalizeBoolean(data.id)}
                          name={`testPackagesJewelry[${index}]`}
                          label={data.label}
                          disabled={(productId === 203 && (isPartialEdit === false || isPartialEdit === true)) ? true :
                            (productId !== 203 && (isPartialEdit === false || isPartialEdit === true)) ? false : true}
                          checked={data.checked}
                          className="p1-sm"
                          isIcon={false}
                        />
                      </span>
                      {testingInfoStates.testPackagesJewelry && data.variant && !data.doNotShowVariants
                        ? testingInfoStates.testPackagesJewelry
                          .filter(element => element != null)
                          .map(id =>
                            id === data.id && find(individualTest[0].jewelry, ['id', id]).variant ? (
                              <PopOver>
                                <span>
                                  <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
                                    Select one (mandatory)<span className="astericks">&#42;</span>
                                  </H4>
                                  {find(individualTest[0].jewelry, ['id', id]).variants.sort(this.sortDynamic('label')).map(variant => (
                                    <div key={data.label} className=" ncss-radio-container pb2-sm">
                                      <Field
                                        type="radio"
                                        component={Radiobutton}
                                        name={`tp-variants${data.id}`}
                                        label={variant.label}
                                        value={variant.id}
                                        id={variant.id}
                                        checked={false}
                                        isIcon={false}
                                      />
                                    </div>
                                  ))}
                                </span>
                              </PopOver>
                            ) : null
                          )
                        : null}
                    </div>
                    : null
                ))}</div>
            </div>
          </Collapsible>
        </div>
        <ConfirmationBox
          message={message}
          submitModal={submitModal}
          submitValue={null}
          closeModal={this.closeSubmitModal}
          buttonsDisplay={false}
        />
      </form>
    );
  }
}
TestingInfo.propTypes = {
  sucessFill: PropTypes.bool,
  change: PropTypes.func,
  displaySubmitButtonTrf: PropTypes.func,
  displayClearButtonTrf: PropTypes.func,
  updateMaterialField: PropTypes.object,
  testingInfoStates: PropTypes.object,
  onChangeProductType: PropTypes.object,
  onChangeScreeningInkVarient: PropTypes.object,
  initialCertificationArry: PropTypes.func,
  getRecycledContentValue: propTypes.func,
};
const mapStateToProps = state => ({
  updateMaterialField: state.trf.updateMaterialField,
  testingInfoStates: getFormValues('testingInfo')(state),
  initialMaterialTypes: get(state, 'trf.trfDataForEdit.materialType', []),
  initialTestPackages: get(state, 'trf.trfDataForEdit', null),
  getProductTypeObj: state.trf.onChangeProductType,
  getScreeningInkVarient: state.trf.onChangeScreeningInkVarient,
  getInitialCertificationArry: state.trf.initialCertificationArry,
  getRecycledContentValue: state.trf.recycledContentValue
});

const mapDispatchToProps = dispatch => ({
  InitialProductTypeStore: data => {
    dispatch(InitialProductTypeStore(data));
  },
  currentProductTypeStore: data => {
    dispatch(currentProductTypeStore(data));
  },
  onChangeProductType: (data) => {
    dispatch(onChangeProductType(data));
  },
  onChangeScreeningInkVarient: (data) => {
    dispatch(onChangeScreeningInkVarient(data));
  },
  getProductIdEdit: data => {
    dispatch(getProductIdEdit(data));
  },
  initialCertificationArry: data => {
    dispatch(initialCertificationArry(data))
  },
  recycledContentValue: data => {
    dispatch(recycledContentValue(data))
  }
});


export default reduxForm({
  form: 'testingInfo',
  initialValues: {
    testingType: 'PR',
    repellent: 'No',
    foodOrMouth: 'No',
    //recycledContent: 'No'
  },
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepValues: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TestingInfo)
);