import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '../../components/grid/Grid.js';
import userInfoGridConfig from '../../configs/gridConfigs/userInfo/userInfo.js';
import { createServiceAction } from '../../util/actionHelper.js';
import pageConstants from './userInfoGrid.constants.js';
import EditUserProfile from './editUserProfile/EditUserProfile.js';
import EditLocation from './editLocation/EditLocation.js'
import AddLocation from '../addSupplier/AddLocation/AddLocation';

class UserInfoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isEditUserProfileModalOpen:false,
        isEditLocationModal:false,
        isAddLocationModalOpen:false,
        userType:'',
        userInfo: {},
        isEdit :false,
        supplierId: null,
        comapnyType: null,
        companyName: null,
        OSHId: null,
        parentCompany: null,
        locationName: null
    }
    this.setGridParams = this.setGridParams.bind(this);
    this.onUserGridRowClick = this.onUserGridRowClick.bind(this);
    this.toggleEditUserProfileModal = this.toggleEditUserProfileModal.bind(this);
    this.setUserGridColumns = this.setUserGridColumns.bind(this);
  }

  setUserGridColumns = (params) => {
    const { useFor } = this.props;
    let columnsToDisplay = pageConstants.userInfo;
    // Diplay columns based on usage
    if(useFor === 'EditUser'){
      columnsToDisplay = pageConstants.userInfoEdit;
    } else if(useFor === 'TPException'){
      columnsToDisplay = pageConstants.userInfoTPException;
    } else if(useFor === 'TPExceptionRSL'){
      columnsToDisplay = pageConstants.userInfoTPExceptionRSL;
    } else if(useFor === 'TPExceptionFactoryOrSupplier'){
      columnsToDisplay = pageConstants.userInfoTPExceptionFactoryOrSupplier;
    } else if(useFor === 'TPExceptionAllUsers'){
      columnsToDisplay = pageConstants.userInfoTPExceptionAllUsers;
    } else if(useFor === 'EditSupplier'){
      columnsToDisplay = pageConstants.supplierInfo;
    }
    const allColumns = params.columnApi.getColumnState();
    const filteredColumns = allColumns.map(column => {
      if (columnsToDisplay.indexOf(column.colId) === -1) {
        column.hide = true;
      } else {
        column.hide = false;
      }
      return column;
    });
    params.columnApi.setColumnState(filteredColumns);
    // Fix for IE
    params.api.sizeColumnsToFit();
    params.api.refreshHeader();
  }

  componentDidUpdate(prevProps){
    const { useFor } = this.props;
    if(prevProps.useFor !== useFor){
      this.gridParams && this.setUserGridColumns(this.gridParams);
    }
  }

  setGridParams = (params) => {
    this.gridParams = params;
    const { userInfoGridParams } = this.props;
    this.setUserGridColumns(params);
    // Execute Grid params passed from props
    userInfoGridParams && userInfoGridParams(params);
  }

  onUserGridRowClick = params => {
    const { userDataFetch, requestEditLocationLocationList } = this.props;
    const colId = params.api.getFocusedCell().column.colId
    if((colId === 'editUser')&&(params.data.userType !== 'Rsl')&& (params.data.userType !== 'Nlo')){  
      this.setState({isEditUserProfileModalOpen:true, userType:params.data.userType, userInfo:params.data, isEdit:true})
      userDataFetch(params.data.userId)
    } else if(colId === 'editSupplier'){   
      requestEditLocationLocationList(params.data.supplierId)  ;
      this.setState({isEditLocationModal:true, supplierId:params.data.supplierId, companyType:params.data.companyType, companyName:params.data.supplierName,
        OSHId:params.data.OSHId,parentCompany:params.data.parentCompany,locationName:params.data.locationName })
    
    }  else if(colId === 'addLocation'){  
      this.setState({isAddLocationModalOpen:true, supplierId:params.data.supplierId, })
    
    }
  }

  toggleEditUserProfileModal= () =>{
    const {isEditUserProfileModalOpen} = this.state;
    this.setState({isEditUserProfileModalOpen:!isEditUserProfileModalOpen, isEdit: false})
  }

  toggleEditLocationModal = () =>{
    const {isEditLocationModal} = this.state;
    this.setState({isEditLocationModal:!isEditLocationModal})

  }
  toggleisAddLocationModal = () => {
    const { isAddLocationModalOpen } = this.state;
    this.setState({isAddLocationModalOpen : !isAddLocationModalOpen})

  }

  onCellClicked = (params) => {
    const { isRslUser, userStatusChange, supplierStatusChange } = this.props
    const colId = params.api.getFocusedCell().column.colId


 if (isRslUser  && colId === 'activeStatus') {
      if (params.event.target.checked !== undefined) {
        userStatusChange(params.data.userId,params.data.isActive)
      }
    } else if (isRslUser  && colId === 'supplierActiveStatus') {
     
      if (params.event.target.checked !== undefined) {
        supplierStatusChange(params.data.id,params.data.isActive)
      }
    }
  }

  render() {
    const { 
      gridData, 
      rowSelection, 
      rowMultiSelectWithClick, 
      userInfoById, 
      userInfoForAdd, 
      useFor, 
      overlayLoadingTemplate, 
      customNoRowTemplete,
      onSelectionChanged
    } = this.props;
    const { isEditUserProfileModalOpen, isEditLocationModal, isAddLocationModalOpen, userType, isEdit, userInfo, supplierId, companyType, companyName,OSHId,parentCompany,locationName } = this.state;    
    return (
      <span>
        <Grid
          rowData={gridData}
          columnDefs={userInfoGridConfig}
          onRowClick={this.onUserGridRowClick}
          setParams={this.setGridParams}  
          onCellClicked={this.onCellClicked.bind(this)}                  
          { ...( rowSelection && {rowSelection: rowSelection} ) }
          { ...( rowMultiSelectWithClick && {rowMultiSelectWithClick: true} ) }
          { ...( overlayLoadingTemplate && {overlayLoadingTemplate: overlayLoadingTemplate} ) }
          { ...( customNoRowTemplete && {customNoRowTemplete: customNoRowTemplete} ) }
          { ...( onSelectionChanged && {onSelectionChanged: onSelectionChanged} ) }
        />
       {useFor==='EditUser'? 
       <EditUserProfile 
          isModalOpen={isEditUserProfileModalOpen}
          toggleModal={this.toggleEditUserProfileModal} 
          userType={userType}
          userInfo={userInfo}
          supplierName={(userType === 'Suppliers'||'Factories') && userInfoById?userInfoById.supplierName:''}
          supplierId={(userType === 'Suppliers'||'Factories') && userInfoById?userInfoById.supplierId:''}
          { ...(  {initialValues:isEdit? userInfoById:userInfoForAdd}) }
          purpose="Edit"
          isAdd={useFor==='EditUser'? false: true}
          OSIdArr={userInfoById ? userInfoById.OSIds:[""]}
        />:null}
        <EditLocation
          isModalOpen={isEditLocationModal}
          supplierId={supplierId}
          companyType={companyType}
          companyName={companyName}
          OSHId={OSHId}
          parentCompany ={parentCompany}
          locationName={locationName}
          toggleModal={this.toggleEditLocationModal}/>
         <AddLocation
            supplierData={supplierId}
            isModalOpen={isAddLocationModalOpen}
            toggleModal={this.toggleisAddLocationModal}
          />
      </span>
    );
  }

}

const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => ({
  requestEditLocationLocationList: supplierId => {
    dispatch(createServiceAction('addSupplierLocationSearch', 'requested')(supplierId));
  },
});


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(UserInfoGrid);

