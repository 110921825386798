import React, { Component } from 'react';
import Modal from 'react-modal';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { createServiceAction } from '../../../util/actionHelper.js';
import { H5, H4, TextInput, Button, Container, IconButton } from '../../../components/index.js';


class AddLocation extends Component {
    constructor(props) {
        super(props);
        this.state ={ 
          supplierID:null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        
      }
      componentDidMount() {
        const {supplierData } = this.props;
        if(supplierData){
          this.setState({supplierID:supplierData})
        }

      }
    
      handleSubmit= (location) => {
        const { submitLocation, history, supplierData } = this.props;
        const requestObject = {};
        requestObject.locationName = location.locationName;
        requestObject.address1 = location.address1;
        requestObject.address2 = location.address2
        requestObject.city = location.city;
        requestObject.country = location.country;
        requestObject.supplierId = supplierData;
        submitLocation(requestObject,history)
      }
    render() {
        const {location, isModalOpen, toggleModal,errorClassName, resetForm } = this.props; 
        
    return (
    <div>
    <Modal
        isOpen={isModalOpen}
        onRequestClose={() =>  {toggleModal(false);resetForm();} }
        className="modal-container add-material-modal-container"
        overlayClassName="modal-overlay"
      >
        <IconButton icon="times" className="modal-close-btn" onClick={() => {toggleModal(false);resetForm();}} />
        <div className="p2-sm">
            <H4 className="text-color-accent u-bold page-title-has-back sub-heading">ADD LOCATION</H4>
          </div>
        <Container section className="p4-sm ml10-sm mr10-sm mt2-sm">
                <div className="ncss-col-sm-12 ncss-input-container align-center ">
                    <div className="ncss-col-sm-8 align-center">
                        <div className="ncss-col-sm-12 ncss-input-container ">
                            <H5 className="u-bold description ">Location Name<span className="mandatory_supplier">*</span></H5>
                            <Field component={TextInput} name="locationName" type="text" />
                        </div>
                        <div className="ncss-col-sm-6 ncss-input-container ">
                            <H5 className="u-bold description ">Address 1<span className="mandatory_supplier">*</span></H5>
                            <Field component={TextInput} name="address1" type="text" />
                        </div>
                        <div className="ncss-col-sm-6 ncss-input-container ">
                            <H5 className="u-bold description ">Address 2</H5>
                            <Field component={TextInput} name="address2" type="text" />
                        </div>
                        <div className="ncss-col-sm-6 ncss-input-container ">
                            <H5 className="u-bold description  ">Country<span className="mandatory_supplier">*</span></H5>
                            <Field component={TextInput} name="country" type="text" />
                        </div>
                        <div className="ncss-col-sm-6 ncss-input-container ">
                            <H5 className="u-bold description ">City<span className="mandatory_supplier">*</span></H5>
                            <Field component={TextInput} name="city" type="text" />
                        </div>
                    </div>
                </div>
                <div className="ncss-col-sm-12 ">
                    <div className="bottom-section p2-top-sm">
                    
                
                    <Button className="ncss-btn-secondary-dark u-bold secondary-button new-material"
                    onClick={() => {toggleModal(false);resetForm();}}>
                    CANCEL
                    </Button>
                    {errorClassName? (
                    <span>
                      <Button
                        className="ncss-btn-secondary-dark u-bold secondary-button-addlocation new-material "
                        onClick={() => {this.handleSubmit(location);resetForm(); 
                        toggleModal(false)} }>
                      SUBMIT
                    </Button>
                    </span>
                  ) : (
                    <Button className=" disabled ncss-btn-secondary-dark u-bold secondary-button-addlocation new-material">
                      SUBMIT
                    </Button>
                  )}
                    
                    </div>   
                </div>
        </Container>
        </Modal>
    </div>
    )
}
}
AddLocation.propTypes = {
    isModalOpen: PropTypes.bool,
    errorClassName: PropTypes.array,
    toggleModal: PropTypes.func,
    submitLocation: PropTypes.func,
  };

const mapStateToProps =(state) => {
    return{
    EditLocationInfoStates: getFormValues('editLocationInfo')(state),
    location: getFormValues('AddLocation')(state),
    errorClassName: state.addLocation.errorClassName
}
}
  
const mapDispatchToProps =dispatch => ({
    submitLocation: (data, history) => {
        dispatch(createServiceAction('locationSubmit', 'submitted')({ ...data, history }));
      },
      resetForm: () => {
        dispatch(reset('AddLocation'));
      }
      
})
  
  export default reduxForm({ form: 'AddLocation' })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AddLocation));
  