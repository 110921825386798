import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Label from '../../../components/ncss/Label';
import TRF from '../TrfAndQrf/TRFQRF';
import isEmpty from 'lodash/isEmpty';
import { Collapsible } from '../../../components/index.js';

const style = {
  'font-weight': '800',
  'font-size': '1.8rem',
  'line-height': '1.5',
  color: '#0078B9'
};

class Review extends Component {
  componentDidMount() {
    const { displaySubmitButtonFrf, displayClearButtonFrf } = this.props;
    displaySubmitButtonFrf(true);
    displayClearButtonFrf(false);
  }

  render() {
    const {
      dateOfCompletion,
      correctiveAction,
      rootCause,
      failureDesc,
      PDCA,
      attachment,
      displaySubmitButtonFrf,
      displayClearButtonFrf
    } = this.props;
 
    return (
      <div className="form">
        <Collapsible
          trigger={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className="ncss-col-sm-11 collapse-p0"> TRF &amp; QRF INFORMATION</div>
              <div className="ncss-col-sm-1 ">
                <i className="fas fa-caret-down fa-pull-right" />
              </div>
            </div>
          }
          triggerWhenOpen={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className="ncss-col-sm-11 collapse-p0"> TRF &amp; QRF INFORMATION</div>
              <div className="ncss-col-sm-1">
                <i className="fas fa-caret-up fa-pull-right" />
              </div>
            </div>
          }
          triggerStyle={style}
          triggerClassName="collapsible"
        >
          <TRF showTitle={false}
            displaySubmitButtonFrf={displaySubmitButtonFrf}
            displayClearButtonFrf={displayClearButtonFrf}
          />
        </Collapsible>
        <Collapsible
          trigger={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className="ncss-col-sm-11 collapse-p0"> FRF INFORMATION</div>
              <div className="ncss-col-sm-1 ">
                <i className="fas fa-caret-down fa-pull-right" />
              </div>
            </div>
          }
          triggerWhenOpen={
            <div className="ncss-col-sm-12 collapse-p0">
              <div className="ncss-col-sm-11 collapse-p0"> FRF INFORMATION</div>
              <div className="ncss-col-sm-1">
                <i className="fas fa-caret-up fa-pull-right" />
              </div>
            </div>
          }
          triggerStyle={style}
          triggerClassName="collapsible"
        >
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              Why is this chemical used in the manufacturing process? <br/>
              Conduct PDCA to identify root cause
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {PDCA? <span>{PDCA}</span> : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              Failure Description
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {failureDesc ? <span>{failureDesc}</span> : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              What is the root cause of this failure?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {rootCause  ? <span>{rootCause}</span> : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              What are your proposed corrective actions?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {correctiveAction  ? <span>{correctiveAction}</span> : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              What date will the corrective action be completed?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {dateOfCompletion ?
              //  <span>{convertDateReview(dateOfCompletion)}</span>
              <span>{moment.utc(dateOfCompletion).format('DD-MMM-YYYY')}</span>
                : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
             Attachment
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {!isEmpty(attachment) ? <div >{attachment.map(data =>(<div>{data.fileName}</div>))}</div> : 'Not Added'}
            </Label>
          </div>
        </Collapsible>
      </div>
    );
  }
}

Review.propTypes = {
  displaySubmitButtonFrf: PropTypes.func,
  displayClearButtonFrf: PropTypes.func,
  dateOfCompletion: PropTypes.string,
  correctiveAction: PropTypes.string,
  rootCause: PropTypes.string,
  PDCA: PropTypes.string
};

export default connect()(Review);
