import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const model = {
      supplierName: null,
      companyType: null,
      locationName: null,
      address1: null,
      address2: null,
      city: null,
      country:null,
      OSHId:null,
      mLocCode:null,
      parentCompany:null
    };
    const path = {
      history: null
    };

    const result = pick(action.data, keys(model));
    const routes = pick(action.data, keys(path));
    const data = { ...result };
    const infoPopDuration = 1000;

    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(postRequest, apiConfig.addSupplier.endPointUrl, data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if(!statusErrorMessage){  
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response.data));
        const message = `Added ${response.message} with Supplier ID: - ${response.data.supplierId}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        yield put(createServiceAction('listSuppliers','requested')())
        routes.history.push(`/`);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addSupplierSagas() {
  const serviceType = 'addSupplier';
  yield takeEvery(createServiceAction(serviceType, 'submited').type, listener, serviceType);
}

export default addSupplierSagas;
