
export function strUpperCase(str) {
  return str.substring(0,1).toUpperCase() + str.substring(1);
}

export function trfElectricMaterial(eleMaterial){
  if (eleMaterial !== '' || eleMaterial !== 'null' || eleMaterial !== 'undefined') {
    if (eleMaterial === 'electrical') {
      return 'Electrical and Electronic Equipment'
    } else if (eleMaterial === 'battery') {
      return 'Heavy Metals in Battery or Button Cell'
    }else {
      return ''
    }
  }
}
export function trfSampleTypeName(sampleType){
  if (sampleType !== '' || sampleType !== 'null' || sampleType !== 'undefined') {
    if (sampleType === 'finished good') {
      return 'Finished Good (Finished Component or Article)'
    } else if (sampleType === 'raw material') {
      return 'Raw Materials (Pellets, PU, Yarn, etc.)'
    }
    else if (sampleType === 'component') {
      return 'Component (Zipper, button, small parts etc.)'
    }
    else if (sampleType === 'chemical') {
      return 'Chemical Formulation (Adhesive, Dye, Ink, etc.)'
    }
    else {
      return ''
    }
  }
}
export function trfAgeGroupName(ageGroup){
  if (ageGroup !== '' || ageGroup !== 'null' || ageGroup !== 'undefined') {
    if (ageGroup === 'babies/infants/toddlers') {
      return 'Babies / Infants / Toddlers (<36 months)'
    } else if (ageGroup === 'children') {
      return 'Children (36 months – 14 years)'
    }
    else if (ageGroup === 'adults') {
      return 'Adults (>14 years)'
    }
    else if (ageGroup === 'unknown') {
      return 'Unknown (test for all ages)'
    }
    else {
      return ''
    }
  }
}
export function trfCertificationName(certificationName){
  if (certificationName !== '') {
    if (certificationName === 'oeko') {
      return "OEKO-TEX"
    }
    else if (certificationName === 'bluesign') {
      return "Bluesign"
    }
    else if (certificationName === 'eco') {
      return "ECO PASSPORT by OEKO-TEX"
    }
    else if (certificationName !== 'oeko' && certificationName !== 'bluesign' && certificationName !== 'eco') {
      return "Other"
    }
  }
}
