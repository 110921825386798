import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { selectUserDetails } from '../reducers/authReducer.js';

import { put, takeEvery, call, select } from 'redux-saga/effects';
import { selectStatusButton } from '../actions/dashboardActions';


function* listener(serviceType, action) {
  try {
    // Dispatch TESTSUMMARYCOUNT_LOADING action
    yield put(createServiceAction(serviceType, 'loading')());
    // Pass LabId in request params if Lab id exist (Lab id will have value for lab user)
    const { labId } = yield select(selectUserDetails);
    const options = {
      supplierName: action.data.supplierName,
      facilityName: action.data.facilityName,
      ...(action.data.fromDate ? { fromDate: action.data["fromDate"] } : {}),
      ...(labId ? { labId } : {})
    };
    // Trigger Call to API endpoint
    const res = yield call(getRequest, apiConfig.testSummaryCount.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      // Parse response object
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        // Dispatch TESTSUMMARYCOUNT_LOADING action with response object
        yield put(createServiceAction(serviceType, 'success')(response));
        // if(action.data.isClearHistory){
        yield put(selectStatusButton(action.data.status));
        //yield put(createServiceAction('testSummary', 'requested')({ status: action.data.status, supplierName: action.data.supplierName, fromDate: action.data.fromDate }));
        // }
      }
    }
  } catch (e) {
    // Dispatch TESTSUMMARYCOUNT_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* testSummaryCountSagas() {
  const serviceType = 'testSummaryCount';
  // Listen to TESTSUMMARYCOUNT_REQUESTED actions
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default testSummaryCountSagas;
