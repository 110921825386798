import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';


class Grid extends Component {
    constructor(...props) {
        super(...props);
        this.state = {
            searchParams: true,
            rowSelection: "single",
            rowModelType: "infinite",
            paginationPageSize: 100,
            cacheOverflowSize: 2,
            maxConcurrentDatasourceRequests: 1,
            infiniteInitialRowCount: 100,
            maxBlocksInCache: 100
        };
    }

    onGridReady = (params) => {

        const { setParams } = this.props;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        if (setParams) {
            setParams(params);
        }

    };
    onComponentStateChanged = (params) => {
        const { rowData } = this.props;
        this.updateData(params, rowData);
    }
    updateData = (params, data) => {

        const { totalCount, currentCount } = this.props;

        var datasource = {

            getRows: function (params) {
                let page = totalCount / 10;
                setTimeout(function () {

                    if (data && data.length > 0) {
                        let lastRow = () => {
                            if (totalCount <= currentCount) return totalCount
                            else if (page <= totalCount / 10) return -1
                            else return totalCount
                        }
                        params.successCallback(data, lastRow());
                    }
                }, 500);
            }

        };
        params.api.setServerSideDatasource(datasource);
    }



    render() {
        const {
            columnDefs,
            adjustHeight,
            uploadHeight,
            setMaterialHeight
        } = this.props;
        const { paginationPageSize, infiniteInitialRowCount, rowSelection } = this.state;
        return (
            uploadHeight,
            <div className={`ag-theme-balham infinite-grid ${uploadHeight ? 'upload-height' : (!adjustHeight && !setMaterialHeight ? 'custom-height' : (setMaterialHeight ? 'custom-height-material' : 'custom-height-lg'))}`}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowModelType="serverSide"
                    cacheBlockSize={1000}
                    maxBlocksInCache={100}
                    rowSelection={rowSelection}
                    paginationPageSize={paginationPageSize}
                    infiniteInitialRowCount={infiniteInitialRowCount}
                    onGridReady={this.onGridReady}
                    onComponentStateChanged={this.onComponentStateChanged}
                />
            </div>
        );
    }
}

Grid.propTypes = {
    columnDefs: PropTypes.array,
    rowData: PropTypes.array,
    showFilter: PropTypes.bool,
    edit: PropTypes.bool,
    suppressEdit: PropTypes.bool,
    adjustHeight: PropTypes.bool,
    uploadHeight: PropTypes.bool,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    onCellValueChanged: PropTypes.func,
    setParams: PropTypes.func,
    customNoRowTemplete: PropTypes.object,
    overlayLoadingTemplate: PropTypes.object,
    setMaterialHeight: PropTypes.bool
};

Grid.defaultProps = {
    showFilter: true,
    headerHeight: 32,
    filterKeepAlive: false,
    infiniteScroll: false,
    pagination: true
};

export default Grid;
