const config = [

    {
        field: 'supplierName',
        rowGroup: true,
        hide: true
    },

    {
        colId: 'email',
        headerName: 'E-Mail',
        field: 'email',
        suppressFilter: true,
        suppressMovable: true,
        suppressMenu: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    }


];
export const autoGroupColumnDefForAdd =
{
    headerName: 'Supplier Name',
    field: 'name',
    minWidth: 200,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true },

}



export default config;
