import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { isRslUser } from '../../../reducers/authReducer.js';

import { TextInput, Button } from '../../../components/index.js';
import Label from '../../../components/ncss/Label';
import { createServiceAction } from '../../../util/actionHelper.js';
import TRF from '../../trf_review/Trf/Trf';
import QRF from '../../trf_review/Qrf/Qrf';
import FRF from '../../trf_review/Frf/Frf';

const NikeComment = ({ formValue, handleDataSubmit, frfId }) => {
  const acceptedStatus = '1';
  const qrfAccFRFRequired = '3';
  const acceptedForProd = '2';
  const rejectedStatus = frfId ? '5' : '4';
  return (<div className=" ncss-col-sm-12 ncss-form-group p2-bottom-sm qrf-padding-zero">
    <div className="p2-sm">
      <Field
        component={TextInput}
        name={frfId ? 'frfComment' : 'qrfComment'}
        type="textarea"
        className="ncss-label ncss-col-sm-3 pb3-sm u-bold description"
        label={frfId ? 'NIKE COMMENTS ON FRF' : 'NIKE COMMENTS ON QRF'}
      />
    </div>
    <div className="ncss-col-sm-12 inline-flex-display align-center">
      <div className={`ncss-col-sm-3 ${frfId ? 'ncss-col-sm-offset-3' : ''}`}><Button className="button-test button-size bg-color-bl" onClick={() => handleDataSubmit(formValue, acceptedStatus, frfId)}>{frfId ? 'FRF accepted retest needed' : 'QRF accepted retest needed'}</Button> </div>
      {!frfId && <div className="ncss-col-sm-3 align-center"><Button className=" button-test button-size bg-color-bl" onClick={() => handleDataSubmit(formValue, qrfAccFRFRequired)}>Accepted FRF Needed</Button> </div>}
      {!frfId && <div className="ncss-col-sm-3 align-center"><Button className="button-test button-size bg-color-grey" onClick={() => handleDataSubmit(formValue, acceptedForProd)}>Accepted NIKE Approved For Production</Button> </div>}
      <div className="ncss-col-sm-3 align-center"> <Button className=" button-test button-size bg-color-or" onClick={() => handleDataSubmit(formValue, rejectedStatus, frfId)}>{frfId ? 'Rejected Resubmit FRF' : 'Rejected Resubmit QRF'}</Button> </div>
    </div>
  </div>
  )
}

class TestDetailInfo extends Component {

  handleDataSubmit = (data, status, frfId = null) => {
    const { submitStatus, trfResult } = this.props;
    const requestObject = {};
    requestObject.qrfComment = data && data.qrfComment ? data.qrfComment : '';
    requestObject.frfComment = data && data.frfComment ? data.frfComment : '';
    requestObject.testResult = parseInt(status);
    requestObject.trfId = trfResult.data.trfDetails.trfId;
    requestObject.frfId = frfId;
    submitStatus(requestObject);
  };

  downloadFile = (requestParams) => {
    const { downloadAttachment } = this.props;
    const requestObject = Object.assign({}, requestParams);
    downloadAttachment(requestObject);
  }

  trfDataView = trfDetails => (<TRF trfDetails={trfDetails} />);

  qrfDataView = (qrfDetails, trfData, qrfattachmentData) => {
    const { formValue, isRslUser, getTrfDetails, isSuccess } = this.props;
    return (
      <Fragment>
        {isSuccess ? getTrfDetails(trfData.trfId) : null}
        <QRF qrfDetails={qrfDetails}  attachment={qrfattachmentData}/>
        <div className="ncss-row">
          <Label className="ncss-col-sm-6 pb3-sm u-bold description qrf-comments-padding">
            Comments
            </Label>
          <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
            {qrfDetails.comment ? (
              <span>{qrfDetails.comment}</span>
            ) : (
                <span className="error-text-not text-left">Not Added</span>
              )}
          </Label>
        </div>
        {!isSuccess && trfData && isRslUser && (trfData.statusValue === 'QRF Submitted') ? (
          <NikeComment formValue={formValue} handleDataSubmit={this.handleDataSubmit} />
        ) : null}
      </Fragment>
    )
  };

  frfDataView = (frfDetails, trfData, frfAttachmentDetails) => {
    const { formValue, isRslUser, isSuccess, getTrfDetails } = this.props;
    return (
      <Fragment>
        {isSuccess ? getTrfDetails(trfData.trfId) : null}
        <FRF frfDetails={frfDetails} attachment={frfAttachmentDetails} />
        <div className="ncss-row">
          <Label className="ncss-col-sm-6 pb3-sm u-bold description frf-comments-padding">
            Comments
              </Label>
          <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
            {frfDetails.comment ? (
              <span>{frfDetails.comment}</span>
            ) : (
                <span className="error-text-not text-left">Not Added</span>
              )}
          </Label>
        </div>
        {!isSuccess && trfData && isRslUser && (trfData.statusValue === 'FRF Submitted') ? (
          <NikeComment formValue={formValue} handleDataSubmit={this.handleDataSubmit} frfId={frfDetails.frfId} />
        ) : null}
      </Fragment>
    )
  };

  render() {
    const { trfResult, from } = this.props;
    const trfData = trfResult ? trfResult.data.trfDetails : null;
    const qrfData = trfResult ? trfResult.data.qrfDetails : null;
    const frfData = trfResult ? trfResult.data.frfDetails : null;
    const frfattachmentData = trfResult.data.frfAttachmentDetails ? trfResult.data.frfAttachmentDetails : null;
    const qrfattachmentData = trfResult.data.qrfAttachmentDetails ? trfResult.data.qrfAttachmentDetails : null;
    const qrfDataView = from === 'qrfDetails' ? this.qrfDataView(qrfData, trfData,qrfattachmentData) : null;
    const trfDataView = from === 'trfDetails' ? this.trfDataView(trfData) : null;
    const frfDataView = from === 'frfDetails' ? this.frfDataView(frfData, trfData, frfattachmentData) : null;
    return (
      <div>
        <form>
          {trfDataView}
          {qrfDataView}
          {frfDataView}
        </form>
      </div>
    );
  }
}
TestDetailInfo.propTypes = {
  trfResult: PropTypes.object,
  from: PropTypes.any,
  isSuccess: PropTypes.bool,
  handleDataSubmit: PropTypes.func,
  submitStatus: PropTypes.func,
  isRslUser: PropTypes.bool,
  downloadAttachment: PropTypes.func
};

function mapStateToProps(state) {
  return {
    formValue: getFormValues('trfinfo')(state),
    trfResult: state.trf.trfData,
    isSuccess: state.testDetail.isSuccess,
    data: state.testDetail.data,
    isRslUser: isRslUser(state)
  };
}

const mapDispatchToProps = dispatch => ({
  submitStatus: (data) => {
    dispatch(createServiceAction('testDetailsSubmit', 'submitted')({ ...data }));
  },
  getTrfDetails: data => {
    dispatch(createServiceAction('trfDetails', 'requested')(data));
  },
  downloadAttachment: data => {
    dispatch(createServiceAction('downloadAttachment', 'requested')({ ...data }));
  }
})

export default reduxForm({ form: 'trfinfo' })(connect(mapStateToProps, mapDispatchToProps)(TestDetailInfo));