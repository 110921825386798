import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { H4, H5, H6, TextInput } from '../../../components/index.js';
import { renderRadiobutton } from '../../../components/ncss/Radiobutton.js';
import SampleInfo from '../../../constants/json/radiobuttons/qrffile1.json';
import Sample from '../../../constants/json/radiobuttons/qrffile2.json';
import UploadAttachment from '../../../components/uploadAttachment/UploadAttachment.js';

class qrfinfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: []
    };
  }

  componentDidMount(){
    const { displaySubmitButtonQrf, displayClearButtonQrf } = this.props;
    displaySubmitButtonQrf(false);
    displayClearButtonQrf(true);
  }

  onDropData = files => {
    const { rowData } = this.state;
    const data = [...rowData, ...files];
    this.setState({ rowData: data });
  };

 
  render() {
    const { successFill,attachmentQrf,attachment } = this.props;
    return (
      <form className="form">
        <div>
          <H4 className={successFill ? 'error-text' : 'error-hide'}>
            All Fields are Mandatory <span className="astericks ml-neg3">&#42;</span>
          </H4>
          <div className="p2-sm">
            <H4 className="text-color-accent u-bold description">QUESTIONS FOR SUPPLIER</H4>
          </div>
          <div className="ncss-col-sm-12 inline-flex-display p5-sm u-bold qrf-padding-zero ie-inline-display">
            <H5 className="ncss-col-sm-12  u-bold trf-data-text">
            Has any of the affected material left the manufacturer's facility? <span className='astericks ml-neg3'>&#42;</span>
            </H5>

            <div className="ncss-col-sm-8 ncss-radio-container">
              {SampleInfo.map(data => (
                <div key={data.id} className="ncss-col-sm-4 description ncss-radio-container">
                  <Field
                    type="radio"
                    component={renderRadiobutton}
                    name="affectedMaterialLeft"
                    value={data.value}
                    label={data.label}
                    id={data.id}
                    isIcon={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
          <div className="ncss-col-sm-12 ncss-input-container qrf-padding-zero">
              <div className="ncss-col-sm-12 u-bold trf-data-text">
              Where is the affected material now? <span className='astericks ml-neg3'>&#42;</span>
                <Field
                  component={TextInput}
                  name="affectedMaterial"
                  type="textarea"
                  className="ncss-col-sm-12 u-bold trf-data-text"
                />
              </div>
              <div className="p2-bottom-sm qrf-padding-left">
                <H6 className="u-bold p1-top-sm p1-bottom-sm">Example answers:</H6>
                <ul className="ncss-list-ul">
                  <li className="ncss-li">
                    Manufacturing location (use Nike supplier code where applicable)
                  </li>
                  <li className="ncss-li">Distribution Center (use name and code of DC)</li>
                  <li className="ncss-li">
                    In transit to Factory (list factories receiving POs linked to affected material)
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div>
            <div className="ncss-col-sm-12 ncss-input-container qrf-padding-zero">
              <div className="ncss-col-sm-12 u-bold trf-data-text">
                How much material is affected by this failure? <span className='astericks ml-neg3'>&#42;</span>
                <H6 className="ncss-col-sm-6 u-bold p1-top-sm p1-bottom-sm">
                  (Example answers:
                  <span className="normal-text"> 100 kg; 10,000 yards; 1,000 sq. ft; 500 pieces)</span>
                </H6>
              </div>
              <div className="ncss-col-sm-12 u-bold trf-data-text">
                <Field component={TextInput} name="materialAffectedQty" type="text" />
              </div>
            </div>
          </div>
          <div>
            <div className="ncss-col-sm-12 ncss-input-container qrf-padding-zero">
              <div className="ncss-col-sm-12 u-bold trf-data-text">
              How much is the material worth? <span className='astericks ml-neg3'>&#42;</span>
              <H6 className="ncss-col-sm-6 u-bold p1-top-sm p1-bottom-sm">
                  <span className="normal-text"> (To go after How much material is affected by this failure?)</span>
              </H6>
              </div>
              <div className="ncss-col-sm-12 u-bold trf-data-text">
                <Field component={TextInput} name="materialWorth" type="text" />
              </div>
            </div>
          </div>

          <div>
            <div className="ncss-col-sm-12 ncss-input-container qrf-padding-zero">
              <div className="ncss-col-sm-12 u-bold trf-data-text">
                List all factory(s) that are expecting to receive this material <span className='astericks ml-neg3'>&#42;</span>
                <span className="normal-text"> (Please use factory codes whenever possible)</span>
              </div>
              <div className="ncss-col-sm-12 u-bold trf-data-text">
                <Field component={TextInput} name="factoryList" type="text" />
              </div>
            </div>
          </div>
          <div className="ncss-col-sm-12 inline-flex-display p5-sm u-bold qrf-padding-zero ie-inline-display">
            <H5 className="ncss-col-sm-8  u-bold trf-data-text">
              Is this affected material used for Kid product? <span className='astericks ml-neg3'>&#42;</span>
            </H5>

            <div className="ncss-col-sm-8 ncss-radio-container qrf-padding-left">
              {Sample.map(data => (
                <div key={data.id} className="ncss-col-sm-4 description ncss-radio-container">
                  <Field
                    type="radio"
                    component={renderRadiobutton}
                    name="affectedMaterialForKid"
                    label={data.label}
                    id={data.id}
                    value={data.value}
                    isIcon={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
          <div className="ncss-col-sm-12 ncss-input-container qrf-padding-zero">
              <div className="ncss-col-sm-12 u-bold trf-data-text">
              Describe your immediate next steps: <span className='astericks ml-neg3'>&#42;</span>
                <Field
                  component={TextInput}
                  name="immediateStep"
                  type="textarea"
                  className="ncss-col-sm-12 u-bold trf-data-text"
                />
              </div>
            </div>

            <div>
              <H6 className="ncss-col-sm-12 u-bold p1-top-sm p1-bottom-sm">
                Example answers: &nbsp;
                <span className="sample normal-text">
                  Material on hold; Material will be dropped; Factory partner informed; Formal root cause
                  analysis to be performed
                </span>
              </H6>
            </div>
          </div>
          <UploadAttachment uploadAction={attachmentQrf}  attachment={attachment}/>
        </div>
      </form>
    );
  }
}

qrfinfo.propTypes = {
  successFill: PropTypes.bool,
  displaySubmitButtonQrf: PropTypes.func,
  displayClearButtonQrf: PropTypes.func,
  attachmentQrf: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    trfDetail: state.qrf.trfDetail
  };
}
export default reduxForm({
  form: 'QrfInfo',
  destroyOnUnmount: false,
  enableReinitialize: true
})(connect(mapStateToProps)(qrfinfo));
