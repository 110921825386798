import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getMaterialNextPage, getMaterialRemainingPages } from '../reducers/authReducer.js';

import { put, takeEvery, call, select } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      // Get next page number
      const pageNo = action.data.pageNo ? action.data.pageNo : yield select(getMaterialNextPage);
      // Get 50 records at a time.
      const options = {
        supplierLocation: action.data.supplierLocation,
        prodType: action.data.prodType,
        page: pageNo,
        limit: 200
      };

      const res = yield call(getRequest, apiConfig.materialSearch.endPointUrl, options);
      // Retry if the server responded back with too many request issue else go to success
      if (res.status === 400 || res.status === 504) {
        const response = yield res.json();
        if (response.message === "Material details not found for this record.") {
          yield put(createServiceAction(serviceType, 'success')(response));
        }
        else {
          yield put(createServiceAction(`materialSearchLazyLoad`, 'requested')({ ...action.data, pageNo }));
        }

      } else {
        const response = yield res.json();
        yield put(createServiceAction(serviceType, 'success')(response));
        // Get remaining pages
        const remainingPages = yield select(getMaterialRemainingPages);
        // Trigger call again for next set of records till there are pages remaining
        if (remainingPages > 0) {
          yield put(createServiceAction(`materialSearchLazyLoad`, 'requested')({ supplierLocation: action.data.supplierLocation, prodType: action.data.prodType }));
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* materialSearchSaga() {
  const serviceType = 'materialSearch';
  const serviceTypeLazyLoad = 'materialSearchLazyLoad';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeEvery(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);
}

export default materialSearchSaga;
