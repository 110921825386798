//import authSagas from './authSagas.js';
import notificationSagas from './notificationSagas.js';
import testSummarySagas from './testSummarySagas.js';
import testSummaryCountSagas from './testSummaryCountSagas.js';
import trfSubmitSagas from './trfSubmitSagas.js';
import testDetailSagas from './testDetailSagas.js';
import trfDetailSagas from './trfDetailSagas.js';
import trfTestDetailSagas from './trfTestDetailSagas.js';
import qrfSagas from './qrfSagas.js';
import qrfUpdateSagas from './qrfUpdateSagas';
import frfUpdateSagas from './frfUpdateSagas';
import getProfileSagas from './getProfileSagas';
import createProfileSagas from './createProfileSagas';
import frfSagas from './frfSagas.js';
import supplierSearchSagas from './supplierSearchSagas.js';
import locationSearchSagas from './locationSearchSagas.js';
import labResultsSubmitAndRetrieveSagas from './labResultsSubmitAndRetrieveSagas.js';
import labSubmitSagas from './labSubmitSagas';
import materialSearchSaga from './materialSearchSaga';
import addMaterialSagas from './addMaterialSagas';
import addSupplierSearchSagas from './addSupplierSearchSagas';
import addSupplierSagas from './addSupplierSagas';
import addSupplierLocationSaga from './addSupplierLocationSaga';
import addSupplierLocationDetailsSagas from './addSupplierLocationDetailsSagas';
import locationUpdateSagas from './locationUpdateSagas';
import companyUpdateSagas from './companyUpdateSagas';
import downloadAttachmentSagas from './downloadAttachmentSagas.js';
import addLocationSagas from './addLocationSagas';
import trfUpdateSagas from './trfUpdateSagas';
import updateStatusSagas from './updateStatusSagas';
import trfUpdateSubmitSagas from './trfUpdateSubmitSagas';
import userDetailsSagas from './userDetailsSagas';
import userDataByIdSaga from './userDataByIdSaga'
import supplierDetailsSagas from './supplierDetailsSagas'
import getExceptionUsersSagas from './getExceptionUsersSagas';
import addUserSaga from './addUserSaga';
import updateUserProfileSagas from './updateUserProfileSagas';
import setExceptionUsersSagas from './setExceptionUsersSagas';
import addOrRemoveFavouritesSagas from './addOrRemoveFavouritesSagas';
import saveFilterData from './filterSaveSaga';
import getFilterHistory from './getFilterHistorySaga';
import listNotificationSaga from './listNotificationSaga';
import createNotificationSaga from './createNotificationSaga';
import updateNotificationSagas from './updateNotificationSaga';
import readNotificationSaga from './readNotificationSaga';
import deleteNotificationSaga from './deleteNotificationSaga';
import addLicenseeSearch from './addLicenseeSearchSagas';
import setLicensee from './setLicenseeSaga';
import listLicensee from './listLicenceesSaga';
import activeLicenseeList from './activeLicenseeListSaga';
import logVideo from './logVideoSaga';
import getArchive from './archiveSagas';
import getMaterialListSagas from './getMaterialListSagas';
import setUserStatus from './setUserStatusSaga';
import { fork, all } from 'redux-saga/effects';
import changeMaterialStatusSagas from './changeMaterialStatusSagas.js';
import listAllSuppliersSaga from './listAllSupplierSaga.js';
import setSupplierStatus from './setSuplierStatusSaga.js';
import setTRFStatus from './setTRFStatusSagas.js';
import listComments from './listCommentsSagas';
import login from './loginSagas';
import verifyOSHId from './verifyOSHIdSaga.js';
import addLitmos from './addLitmosSaga.js';

function* rootSaga() {
  yield all([
   // fork(authSagas),
    fork(notificationSagas),
    fork(testSummarySagas),
    fork(testSummaryCountSagas),
    fork(trfSubmitSagas),
    fork(testDetailSagas),
    fork(qrfSagas),
    fork(qrfUpdateSagas),
    fork(trfDetailSagas),
    fork(trfTestDetailSagas),
    fork(getProfileSagas),
    fork(createProfileSagas),
    fork(frfSagas),
    fork(supplierSearchSagas),
    fork(locationSearchSagas),
    fork(labResultsSubmitAndRetrieveSagas),
    fork(labSubmitSagas),
    fork(frfUpdateSagas),
    fork(materialSearchSaga),
    fork(addMaterialSagas),
    fork(addSupplierSearchSagas),
    fork(addSupplierSagas),
    fork(addSupplierLocationSaga),
    fork(addSupplierLocationDetailsSagas),
    fork(locationUpdateSagas),
    fork(companyUpdateSagas),
    fork(downloadAttachmentSagas),
    fork(addLocationSagas),
    fork(trfUpdateSagas),
    fork(updateStatusSagas),
    fork(trfUpdateSubmitSagas),
    fork(userDetailsSagas),
    fork(userDataByIdSaga),
    fork(supplierDetailsSagas),
    fork(getExceptionUsersSagas),
    fork(addUserSaga),
    fork(updateUserProfileSagas),
    fork(setExceptionUsersSagas),
    fork(addOrRemoveFavouritesSagas),
    fork(saveFilterData),
    fork(getFilterHistory),
    fork(listNotificationSaga),
    fork(createNotificationSaga),
    fork(updateNotificationSagas),
    fork(readNotificationSaga),
    fork(deleteNotificationSaga),
    fork(addLicenseeSearch),
    fork(setLicensee),
    fork(listLicensee),
    fork(activeLicenseeList),
    fork(logVideo),
    fork(getArchive),
    fork(getMaterialListSagas),
    fork(changeMaterialStatusSagas),
    fork(setUserStatus),
    fork(listAllSuppliersSaga),
    fork(setSupplierStatus),
    fork(setTRFStatus),
    fork(listComments),
    fork(login),
    fork(verifyOSHId),
    fork(addLitmos)

  ]);
}

export default rootSaga;