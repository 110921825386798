import { createServiceAction } from '../util/actionHelper.js';
import { fromJS } from 'immutable';
import get from 'lodash/get';

const defaultState = fromJS({
  testDetailData: {},
  isSuccess: false,
  isLoading: false,
  getTestDetailsNextPage: 1
});

export default function testDetail(state = defaultState, action) {
  const pageCount = 10;
  switch (action.type) {
    // case createServiceAction('testDetailsSubmit', 'submitted').type:
    case createServiceAction('trfDetails', 'requested').type:
      return state.set('isLoading', true);
    case createServiceAction('trfDetails', 'success').type:
      return state.set('isLoading', false).set('isSuccess', false);
    case createServiceAction('testDetails', 'loading').type:

      return state.set('testDetailData', [])

    case createServiceAction('testDetails', 'success').type:
      let nextPage = 1
      let totalPage_success = 0
      if (get(action.data.data, 'testDetails.totalCount') > pageCount) {
        nextPage = parseInt(get(action.data.data, 'testDetails.currentPage'), 10) + 1
        totalPage_success = Math.ceil(parseInt(get(action.data.data, 'testDetails.totalCount'), 10) / pageCount)
      }
      return state.set('testDetailData', get(action.data, `data.testDetails`, []))
        .set('testDetailsByClass', get(action.data, `data.classStatus`, []))
        .set('getTestDetailsTotalCount', get(action.data.data, 'testDetails.totalCount'))
        .set('getTestDetailsTotalPage', totalPage_success)
        .set('getTestDetailsNextPage', nextPage)
        .set('getTestDetailsRemainingPages', Math.ceil(parseInt(get(action.data.data, 'testDetails.totalCount'), 10) / pageCount) - parseInt(get(action.data.data, 'testDetails.currentPage'), 10))
    // return {
    //   ...state, 'testDetailData': action.data.data,
    //   'summaryTotalCount': get(action.data.data, 'testDetails.totalCount'),
    //   'getTestDetailsTotalPage': totalPage_success,
    //   'getTestDetailsNextPage': nextPage,
    //   'getTestDetailsRemainingPages': Math.ceil(parseInt(get(action.data.data, 'testDetails.totalCount'), 10) / pageCount) - parseInt(get(action.data.data, 'testDetails.currentPage'), 10)
    // };


    case createServiceAction('testDetailsInfiniteLoad', 'success').type:
      let temp = state.get('testDetailData')
      let updateTemp = fromJS(temp)

      let InitialData = temp.data;
      InitialData = [...InitialData, ...get(action.data, `data.testDetails.data`, [])];
      updateTemp = updateTemp.updateIn(['currentPage'], data => action.data.data.testDetails.currentPage)
      updateTemp = updateTemp.updateIn(['data'], index => InitialData)
      let nextSummaryPage = parseInt(get(action.data.data, 'testDetails.currentPage'), 10) + 1;
      let totalPage = Math.ceil(parseInt(get(action.data.data, 'testDetails.totalCount'), 10) / pageCount)
      if (nextSummaryPage > state.get('getTestDetailsTotalPage')) {
        nextSummaryPage = 1
        totalPage = 0
      }
      return state.set('testDetailData', updateTemp.toJS())
        .set('getTestDetailsTotalPage', totalPage)
        .set('getTestDetailsNextPage', nextSummaryPage)
        .set('getTestDetailsRemainingPages', Math.ceil(parseInt(get(action.data.data, 'testDetails.totalCount'), 10) / pageCount) - parseInt(get(action.data.data, 'testDetails.currentPage'), 10))



    // case createServiceAction('testDetailsSubmit', 'success').type:
    //   return { ...state, isSuccess: true, data: action.data, isLoading: false };
    // case createServiceAction('testDetailsSubmit', 'error').type:
    //   return { ...state, isSuccess: true, isLoading: false };
    // case createServiceAction('testDetails', 'success').type:
    //   console.log(action.data)
    //   //return { ...state, testDetailData: action.data.data };
    //   return state.set('testDetailData', action.data.data);
    default:
      return state;
  }
}

export const getTrfDetailsLoading = state => state.testDetail.get('isLoading');
export const getTestDetailsIsSuccess = state => state.testDetail.get('isSuccess');
export const getTestDetailsRemainingPages = state => state.testDetail.get('getTestDetailsRemainingPages');
export const getTestDetailsTotalPage = state => state.testDetail.get('getTestDetailsTotalPage');
export const getTestDetailsNextPage = state => state.testDetail.get('getTestDetailsNextPage');
export const getTestDetailsTotalCount = state => state.testDetail.get('getTestDetailsTotalCount');

export const getTestDetailData = state => state.testDetail.get('testDetailData');
export const getTestDetailsByClass = state => state.testDetail.get('testDetailsByClass');
