import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import find from 'lodash/find';
import union from 'lodash/union';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';

import { H4, H5, Container, Button, IconButton } from '../../../components/index.js';
import Grid from '../../../components/grid/Grid.js';
import resultsTableConfig, {resultsTableReadonlyConfig} from '../../../configs/gridConfigs/labResults/resultsTableConfig.js';
import Upload from '../upload/Upload';
import individualTest from '../../../constants/json/checkboxes/individualTest.json';
import labConstants from '../Lab.constants';

class TestValues extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      paramsForExcel: {},
      isPopoverOpen: false,
      uploadData: []
    };
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  componentDidMount() {
    const { displayClearButtonLab, displaySubmitButtonLab, data } = this.props;
    this.setState({uploadData: data});
    displayClearButtonLab(true);
    displaySubmitButtonLab(false);
  }
  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (!isEqual(prevProps.data, data)) {
      this.setState({uploadData: data});
    }
  }

  onCellValueChanged = () => {
    const rowData = [];
    let error_val =  0;
    const { validateData } = this.props;
    if (this.agGridParams) {
      this.agGridParams.api.forEachNode(rowNode => {
            if(rowNode.data.testResultValue === 'undefined' || rowNode.data.testResultValue === '' || rowNode.data.testResultValue === null )
            {
            error_val = error_val + 1;
            }
            rowData.push(rowNode.data);
      });
    }
    if(!error_val)
    {
    validateData(rowData);
    }
    else { 
      validateData(false);
    }
  };

  onUpdate = val => {
    const { getReviewInfo, validateData } = this.props;
    const { uploadData } = this.state;
    let count = 0;
    let nullCount = 0;
    let error_data = 0;
    let error_data2 = 0;
   
    if (val.length > 0 && val.length === uploadData.length) {
      for (let i = 0; i < uploadData.length; i++) {
        if (typeof uploadData[i].testPackageId !== 'string') {
          uploadData[i].testPackageId = uploadData[i].testPackageId &&
          find(union(individualTest[0].common, individualTest[0].toys, individualTest[0].electronic), [
            'id',
            uploadData[i].testPackageId
          ]).label;
        }
        for (let j = 0; j < val.length; j++) {
          if (
            val[j].chemicalName.trim() === uploadData[i].chemicalName.trim() &&
            val[j].CAS === uploadData[i].CAS &&
            val[j].chemicalClass.trim() === uploadData[i].testPackageId.trim()
          ) {
            if (val[j].testResultValue !== null && val[j].testResultValue !== '' && typeof val[j].testResultValue !== "undefined" && val[j].testResultValue.toString().length > 0) {
              if( (uploadData[i].testPackageId.trim() === "Asbestos" || uploadData[i].testPackageId.trim() === "Polyvinyl Chloride (PVC)") && (
                  (typeof val[j].testResultValue === 'string' &&
                    (val[j].testResultValue.toLowerCase() === 'nd' || val[j].testResultValue.toLowerCase() === 'not tested')
                  ) || val[j].testResultValue >= 0))
              {
                error_data += 1;
                count += 1;
              }
              else if(typeof val[j].testResultValue === 'string' &&
                (val[j].testResultValue.toLowerCase() === 'present' || val[j].testResultValue.toLowerCase() === 'absent') &&
                (uploadData[i].testPackageId.trim() === "Asbestos" || uploadData[i].testPackageId.trim() === "Polyvinyl Chloride (PVC)"))
              {
                count += 1;
              }
              else if (
                typeof val[j].testResultValue === 'string' &&
                (val[j].testResultValue.toLowerCase() === 'present' || val[j].testResultValue.toLowerCase() === 'absent' ||
                  val[j].testResultValue.toLowerCase() === 'nd' || val[j].testResultValue.toLowerCase() === 'not tested') &&
                (uploadData[i].testPackageId.trim() !== "Asbestos" && uploadData[i].testPackageId.trim() !== "Polyvinyl Chloride (PVC)"))
              {
                count += 1;
              }
               else if (val[j].testResultValue >= 0) {
                count += 1;

              }
              else if (val[j].testResultValue < 0)
              {
                error_data2 += 1;
                count += 1;
              }

              
            } else if (val[j].testResultValue === null || typeof val[j].testResultValue !== "undefined" || val[j].testResultValue !== '') {
              count += 1;
              nullCount += 1;
            }
            if(labConstants.repeatTestPackages.includes(uploadData[i].testPackageId) && val[j].unit.trim() !== uploadData[i].unit.trim())
            {
              count -= 1;

            }
          }
        }
      }
      if (count === uploadData.length) {
        if(!nullCount && !error_data && !error_data2){
        getReviewInfo(val);
        validateData(val);
        toast.success(labConstants.success_msg, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        }
        else if(error_data2)
        {
          toast.error(labConstants.notMatch_msg3, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }); 
        }
        else if(error_data)
        {
          toast.error(labConstants.notMatch_msg2, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }); 
        }
        else {
        toast.error(labConstants.success_msg2, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } else {
      toast.error(labConstants.notMatch_msg, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    } else {
      toast.error(labConstants.notMatch_msg, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  setParams = params => {
    this.agGridParams = params;
    const { paramsForExcel } = this.state;
    if (paramsForExcel) {
      this.setState({ paramsForExcel: params });
    }
  };

  displayPopOver = () => {
    const { isPopoverOpen } = this.state;
    const togglePopover = !isPopoverOpen;
    this.setState({ isPopoverOpen: togglePopover });
  };

  exportData = params => {
    const { rslNo } = this.props;
    params.processCellCallback = params => {
      if (params.value && params.column.colDef.headerName === 'Chemical Class') {
        return find(union(individualTest[0].common, individualTest[0].toys, individualTest[0].electronic), [
          'id',
          params.value
        ]).label;
      }
      return params.value;
    };
    const date = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const currDate = `${date}-${month}-${year} `;
    params.fileName = `${rslNo}_${currDate}`;
    params.api.exportDataAsExcel(params);
  };

  render() {
    const { data, isInReview } = this.props;
    const { paramsForExcel, isPopoverOpen } = this.state;
    return (
      <div>
        <div className="ncss-row labrow">
          <div className="ncss-col-sm-8 ">
            <div className="p2-sm">
              <H4 className="text-color-accent u-bold page-title-has-back attachment">
                UPLOAD TEST RESULTS
                <Popover
                  isOpen={isPopoverOpen}
                  position="bottom"
                  padding={5}
                  onClickOutside={this.displayPopOver}
                  content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      targetRect={targetRect}
                      popoverRect={popoverRect}
                      arrowColor="#dddcde"
                      arrowSize={0}
                      arrowStyle={{ opacity: 0.7, left: '120px' }}
                    >
                      <IconButton icon="times" className="popover-button" onClick={this.displayPopOver} />
                      <div className="popover-trfsection">
                        <H5 className="u-bold ">GUIDELINES FOR UPLOADING TEST RESULTS</H5>
                        <ol className="ncss-list-ol ol-font-size">
                          <li className="ncss-li">
                            Please download the excel by clicking the Download Button
                          </li>
                          <li className="ncss-li">
                            Enter the test results in Test Result Values column in the downloaded excel
                          </li>
                          <li className="ncss-li">Upload the test results and verify.</li>
                          <li className="ncss-li">
                            For PVC and ASBESTOS enter Test value Present/Absent
                          </li>
                          <li className="ncss-li">For Not detected chemical enter test value ND</li>
                          <li className="ncss-li">
                            For Chromium/Tin analysis, enter value NOT tested if test is not conducted
                          </li>
                        </ol>
                      </div>
                    </ArrowContainer>
                  )}
                >
                  <span>
                    <IconButton icon="comment-alt" extraClassName="color-trf" onClick={this.displayPopOver} />
                  </span>
                </Popover>
              </H4>
              { !isInReview &&
                <H5 className="u-bold description">
                  Download the excel to enter the test results values
                </H5>
              }
            </div>
          </div>
          { !isInReview &&
            <Fragment>
              <div className="ncss-col-sm-2 ">
                <Upload onUpdate={this.onUpdate} />
              </div>
              <div className="ncss-col-sm-2">
                <span className="download-section download">
                  <i className="fas fa-download" />
                  <Button
                    className="text-color-accent download-button"
                    onClick={() => this.exportData(paramsForExcel)}
                  >
                    DOWNLOAD
                  </Button>
                </span>
              </div>
            </Fragment>
          }
        </div>
        <div className="dashboard">
          <Container section className="p0-top-sm m0-sm lab">
            <div className="ncss-row">
              <div className="ncss-col-sm-12">
                <Grid
                  columnDefs={isInReview ? resultsTableReadonlyConfig : resultsTableConfig}
                  suppressEdit={false}
                  rowData={data}
                  showFilter={false}
                  setParams={this.setParams}
                  onCellValueChanged={this.onCellValueChanged}
                />
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

TestValues.propTypes = {
  getReviewInfo: PropTypes.func,
  data: PropTypes.array,
  displayClearButtonLab: PropTypes.func,
  displaySubmitButtonLab: PropTypes.func,
  validateData: PropTypes.func,
  rslNo: PropTypes.string
};

export default TestValues;