import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import { connect } from 'react-redux';
import { Switch, Route,BrowserRouter as Router } from 'react-router-dom';

import App from "../../root/App";
import {  setUserLogin, setUserLogout, authCheckRequested } from '../../actions/authActions.js';
import { selectUserDetails, loginAuthFinish, logout, validUser, validGroup } from '../../reducers/authReducer.js';
import { getUserGroup, getFullToken } from './authHelpers'
import { Spinner, Container,H1,Button} from '../../components/index.js';

import UserPage from '../../views/user/UserPage.js';
import Dashboard from '../../views/dashboard/Dashboard.js';
import LabResults from '../../views/labResults/LabResults';
import Trf from '../../views/trf/Trf.js';
import ReviewDownload from '../../views/trf/reviewDownload/ReviewDownload.js';
import Qrf from '../../views/qrf/Qrf.js';
import Frf from '../../views/frf/Frf.js';
import { InvalidRoute } from '../../components/invalid_route/InvalidRoute.js';
import LogoutUser from '../../views/auth/logoutUser.js';
import TestDetails from '../../views/test_details/TestDetails.js';
import TestCompleted from '../../views/labResults/TestCompleted.js';
import UserNotExistsError from '../../views/Generic/UserNotExistsError.js';
import UserMismatchError from '../../views/Generic/UserMismatchError.js'
import Training from '../../views/guide/pdf/Training.js';
import RslAdmin from '../../views/rslAdmin/rslAdmin.js';
import CreateNotifications from '../../views/notifications/createNotification/CreateNotification.js';
import Videos from '../../views/guide/videos/Videos.js';
import Archive from '../../views/archive/archive.js';
import LoginAssistance from '../../views/loginAssistance/loginAssistance.js';
import  ActionCenter  from '../welcome/actionCenter.js';
export const routes = [
  {
    path: '/labresult',
    component: LabResults,
    nav: false,
    heading: null,
    id: 2
  },
  {
    path: '/',
    title: 'Dashboard',
    component: Dashboard,
    nav: true,
    icon: 'home',
    heading: null,
    id: 3
  },
  {
    path: '/user',
    title: 'My Profile',
    component: UserPage,
    nav: true,
    icon: 'user-circle',
    heading: 'MY STUFF',
    id: 5
  },
  {
    path: '/action-center',
    title: 'Action Center',
    component: ActionCenter,
    nav: true,
    icon: 'tasks',
    heading: null,
    id: 28
  },
  {
    path: '/rsl-admin',
    title: 'RSL ADMIN',
    component: RslAdmin,
    nav: true,
    icon: 'user-shield',
    heading: null,
    id: 20
  },
  {
    path: '/notification',
    title: 'Create Notification',
    component: CreateNotifications,
    nav: true,
    icon: 'bell',
    heading: null,
    id: 20
  },
  {
    path: '/trf',
    title: 'My TRF Issues',
    component: Trf,
    nav: false,
    icon: 'exclamation-triangle',
    heading: null,
    id: 6
  },
  // {
  //   path: '/login',
  //   title: 'login',
  //   component: Login,
  //   nav: false,
  //   icon: 'null',
  //   heading: null,
  //   id: 0
  // },
  {
    path: '/qrf',
    title: 'My QRF Issues',
    component: Qrf,
    nav: false,
    icon: 'exclamation-triangle',
    heading: null,
    id: 7
  },

  {
    path: '/frf',
    title: 'Create FRF',
    component: Frf,
    nav: false,
    icon: 'exclamation-triangle',
    heading: null,
    id: 8
  },
  {
    path: '/rsl-policy-training',
    title: 'RSL Policy Training',
    component: null,
    nav: true,
    icon: 'id-card',
    heading: null,
    id: 9,
    url: 'https://about.nike.com/pages/chemistry-training'
  },
  {
    path: '/chemistry',
    title: 'Chemistry Website',
    component: null,
    nav: true,
    icon: 'atom',
    heading: null,
    id: 10,
    url: 'https://about.nike.com/pages/chemistry-homepage'
  },
  {
    path: '/help',
    title: 'Get Help',
    component: null,
    nav: true,
    icon: 'question-circle',
    heading: null,
    id: 11,
    url: 'mailto:RSLSupport@nike.com'
  },
  {
    path: '/guide',
    title: 'How To Guide',
    component: Training,
    nav: true,
    icon: 'file-pdf',
    heading: null,
    id: 19,
  },
  {
    path: '/login-assistance',
    title: 'Login Assistance Guide',
    component: LoginAssistance,
    nav: true,
    icon: 'sign-in-alt',
    heading: null,
    id: 25,
  },
  {
    path: '/user-validation',
    title: 'User Validation Form',
    component: null,
    nav: true,
    icon: 'address-card',
    heading: null,
    id: 26,
    url: '../userValidationForm/RSL_APP_ONBOARDING_VENDOR_&_END_USER_VALIDATION_TEMPLATE_Mar_27_2023.xlsx'
  },
  {
    path: '/video',
    title: 'Tutorial Videos',
    component: Videos,
    nav: true,
    icon: 'video',
    heading: null,
    id: 21,
  },
  {
    path: '/logout',
    title: 'Logout',
    component: LogoutUser,
    nav: false,
    icon: 'sign-out-alt',
    heading: '',
    id: 12
  },
  {
    path: '/test-details',
    component: TestDetails,
    nav: false,
    heading: null,
    id: 13
  },
  {
    path: '/download-trf',
    title: 'TRF Download',
    component: ReviewDownload,
    nav: false,
    heading: null,
    id: 14
  },
  {
    path: '/download-qrf',
    title: 'QRF Download',
    component: ReviewDownload,
    nav: false,
    heading: null,
    id: 15
  },
  {
    path: '/download-frf',
    title: 'FRF Download',
    component: ReviewDownload,
    nav: false,
    heading: null,
    id: 16
  },
  {
    path: '/lab-completed',
    component: TestCompleted,
    nav: false,
    heading: null,
    id: 17

  },
  // Routes starting with '/error' will not have access to Nav bar
  {
    path: '/error',
    title: 'Authentication Issues',
    component: UserNotExistsError,
    nav: false,
    heading: null,
    id: 18
  },
  {
    path: '/error-mismatch',
    title: 'Authentication Issues',
    component: UserMismatchError,
    nav: false,
    heading: null,
    id: 18
  },
  {
    path: '/archive',
    title: 'Search All',
    component: Archive,
    nav: true,
    icon: 'search',
    heading: null,
    id: 22
  },
  { component: InvalidRoute, id: 20 },

];

export const navRoutes = routes?routes.filter(r => r.nav):[];
async function checkUser() {
    const {authState, requestSetUser, logout, oktaAuth} =this.props;
    const { authCheckRequested } = this.props;
    const { userInfo } = this.state;

    if(sessionStorage.getItem("token-satus")!== "expired"){
    authCheckRequested();
  if (authState.isAuthenticated && userInfo === null ) {
    const authStates = await authState;

    let userInfo = await oktaAuth.token.getUserInfo();
    const tokens = await oktaAuth.tokenManager.getTokens();
    const getAccessToken = tokens.accessToken;
    if(getAccessToken && getAccessToken.expiresAt && !logout ){
      
    authState.expiresAt = getAccessToken.expiresAt
    this.setState({currentExpireTime:getAccessToken.expiresAt})
   
    }
    
    if(userInfo){

    userInfo.group = getUserGroup(userInfo)

    let tokenInfo = null
    if(!logout){
     tokenInfo = getFullToken(authStates,userInfo, getAccessToken)
    } else{
      tokenInfo=null
    }

    if (this._isMounted ) {
        if(authStates){
        requestSetUser(tokenInfo)
        }
        
        
      this.setState({ userInfo,authStates });
    }
  }
  
  }
}
}

class Login extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { userInfo: null,currentExpireTime:null };
    this.checkUser = checkUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async login() {
    const { oktaAuth } = this.props;
    await oktaAuth.signInWithRedirect("/login");
  }

  async logout() {
    const { oktaAuth } = this.props;
    await oktaAuth.signOut({ postLogoutRedirectUri: window.location.orign + '/login' });
  }

  async componentDidMount() {
    this._isMounted = true;
    this.checkUser();
    // if (window.performance) {
    //   if (performance.navigation.type === 1) {
    //     console.log( "accessClaims This page is reloaded" );
    //   } else {
    //     console.log ( "accessClaims This page is not reloaded");
    //   }
    // }
    
  }

  loginFlagChange = async () =>{
    const { requestLogin, oktaAuth } = this.props;
    this.setState({userInfo:null})
    await oktaAuth.signInWithRedirect('/');
    requestLogin(false)
    sessionStorage.setItem("token-satus", "login-again");
    //this._isMounted = true;
    this.checkUser();
  }

  async componentDidUpdate(prevProps) {
    this._isMounted = true;
    const { authCheckRequested ,selectUserDetails, logout, authState, requestSetUser, oktaAuth} = this.props;
    
    const { currentExpireTime } = this.state;
   
    // const newExpire= await authService.getTokenManager().get('accessToken')
    const tokens = await oktaAuth.tokenManager.getTokens();
    const accessToken = tokens.accessToken;

    if(currentExpireTime&&accessToken.expiresAt &&accessToken.expiresAt !== currentExpireTime && sessionStorage.getItem("token-satus")!== "expired"){
      let tokenInfo = null
      const authStates = await authState;
      let userInfo = await oktaAuth.token.getUserInfo();
      await authCheckRequested () 
      userInfo.group = getUserGroup(userInfo)
    
  
     tokenInfo = getFullToken(authStates)
     if(authStates){
      requestSetUser(tokenInfo)
      }
    
      this.setState({currentExpireTime:accessToken.expiresAt})
    }

    if(Object.keys(prevProps.selectUserDetails).length === 0 && prevProps.selectUserDetails !== selectUserDetails){
      
      await authCheckRequested () 
    }
   if(!logout && prevProps.logout !== logout && sessionStorage.getItem("token-satus")!== "expired" ){
    await authCheckRequested ()
     }
    
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  validateActionCenterUser(){
    const {selectUserDetails} = this.props;
    // for new user
    if((selectUserDetails.savedUserType === "Suppliers" || selectUserDetails.savedUserType === "Factories" )&& 
    selectUserDetails.userInactive === true
          && ((selectUserDetails.OSHStatus === null && (selectUserDetails.OSHId === ""
          || selectUserDetails.OSHId === null)) || (selectUserDetails.LitmosStatus === null))){
            return true
          }
          if((selectUserDetails.savedUserType === "Suppliers"|| selectUserDetails.savedUserType === "Factories") && 
          selectUserDetails.userInactive === true
          && ((selectUserDetails.OSHStatus === "pending" && selectUserDetails.OSHId !== "") && (selectUserDetails.LitmosStatus === null && selectUserDetails.litmos !== null))){
            return true
          }
    return false;
  }

  render() {
    const { loginAuthFinish, authState, userValid, groupValid, selectUserDetails } = this.props;
    const { userInfo } = this.state
    const noActionCenter =navRoutes.filter(r => r.path !== "/action-center")
    let enableActionCenter = false;
    if((selectUserDetails.savedUserType === "Suppliers" || selectUserDetails.savedUserType === "Factories")
    ){
      enableActionCenter = true;
    }
    if (!loginAuthFinish && sessionStorage.getItem("token-satus") !== "expired") {
      return <Spinner large/>;
    }else if ((userInfo && !userInfo.group)){
      return <UserNotExistsError />;
    }
     else if ( !groupValid) {
      return <UserMismatchError />; 
    } 
    else if(!userValid){
      return( <UserNotExistsError />)
    }
    
    return authState.isAuthenticated && sessionStorage.getItem("token-satus") !== "expired" ?
      <div>
        {(userInfo !== null && loginAuthFinish) ? 
        <Router>
         <App userInfo={userInfo} navRoutes={enableActionCenter ? navRoutes : noActionCenter} enableNav={this.validateActionCenterUser()}>
        <Switch>
        {routes.map(route => (
                <Route key={route.id} path={route.path} exact component={route.component} />
              ))}
        </Switch>
        </App>
        </Router> : null}
      </div>
     :
       <div> 
         {/* {this.logout()}   */}
       <div className="page-align-items">
          <Container fluid>
            <H1 className="u-sm-ib u-va-m">You must be logged in to view this page</H1>
            <Button extraClassName="m2-sm u-sm-ib u-va-m" onClick={this.loginFlagChange} >
              Login
            </Button>
          </Container>
        </div>
      </div>
  }
};
export const mapStateToProps = state => {
    return {
        selectUserDetails: selectUserDetails(state),
        loginAuthFinish: loginAuthFinish(state),
        logout:logout(state),
        userValid:validUser(state),
        groupValid:validGroup(state)
    }
  };
  
  export const mapDispatchToProps = dispatch => ({
    
    requestSetUser: (params) => {
      dispatch(setUserLogin(params))
    },
    requestLogin: (params) => {
      dispatch(setUserLogout(params))
    },
    authCheckRequested:() =>{
        dispatch(authCheckRequested())
    }
    
  });
export {Login};
export default withOktaAuth(
    connect(
        mapStateToProps,
        mapDispatchToProps
      )(Login))