import find from 'lodash/find';
import filter from 'lodash/filter';
import union from 'lodash/union';
import location from '../../constants/json/radiobuttons/location.json';
import { flattenItems, strUpperCase, trfAgeGroupName, trfSampleTypeName } from '../../util/index.js';
import productInfo from '../../constants/json/checkboxes/productInfo.json';
import materialTypes from '../../constants/json/radiobuttons/materialTypes.json';
import individualTest from '../../constants/json/checkboxes/individualTestPackages.json';
import brandInfo from '../../constants/json/checkboxes/brandInfo.json';
import { testPackageArry } from './testPackageTableLogic.js';
import Season from './../../constants/json/checkboxes/season.json';
import { convertToRSLNumber } from '../../util/index.js';

export default function trfDetailsJson(props) {
  function getLabName(lablocationId) {
    if (filter(location, { location: [{ id: lablocationId }] }).length >= 1) {
      return filter(location, { location: [{ id: lablocationId }] })[0].lab
    }

  }
  function getLabLocation(lablocationId) {
    if (lablocationId) {
      return find(flattenItems(location, 'location'), ['id', lablocationId]).area + ' - ' +
        find(flattenItems(location, 'location'), ['id', lablocationId]).country
    }

  }

  function testPackages(testPackages,customeTestpackages, testTypes){
    let packages =[];
    Object.keys(testPackages).map((key, i) => 
       packages=packages.concat(getTestPackagesWithType(testPackages[key],key.toUpperCase()))
    )
    let customePackages =getTestPackagesWithType(customeTestpackages,"CUSTOM");
    packages = packages.concat(customePackages);
    packages.sort();
    return testPackageArry(packages)
    
  }

  function getTestPackagesWithType(testPackages, testType) {
   let testPackageFilterArry = [...new Set(testPackages)];
    let testPackage = testPackageFilterArry.map(trfInfo => {

      if (trfInfo && filter(
        union(
          individualTest[0].common,
          individualTest[0].toys,
          individualTest[0].electronic,
          individualTest[0].packaging,
          individualTest[0].jewelry
        ),
        { variants: [{ id: trfInfo }] }
      ).length >= 1) {
        return filter(
          union(
            individualTest[0].common,
            individualTest[0].toys,
            individualTest[0].electronic,
            individualTest[0].packaging,
            individualTest[0].jewelry
          ),
          { variants: [{ id: trfInfo }] }
        )[0].label + ' - ' +
          find(
            flattenItems(
              union(
                individualTest[0].common,
                individualTest[0].toys,
                individualTest[0].electronic,
                individualTest[0].packaging,
                individualTest[0].jewelry
              ),
              'variants'
            ),
            ['id', trfInfo]
          ).label + ':' + testType
      }
      else {

        return find(
          union(
            individualTest[0].common,
            individualTest[0].toys,
            individualTest[0].electronic,
            individualTest[0].packaging,
            individualTest[0].jewelry
          ),
          ['id', trfInfo]
        ).label + ':' + testType
      }
    })
    return testPackage
  }

  function nonCompliances(data)
  {
    var list=data;
    if(list && list.length > 0){
      var middle = list.length/2;
      let lists = [];
      for(let i=0; i<middle; i++){
        let middleRound = Math.round(middle+i);
      let myJson = {"columnOne" : list[i],"columnTwo" : typeof(list[middleRound]) === 'undefined' ? '': list[middleRound] }
      lists.push(myJson);  
      }
      return lists
    }
  }
  function replaceExtendedCharacters(data)
    {
      var extendedArray = [['％','%'],['；',';']];
        for (var i = 0; i < extendedArray.length; i++) {
            var extChar = extendedArray[i];
            data = data.replaceAll(extChar[0], extChar[1]);                
        }
       return data;
    }

  const trfDetails = props;
  const trfDetailsData =trfDetails && trfDetails.data.trfDetails
  const filterRetestTrf = (trfDetailsData && trfDetailsData.linkage && 
    trfDetailsData.linkage.retestTRFArr !== undefined && 
    trfDetailsData.linkage.retestTRFArr !== null && 
    trfDetailsData.linkage.retestTRFArr.length > 0) ?  
    trfDetailsData.linkage.retestTRFArr.filter((data) => (data.level === 0)): "";
  const retestTrf = filterRetestTrf ? filterRetestTrf.map((retestData) => (convertToRSLNumber(retestData.id))):"";
  
  const filterOriginalTrf = (trfDetailsData && trfDetailsData.linkage && 
    trfDetailsData.linkage.originalTRFArr !== undefined && 
    trfDetailsData.linkage.originalTRFArr !== null && 
    trfDetailsData.linkage.originalTRFArr.length > 0) ?  
    trfDetailsData.linkage.originalTRFArr.filter((data) => (data.level === 0)): "";
  const originalTrf = filterOriginalTrf ? filterOriginalTrf.map((originalData) => (convertToRSLNumber(originalData.id))):"";

  let jsonData = [
    {
      "headerName": "Summary",
      "RSL Submit Number": trfDetailsData.RSLNo,
      "TRF Submit Date": trfDetailsData.createdAt,
      "Overall Test Results": trfDetailsData.overAllStatus !== "Warning" ? trfDetailsData.overAllStatus : "Warning - Approved For Production",
      "Non-Compliances": nonCompliances(trfDetails.data.reason),
      "Expiry":trfDetailsData.expiry,
    },
    {
      "headerName": "Supplier Information",
      "Supplier Name": trfDetailsData.supplierName,
      "Supplier Location": trfDetailsData.supLoc
    },
    {
      "headerName": "General Information",
      "Brand Tested For": find(brandInfo, ['id', trfDetailsData.brand]).value,
      "Product Type": find(productInfo, ['id', trfDetailsData.product]).label,
      "Licensee": trfDetailsData.licensee ? trfDetailsData.licensee : "Not Added",
      "Link TRF": originalTrf ? originalTrf.join(" , ") : "Not Added",
      "Re-Test TRF": (trfDetailsData.linkage && typeof trfDetailsData.linkage.retestTRFArr !== 'undefined' && 
      trfDetailsData.linkage.retestTRFArr !== null 
       && trfDetailsData.linkage.retestTRFArr.length > 0)
       ? retestTrf.join(" , ") : "Not Added",
      "Season": trfDetailsData.seasonId ? find(Season, ['id', trfDetailsData.seasonId]).label : "Not Added",
      "Year": trfDetailsData.year ?  trfDetailsData.year : "Not Added"
    },
    {
      "headerName": "Material Information",
      "Material Name": trfDetailsData.materialName,
      "Material Number": trfDetailsData.materialNumber,
      "PDM Number": trfDetailsData.PDMNumber ? trfDetailsData.PDMNumber : 'Not Added',
      "Material ID": trfDetailsData.refId ? trfDetailsData.refId : 'Not Added',
      "Sample Type": trfDetailsData.sampleType ? trfSampleTypeName(trfDetailsData.sampleType) : 'Not Added',
      "Color Name": trfDetailsData.colorName ? trfDetailsData.colorName : 'Not Added',
      "Color Code": trfDetailsData.colorCode ? trfDetailsData.colorCode : 'Not Added',
      "Color Way": trfDetailsData.colorWay ? trfDetailsData.colorWay : 'Not Added',
      "Age Group": trfDetailsData.ageGroup ? trfAgeGroupName(trfDetailsData.ageGroup) : 'Not Added',
      "Sample Color": trfDetailsData.sampleColor ? strUpperCase(trfDetailsData.sampleColor) : 'Not Added',
      "Sample Description": trfDetailsData.description ? replaceExtendedCharacters(trfDetailsData.description) : 'Not Added',
      "Certificate": trfDetailsData.certification,
      "Does this material have repellent finish?": trfDetailsData.repellent === false ? 'No' : 'Yes' ,
      "Is the material intended to come into contact with food or mouth?" : trfDetailsData.foodOrMouth === false ? 'No' : 'Yes',
      "Is this material intended for eye-wear frames?" :  trfDetailsData.eyeWear === false ? 'No' : 'Yes',
      "Does this material sample contain recycled content?" :  trfDetailsData.recycle === false ? 'No' : 'Yes',
      "List the samples recycled material source" : trfDetailsData.listRecycle,
      "Describe the recycled content in the material sample" :  trfDetailsData.describeRecycle,
      "Will this electonic material be embedded into a finished product (e.g. Footwear)?" : trfDetailsData.isElectEmbedded === false ? 'No' : 'Yes',
      "Is this electronic material intended to come in contact with user's skin?" : trfDetailsData.skinContact === false ? 'No' : 'Yes',
      "Material Types":
        trfDetailsData.materialType &&
        (trfDetailsData.materialType.filter(value => value !== null)).length > 0 &&
        trfDetailsData.materialType.map(trfInfo => {
          if (filter(materialTypes, { variants: [{ id: trfInfo }] }).length >= 1) {
            return filter(materialTypes, { variants: [{ id: trfInfo }] })[0].label + ' - ' +
            find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label
          }
          else if( find(flattenItems(materialTypes, 'variants'), ['id', trfInfo])) {
            return find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label
          }
          else if(trfInfo === 4050 ||trfInfo === 4051){
            if(trfInfo === 4050){
              return 'Inks and Paints - Screenprint Inks - Not White'
          } 
          else{
            return 'Inks and Paints - Screenprint Inks - White'
          }
          }else{
            return ''
          }
        }),
       "Default Test": trfDetailsData.testLogic ? trfDetailsData.testLogic : 'Not Added',
       "Testing Type": trfDetailsData.testingType ? trfDetailsData.testingType : 'Not Added',
       "Test Packages": testPackages(trfDetailsData.defaultClass ,trfDetailsData.customClass, trfDetailsData.testLogic)  
     },

    {
      "headerName": "Laboratory Information",
      "Lab": getLabName(trfDetailsData.labLocationId),
      "Lab Accept Date": trfDetailsData.labAccept !== null ? trfDetailsData.labAccept : 'Not Available',
      "Location": getLabLocation(trfDetailsData.labLocationId),
      "Test Report Date": trfDetailsData.labRecDate,
      "Lab Reference Number": trfDetailsData.labRefNum ? trfDetailsData.labRefNum : 'Not Added'
    },

    {
      "headerName": "Sample Submitter Information",
      "Name": trfDetailsData.name,
      "E-mail": trfDetailsData.userEmail,
      "":""
    },
    trfDetailsData.pColor ? {
      "headerName": "Inks",
      "Ink system name": trfDetailsData.inkSystem ? trfDetailsData.inkSystem : 'Not Added',
      "Base Name": trfDetailsData.bName ? trfDetailsData.bName : 'Not Added',
      "Pigment Color": trfDetailsData.pColor ? trfDetailsData.pColor : 'Not Added',
      "Additive 1 name": trfDetailsData.addit1 ? trfDetailsData.addit1 : 'Not Added',
      "Additive 2 name": trfDetailsData.addit2 ? trfDetailsData.addit2 : 'Not Added',
      "Additive 3 name": trfDetailsData.addit3 ? trfDetailsData.addit3 : 'Not Added'
    } : {}
  ]
  return jsonData
}