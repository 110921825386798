
export default function previewDetailsJson(props) {
  const trfDetails = props.trfDataForEdit
  const trfTestingDetails = props.testingInfoStates
  const trflabLocationStates = props.labLocationStates
  const trfInfoForEdit = props.testingInfoForEdit
  const trfBrandInfoStates = props.brandInfoStates

  const testPackages = props.testingInfoStates.testPackages
  const testPackagrEle = props.testingInfoStates.testPackagesElectronic
  const testPackageJewellery = props.testingInfoStates.testPackagesJewelry
  const testPackagePacking = props.testingInfoStates.testPackagesPackaging

  const concateArry = testPackages.concat(testPackagrEle,testPackageJewellery,testPackagePacking);
  const testPackagesArry= concateArry.filter(function(val) {
    return val != null;
  })

  let jsonData = 
    {
        "trfId": trfDetails.trfId,
        "RSLNo": trfDetails.RSLNo,
        "testingType": props.testingInfo.testingType,
        "labLocationId": trflabLocationStates.labLocation,
        "year": (trfTestingDetails.Year).year(),
        "status": "InProgress",
        "overAllStatus": "In Progress",
        "statusValue": "Review",
        "formStatus": "TRF",
        "service": trflabLocationStates.serviceRequested,
        "materialType": trfInfoForEdit.materialType,
        "updatedAt": trfDetails.updatedAt,
        "userId": trfDetails.userId,
        "brand": trfBrandInfoStates.brandId,
        "product": trfBrandInfoStates.productId,
        "seasonId": trfInfoForEdit.season,
        "locationId": trfDetails.locationId,
        "supplierId": trfDetails.supplierId,
        "manualTest": trfDetails.manualTest,
        "testPackages": testPackagesArry,
        "materialNumber": trfBrandInfoStates.materialNumber !== '' ? trfBrandInfoStates.materialNumber : 'Not Added',
        "refId": trfBrandInfoStates.refId !== '' ? trfBrandInfoStates.refId : 'Not Added',
        "PDMNumber": trfDetails.PDMNumber,
        "materialName": trfBrandInfoStates.materialName !=='' ? trfBrandInfoStates.materialName : 'Not Added',
        "name": trfDetails.name,
        "userEmail": trfDetails.userEmail,
        "userContactNumber": trfDetails.userContactNumber,
        "description": trfTestingDetails['sampleDescription'],
        "colorCode": trfTestingDetails.colorCode,
        "colorName": trfTestingDetails.colorName,
        "colorWay": trfTestingDetails.colorway,
        "inkSystem": trfTestingDetails.inkSystemName,
        "bName": trfTestingDetails.baseName,
        "pColor": trfTestingDetails.pigmentColor,
        "addit1": trfTestingDetails.additive1Name,
        "addit2": trfTestingDetails.additive2Name,
        "addit3": trfTestingDetails.additive3Name,
        "repellent": trfTestingDetails.repellent,
        "enableTP2": trfTestingDetails.enableTP2,
        //"subRecycledContent": trfTestingDetails.subRecycledContent !== null ? trfTestingDetails.subRecycledContent  : 'No',
        "supplierName": props.testingInfo.supplierName !== ''? props.testingInfo.supplierName : 'Not Added',
        "labRefNum": null,
        "labRecDate": null,
        "labComment": null,
        "supLoc": props.testingInfo.supplierName !== '' && props.brandInfoForEdit.supLocName !=='' ? props.brandInfoForEdit.supLocName : 'Not Added', 
        "licensee": trfBrandInfoStates.licensee,        
        "isRetest": trfBrandInfoStates.isRetest,
        "originalTRF": trfBrandInfoStates.originalTRF,
        "foodOrMouth": trfTestingDetails.foodOrMouth,
        "createdAt": trfDetails.createdAt
    }
  return jsonData
}



