/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TRF from './Trf/Trf';
import QRF from './Qrf/Qrf';
import FRF from './Frf/Frf';

class TrfReview extends Component {

  noDataAvailable = () => (
    <div className="ncss-row">
      <div className="ncss-col-sm-12 u-bold">No data to show</div>
    </div>
  );

  render() {
    const {
      trfData: { trfDetails, qrfDetails, frfDetails, frfAttachmentDetails,qrfAttachmentDetails },
      match: { path },
      isDownload,
      showTitle
    } = this.props;
    
    const trf = trfDetails ? <TRF isDownload={isDownload} trfDetails={trfDetails} showTitle={showTitle}/> : this.noDataAvailable;
    const qrf =
    qrfDetails && qrfDetails.qrfId && path !== '/qrf' ? <QRF isDownload={isDownload} attachment={qrfAttachmentDetails} qrfDetails={qrfDetails} showTitle={showTitle}/> : this.noDataAvailable;
    const frf =
    frfDetails && frfDetails.frfId && path !== '/frf' ? <FRF isDownload={isDownload} attachment={frfAttachmentDetails} frfDetails={frfDetails} showTitle={showTitle}/> : this.noDataAvailable;

    return (
      <div>
        {trf}
        {qrf}
        {frf}
      </div>
    );
  }
}
TrfReview.defaultProps = {
  showTitle: true
};

TrfReview.propTypes = {
  trfData: PropTypes.object,
  match: PropTypes.object,
  isDownload: PropTypes.bool,
  showTitle: PropTypes.bool
};

export default withRouter(
  connect(
    null,
    null
  )(TrfReview)
);
