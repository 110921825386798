import { fromJS } from 'immutable';
import get from 'lodash/get';

import { createServiceAction } from '../util/actionHelper.js';


const defaultState = fromJS({
    getMaterialNextPage: 1,
    materialLoading: false,
});

export default function materialData(state = defaultState, action) {
    state = fromJS(state);
    const pageCount = 500;
    switch (action.type) {
        case createServiceAction('materialDetails', 'loading').type:
            return state.set('getmaterialprogress', 0);

        
        case createServiceAction('materialDetails', 'success').type:
            let nextPage = 1
            let totalPage_success = 0
            if (get(action.data, 'data.count') > pageCount) {
                nextPage = state.get('getMaterialNextPage') + 1
                // totalPage_success = Math.ceil(parseInt(get(action.data, 'data.count'), 10) / pageCount)
                totalPage_success = get(action.data, 'data.totalPages');
            }
            let tempForAll = action.data.data
            let updateTempForAll = fromJS(tempForAll)

            if (totalPage_success > 1 && nextPage === 2) {
                state.getmaterialprogress = (nextPage / totalPage_success) * 100
            }

            return state.set('materialData', updateTempForAll.toJS())
                .set('materialTotalCount', get(action.data, 'data.count'))
                .set('getMaterialTotalPage', totalPage_success)
                .set('getMaterialNextPage', nextPage)
                .set('getMaterialRemainingPages', totalPage_success)
                .set('getmaterialprogress', state.getmaterialprogress)


        case createServiceAction('materialDetailsLazyLoad', 'success').type:
            let newData = action.data.data.data
            let temp = state.get('materialData')
            let updateTemp = fromJS(temp)

            let InitialData = temp.data;

            InitialData = [...InitialData, ...newData];

            updateTemp = updateTemp.updateIn(['data'], index => InitialData)
            let nextSummaryPage = state.get('getMaterialNextPage') + 1;
            let totalPage = Math.ceil(parseInt(get(action.data, 'data.count'), 10) / pageCount)
            if (nextSummaryPage > state.get('getMaterialTotalPage')) {
                nextSummaryPage = 1
                totalPage = 0
            }
            if (nextSummaryPage <= totalPage && totalPage !== 0)
                state.getmaterialprogress = (nextSummaryPage / totalPage) * 100;
            else
                state.getmaterialprogress = 0;

            return state.setIn(['materialData'], updateTemp.toJS())
                .set('getMaterialTotalPage', totalPage)
                .set('getMaterialRemainingPages', Math.ceil(parseInt(get(action.data, 'data.count'), 10) / pageCount) - (state.get('getMaterialNextPage')), 10)
                .set('getMaterialNextPage', nextSummaryPage)
                .set('getmaterialprogress', state.getmaterialprogress)


        default:
            return state;
    }
}



export const getMaterialData = state => state.materialList.get('materialData');
export const getMaterialNextPage = state => state.materialList.get('getMaterialNextPage');
export const getMaterialRemainingPages = state => state.materialList.get('getMaterialRemainingPages');
export const isMaterialLoading = state => state.materialList.get('materialLoading');
export const getMaterialLoadStatus = state => state.materialList.get('getmaterialprogress');


