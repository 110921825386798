import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {  Button, Container, Spinner } from '../../components/index.js';
import UserInfoGrid from '../userInfoGrid/userInfoGrid.js';
import { history } from '../../util/index.js';
import EditUserProfile from '../userInfoGrid/editUserProfile/EditUserProfile.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { hideProgress } from '../../actions/adminUserActions.js';
import { isRslUser } from '../../reducers/authReducer.js';
export class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      isOpenSupplierList: false,
      status: null,
    };
    this.cancelAddSupplier = this.cancelAddSupplier.bind(this);
    this.userInfoFetch = this.userInfoFetch.bind(this);
  }
  componentDidMount() {
    const { fetchUsersData } = this.props;
    fetchUsersData()
  }

  componentDidUpdate(prevProps, prevState){
    const { hideProgress, editPercentage } = this.props;
    if(editPercentage === 100){
      setTimeout(() => {
        hideProgress();
      }, 500);
    }
  }

  userInfoFetch = (userId) =>{
    const { userInfoById } = this.props;
    userInfoById(userId)
  }

  closeSupplierListModal = () => {
    this.setState({ isOpenSupplierList: false });
  };
  
  cancelAddSupplier = () => { 
    history.push('/');
  };

  createNewUser = () => {
    this.setState({showAddUserModal: true});
  }

  toggleCreateUserModal= () =>{
    this.setState(prevState => ({ showAddUserModal: !prevState.showAddUserModal }))
  } 
  
  userStatusChange = (userId,status) => {
    const { setUserStatus } =  this.props;
    setUserStatus({userId:userId,status:!(status)})
  }
  
  render() {
    const {  userDetails, userDetailsById, userInfo, isRslUser} = this.props;
    const {  showAddUserModal, userType  } = this.state;
    return (
      <div>
        {userDetails && userDetails.userList  ? '' : (<Spinner large />)}
        <Container section className="ml1-sm mr1-sm mt2-sm tab-class-container tab-class padding-0">
          <div className="ncss-col-sm-12 ncss-input-container">
            <div className="ncss-col-sm-12 align-left">
              <div className="pt6-sm pb3-sm" >
                <UserInfoGrid 
                  gridData={userDetails && userDetails.userList ?userDetails.userList : []} 
                  useFor='EditUser' 
                  isRslUser={isRslUser}
                  userInfoById = {userDetailsById}
                  userInfoForAdd={userInfo} 
                  userStatusChange = {this.userStatusChange}
                  userDataFetch={this.userInfoFetch}
                   customNoRowTemplete={
                    userDetails === undefined && "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                  } 
                   />
              </div>
            </div>
          </div>
        </Container>
        <div className="ncss-col-sm-12 align-center">
          <Button className="ncss-btn-secondary-dark u-bold secondary-button add-supplier-location-buttton"
                    onClick={this.cancelAddSupplier}>
              CANCEL
          </Button> 
          <span>
            <Button className="ncss-btn-grey u-bold new-material add-supplier-location-buttton btn btn-default dropdown-toggle"
                onClick={this.createNewUser}>
                ADD USER
            </Button>
          </span>
        </div> 
        <EditUserProfile 
          isModalOpen={showAddUserModal}
          isAdd={true}
          toggleModal={this.toggleCreateUserModal} 
          userType={userType}
        />
      </div>
    );
  }
}

EditUser.propTypes = {
  toggleAddMaterailModal: PropTypes.func,
  isAddMaterialModalOpen: PropTypes.bool,
  requestSupplierList: PropTypes.func,
  history: PropTypes.object,
  fetchUsersData: PropTypes.func,
  hideProgress: PropTypes.func
};
const mapStateToProps = state =>(
   {
  userDetails: state.adminUser.userDetails,
  userDetailsById: state.adminUser.userDataById,
  addSupplierForm: getFormValues('addSupplier')(state),
  isRslUser: isRslUser(state),
  editPercentage : state.adminUser.getprogress,
  userInfo: {
    userId: '',
    userName:'',
    email: '',
    supplierName: '',
    supplierId : '',
    labId: '',
    labLocationId:'',
    contact:'',
    userType: '',
    osh:false,
    facilityName:'',
    parentName:'',
    OSHId:'',
    OSIds:[""]
  }
}
);

export const mapDispatchToProps = dispatch => ({
    fetchUsersData: (data, history) => {
      dispatch(createServiceAction('userDetails', 'requested')({...data, history }));
    },

    userInfoById:(userId) => {
      dispatch(createServiceAction('userDataById', 'requested')(userId));
    },

    setUserStatus:(data) => {
      dispatch(createServiceAction('setUserStatus', 'submitted')(data));
    },

    hideProgress:() =>{
      dispatch(hideProgress());
    }
  
});

export default  compose( reduxForm({ form: 'editUser' }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ))(EditUser);
