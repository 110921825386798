import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const model = {
      trfId: null,
      qrfId: null,
      location: null,
      quantity: null,
      leftFacility: null,
      factories: null,
      isAffectedKidProduct: null,
      nextStep: null,
      attachment:null,
      materialWorth:null
    };
    const path = {
      history: null
    };
    const result = pick(action.data, keys(model));
    const routes = pick(action.data, keys(path));
    const data = { ...result };
    const infoPopDuration = 1000;
  
    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, apiConfig.qrfUpdate.endPointUrl, data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if(!statusErrorMessage){
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        routes.history.push(`/download-qrf/`, { trfId: response.data.trfId }); 
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* qrfUpdateSagas() {
 
  const serviceType = 'qrfUpdate';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default qrfUpdateSagas;
