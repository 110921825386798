import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { H4, TextInput, DatePicker } from '../../../components/index.js';
import UploadAttachment from '../../../components/uploadAttachment/UploadAttachment.js';
import { toggleAddMaterailModal } from '../../../actions/trfActions.js';

class Frfinfo extends Component {
  
  componentDidMount() {
    const { displaySubmitButtonFrf,displayClearButtonFrf } = this.props;
    displaySubmitButtonFrf(false);
    displayClearButtonFrf(true);
  }
 
  render() {
    const { successFill, attachment, attachmentFrf, initialValues, FrfInfoStates } = this.props;
    return (
      <form className="form">
      <div>
        <H4 className={successFill ? 'error-text' : 'error-hide'}>
          All Fields are Mandatory <span className="astericks ml-neg3">&#42;</span>
        </H4>
        <div className="ncss-row">
          <div className=" ncss-col-sm-12 ncss-form-group p2-bottom-sm p2-top-sm u-bold">
          Why is this chemical used in the manufacturing process? Conduct PDCA to identify root cause <span className='astericks ml-neg3'>&#42;</span>
            <Field
              component={TextInput}
              type="textarea"
              name="PDCA"
              className="ncss-col-sm-12 u-bold description p2-top-sm"
            />
          </div>
        </div>
        <div className="ncss-row">
          <div className=" ncss-col-sm-12 ncss-form-group p2-bottom-sm p2-top-sm u-bold">
          Failure Description <span className='astericks ml-neg3'>&#42;</span>
            <Field
              component={TextInput}
              type="textarea"
              name="failureDesc"
              className="ncss-col-sm-12 u-bold description p2-top-sm"
            />
          </div>
        </div>
        <div className="ncss-row">
          <div className=" ncss-col-sm-12 ncss-form-group p2-bottom-sm p2-top-sm u-bold">
          What is the root cause of this failure? <span className='astericks ml-neg3'>&#42;</span>
            <Field
              component={TextInput}
              type="textarea"
              name="rootCause"
              className="ncss-col-sm-12 u-bold description p2-top-sm"
            />
          </div>
        </div>
        <div className="ncss-row">
          <div className=" ncss-col-sm-12 ncss-form-group p2-bottom-sm p2-top-sm u-bold">
          What are your proposed corrective actions? <span className='astericks ml-neg3'>&#42;</span>
            <Field
              component={TextInput}
              type="textarea"
              name="correctiveAction"
              className="ncss-col-sm-12 u-bold description p2-top-sm"
            />
          </div>
        </div>
        <div className="ncss-row">
          <div className="ncss-col-sm-4 ncss-form-group p2-bottom-sm p2-top-sm u-bold">
              What date will the corrective action be completed?&nbsp;<span className='astericks ml-neg3'>&#42;</span>
            </div>
            <div className="ncss-col-sm-3 grid-align-fix">
                <Field 
                  component={DatePicker}
                  defaultValue={initialValues ? initialValues.dateOfCompletion : FrfInfoStates ? FrfInfoStates.dateOfCompletion : null }
                  showTime={false}
                  name="dateOfCompletion"
                  minDate={new Date()}
                />
            </div>
        </div>
        <UploadAttachment uploadAction={attachmentFrf} attachment={attachment}/>
      </div>
   </form>
    );
  }
}

Frfinfo.propTypes = {
  successFill: PropTypes.bool,
  displaySubmitButtonFrf: PropTypes.func,
  displayClearButtonFrf: PropTypes.func,
  attachmentFrf: PropTypes.func,
  FrfInfoStates: PropTypes.object,
  data: PropTypes.array,
  isAddMaterialModalOpen: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    FrfInfoStates: getFormValues('Frfinfo')(state),
    isAddMaterialModalOpen: state.trf.isAddMaterialModalOpen
  } 
} 
const  mapDispatchToProps = dispatch => ({
  toggleAddMaterailModal: isOpen => {
    dispatch(toggleAddMaterailModal(isOpen));
  }
})
export default reduxForm({
  form: 'Frfinfo',
  destroyOnUnmount: false,
  enableReinitialize: true
})(connect(mapStateToProps,mapDispatchToProps)(Frfinfo));
