import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { Field, reduxForm, getFormValues } from 'redux-form';

import labLocations from '../../constants/json/radiobuttons/location.json';
import requireLogin from '../auth/requireLogin.js';
import {
  selectUsername,
  selectEmail,
  isSupplierUser,
  isRslUser,
  isNloUser,
  isFactoryUser,
  isLabUser,
  getSupplierList,
  selectUserDetails
} from '../../reducers/authReducer.js';
import {
  Button,
  Container,
  PageTitle,
  PageHeader,
  TextInput,
  InputContainer,
  DropDown,
  Input,
  ConfirmationBox,
  ContentPopup
} from '../../components/index.js';
import Label from '../../components/ncss/Label';
import Grid from '../../components/grid/Grid.js';
import supplierGridConfig from '../../configs/gridConfigs/userPage/supplierGridConfig.js';
import { createServiceAction } from '../../util/actionHelper.js';

function getLabNames() {
  return labLocations.map(lab => ({
    id: lab.id,
    value: lab.id,
    label: lab.lab
  }));
}

function getLabLocations(labLocationNameIdentifier) {
  if (labLocationNameIdentifier) {
    const selectedLocation = labLocations.find(
      labLocation => labLocation.id === parseInt(labLocationNameIdentifier, 10)
    );
    return (
      selectedLocation &&
      selectedLocation.location.map(labLocation => ({
        id: labLocation.id,
        value: labLocation.id,
        label: `${labLocation.area},${labLocation.country}`
      }))
    );
  }
  return [];
}

const flattenItems = (items, key) =>
  items.reduce((flattenedItems, item) => {
    flattenedItems.push(item);
    if (Array.isArray(item[key])) {
      flattenedItems = flattenedItems.concat(flattenItems(item[key], key));
    }
    return flattenedItems;
  }, []);

export class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      isOpenSupplierList: false
    };
    this.hidden = {
      display: 'none'
    };
    this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
    this.closeSubmitModal = this.closeSubmitModal.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.closeSupplierListModal = this.closeSupplierListModal.bind(this);
    this.openSupplierListModal = this.openSupplierListModal.bind(this);
    this.submitUserProfile = this.submitUserProfile.bind(this);
    this.shouldEnableSubmit = this.shouldEnableSubmit.bind(this);
  }

  onRowClick = params => {
    const { change } = this.props;
    this.closeSupplierListModal();
    change('supplierName', params.data.supplierName ? params.data.supplierName : '');
    change('supplierOrLocationId', params.data.supplierID ? params.data.supplierID : '');
  };

  confirmationBoxOpen = (modalopen) => {
    this.setState({ submitModal: true });
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  closeSupplierListModal = () => {
    this.setState({ isOpenSupplierList: false });
  };

  openSupplierListModal = () => {
    this.setState({ isOpenSupplierList: true });
  };

  submitUserProfile = () => {
    const { userPageForm, submitUserProfile } = this.props;
    submitUserProfile(userPageForm);
  };

  shouldEnableSubmit = () => {
    const { userPageForm, isSupplierUser, isFactoryUser, isLabUser } = this.props;
    if (userPageForm) {
      if (isSupplierUser || isFactoryUser) {
        return !!(userPageForm.supplierName && userPageForm.supplierOrLocationId && userPageForm.contact);
      }
      if (isLabUser) {
        return !!(userPageForm.labId && userPageForm.supplierOrLocationId && userPageForm.contact);
      }
    }
    return false;
  };

  render() {
    const {
      username,
      email,
      isSupplierUser,
      isRslUser,
      isNloUser,
      isFactoryUser,
      userPageForm,
      requestSupplierList,
      supplierList,
      userDetails,
      userExist,
      labLocationId
    } = this.props;
    const { submitModal, isOpenSupplierList } = this.state;
    return (
      <div className="userpage">
        <div className="page-align-items">
          <PageTitle title="User" />
          <PageHeader title="User">
            <div className="user-page ie-icon-align">
              <i className="fas fa-user-circle" />
            </div>
          </PageHeader>
          <Container section>
            <InputContainer
              className="u-bold ncss-col-sm-12 user-padding-empty"
              childClassName="text-size ncss-col-sm-2 user-padding-empty"
              label="Username"
            >
              <Input className="ncss-col-sm-10 no-color" value={username || ''} disabled />
            </InputContainer>
            <InputContainer
              className="u-bold ncss-col-sm-12 p-top-user user-padding-empty"
              childClassName="text-size ncss-col-sm-2 user-padding-empty"
              label="Email"
            >
              <Input className="ncss-col-sm-10 no-color" value={email || ''} disabled />
            </InputContainer>
            {!isRslUser && !isNloUser ? (
              <div className="padding-bottom">
                {!(isSupplierUser || isFactoryUser) ? (
                  <div>
                    <div className="ncss-row p2-bottom-sm">
                      <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size">Lab Name:</Label>
                      <div className="ncss-col-sm-4 ncss-input-container inline-flex-display u-bold">
                        {userExist ? (
                          <Input
                            className="no-color input-width"
                            value={
                              filter(labLocations, { location: [{ id: labLocationId }] }).length >= 1
                                ? filter(labLocations, { location: [{ id: labLocationId }] })[0].lab
                                : 'No Lab found'
                            }
                            disabled
                          />
                        ) : (
                            <Field
                              component={DropDown}
                              name="labId"
                              placeholder="Select a Lab Name"
                              options={getLabNames()}
                              className="dropdown-border"
                            />
                          )}
                      </div>
                    </div>
                    <div className="ncss-row p2-bottom-sm">
                      <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size">Lab Location:</Label>
                      <div className=" ncss-col-sm-10 ncss-input-container inline-flex-display u-bold">
                        {userExist ? (
                          <Input
                            className="no-color input-width"
                            value={
                              find(flattenItems(labLocations, 'location'), ['id', labLocationId])
                                ? `${
                                find(flattenItems(labLocations, 'location'), ['id', labLocationId]).area
                                }, ${
                                find(flattenItems(labLocations, 'location'), ['id', labLocationId])
                                  .country
                                }`
                                : 'No Lab Location found'
                            }
                            disabled
                          />
                        ) : (
                            <Field
                              component={DropDown}
                              name="supplierOrLocationId"
                              placeholder="Select a Lab Location"
                              options={getLabLocations(userPageForm && userPageForm.labId)}
                              className="dropdown-border"
                            />
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                    <div>
                      <div className="ncss-row p2-bottom-sm">
                        <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size">Facility Name:</Label>
                        {userExist ? (
                          <div className="ncss-col-sm-10 ncss-input-container inline-flex-display u-bold">
                            <Input
                              className="u-full-width no-color"
                              value={userDetails.facilityName !== '' ?userDetails.facilityName :`Not Available`}
                              disabled
                            />
                          </div>
                        ) : (
                            <div className=" ncss-col-sm-8 ncss-input-container inline-flex-display u-bold">
                              <Field
                                component={TextInput}
                                name="supplierName"
                                type="text"
                                containerClass="u-full-width"
                              />
                              {userPageForm && userPageForm.supplierName.length >= 3 ?
                                <Button
                                  className="ncss-btn-grey u-bold new-material search-button"
                                  onClick={() => {
                                    this.openSupplierListModal();
                                    requestSupplierList(userPageForm && userPageForm.supplierName);
                                  }}
                                >
                                  <i className="fas fa-search" />
                                  Search
                          </Button>
                                :
                                <Button className="ncss-btn-grey u-bold new-material disabled " disabled>
                                  <i className="fas fa-search" />
                                  Search
                          </Button>}


                              <Field component={TextInput} name="supplierOrLocationId" type="hidden" />
                            </div>
                          )}
                      </div>
                      <ContentPopup
                        title="Select Supplier:"
                        isOpen={isOpenSupplierList}
                        closeModal={this.closeSupplierListModal}
                        content={
                          <Grid rowData={supplierList} columnDefs={supplierGridConfig} onRowClick={this.onRowClick}
                            customNoRowTemplete={
                              supplierList === undefined
                                ? "<span class='ag-overlay-loading-center'>No results found</span>"
                                : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                            }
                          />
                        }
                      />
                    </div>
                  )}
                <div className="ncss-row p2-bottom-sm">
                  <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size">Phone:</Label>
                  <div className=" ncss-col-sm-10 ncss-input-container inline-flex-display u-bold">
                    {userExist ? (
                      <Input className="no-color" value={userDetails.contact || ''} disabled />
                    ) : (
                        <Field component={TextInput} name="contact" type="text" />
                      )}
                  </div>
                </div>
              </div>
            ) : null}
          </Container>
        </div>
        {!userExist && (
          <div>
            <Container
              section
              className="prevent-scroll bottom-scroll-fix bottom-section remove-margin sumbmit-area"
            >
              <Button
                className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected ${
                  !this.shouldEnableSubmit() ? 'disabled' : ''
                  }`}
                {...this.shouldEnableSubmit() && { onClick: () => this.confirmationBoxOpen(true, 'submit') }}
              >
                SUBMIT
              </Button>
            </Container>
            <ConfirmationBox
              message="Are you sure you want to submit User Profile details?"
              submitModal={submitModal}
              submitValue={this.submitUserProfile}
              closeModal={this.closeSubmitModal}
            />
          </div>
        )}
      </div>
    );
  }
}

UserPage.propTypes = {
  username: PropTypes.string,
  email: PropTypes.string,
  isSupplierUser: PropTypes.bool,
  isFactoryUser: PropTypes.bool,
  isRslUser: PropTypes.bool,
  isLabUser: PropTypes.bool,
  isNloUser: PropTypes.bool,
  userPageForm: PropTypes.object,
  requestSupplierList: PropTypes.func,
  supplierList: PropTypes.array,
  change: PropTypes.func,
  submitUserProfile: PropTypes.func,
  history: PropTypes.object,
  userDetails: PropTypes.object,
  userExist: PropTypes.bool,
  labLocationId: PropTypes.number
};

const mapStateToProps = state => ({
  username: selectUsername(state),
  email: selectEmail(state),
  isSupplierUser: isSupplierUser(state),
  isFactoryUser: isFactoryUser(state),
  isRslUser: isRslUser(state),
  isLabUser: isLabUser(state),
  isNloUser: isNloUser(state),
  supplierList: getSupplierList(state),
  userPageForm: getFormValues('userPage')(state),
  userDetails: selectUserDetails(state),
  labLocationId:
    selectUserDetails(state).labLocationId && parseInt(selectUserDetails(state).labLocationId, 10),
  userExist: !!selectUserDetails(state).userId
});

const mapDispatchToProps = dispatch => ({
  requestSupplierList: searchTerm => {
    dispatch(createServiceAction('supplierSearch', 'requested')(searchTerm));
  },
  submitUserProfile: data => {
    dispatch(createServiceAction('createUserProfile', 'requested')({ formValues: data }));
  }
});

export default compose(
  requireLogin('User'),
  reduxForm({
    form: 'userPage',
    destroyOnUnmount: false
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserPage);
