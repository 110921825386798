import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    const path = {
      history: null
    };
    const routes = pick(action.data, keys(path));
    const infoPopDuration = 1000;
  
    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, apiConfig.labResultsSubmit.endPointUrl, action.data.labSubmitData);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if(!statusErrorMessage){
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
        // Display success message
        yield call(displayMessage, 'success', 'Test values submitted successfully', 5000);
        routes.history.push('/lab-completed', { trfId: action.data.labSubmitData.trfId });
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* labSubmitSagas() {
  const serviceType = 'labSubmit';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default labSubmitSagas;
