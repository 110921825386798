import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {

  try {
    const model = {
      userId: null,
      brandId: null,
      productId: null,
      licensee: null,
      seasonId: null,
      year: null,
      testingType: null,
      labLocationId: null,
      supplierName: null,
      service: null,
      materialName: null,
      materialNumber: null,
      refId: null,
      colorCode: null,
      colorName: null,
      colorWay: null,
      description: null,
      materialType: null,
      testPackages: null,
      inkSystem: null,
      bName: null,
      pColor: null,
      addit1: null,
      addit2: null,
      addit3: null,
      repellent: null,
      //recycle:null,
      foodOrMouth: null,
      supplierId: null,
      labId: null,
      locationId: null,
      supLocName: null,
      PDMNumber: null,
      enableTP2:null,
      eyeWear:null,
      isElectEmbedded:null,
      electCategory:null,
      sampleType:null,
      ageGroup:null,
      sampleColor:null,
      certification:null,
      skinContact:null,
      recycle:null,
      listRecycle:null,
      describeRecycle:null,
      isRetest: null, 
      originalTRF: null
    };
    const path = {
      history: null
    };
    const body = pick(action.data, keys(model));
    const routes = pick(action.data, keys(path));
    const data = { ...body };
    const infoPopDuration = 1000;

    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(postRequest, apiConfig.trfData.endPointUrl, data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message} with Trf ID: - ${response.data.rsl}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        routes.history.push(`/download-trf/`, { trfId: response.data.trfId });

      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* trfSubmitSagas() {
  const serviceType = 'trfData';
  yield takeEvery(createServiceAction(serviceType, 'submited').type, listener, serviceType);
}

export default trfSubmitSagas;
