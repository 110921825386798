import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Field, reduxForm, getFormValues } from 'redux-form';
import {
    Button,
    Container,
    H3,
    H5,
    TextInput,
    MultiSelect,
    DatePicker,
    IconButton
} from '../../../components/index.js';
import Label from '../../../components/ncss/Label';
import usertypes from '../../../constants/json/usertypes/usertypes.json';
import { createServiceAction } from '../../../util/actionHelper.js';
import { convertDateToDBFormat } from '../../../util/index.js'
import moment from 'moment';

class EditNotification extends Component {
    updateNotificationData = () => {
        const { editedNotification, updateNotification, closeModal } = this.props;
        const reqObj = {};
        reqObj.notificationId = editedNotification.notificationId
        reqObj.startDate = convertDateToDBFormat(editedNotification.startDate) || '';
        reqObj.endDate = convertDateToDBFormat(editedNotification.endDate) || '';
        reqObj.message = editedNotification.message || '';

        if (editedNotification.userTypes) {
            editedNotification.userTypes = editedNotification.userTypes
                ? editedNotification.userTypes.map(item => {
                    return item.value;
                })
                : [];
        }
        reqObj.userGroups = editedNotification.userTypes;
        updateNotification(reqObj);
        closeModal()
    }

    shouldEnableSubmit = () => {
        const { editedNotification } = this.props;

        if (editedNotification) {
            if (editedNotification.startDate &&
                editedNotification.endDate &&
                editedNotification.message &&
                editedNotification.userTypes &&
                editedNotification.userTypes.length > 0 &&
                (new Date(moment(editedNotification.startDate).utc().format('DD-MMM-YYYY'))) <= (new Date(moment(editedNotification.endDate).utc().format('DD-MMM-YYYY')))) {
                return false;
            }
        }
        return true;
    }

    render() {
        const { editModal, closeModal, initialValues } = this.props;

        return (
            <Modal
                isOpen={editModal}
                onRequestClose={() => { closeModal() }}
                className="modal-container notify-modal"
                overlayClassName="modal-overlay">
                <IconButton icon="times" className="modal-close-btn" onClick={() => closeModal()} />
                <div className="ncss-col-sm-12 ">
                    <div className="notification-page pb2-sm">

                        <H3 className="u-bold">Edit Notification</H3>
                        <hr />
                        <Container section>
                            <div className="ncss-row p2-bottom-sm">
                                <Field component={TextInput} name="notificationId" type="hidden" />
                                <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size-notification align-center">Start Date: <span className="astericks">&#42;</span></Label>
                                <div className='ncss-col-sm-3'>
                                    <Field
                                        component={DatePicker}
                                        showTime={false}
                                        name="startDate"
                                        minDate={new Date()}
                                        defaultValue={new Date(moment(initialValues.startDate).utc().format('DD-MMM-YYYY'))}
                                    />
                                </div>
                                <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size-notification text-size-notification-date align-center">End Date: <span className="astericks">&#42;</span></Label>
                                <div className='ncss-col-sm-3'>
                                    <Field
                                        component={DatePicker}
                                        showTime={false}
                                        name="endDate"
                                        minDate={new Date()}
                                        defaultValue={new Date(moment(initialValues.endDate).utc().format('DD-MMM-YYYY'))}
                                    />
                                </div>
                            </div>
                            <div className="ncss-row p2-bottom-sm">
                                <Label className="ncss-col-sm-2 p2-top-sm u-bold text-size-notification align-center">User Types: <span className="astericks">&#42;</span></Label>
                                <div className="ncss-col-sm-4">
                                    <Field
                                        component={MultiSelect}
                                        name="userTypes"
                                        options={usertypes}
                                        placeholder="Select user types"
                                        containerClass="u-full-width"
                                    />
                                </div>
                                <div className="ncss-col-sm-12 ncss-form-group p2-bottom-sm p2-top-sm text-area-container-notification pl6-sm text-size-notification">
                                <H5 className="u-bold description add-supplier-text">Notification Text: <span className="astericks">*</span></H5>
                                    <Field
                                        component={TextInput}
                                        //label="Notification Text :"
                                        type="textarea"
                                        name="message"
                                        className="ncss-col-sm-12 u-bold description p2-top-sm"
                                    />
                                </div>

                            </div>

                        </Container>
                        <div className="ncss-col-sm-12 align-center">
                            <Button
                                className="ncss-btn-secondary-dark u-bold secondary-button update-exception-buttton"
                                onClick={() => { closeModal() }}
                            >
                                CANCEL
                        </Button>
                            <Button
                                className={`ncss-btn-grey u-bold new-material update-exception-buttton ${
                                    this.shouldEnableSubmit() ? 'disabled' : ''
                                    }`}
                                onClick={() => { this.updateNotificationData(); }}
                            >
                                UPDATE
                        </Button>
                        </div>
                    </div>


                </div>

            </Modal>
        );
    }
}
EditNotification.propTypes = {
    editModal: PropTypes.bool,
    closeModal: PropTypes.func,
    message: PropTypes.string,

};

const mapStateToProps = state => ({
    editedNotification: getFormValues('editNotification')(state),

});

const mapDispatchToProps = dispatch => ({
    updateNotification: data => {
        dispatch(createServiceAction('updateNotification', 'requested')(data));
    },

});

export default compose(

    reduxForm({
        form: 'editNotification',
        enableReinitialize: true
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EditNotification);