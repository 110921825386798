import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';

import '../stylus/app.styl';

import config from '../config.js';
import Header from '../views/header/header.js';
import SlideMenuContainer from '../views/navigation/SlideMenuContainer.js';
import { navMenuclose } from '../actions/navigationActions.js';
import OfflineBanner from '../views/offline/offline.js';
import { LoginRequired } from '../views/auth/requireLogin.js';
import NotificationsMessage from '../components/notifications/NotificationsMessage';
import Footer from '../views/footer/footer';


class App extends Component {
  // componentDidMount() {
  //   const { authCheckRequested } = this.props;
  //   authCheckRequested();
  // }

  render() {
    const { navMenuclose, children, location, navRoutes, enableNav } = this.props;
    //if (loginError) return <GenericError error={loginError} />;
    //if (!loginFinished) return <Spinner large />;

    const navLinks = navRoutes.map(r => ({
      to: r.path,
      label: r.title,
      icon: r.icon,
      heading: r.heading,
      id: r.id,
      url: r.url || ''
    }));

    return (
      <DocumentTitle title={config.appName}>
        <div className="u-full-height">
          <OfflineBanner />
          <Header 
            isErrorRoute={(location.pathname && location.pathname.indexOf('/error') === -1) ? false : true} 
            enableNav ={enableNav}
          />
          <NotificationsMessage />
          <SlideMenuContainer
            title={config.appName}
            description={config.appFullName}
            navLinks={navLinks}
            onClose={navMenuclose}
          >
            <div className="app-container">
              <LoginRequired>{children}</LoginRequired>
            </div>
          </SlideMenuContainer>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

App.propTypes = {
 // authCheckRequested: PropTypes.func,
  navMenuclose: PropTypes.func,
  children: PropTypes.object,
  loginFinished: PropTypes.bool,
  loginError: PropTypes.bool
};

export { App };
export default withRouter(
  connect(
    state => ({
      //loginError: selectLoginError(state),
      //loginFinished: hasAuthCheckFinished(state),
      //userLoggedIn: userLoggedIn(state)
    }),
    { navMenuclose}
  )(App)
);