const labConstants = {
  success_msg: 'File Uploaded Successfully',
  invalidFile_msg: 'Invalid File Type selected. Please select .xlsx file type only',
  notMatch_msg: `Uploaded File Doesn't Match The TRF Chemical Classes`,
  notMatch_msg3: `Your file was not uploaded successfully due to negative test values. Please re-upload after corrections have been made.`,
  notMatch_msg2: `Your file was not uploaded successfully due to Invalid Visual Confirmation data. Please re-upload after corrections have been made.`,
  success_msg2: `Your file was not uploaded successfully due to missing data.  Please re-upload after corrections have been made.`,
  success_msg3: `Missing test result values. Please enter data for all chemical tests.`,
  repeatTestPackages:['Formaldehyde (Packaging)']
};

export default labConstants;