
import find from 'lodash/find';
import union from 'lodash/union';
import filter from 'lodash/filter';

import MaterialTypes from '../../constants/json/radiobuttons/materialTypes.json';
import individualTest from '../../constants/json/checkboxes/individualTestPackages.json';

let brandArray = {
  brandId: false,
  productId: false,
  isMaterial: '',
  isMaterialNumber: '',
  isError: true,
  isRetest:false,
  originalTRF:'',
  materialType:[],
  testPackagesValidationArry: [],
};

let testInfoArray = {
  season: false,
  variants: false,
  testingType: 'PR',
  isMaterialTypeVariant: true,
  isMaterialType: false,
  materialType: '',
  materialNames: [],
  multiVariantMaterial: false,
  multiVariants: [],
  isMaterialVarient: false,
  allVarientPresent: false,
  pColor: '',
  sampleDescription: '',
  repellent: false,
  foodOrMouth: false,
  isTestingType: false,
  testPackages: [],
  testPackage: '',
  isTestPackageVariant: false,
  testPackageVariants: [],
  initialProductId: 0,
  currentProductId: 0,
  isError: true,
  recycle:false,
  listRecycle:'',
  describeRecycle:'',
  //subRecycledContent: 'No',
  //recycledContent: 'No',
  currentProductType: 100,
  InitialProductType: 100,
  testPackagesValidationArry: [],
  testPackagesJewelryValidationArry: [],
  testPackagesElectronicValidationArry: [],
  testPackagesPackagingValidationArry: [],
  jewelryMaterialTypeValidation:' ',
  subVariants: 0,
  skinContact: false,
  electCategory: '',
  productIdEdit: 1,
  sampleType:'',
  ageGroup:'',
  sampleColor:''
};


let labSelectionArray = {
  islabSelect: false,
  locationPayload: '',
  labLocation: false,
  isServiceRequest: false,
  isError: true
};


export const brandInfoValidations = (field, payload, mode) => {
  if (mode === 'Create') {
    if (field.indexOf('brandId') !== -1) {
      brandArray.brandId = true;
    }
    if (field.indexOf('productId') !== -1) {
      brandArray.productId = true;
    }
    if (field.indexOf('isLicensee') !== -1) {
      brandArray.isLicensee = payload;
    }
    if (field.indexOf('licensee') !== -1) {
      brandArray.licensee = payload;
    }
    if (field.indexOf('isRetest') !== -1) {
      brandArray.isRetest = payload;
    }
    if (field.indexOf('originalTRF') !== -1) {
      brandArray.originalTRF = payload;
    }
    if (field.indexOf('materialName') !== -1) {
      brandArray.isMaterial = payload;
    }
    if (field.indexOf('materialNumber') !== -1) {
      brandArray.isMaterialNumber = payload;
    }
  }
  else if (mode === 'Edit') {
    brandArray.productId = payload.productId ? true : false;
    brandArray.brandId = payload.brandId ? true : false;
    brandArray.isMaterial = payload.materialName ? payload : '';
    brandArray.isMaterialNumber = payload.materialNumber ? payload : '';
  }
  if (field.indexOf('materialType') !== -1) {
    brandArray.materialType = payload;
  }
  if (field.indexOf('testPackagesValidationArry') !== -1) {
    brandArray.testPackagesValidationArry = payload;
  }
  return brandArray;
};
export const brandInfoValidationsForEdit = (field, payload, mode) => {
  brandArray.productId = payload.productId ? true : false;
  brandArray.brandId = payload.brandId ? true : false;
  brandArray.isLicensee = payload.licensee ? "Yes" : "No";
  brandArray.licensee = payload.licensee ? payload.licensee : null;
  brandArray.isRetest = (payload.isRetest && payload === 'Yes') ? true : false;
  brandArray.originalTRF = payload.originalTRF ? payload.originalTRF : null; //new added
  brandArray.isMaterial = payload.materialName ? payload : '';
  brandArray.isMaterialNumber = payload.materialNumber ? payload : '';
  return brandArray;
};
export const testingInformationArry = (field, payload, oldProductType, newProductType) => {
  if (field.indexOf('season') !== -1) {
    testInfoArray.season = true;
  }
  if (field.indexOf('testingType') !== -1) {
    testInfoArray.testingType = payload;
  }
  if (field.indexOf('sampleDescription') !== -1) {
    testInfoArray.sampleDescription = payload;
  }
  if (field.indexOf('pigmentColor') !== -1) {
    testInfoArray.pColor = payload;
  }
  if (field.indexOf('materialName') !== -1) {
    testInfoArray.isMaterial = payload;
  }
  if (field.indexOf('repellent') !== -1) {
    if (payload === 'Yes') {
      testInfoArray.repellent = true;
    } else {
      testInfoArray.repellent = false;
    }
  }
  if (field.indexOf('skinContact') !== -1) {
    if (payload === 'Yes') {
      testInfoArray.skinContact = true;
    } else {
      testInfoArray.skinContact = false;
    }
  }
  if (field.indexOf('electCategory') !== -1) {
    testInfoArray.electCategory = payload;
  }
  if (field.indexOf('jeweleryMaterialType') !== -1) {
    testInfoArray.jeweleryMaterialType = payload;
    testInfoArray.jewelryMaterialTypeValidation = payload
  }
  if (field.indexOf('sampleType') !== -1) {
    testInfoArray.sampleType = true;
  }
  if (field.indexOf('ageGroup') !== -1) {
    testInfoArray.ageGroup = true;
  }
  if (field.indexOf('sampleColor') !== -1) {
    testInfoArray.sampleColor = true;
  }
  if (field.indexOf('recycle') !== -1) {
    if (payload === 'Yes') {
      testInfoArray.recycle = true;
    } else {
      testInfoArray.recycle = false;
    }
  }
  if (field.indexOf('pigmentColor') !== -1) {
    testInfoArray.pColor = payload;
  }
  if (field.indexOf('listRecycle') !== -1) {
    testInfoArray.listRecycle = payload;
  }
  if (field.indexOf('describeRecycle') !== -1) {
    testInfoArray.describeRecycle = payload;
  }

  /* if (field.indexOf('recycledContent') !== -1) {
    if (payload === 'Yes') {
      testInfoArray.recycledContent = 'Yes';
    } else {
      testInfoArray.recycledContent = 'No';
    }
  }
  if (field.indexOf('subRecycledContent') !== -1) {
    if (payload === 'Post Consumer' || payload === 'Post-Industrial') {
      testInfoArray.subRecycledContent = 'Yes';
    } else {
      testInfoArray.subRecycledContent = 'No';
    }
  } */
  if (oldProductType !== newProductType && testInfoArray.selectedPackages === false) {
    testInfoArray.testPackages = []
    testInfoArray.testPackageVariants = []
    testInfoArray.variants = false
    testInfoArray.selectedPackages = true
    testInfoArray.testPackage = ''
    testInfoArray.isTestPackageVariant = false;
    testInfoArray.testPackagesElectronicValidationArry = []
    testInfoArray.testPackagesJewelryValidationArry = []
    testInfoArray.testPackagesPackagingValidationArry = []
    testInfoArray.testPackagesValidationArry = []
    delete testInfoArray[`tp-variants${testInfoArray.testPackage}`]
  }
  return testInfoArray
}

export const individualPackageNotEqualProductTypeArry = (testInfoArray, field, payload, oldProductType, newProductType) => {
  if (field.match(/\d/g) === null) {
    testInfoArray.testPackagesValidationArry = []
    testInfoArray.testPackagesPackagingValidationArry = []
    testInfoArray.testPackagesElectronicValidationArry = []
    testInfoArray.testPackagesJewelryValidationArry = []
    testInfoArray.testPackageVariants= []
  } else {
    if (field.indexOf('testPackagesJewelry') !== -1) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesJewelryValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesJewelryValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
    else if (field.indexOf('testPackagesElectronic') !== -1) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesElectronicValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesElectronicValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
    else if (field.indexOf('testPackagesPackaging') !== -1) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesPackagingValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesPackagingValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
    else if (field.indexOf('testPackages') !== -1) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
  }
  const selectedTestPackagesArry = testInfoArray.testPackagesValidationArry
  const selectedTestPackagesJeweleryArry = testInfoArray.testPackagesJewelryValidationArry
  const selectedTestPackagesPackagingArry = testInfoArray.testPackagesPackagingValidationArry
  const selectedTestPackagesElectronicArry = testInfoArray.testPackagesElectronicValidationArry
  const finalTestPackagesArry = union(selectedTestPackagesArry, selectedTestPackagesElectronicArry, selectedTestPackagesJeweleryArry, selectedTestPackagesPackagingArry)
  const testPackagesRemoveNull = finalTestPackagesArry.filter((result) => {
    return result !== null && result !== undefined && result !== 'empty'
  })
  if (testPackagesRemoveNull.length > 0) {
    testInfoArray.testPackages = testPackagesRemoveNull
    testInfoArray.isTestPackageVariant = false;
  } else {
    testInfoArray.testPackages = testPackagesRemoveNull
    testInfoArray.isTestPackageVariant = true;
  }
  return testInfoArray
}
//To combine the all Individual test packages for same Product Type
export const individualPackageEqualProductTypeArry = (testInfoArray, field, payload, oldProductType, newProductType) => {
  if (field.indexOf('testPackagesJewelry') !== -1) {
    if (field.match(/\d/g) !== null) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesJewelryValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesJewelryValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
    if (payload === null) {
      testInfoArray.isMaterialVarient = false;
    }
  }
  else if (field.indexOf('testPackagesElectronic') !== -1) {
    if (field.match(/\d/g) !== null) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesElectronicValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesElectronicValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
  }
  else if (field.indexOf('testPackagesPackaging') !== -1) {
    if (field.match(/\d/g) !== null) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesPackagingValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesPackagingValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
  }
  else if (field.indexOf('testPackages') !== -1) {
    if (field.match(/\d/g) !== null) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.testPackagesValidationArry[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.testPackagesValidationArry[field.match(/\d\d/g)] = payload;
      }
    }
  }
  const selectedTestPackagesArry = testInfoArray.testPackagesValidationArry
  const selectedTestPackagesJeweleryArry = testInfoArray.testPackagesJewelryValidationArry
  const selectedTestPackagesPackagingArry = testInfoArray.testPackagesPackagingValidationArry
  const selectedTestPackagesElectronicArry = testInfoArray.testPackagesElectronicValidationArry
  const finalTestPackagesArry = union(selectedTestPackagesArry, selectedTestPackagesElectronicArry, selectedTestPackagesJeweleryArry, selectedTestPackagesPackagingArry)
  const testPackagesRemoveNull = finalTestPackagesArry.filter((result) => {
    return result !== null && result !== undefined && result !== 'empty'
  })
  if (testPackagesRemoveNull.length > 0) {
    testInfoArray.testPackages = testPackagesRemoveNull
    testInfoArray.isTestPackageVariant = false;
  } else {
    testInfoArray.testPackages = testPackagesRemoveNull
    testInfoArray.isTestPackageVariant = true;
  }
  //Edit TRF Form to cancate new test packages
  if (testInfoArray.testingInfoStatus === 'Edit' && testInfoArray.testPackagesForEdit.length > 0) {
    testInfoArray.testPackages = testInfoArray.testPackagesForEdit
    testInfoArray.isTestPackageVariant = false;
    if (testPackagesRemoveNull.length > 0) {
      testInfoArray.testPackages = testInfoArray.testPackages.concat(testPackagesRemoveNull)
      testInfoArray.isTestPackageVariant = false;
    }
  }
  testInfoArray.testPackage = (oldProductType !== newProductType && testInfoArray.selectedPackages === true) ? [] : payload
 /*  if (!(testInfoArray.testPackagesValidationArry
    .filter(element => element != null).length === 0)  && testInfoArray.testPackage !== null) {
    testInfoArray.isTestPackageVariant = find(
      union(
        individualTest[0].common,
        //individualTest[0].toys,
        individualTest[0].electronic,
        individualTest[0].packaging,
        individualTest[0].jewelry
      ),
      ['id', payload]
    ).variant;
  } */
 return testInfoArray
}


export const testingInfoValidations = (field, payload, type, oldProductType, newProductType, editProductId) => {
  const testingInformationArryObj = testingInformationArry(field, payload, oldProductType, newProductType)
  const testPackagesElectronicNullArry = new Array(individualTest[0].electronic.filter(data => data.isTestAvailable === true).length).fill(null);
  testInfoArray = testingInformationArryObj
  testInfoArray.InitialProductType = oldProductType;
  testInfoArray.currentProductType = newProductType;
  testInfoArray.productIdEdit = editProductId !== '' ? editProductId : ''
  //testInfoArray.materialNames = testInfoArray.materialType && testInfoArray.materialType.length > 0 ? testInfoArray.materialType : []
  testInfoArray.testPackagesElectronicValidationArry = 
  (testInfoArray.electCategory === "" && testInfoArray.productIdEdit === 204) ?  testPackagesElectronicNullArry : testInfoArray.testPackagesElectronicValidationArry

  if (field.indexOf('subVariants') === 0) {
    testInfoArray.subVariants = payload;
  }
  if (field.indexOf('materialType') !== -1) {
    if (field.match(/\d/g) !== null) {
      if (field.match(/\d/g) && field.match(/\d/g).length === 1) {
        testInfoArray.materialNames[field.match(/\d/g)] = payload;
      } else {
        testInfoArray.materialNames[field.match(/\d\d/g)] = payload;
      }
      testInfoArray.materialName = payload;
      const materialName = testInfoArray.materialNames.filter(value => value !== null)
      for (let val of materialName) {
        testInfoArray.materialName = val;
        if (find(MaterialTypes, ['id', val]).variant) {
          testInfoArray.isMaterialVarient = true;
          if ((Object.prototype.hasOwnProperty.call(testInfoArray, `variants${val}` && `variants${val}` !== null))) {
            testInfoArray.allVarientPresent = true;
          } else {
            if (payload && find(MaterialTypes, ['id', payload]).variant) {
              if (testInfoArray[`variants${payload}`]) {
                testInfoArray.allVarientPresent = true;
              }
              else if (find(MaterialTypes, ['id', payload]).variantNotMandatory) {
                testInfoArray.allVarientPresent = true;
              }
              else {
                testInfoArray.allVarientPresent = false;
              }
            }
            break
          }
        }
      }
      if (materialName.includes(400)) {
        testInfoArray.multiVariantMaterial = true;
      } else {
        testInfoArray.multiVariantMaterial = false;
      }
    } else {
       testInfoArray.materialNames =  [null] 
       testInfoArray.testPackagesValidationArry =  [null]
    }
    if (payload === null) {
      testInfoArray.isMaterialVarient = false;
    }
  }
  if (field.indexOf(`variants`) !== -1 && field.startsWith("variants")) {
    if (payload !== null) {
      testInfoArray.variants = true;
      testInfoArray[`variants${filter(MaterialTypes, { variants: [{ id: payload }] })[0].id}`] = payload;
    }
    if (field.indexOf(`variants400`) !== -1) {
      if (payload !== null) {
        testInfoArray.multiVariants.push(payload);
      } else {
        testInfoArray.multiVariants.pop();
      }
    }
    if (payload === null) {
      testInfoArray.materialNames.filter(value => value !== null).map(val => (
        testInfoArray[`variants${val}`] = null
      ))
    }

    const materialName = testInfoArray.materialNames.filter(value => value !== null)
    for (let val of materialName) {
      testInfoArray.materialName = val;
      if (find(MaterialTypes, ['id', val]).variant) {
        if ((Object.prototype.hasOwnProperty.call(testInfoArray, `variants${val}`))) {
          testInfoArray.allVarientPresent = true;
        }
        else if (find(MaterialTypes, ['id', val]).variantNotMandatory) {
          testInfoArray.allVarientPresent = true;
        }
        else {
          testInfoArray.allVarientPresent = false;
          break
        }
      }
    }

  }
  //Logic for to reset the Individual test packages based on Product Type
  if (field.indexOf('testPackages') !== -1 && (oldProductType !== newProductType)) {
    testInfoArray.selectedPackages = true
    const finalIndividualTestPackages = individualPackageNotEqualProductTypeArry(testInfoArray, field, payload, oldProductType, newProductType)
    testInfoArray = finalIndividualTestPackages
    testInfoArray.testPackage = payload !== null ? payload : '';
  }
  //Logic for without changing the Product Type/ Equal Product Type
  else if (field.indexOf('testPackages') !== -1 && (oldProductType === newProductType)) {
    const finalIndividualPackages = individualPackageEqualProductTypeArry(testInfoArray, field, payload, oldProductType, newProductType)
    testInfoArray = finalIndividualPackages
  }
  else if(field.indexOf('testPackages') === -1){
    testInfoArray.isTestPackageVariant = false;
  }
  if (field.indexOf(`tp-variants${testInfoArray.testPackage}`) === 0) {
    testInfoArray.tp_variants = true;
    testInfoArray[`tp-variants${testInfoArray.testPackage}`] = payload;
    testInfoArray.testPackageVariants.push(payload);
  }
  else if (field.indexOf(`tp-variants${testInfoArray.testPackage}`) !== -1) {
    testInfoArray.tp_variants = true;
    testInfoArray[`tp-variants${testInfoArray.testPackage}`] = payload;
    testInfoArray.testPackageVariants.push(payload);
  }
  //Logic for PFAS(597) Common Validation Check
  if(testInfoArray.testingInfoStatus !== 'Edit' &&
    testInfoArray.testPackage.length > 0 && testInfoArray.testPackage.filter(ele => ele !== null).includes(597) === true){
      if(testInfoArray.testPackagesValidationArry.includes(597) === false){
        testInfoArray.testPackagesValidationArry[8] = 597
      }
  }
  //Logic for PFAS(586) Packaging Validation Check
  if(testInfoArray.testingInfoStatus !== 'Edit' &&
    testInfoArray.testPackage.length > 0 && testInfoArray.testPackage.filter(ele => ele !== null).includes(586) === true){
      if(testInfoArray.testPackagesPackagingValidationArry.includes(586) === false){
        testInfoArray.testPackagesPackagingValidationArry[5] = 586
      }
  }
  return testInfoArray
  
};


//Edit form to append the form values here
export const testingInfoValidationsForEdit = (field, payload, editProductId) => {
  testInfoArray.season = payload.season ? true : false;
  testInfoArray.testingType = payload.testingType ? payload.testingType : '';
  testInfoArray.isMaterial = payload.materialName ? payload.materialName : '';
  testInfoArray.pColor = payload.pigmentColor ? payload.pigmentColor : '';
  testInfoArray.testPackage = payload.testPackage ? payload.testPackage : '';
  testInfoArray.sampleDescription = payload.sampleDescription ? payload.sampleDescription : '';
  testInfoArray.sampleType = payload.sampleType ? payload.sampleType : '';
  testInfoArray.ageGroup = payload.ageGroup ? payload.ageGroup : '';
  testInfoArray.sampleColor = payload.sampleColor ? payload.sampleColor : '';
  testInfoArray.skinContact = (payload.skinContact === 'Yes' || payload === 'Yes') ? true : false;
  /* testInfoArray.subRecycledContent = (payload.subRecycledContent === 'Post Consumer' ||
    payload.subRecycledContent === 'Post-Industrial') ? 'Yes' : 'No';
  testInfoArray.recycledContent = payload.recycledContent ? payload.recycledContent : ''; */
  testInfoArray.repellent = (payload.repellent && payload === 'Yes') ? true : false;
  testInfoArray.foodOrMouth = (payload.foodOrMouth && payload === 'Yes') ? true : false;
  testInfoArray.materialType = payload.materialType;
  //testInfoArray.productIdEdit = payload.prevProductId === 204 || payload.prevProductId === 206  || payload.prevProductId === 205? payload.prevProductId : '';
  testInfoArray.productIdEdit = payload.prevProductId !== '' ? payload.prevProductId : '';
  testInfoArray.electCategory = payload.electCategory ? payload.electCategory : '';
  testInfoArray.subVariants = payload.subVariants ? payload.subVariants : '';
  testInfoArray.testingInfoStatus = payload.testingInfoStatus;
  testInfoArray.testPackages = payload.testPackages.length > 0 ? payload.testPackages : [];
  testInfoArray.testPackagesElectronicValidationArry =  payload.testPackagesElectronic; //testPackagesElectronicValidationArry for Electronic section in testPackages
  testInfoArray.testPackagesJewelryValidationArry = payload.testPackagesJewelry; // testPackagesJewelryValidationArry for Jewelry section in testPackages
  testInfoArray.jewelryMaterialTypeValidation = payload.jeweleryMaterialType ? payload.jeweleryMaterialType : '';
  
  testInfoArray.recycle = payload.recycle === 'Yes' ? true : false;
  testInfoArray.listRecycle = payload.listRecycle ? payload.listRecycle : '';
  testInfoArray.describeRecycle = payload.describeRecycle ? payload.describeRecycle : '';

  if (payload && payload[`variants${testInfoArray.materialType}`]) {
    testInfoArray.variants = true;
    testInfoArray[`variants${testInfoArray.materialType}`] = payload[`variants${testInfoArray.materialType}`];
  }
  if (payload.materialType) {
    if (payload.materialType.filter(value => value !== null).length) {
      testInfoArray.materialNames = payload.materialType;
    }
    testInfoArray.materialNames.filter(value => value !== null)
      .forEach((val, index) => {
        if (find(MaterialTypes, ['id', val]).variant) {
          testInfoArray.allVarientPresent = true;
          testInfoArray[`variants${val}`] = payload[`variants${val}`]
          if (val === 400) {
            testInfoArray.multiVariantMaterial = true;
            testInfoArray.multiVariants = payload[`variants${val}`].filter(value => value !== null);
          }
        }
      })
  }

  if (payload.testPackages) {
  
    testInfoArray.testPackagesForEdit = testInfoArray.testPackages.length > 0 ? testInfoArray.testPackages : [];
    testInfoArray.testPackagesPackagingValidationArry  =  payload.testPackagesPackaging; //testPackagesPackagingValidationArry for packaging section in testPackages
    testInfoArray.testPackagesValidationArry = payload.testPackages; // testPackagesValidationArry for common section in testPackages
    testInfoArray.testPackages.filter(value => value !== null)
      .forEach((val, index) => {
        testInfoArray.testPackage = val;
        const testPackagesArr = payload.testPackages;
        if (!(testPackagesArr.filter(element => element != null).length === 0) && testInfoArray.testPackage !== null) {
          testInfoArray.isTestPackageVariant = find(
            union(
              individualTest[0].common,
              individualTest[0].toys,
              individualTest[0].electronic,
              individualTest[0].packaging,
              individualTest[0].jewelry
            ),
            ['id', testInfoArray.testPackage]
          ).variant;
        }
        if (payload === null) {
          testInfoArray.isTestPackageVariant = false;
        } else if (testInfoArray.testPackages.length > 0) {
          testInfoArray.isTestPackageVariant = false;
        }
        if (payload[`tp-variants${testInfoArray.testPackage}`]) {
          testInfoArray.tp_variants = true;
          testInfoArray[`tp-variants${testInfoArray.testPackage}`] = payload[`tp-variants${testInfoArray.testPackage}`];
          testInfoArray.testPackageVariants.push(payload[`tp-variants${testInfoArray.testPackage}`]);
        }
      });
  }
  return testInfoArray;
};

export const labLocationValidations = (field, payload, mode) => {
  if (field.indexOf('lab') !== -1) {
    labSelectionArray.locationPayload = payload;
    labSelectionArray[`labLocation${labSelectionArray.locationPayload}`] = null;
  }
  if (field.indexOf(`location${labSelectionArray.locationPayload}`) !== -1) {
    labSelectionArray[`labLocation${labSelectionArray.locationPayload}`] = payload;
  }
  return labSelectionArray;
};

export const labLocationValidationsForEdit = (field, payload) => {
  labSelectionArray.locationPayload = payload.lab ? payload.lab : '';
  labSelectionArray[`labLocation${labSelectionArray.locationPayload}`] = payload.labLocation;
  return labSelectionArray;
}

export const clearTestingInfo = () => {
 brandArray = {
    brandId: false,
    productId: false,
    isMaterial: '',
    isMaterialNumber: '',
    isError: true,
    isRetest:false,
    originalTRF:''
  };
  testInfoArray = {
    season: false,
    variants: false,
    testingType: 'PR',
    isMaterialTypeVariant: true,
    isMaterialType: false,
    materialType: '',
    materialNames: [],
    multiVariantMaterial: false,
    multiVariants: [],
    isMaterialVarient: false,
    allVarientPresent: false,
    pColor: '',
    sampleDescription: '',
    repellent: false,
    foodOrMouth: false,
    isTestingType: false,
    testPackages: [],
    testPackage: '',
    testPackageVariants: [],
    isTestPackageVariant: false,
    initialProductId: 0,
    currentProductId: 0,
    isError: true,
    recycle:false,
    listRecycle:'',
    describeRecycle:'',
    //subRecycledContent: 'No',
    //recycledContent: 'No',
    currentProductType: 100,
    InitialProductType: 100,
    selectedPackages: false,
    testPackagesValidationArry: [],
    testPackagesJewelryValidationArry: [],
    testPackagesElectronicValidationArry: [],
    testPackagesPackagingValidationArry: [],
    jewelryMaterialTypeValidation:' ',
    testPackagesForEdit: [],
    subVariants: 0,
    skinContact: false,
    electCategory: '',
    productIdEdit: 1,
    sampleType:'',
    ageGroup:'',
    sampleColor:''
  };
  labSelectionArray = {
    islabSelect: false,
    locationPayload: '',
    labLocation: false,
    isServiceRequest: false,
    isError: true
  };
};
