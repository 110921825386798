import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';


function* suplistener(serviceType, action) {
  try {
    const model = {
      supId: null,
      supName: null,
      parentCompany: null,
      OSHId: null
    };
    
    const result = pick(action.data, keys(model));
    const data = { ...result };
    const infoPopDuration = 1000;
    
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, apiConfig.updateSupplier.endPointUrl, data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if(!statusErrorMessage){ 
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        yield put(createServiceAction('listSuppliers','requested')())
        if(action.data.isOnlySupplier === false){
        yield put(createServiceAction('locationUpdate', 'submitted')(action.data))
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}


function* companyUpdateSagas() {
 
  const serviceType = 'companyUpdate';
  yield takeEvery(createServiceAction(serviceType, 'submitted').type, suplistener, serviceType);
}

export default companyUpdateSagas;
