import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import productInfo from '../constants/json/checkboxes/productInfo.json';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const model = {
      supplierId: null,
      materialName: null,
      materialDesc: null,
      refId: null,
      isExist: null,
      supplierLocationId: null,
    };

    const result = pick(action.data, keys(model));
    const data = { ...result };
    const infoPopDuration = 1000;
    const productLabel = productInfo.filter(e => e.id === action.data.productId);
    const locationID = action.data.supplierLocationId;

    yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(postRequest, apiConfig.addMaterial.endPointUrl, data);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response.data));
        const message = `${response.message} with Material Number: - ${response.data.materialNumber}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        yield put(createServiceAction('materialSearch', 'requested')({ supplierLocation: locationID, prodType: productLabel[0].label }));
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addMaterialSagas() {
  const serviceType = 'addMaterial';
  yield takeEvery(createServiceAction(serviceType, 'submited').type, listener, serviceType);
}

export default addMaterialSagas;
