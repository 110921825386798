import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { ProgressTracker, Container, Button, H4, ConfirmationBox } from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { displaySubmitButtonQrf, displayClearButtonQrf,clearFormValueQRF , attachmentQrf } from '../../actions/qrfActions';
import { isLabUser } from '../../reducers/authReducer.js';

import TrfInfo from './trfInfo/TrfInfo';
import QrfInfo from './qrfInfo/QrfInfo';
import ReviewAndSubmitInfo from './reviewAndSubmitInfo/ReviewAndSubmitInfo';

class Qrf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      status: null,
      stepState: 0
    };
    this.hidden = {
      display: 'none'
    };
    this.handleQrfSubmit = this.handleQrfSubmit.bind(this);
    this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
    this.currentStep = this.currentStep.bind(this);
    this.cancelQrf = this.cancelQrf.bind(this);
  }

  componentDidMount() {
    const { location, getTrfDetails, clearFormValueQRF } = this.props;
    getTrfDetails(location.state && location.state.trfId);
    clearFormValueQRF();
  }

  onClickNext = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState + 1
    }));
    this.clickNext();
  };

  onClickPrevious = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState - 1
    }));
    this.clickPrevious();
  };

  handleQrfSubmit = QrfInfor => {
    const { submitQrf, updateQrf, history, location, attachmentNew } = this.props;
    const requestObject = {};
    requestObject.trfId = location.state.trfId;
    requestObject.location = QrfInfor.affectedMaterial;
    requestObject.quantity = QrfInfor.materialAffectedQty;
    requestObject.materialWorth = QrfInfor.materialWorth;
    requestObject.leftFacility = QrfInfor.affectedMaterialLeft;
    requestObject.factories = QrfInfor.factoryList;
    requestObject.isAffectedKidProduct = QrfInfor.affectedMaterialForKid;
    requestObject.nextStep = QrfInfor.immediateStep;
    requestObject.attachment = attachmentNew;
  if (QrfInfor.qrfId) {
    requestObject.qrfId = QrfInfor.qrfId;
 updateQrf(requestObject, history);
  } else {
    submitQrf(requestObject, history);
  }
};

  confirmationBoxOpen = (modalopen, status) => {
    this.setState({ submitModal: true, status });
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  cancelQrf = () => {
    const { history, attachment } = this.props;
    while(attachment.length>0){
      attachment.pop()
    }
    history.push('/');
  };

  currentStep = (step) => {
    this.setState({ stepState: step });
  }

  render() {
    const {
      QrfInfor,
      errorClassName,
      shouldDisplaySubmit,
      shouldDisplayClear,
      displaySubmitButtonQrf,
      displayClearButtonQrf,
      qrfDetails,
      qrfDetailsForEdit,
      isLabUser,
      attachmentQrf,
      attachment,
      
    } = this.props;
    const { submitModal, status, stepState } = this.state;
    const steps = [
      {
        name: 'TRF INFORMATION',
        component: (
          <TrfInfo
            displayClearButtonQrf={displayClearButtonQrf}
            displaySubmitButtonQrf={displaySubmitButtonQrf}
          />
        )
      },
      {
        name: 'QRF INFORMATION',
        component: (
          <QrfInfo
          attachmentQrf={attachmentQrf}
            displaySubmitButtonQrf={displaySubmitButtonQrf}
            displayClearButtonQrf={displayClearButtonQrf}
            { ...( qrfDetailsForEdit.qrfId?{initialValues:qrfDetailsForEdit}:{initialValues:qrfDetails})}
            successFill={typeof errorClassName !== 'undefined' ? errorClassName[1] : true}
            attachment={attachment}
          />
        )
      },
      {
        name: 'REVIEW & SUBMIT',
        component: (
          <ReviewAndSubmitInfo
            displayClearButtonQrf={displayClearButtonQrf}
            displaySubmitButtonQrf={displaySubmitButtonQrf}
            {...QrfInfor}
            attachment={attachment}
          />
        )
      }
    ];
    return (
      <div className="page-align-fixed-items">
        {!isLabUser ? (
          <div className="TRFpage">
            <div section className="prevent-scroll top-scroll-fix">
              <H4 className="text-color-accent u-bold page-title-has-back sub-heading p2-bottom-sm">
                QUARANTINE RESOLUTION FORM (QRF)                
              </H4>
            </div>
            <div section className="clear-margin progress-tracker-align">
              <ProgressTracker
                steps={steps}
                errorClassName={errorClassName}
                clickNext={clickNext => (this.clickNext = clickNext)}
                clickPrevious={clickPrevious => (this.clickPrevious = clickPrevious)}
                currentStepNumber={this.currentStep}
              />
            </div>
            <Container section className="prevent-scroll bottom-scroll-fix bottom-section remove-margin">
              <Button
                style={stepState === 0 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-prevoius-button trf-button-selected"
                onClick={() => this.onClickPrevious('previous')}
              >
                 PREVIOUS
              </Button>
              {shouldDisplaySubmit && (
                <span>
                  {errorClassName && errorClassName.every(element => element === false) ? (
                    <span>
                      <Button
                        className="ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected"
                        onClick={() => this.confirmationBoxOpen(true, 'submit')}
                      >
                        SUBMIT
                      </Button>
                    </span>
                  ) : (
                    <Button className="ncss-btn-primary-dark disabled u-bold submit-button trf-button-selected">
                      SUBMIT
                    </Button>
                  )}
                </span>
              )}
              {shouldDisplayClear && (
                <Button
                  className="ncss-btn-secondary-dark u-bold secondary-button trf-button-selected"
                  onClick={() => this.confirmationBoxOpen(true, 'clear')}
                  background="bg-dark-grey"
                >
                  CLEAR
                </Button>
              )}
              <Button
                style={stepState === 2 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-next-button trf-button-selected"
                onClick={() => this.onClickNext('next')}
              >
                NEXT
              </Button>
            </Container>
            <ConfirmationBox
              message={
                status === 'submit'
                  ? 'Are you sure you want to submit the QRF ?'
                  : 'Are you sure you want to clear the QRF ? '
              }
              submitModal={submitModal}
              submitValue={
                status === 'submit'
                  ? () => {
                      this.handleQrfSubmit(QrfInfor);
                    }
                  : () => this.cancelQrf()
              }
              closeModal={this.closeSubmitModal}
            />
          </div>
        ) : (
          <div className="pagenotFound">Error! Page you are looking for is not found</div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    QrfInfor: getFormValues('QrfInfo')(state), // here 'qrfinfo' is the name you have given your redux form
    errorClassName: state.qrf.errorClassName,
    trfDetail: state.qrf.trfDetail,
    qrfDetails:{
      qrfId:'',
      factoryList:  get(state, `form.QrfInfo.values.factoryList`, ''),
      affectedMaterialLeft: get(state, `form.QrfInfo.values.affectedMaterialLeft`, ''),
      affectedMaterialForKid: get(state, `form.QrfInfo.values.affectedMaterialForKid`, ''),
      affectedMaterial: get(state, `form.QrfInfo.values.affectedMaterial`, ''),
      materialAffectedQty: get(state, `form.QrfInfo.values.materialAffectedQty`, ''),
      materialWorth: get(state, `form.QrfInfo.values.materialWorth`, ''),
      immediateStep:get(state, `form.QrfInfo.values.immediateStep`, ''),

    },
    qrfDetailsForEdit: {
      qrfId: get(state.qrf.trfDetail, `data.qrfDetails.qrfId`, ''),
      factoryList:  get(state, `form.QrfInfo.values.factoryList`, get(state.qrf.trfDetail, `data.qrfDetails.factories`, '')),
      affectedMaterialLeft:  get(state, `form.QrfInfo.values.affectedMaterialLeft`, get(state.qrf.trfDetail, `data.qrfDetails.leftFacility`, '')),
      affectedMaterialForKid:  get(state, `form.QrfInfo.values.affectedMaterialForKid`, get(state.qrf.trfDetail, `data.qrfDetails.isAffectedKidProduct`, '')),
      affectedMaterial:  get(state, `form.QrfInfo.values.affectedMaterial`, get(state.qrf.trfDetail, `data.qrfDetails.location`, '')),
      materialAffectedQty:  get(state, `form.QrfInfo.values.materialAffectedQty`, get(state.qrf.trfDetail, `data.qrfDetails.quantity`, '')),
      materialWorth:  get(state, `form.QrfInfo.values.materialWorth`, get(state.qrf.trfDetail, `data.qrfDetails.materialWorth`, '')),
      immediateStep:  get(state, `form.QrfInfo.values.immediateStep`, get(state.qrf.trfDetail, `data.qrfDetails.nextStep`, '')),
      
    },
    attachment: state.qrf.attachment,
    attachmentNew:state.qrf.attachmentNew,
    shouldDisplaySubmit: state.qrf.shouldDisplaySubmit,
    shouldDisplayClear: state.qrf.shouldDisplayClear,
    isLabUser: isLabUser(state)
  };
}

const mapDispatchToProps = dispatch => ({
  submitQrf: (data, history) => {
    dispatch(createServiceAction('qrfSubmit', 'submitted')({ ...data, history }));
  },
  updateQrf: (data, history) => {
    dispatch(createServiceAction('qrfUpdate', 'submitted')({ ...data, history }));
  },
  getTrfDetails: data => {
    dispatch(createServiceAction('trfDetailsForQrf', 'requested')(data));
  },
  displaySubmitButtonQrf: isOpen => {
    dispatch(displaySubmitButtonQrf(isOpen));
  },
  displayClearButtonQrf: isOpen => {
    dispatch(displayClearButtonQrf(isOpen));
  },
  clearFormValueQRF: () => {
    dispatch(clearFormValueQRF());
  },
  attachmentQrf: data => {
    dispatch(attachmentQrf(data));
  },

});
  

Qrf.propTypes = {
  getTrfDetails: PropTypes.func,
  displaySubmitButtonQrf: PropTypes.func,
  shouldDisplaySubmit: PropTypes.bool,
  displayClearButtonQrf: PropTypes.func,
  shouldDisplayClear: PropTypes.bool,
  clearFormValueQRF: PropTypes.func,
  isLabUser: PropTypes.bool,
  attachmentQrf: PropTypes.func,
  attachment: PropTypes.object,
  attachmentNew:PropTypes.object,
  trfDetails: PropTypes.object,
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Qrf)
);
