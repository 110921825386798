import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import TrfReview from '../../trf_review/TrfReview';

class TrfInfo extends Component {
  componentDidMount() {
    const { displayClearButtonLab, displaySubmitButtonLab } = this.props;
    displaySubmitButtonLab(false);
    displayClearButtonLab(false);
  }

  render() {
    const { trfResult, showTitle } = this.props;
    if (typeof trfResult !== 'undefined' && trfResult.data.qrfDetails) {
      trfResult.data.qrfDetails.qrfId =  null;
      trfResult.data.frfDetails.frfId =  null;
    }

    return (
      <div className="form">
        <div style={{ marginLeft: '-15px' }}>
          {!isEmpty(trfResult) && (
            <TrfReview trfData={typeof trfResult !== 'undefined' ? trfResult.data : null} showTitle={showTitle}/>
          )}
        </div>
      </div>
    );
  }
}
TrfInfo.defaultProps = {
  showTitle: true
};
TrfInfo.propTypes = {
  trfResult: PropTypes.object,
  displayClearButtonLab: PropTypes.func,
  displaySubmitButtonLab: PropTypes.func,
  showTitle: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    trfResult: state.lab.trfDetail
  };
}

export default reduxForm({ form: 'trfinfo' })(connect(mapStateToProps)(TrfInfo));
