import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import parse from 'html-react-parser'

import { H5, IconButton } from '../../../components/index.js';

class NotificationModal extends Component {

    render() {
        const { notifyModal, closeModal, message, notificationId } = this.props;
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return (
            <Modal
                isOpen={notifyModal}
                onRequestClose={() => { closeModal(notificationId) }}
                className="modal-container notify-modal"
                overlayClassName="modal-overlay">
                <IconButton icon="times" className="modal-close-btn" onClick={() => closeModal(notificationId)} />
                <div className="ncss-col-sm-12 ">
                    {/* <H5 className="modal-text">{message}</H5> */}
                    <H5 className="modal-text">{
                        parse(message.replace(urlRegex, function (url) {
                            return ' <a href="' + url + '" target="_blank" class="notification-link">' + url + '</a>'
                        }))
                    }</H5>
                </div>

            </Modal>
        );
    }
}
NotificationModal.propTypes = {
    notifyModal: PropTypes.bool,
    closeModal: PropTypes.func,
    message: PropTypes.string,

};
NotificationModal.defaultProps = {
    buttonsDisplay: true
};
export default NotificationModal;