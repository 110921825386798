import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createServiceAction } from '../../../util/actionHelper.js';
import PropTypes from 'prop-types';
import { H4, ConfirmationBox } from '../../../components/index.js';
import Label from '../../../components/ncss/Label';

class Qrf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      fileName: ''
    };
    this.openConfirmationPopup = this.openConfirmationPopup.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  openConfirmationPopup = (fileName)=> {
    this.setState({submitModal: true, fileName: fileName});
  }

  downloadFile = (requestParams) =>{
    const {downloadAttachment} = this.props;
    downloadAttachment({ ...requestParams, formType:'qrf'});
  }

  closeSubmitModal = () => {
    this.setState({submitModal: false});
  };

  render() {
  const {
    qrfDetails,
    isDownload, 
    showTitle, 
    attachment
  } = this.props;
  const { fileName, submitModal } = this.state;
        return(
        <div className={isDownload ? 'p5-sm' : 'p2-sm'}>
        {showTitle && (
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">QRF Information</H4>)
        }
        <div className="qrf-content">
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              Has any of the affected material left the manufacturer&rsquo;s facility?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {typeof qrfDetails.leftFacility !== 'undefined' ? (
                <span>{qrfDetails.leftFacility}</span>
              ) : (
                'Not Added'
              )}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              Where is the affected material now?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {typeof qrfDetails.location !== 'undefined' ? <span>{qrfDetails.location}</span> : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              How much material is affected by this failure?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {typeof qrfDetails.quantity !== 'undefined' ? <span>{qrfDetails.quantity}</span> : 'Not Added'}
            </Label>
          </div>
          
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
            How much is the material worth?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {(typeof qrfDetails.materialWorth !== 'undefined' && qrfDetails.materialWorth !== null) ? <span>{qrfDetails.materialWorth}</span> : 'Not Added'}
            </Label>
          </div>  
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              List all factory(s) that are expecting to receive this material:
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {typeof qrfDetails.factories !== 'undefined' ? <span>{qrfDetails.factories}</span> : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              Is this affected material used for Kid product?
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {typeof qrfDetails.isAffectedKidProduct !== 'undefined' ? (
                <span>{qrfDetails.isAffectedKidProduct}</span>
              ) : (
                'Not Added'
              )}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold description">
              Describe your immediate next steps:
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {typeof qrfDetails.nextStep !== 'undefined' ? <span>{qrfDetails.nextStep}</span> : 'Not Added'}
            </Label>
          </div>
          <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold attachment-sets description">
             Attachment
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {attachment && attachment.length > 0 ? <div>{attachment.map(data =>( 
                <div>
                  <span>{data.fileName}</span>
                  <div  className="inline-flex-display download_button" title="Download file" onClick={()=>{this.openConfirmationPopup(data.fileName)} }>
                    <i className="fa fa-download"></i>
                  </div>
                </div>))}
              </div> : 'Not Added'}
            </Label>
        </div>
        <ConfirmationBox
    message={
        'Do you want to download the ' + fileName + '?'
    }
    submitModal={submitModal}
    submitValue={
       () => {
        this.downloadFile({id: qrfDetails.qrfId, fileName: fileName});
          }
    }
    closeModal={this.closeSubmitModal}
  />
        </div>
      </div>
        );
    }
  }
Qrf.defaultProps = {
    showAttachement: true
    };
Qrf.propTypes = {
    qrfDetails: PropTypes.object.isRequired,
    isDownload: PropTypes.bool,
    showTitle: PropTypes.bool
  };
  function mapStateToProps(state) {
    return {
  
    };
  }
    
  const mapDispatchToProps = dispatch => ({
    downloadAttachment: data => {
      dispatch(createServiceAction('downloadAttachment', 'requested')(data));
    }
  });
  
  export default (connect(mapStateToProps, mapDispatchToProps)(Qrf));