import { createAction } from '../util/index.js';

export const startAuthCheck = createAction('AUTH_CHECK_START', { noPayload: true });
export const finishAuthCheck = createAction('AUTH_CHECK_COMPLETE', { noPayload: true });
export const setAuthCheckError = createAction('AUTH_CHECK_ERROR');

export const setUserLogin = createAction('LOGIN_SET_USER');
export const setUserLogout = createAction('LOGOUT_SET_USER');
export const checkUserExist = createAction('CHECK_USER_EXIST');
export const checkValidGroup = createAction('CHECK_VALID_GROUP')
export const requestUserLogin = createAction('LOGIN_USER_REQUESTED');
export const requestUserLogout = createAction('LOGOUT_USER_REQUESTED');

export const authCheckRequested = createAction('AUTH_CHECK_REQUESTED', { noPayload: true });

export const locationReinitilized = createAction('LOCATION_REINITILIZED', { noPayload: true });
export const materialReinitilized = createAction('MATERIAL_REINITILIZED', {noPayload: true});
