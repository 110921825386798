import { connect } from 'react-redux';
import React from 'react';

import * as Constants from '../../constants/footer.js';

export const Footer = () => (
  <span>
    <iframe className="fake-iframe fake-iframe-footer-height" title ='fake'id='fake' src='about:blank'></iframe>
  <div className="footer-container bg-dark-grey footer">
    <div className="m12-sm footer-margin-adjust">
  
      <hr className="footer-hr" />
      <span className="text-color-white fs12-sm lh12-sm">&#x24B8; {Constants.NIKEINC}</span>
      <span className="fs12-sm lh12-sm version-styl"> <i className="g72-swoosh nav-brand fs22-sm menu-logo-footer" /> {Constants.appVERSION}</span>
    </div>
  </div>
  </span>
);
export default connect()(Footer);
