import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Collapsible } from '../../../components/index.js';
import Label from '../../../components/ncss/Label';
import TrfInfo from '../trfInfo/TrfInfo.js';

const style = {
  'font-weight': '800',
  'font-size': '1.8rem',
  'line-height': '1.5',
  color: '#0078B9'
};

class ReviewAndSubmitInfo extends Component {
  componentDidMount() {
    const { displaySubmitButtonQrf, displayClearButtonQrf } = this.props;
    displaySubmitButtonQrf(true);
    displayClearButtonQrf(false);
  }

  render() {
    const {
      affectedMaterial,
      materialAffectedQty,
      materialWorth,
      affectedMaterialLeft,
      factoryList,
      affectedMaterialForKid,
      immediateStep,
      displaySubmitButtonQrf,
      displayClearButtonQrf,
      attachment
    } = this.props;
    return (
      <div className="form">
        <div>
          <Collapsible
            trigger={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
                <div className="ncss-col-sm-1 ">
                  <i className="fas fa-caret-down fa-pull-right" />
                </div>
              </div>
            }
            triggerWhenOpen={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
                <div className="ncss-col-sm-1">
                  <i className="fas fa-caret-up fa-pull-right" />
                </div>
              </div>
            }
            triggerStyle={style}
            triggerClassName="collapsible"
          >
            <TrfInfo
              from="review"
              displaySubmitButtonQrf={displaySubmitButtonQrf}
              displayClearButtonQrf={displayClearButtonQrf}
              showTitle={false}
            />
          </Collapsible>
        </div>
        <div>
          <Collapsible
            trigger={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> QRF INFORMATION</div>
                <div className="ncss-col-sm-1 ">
                  <i className="fas fa-caret-down fa-pull-right" />
                </div>
              </div>
            }
            triggerWhenOpen={
              <div className="ncss-col-sm-12 collapse-p0">
                <div className="ncss-col-sm-11 collapse-p0"> QRF INFORMATION</div>
                <div className="ncss-col-sm-1">
                  <i className="fas fa-caret-up fa-pull-right" />
                </div>
              </div>
            }
            triggerStyle={style}
            triggerClassName="collapsible"
          >
            <div className="pl8-sm">
              <div className="ncss-row">
                <Label className="ncss-col-sm-3 pb3-sm u-bold description">
                  Has any of the <br /> affected material left <br /> the manufacturer&rsquo;s <br /> facility?
                </Label>
                <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
                  {affectedMaterialLeft? (
                    <span>{affectedMaterialLeft}</span>
                  ) : (
                    'Not Added'
                  )}
                </Label>
              </div>
              <div className="ncss-row">
                <Label className="ncss-col-sm-3 pb3-sm u-bold description">
                  Where is the affected <br /> material now?
                </Label>
                <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
                  {affectedMaterial? <span>{affectedMaterial}</span> : 'Not Added'}
                </Label>
              </div>
              <div className="ncss-row">
                <Label className="ncss-col-sm-3 pb3-sm u-bold description">
                  How much material <br /> is affected by this <br /> failure?
                </Label>
                <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
                  {materialAffectedQty? (
                    <span>{materialAffectedQty}</span>
                  ) : (
                    'Not Added'
                  )}
                </Label>
              </div>
              <div className="ncss-row">
                <Label className="ncss-col-sm-3 pb3-sm u-bold description">
                How much is the <br /> material worth?
                </Label>
                <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
                  {materialWorth? (
                    <span>{materialWorth}</span>
                  ) : (
                    'Not Added'
                  )}
                </Label>
              </div>

              <div className="ncss-row">
                <Label className="ncss-col-sm-3 pb3-sm u-bold description">
                  List all factory(s) that <br /> are expecting to <br /> receive this material
                </Label>
                <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
                  {factoryList? <span>{factoryList}</span> : 'Not Added'}
                </Label>
              </div>
              <div className="ncss-row">
                <Label className="ncss-col-sm-3 pb3-sm u-bold description">
                  Is this affected <br /> material used for Kid <br /> product
                </Label>
                <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
                  {affectedMaterialForKid? (
                    <span>{affectedMaterialForKid}</span>
                  ) : (
                    'Not Added'
                  )}
                </Label>
              </div>
              <div className="ncss-row">
                <Label className="ncss-col-sm-3 pb3-sm u-bold description">
                  Describe your <br /> immediate next <br /> steps:
                </Label>
                <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
                  {immediateStep? <span>{immediateStep}</span> : 'Not Added'}
                </Label>
              </div>
              <div className="ncss-row">
            <Label className="ncss-col-sm-3 pb3-sm u-bold description">
             Attachment
            </Label>
            <Label className="ncss-col-sm-9 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {!isEmpty(attachment) ? <div >{attachment.map(data =>(<div>{data.fileName}</div>))}</div> : 'Not Added'}
            </Label>
          </div>
            </div>
          </Collapsible>
        </div>
      </div>
    );
  }
}

ReviewAndSubmitInfo.propTypes = {
  affectedMaterial: PropTypes.string,
  materialAffectedQty: PropTypes.string,
  materialWorth:PropTypes.string,
  affectedMaterialLeft: PropTypes.string,
  factoryList: PropTypes.string,
  affectedMaterialForKid: PropTypes.string,
  immediateStep: PropTypes.string,
  displaySubmitButtonQrf: PropTypes.func,
  displayClearButtonQrf: PropTypes.func
};

export default connect()(ReviewAndSubmitInfo);
