import React from 'react';

import Label from './Label';

const defaultInputClass = 'ncss-input pt2-sm pr4-sm pb2-sm pl4-sm';
const defaultTextAreaClass = 'ncss-textarea p4-sm';
const defaultTextNumberClass = 'ncss-input p4-sm';

export const Input = ({ textArea, onChange, type, className, label, name, placeholder, disabled, value, ...props }) => {
  if (type === 'textarea' || textArea) {
    return (
      <div className={`ncss-textarea-container ${className}`}>
        <Label className="ncss-label pr3-sm u-bold">
          <div>{label}</div>
        </Label>
        <textarea
          className={props.className || defaultTextAreaClass}
          {...props}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </div>
    );
  }
  if (type === 'number') {
    return (
      <input
        type="number"
        className={props.className || defaultTextNumberClass}
        {...props}
        value={value}
        onChange={e => onChange(e.target.value)}
        max="99"
      />
    );
  }

  return (
    <input
      type={type}
      className={className || defaultInputClass}
      {...props}
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      disabled={disabled ? "disabled" : null}
    />
  );
};

const renderTextInput = ({ input, type, label, containerClass, placeholder, disabled, meta: { touched, error, warning } }) => (
  <div className={`${containerClass && containerClass}`}>
    <Input
      {...input}
      name={input.name}
      label={label}
      value={input.value}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onChange={input.onChange}
    />
    {touched &&
      ((error && <span className="error-text">{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
);
export default renderTextInput;
