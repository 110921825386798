// /**
//  * Generate a 10-digit String for Random Number (Format: R0000000012)
//  * @param {Any} n Input digit
//  * @param {Number} width Width of the string
//  * @param {Number} z Input digits
//  * @param {Any} prefix Input string
//  * @returns {string} Random number like R1234567890
//  * @exports padding
//  */
export function padding(n, width, z, prefix){
    const str = n.toString();
  
    return `${prefix + (str.length >= width ? str : new Array(width - str.length + 1).join(z) + str)}`;
  };

  //   /**
//    * Convert trf id to padded RSL number
//    * @param id
//    * @returns {String} padded RSL number
//    */
  export function convertToRSLNumber (id){
    const numberLength = 7;
    const zeroFill = 0;
    const prefix = 'R-';
    return padding(id, numberLength, zeroFill, prefix);
  };
  
  //**
//    * Check if the input is a number
//    * @param {Any} num Input number
//    * @returns {boolean} True when the input is a number, otherwise False
//    * @exports validateNumber
//    */
  export function validateNumber (num){
    return /^\d+$/.test(num);
  };
 