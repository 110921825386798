import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, delay } from 'redux-saga/effects';
import keys from 'lodash/keys';
import pick from 'lodash/pick';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const options = {
        supplierId: action.data.supplierId,
        facilityId: action.data.facilityId
      };
      const path = {
        history: null
      };
      const routes = pick(action.data, keys(path));

      const res = yield call(getRequest, apiConfig.locationSearch.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if(!statusErrorMessage){  
        const response = yield res.json();
        // display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.errorCode === 0) {
          yield put(createServiceAction(serviceType, 'success')(response));
        } else {
          yield delay(5000);
          yield put(createServiceAction(serviceType, 'error')());
          action.data.type === 'editTRF' && routes.history.push(`/`);
        }
      } else {
        yield delay(5000);
        yield put(createServiceAction(serviceType, 'error')());
        action.data.type === 'editTRF' && routes.history.push(`/`);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* locationSearchSagas() {
  const serviceType = 'locationSearch';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default locationSearchSagas;
