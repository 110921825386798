const config = [
  {
    colId: 'tpException',
    headerName: 'Exception Status',
    field: 'tpException',
    suppressMenu: true,
    suppressFilter: true,
    suppressMovable: true,
    suppressSorting: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    minWidth:170,
    maxWidth:190
  },
  {
    colId: 'name',
    headerName: 'Name',
    field: 'name',
    minWidth:150,
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    }
  },
  {
    colId: 'supplierId',
    headerName: 'Supplier Id',
    field: 'supplierId',
    minWidth:150,
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    }
  },
  {
    colId: 'supplierFacilityName',
    headerName: 'Facility Name',
    field: 'supplierName',
    minWidth:150,
    tooltipField: 'facilityName',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'companyType',
    headerName: 'Company Type',
    field: 'companyType',
    minWidth:150,
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    }
  },
  {
    colId: 'email',
    headerName: 'E-Mail',
    field: 'email',
    minWidth:150,
    tooltipField: 'email',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'userType',
    headerName: 'User Type',
    field: 'userType',
    minWidth:100,
    tooltipField: 'email',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'contact',
    headerName: 'Contact',
    field: 'contact',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'facilityName',
    headerName: 'Facility Name',
    field: 'facilityName',
    minWidth:150,
    tooltipField: 'facilityName',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'city',
    headerName: 'City',
    field: 'city',
    minWidth:180,
    tooltipField: 'city',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'country',
    headerName: 'Country',
    field: 'country',
    minWidth:180,
    tooltipField: 'country',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'OSHId',
    headerName: 'OS ID',
    field: 'OSHId',
    minWidth:180,
    tooltipField: 'OSHId',
    suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    headerName: "Edit User",
    field: "editUser",
    colId: "editUser",
    suppressFilter: true,
    suppressMenu: true,
    pinned: 'right',
    suppressSizeToFit: true,
    width: 150,
    suppressMovable: true,
    cellRenderer: params => {
      if((params.data.userType !== 'Rsl')&& (params.data.userType !== 'Nlo')){
      return `<div class="test_button"><i class="fas fa-edit edit-user-button-adjust"></i> Edit </div>`; 
      }    
    }
  },
  {
    headerName: "Add/Edit Supplier Data",
    field: "editSupplier",
    colId: "editSupplier",
    suppressFilter: true,
    suppressMenu: true,
    pinned: 'right',
    suppressSizeToFit: true,
    width: 180,
    suppressMovable: true,
    cellRenderer: params => {
      
      return `<div class="test_button"><i class="fas fa-edit edit-user-button-adjust"></i> Edit Supplier Location </div>`; 
         
    }
  },
  // {
  //   headerName: "",
  //   field: "addLocation",
  //   colId: "addLocation",
  //   suppressFilter: true,
  //   suppressMenu: true,
  //   pinned: 'right',
  //   suppressSizeToFit: true,
  //   width: 150,
  //   suppressMovable: true,
  //   cellRenderer: params => {
      
  //     return `<div class="test_button"><i class="fas fa-plus-circle edit-user-button-adjust"></i> Add Location </div>`; 
         
  //   }
  // },
  {
    headerName: "Active/De-Activate",
    field: "activeStatus",
    colId: "activeStatus",
    pinned: 'right',
    suppressSizeToFit: true,
    width: 150,
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressFilter: true,
    suppressMenu: true,
    tooltipField: 'activeStatus',
    suppressMovable: true,
    cellRenderer: params => {
    //   return `<input type='checkbox' ${params.node.data.favExists ? 'checked' : ''} />`;
    return `<label class="switch">
    <input type="checkbox" ${params.node.data.isActive ? 'checked' : ''} >
    <span class="slider round"></span>
  </label>`
    }
  },
  {
    headerName: "Active Status",
    field: "supplierActiveStatus",
    colId: "supplierActiveStatus",
    pinned: 'right',
    suppressSizeToFit: true,
    width: 150,
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressFilter: true,
    suppressMenu: true,
    suppressMovable: true,
    cellRenderer: params => {
    //   return `<input type='checkbox' ${params.node.data.favExists ? 'checked' : ''} />`;
    return `<label class="switch">
    <input type="checkbox" ${params.node.data.isActive ? 'checked' : ''} >
    <span class="slider round"></span>
  </label>`
    }
  },
  {
    colId: 'tp2Exception',
    headerName: 'tp2Exception',
    field: 'tp2Exception',
  },
  {
    colId: 'id',
    headerName: 'UserId',
    field: 'id',
  }
];
export default config;
