import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
    try {
        yield put(createServiceAction(serviceType, 'loading')());
        if (action.data) {
            const options = {
                video: action.data
            };
            const infoPopDuration = 1000;
            const res = yield call(postRequest, apiConfig.logVideo.endPointUrl, options);
            const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
            if (!statusErrorMessage) {
                const response = yield res.json();
                // Display server error message
                const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
                if (!serverErrorMessage && response.errorCode === 0) {
                    yield put(createServiceAction(serviceType, 'success')(response));
                    //const message = `${response.message}`;
                    // Display success message
                    //yield call(displayMessage, 'success', message, 5000);

                }
            }
        } else {
            yield put(createServiceAction(serviceType, 'success')({}));
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));
    }
}

function* logVideoSagas() {
    const serviceType = 'logVideo';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default logVideoSagas;
