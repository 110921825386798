import React from 'react';
import { Container,Link, H1, H5, Collapsible
} from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { connect } from 'react-redux';
import Label from '../../components/ncss/Label.js';
import { selectEmail, selectFullName, isSupplierUser, isFactoryUser, selectUserDetails } from '../../reducers/authReducer.js';
import Litmos from './Litmos.js';
import OSHActionCenter from './OSHActionCenter.js';
const style = {
    fontWeight: '800',
    fontSize: '1.8rem',
    lineHheight: '1.5',
    color: '#0078B9'
  };
export class ActionCenter extends React.Component {
    
    validateuser= (props) =>{
        const {isFactoryUser,isSupplierUser,userDetails} = this.props
        if((isSupplierUser || isFactoryUser) && !userDetails.userInactive){
            return true
        }
        return false
    }

    render() {
        const { email, username, userDetails,isSupplierUser, isFactoryUser } = this.props;
        
        const setEnableDashboard =() =>{
            const {enableDashboard} =this.props;
            enableDashboard(true)
        }
        const accessStatus = userDetails.OSHStatus === "completed" && userDetails.LitmosStatus === "completed" ?
         "Access Granted" :  userDetails.userInactive ?"Not Yet Granted":"Access Granted"   
          return(
            isSupplierUser || isFactoryUser?
            <form className="form">
            <div className="userpage">
              <div className="page-actioncenter">
                <div className="ncss-col-sm-12  display-flex">
                  <div className="ncss-col-sm-3 ">
                  <Container section extraClassName="flex-container start page-header">
                      <H1 className="ncss-brand u-uppercase flex-fill">
                      User Profile <i className="fas fa-address-card" />
                      </H1>
                    </Container>
                    <div className='bg-white  border-light-grey'>
                      {/* <H1 className="u-bold u-uppercase flex-fill actionUser">
                        User Profile <i className="fas fa-address-card" />
                      </H1> */}
                      <div className="ncss-col-sm-12 mt4-sm ml2-sm">
                        <Label className="ncss-label pr3-sm u-bold">USER NAME</Label><span>:</span>
                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc">{username}</Label>
                      </div>
                      <div className="ncss-col-sm-12 ml2-sm">
                        <Label className="ncss-label pr3-sm u-bold">EMAIL</Label><span>:</span>
                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc">{email}</Label>
                      </div>
                      <div className="ncss-col-sm-12 ml2-sm">
                        <Label className="ncss-label pr3-sm u-bold">USER TESTING ACCESS</Label><span>:</span>
                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc">{accessStatus} <span style={{background:"navajowhite",display:"inline-block"}}>{accessStatus === "Access Granted" && userDetails.userInactive === false
                         &&  (userDetails.OSHStatus !== "completed" || userDetails.LitmosStatus !== "completed") &&"(Failure to complete required actions may result in revoked Testing Access. Please complete your Required Actions as soon as possible)"}</span></Label>
                      </div>
                      <div className="ncss-col-sm-12 ml2-sm">
                        <Label className="ncss-label pr3-sm u-bold">USER TYPE</Label><span>:</span>
                        <Label className="ncss-input pt2-sm pr4-sm pb2-sm pl2-sm fontFAc">{userDetails.group}</Label>
                      </div>
                      <div className="ncss-col-sm-12 mt2-sm commentStyle dashedBorder" >
                        <h6 className='pb2-sm mt2-sm ml2-sm'>Your email is considered your username. Use this email address to complete your onboarding requirements.</h6>
                        <h6 className='pb2-sm ml2-sm'><strong> Note: </strong> your access permissions will default to Supplier access (single facility).
                          If you require Factory access (multi-facility user access), please contact <strong>RSLSupport@nike.com </strong>.
    
                        </h6>
                        <h6 className='pb2-sm ml2-sm'>Note, you will be required to provide OS ID’s for each facility you request.</h6>
                      </div>
                    </div>
                  </div>
                  <div className="ncss-col-sm-9">
                    <Container section extraClassName="flex-container start page-header">
                      <H1 className="ncss-brand u-uppercase flex-fill">
                        Action Center <i className="user-page fas fa-tasks" />
                      </H1>
                      {this.validateuser() &&
                        <div className='ncss-col-sm-12' onClick={setEnableDashboard}>
                          <Link to="/" className="pt1-sm prl6-sm pb1-sm d-sm-b " >
                            <H5>
                              <span className="u-bold support-link text-color-accent" >Skip To Dashboard</span>
                            </H5>
                          </Link>
                        </div>
                      }
                    </Container>
                    <Collapsible
                      trigger={
                        <div className="ncss-col-sm-12 collapse-p0">
                          <div className="ncss-col-sm-10 collapse-p0">Retrieve your Open Supply Hub Identifier (OS ID)</div>
                          <div className="ncss-col-sm-1 collapse-p0 ">
                            {userDetails.OSHStatus === "completed" ? <i className="fas fa-check-circle fa-fw checkAC" /> : <i class="fas fa-exclamation-circle exclamationAc"></i>}
                          </div>
                          <div className="ncss-col-sm-1 ">
                            <i className="fas fa-caret-down fa-pull-right" />
                          </div>
                        </div>
                      }
                      triggerWhenOpen={
                        <div className="ncss-col-sm-12 collapse-p0">
                          <div className="ncss-col-sm-11 collapse-p0">Retrieve your Open Supply Hub Identifier (OS ID)</div>
                          <div className="ncss-col-sm-1">
                            <i className="fas fa-caret-up fa-pull-right" />
                          </div>
                        </div>
                      }
                      triggerStyle={style}
                      triggerClassName="collapsible"
                    >
                      <OSHActionCenter />
                    </Collapsible>
    
                    <Collapsible
                      trigger={
                        <div className="ncss-col-sm-12 collapse-p0">
                          <div className="ncss-col-sm-10 collapse-p0">Complete/renew your Training Certificate</div>
                          <div className="ncss-col-sm-1 collapse-p0">
                          {userDetails.LitmosStatus === "completed" ? <i className="fas fa-check-circle fa-fw checkAC" /> : <i class="fas fa-exclamation-circle exclamationAc"></i>}
                          </div>
                          <div className="ncss-col-sm-1 ">
                            <i className="fas fa-caret-down fa-pull-right" />
                          </div>
                        </div>
                      }
                      triggerWhenOpen={
                        <div className="ncss-col-sm-12 collapse-p0">
                          <div className="ncss-col-sm-11 collapse-p0">Complete/renew your Training Certificate</div>
                          <div className="ncss-col-sm-1">
                            <i className="fas fa-caret-up fa-pull-right" />
                          </div>
                        </div>
                      }
                      triggerStyle={style}
                      triggerClassName="collapsible"
                    >
                      <Litmos />
                    </Collapsible>
                  </div>
                </div>
              </div>
            </div>
          </form>
            :
            <Container section className="user"> 
                <H1>
                Access Denied. Please contact RSLSupport@nike.com
            </H1>
           </Container>
        )
    }
}
const mapStateToProps = state => ({
   // locationInfo: getFormValues('location')(state),
    email: selectEmail(state),
    username: selectFullName(state),
    isSupplierUser: isSupplierUser(state),
    isFactoryUser: isFactoryUser(state),
    userDetails: selectUserDetails(state)
});
const mapDispatchToProps = dispatch => ({
    createRequestAccess: (data) => {
        dispatch(createServiceAction('verifyOSHId', 'requested')(data));
    },
    enableDashboard :(data)=>{
        dispatch(createServiceAction('enableDashboard', 'requested')(data));
    }


});

export default connect(mapStateToProps, mapDispatchToProps)(ActionCenter);
