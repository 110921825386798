import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import CustomDateComponent from "./CustomDateFilter/CustomDateFilter.js";
let data = [], inRangeData = [];

class Grid extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      searchParams: true,
      rowSelection: "multiple",
      rowModelType: "infinite",
      paginationPageSize: 100,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 10,
      maxBlocksInCache: 10,
      components: {
        loadingRenderer: function (params) {
          if (params.value !== undefined) {
            return params.value;
          } else {
            return '<img src="https://raw.githubusercontent.com/ag-grid/ag-grid-docs/master/src/images/loading.gif">';
          }
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressSideButtons: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
              suppressColumns: ['rsl']
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressSideButtons: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true
            }
          },
        ],
        defaultToolPanel: "columns"
      },
      frameworkComponents: { agDateInput: CustomDateComponent }
    };
  }


  onGridReady = params => {
    const { setParams, initgridColumns } = this.props;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
    if (setParams) {
      setParams(params);
    }
    if (initgridColumns && initgridColumns.length > 0) {
      params.columnApi.getAllColumns().map(item => {
        const visible = initgridColumns.indexOf(item.colId) > -1 ? true : false;
        return params.columnApi.setColumnVisible(item.colId, visible);
      });
    }
  };
  onGridSizeChanged = params => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };



  onFilterChanged = params => {
    const { filterKeepAlive, searchParams } = this.props;

    if (filterKeepAlive && searchParams !== '') {
      let filterModel = this.gridApi.getFilterModel()
      let filterKeys = Object.keys(filterModel)
      const uniquesearchParams = Array.from(new Set(searchParams.map(a => a.key)))
        .map(key => { return searchParams.find(a => a.key === key) })
      let commonKeyArray = uniquesearchParams.filter(element => filterKeys.includes(element.key));
      if (commonKeyArray.length !== filterKeys.length) {
        filterKeys && filterKeys.map(key => {
          let temp = filterModel[key]
          if (temp.filterType === 'date' && temp.type === 'inRange') {
            inRangeData = [];
            if (temp.dateFrom && temp.dateFrom !== "NaN-NaN-NaN") {
              let dateTo = temp.dateTo ? temp.dateTo.split('-') : null;
              let dateFrom = temp.dateFrom.split('-');
              let convertedFromDate = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[0]
              let convertedToDate = temp.dateTo ? dateTo[2] + "-" + dateTo[1] + "-" + dateTo[0] : null;
              inRangeData.push({ key: key, type: temp.type, value: dateTo ? convertedFromDate + ',' + convertedToDate : convertedFromDate + ',' + convertedFromDate })
            }
            //getfilterValue(inRangeData.concat(data))
           // window.sessionStorage.setItem("filterParams", JSON.stringify(data))

          }
          return inRangeData
        }
        )
      }


    }

  }

  onFilterModified = params => {
    const { filterKeepAlive } = this.props;
    let filterModel = this.gridApi.getFilterModel()
    let filterColumns = Object.keys(filterModel)
    if (filterKeepAlive && filterColumns) {
      let filterParams = params.api.filterManager.allFilters
      data = []
      let filterKeys = Object.keys(filterParams)

      filterKeys && filterKeys.map(key => {
        if (filterParams[key].filterPromise.resolution) {
          let temp = params.api.getFilterInstance(key).getModel();
          if (((temp && temp.filterType === 'text') || !temp) && params.api.getFilterInstance(key).eValue1) {
            temp = {
              key: key,
              filterType: params.api.getFilterInstance(key).eValue1.type,
              filter: params.api.getFilterInstance(key).eValue1.value
            };
          }

          if (key && temp) {
            if (temp.filterType !== 'set' && temp.filterType === 'text') {
              data.push({ key: key, type: 'contains', value: temp.filter })
            }
            else if (temp.filterType !== 'set' && temp.filterType === 'date') {
              if (temp.dateFrom) {
                let dateTo = temp.dateTo ? temp.dateTo.split('-') : null;
                let dateFrom = temp.dateFrom.split('-');
                let convertedFromDate = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[0]
                let convertedToDate = temp.dateTo ? dateTo[2] + "-" + dateTo[1] + "-" + dateTo[0] : null;
                data.push({ key: key, type: temp.type, value: dateTo ? convertedFromDate + ',' + convertedToDate : convertedFromDate + ',' + convertedFromDate })
              }
            }
          }
          if (filterParams[key].filterPromise.resolution && filterParams[key].filterPromise.resolution.valueModel) {
            data.push({ key: key, type: 'set', value: Object.keys(filterParams[key].filterPromise.resolution.valueModel.selectedValuesMap) })
          }
        }
        if (filterParams[key].filterPromise.resolution && filterParams[key].filterPromise.resolution.valueModel) {
          data.push({ key: key, type: 'set', value: Object.keys(filterParams[key].filterPromise.resolution.valueModel.selectedValuesMap) })

        }

        return data;
      })
      if (!filterKeys.includes('labReceivedDate')) {
        //getfilterValue(inRangeData.concat(data));
        window.sessionStorage.setItem("filterParams", JSON.stringify(data))
      }
      if (filterKeys.includes('labReceivedDate')) {
        window.setTimeout(function () {
          let temp = params.api.getFilterInstance("labReceivedDate").getModel();
          if (temp && temp.filterType !== 'set' && temp.filterType === 'date') {
            if (temp.dateFrom && temp.dateFrom !== "NaN-NaN-NaN") {
              let dateTo = temp.dateTo ? temp.dateTo.split('-') : null;
              let dateFrom = temp.dateFrom.split('-');
              let convertedFromDate = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[0]
              let convertedToDate = temp.dateTo ? dateTo[2] + "-" + dateTo[1] + "-" + dateTo[0] : null;
              data = data.filter(element => element.key !== "labReceivedDate");
              data.push({ key: "labReceivedDate", type: temp.type, value: dateTo ? convertedFromDate + ',' + convertedToDate : convertedFromDate + ',' + convertedFromDate })
              // getfilterValue(inRangeData.concat(data));

            }
            window.sessionStorage.setItem("filterParams", JSON.stringify(data));
          }
          else {
            data = data.filter(element => element.key !== "labReceivedDate");
            // getfilterValue(inRangeData.concat(data));
            window.sessionStorage.setItem("filterParams", JSON.stringify(data))
          }
        }, 500);
      }


    }
  }

  onRowDataChanged = params => {
    const { searchParams, filterKeepAlive } = this.props;

    if (filterKeepAlive && searchParams) {
      searchParams && Array.isArray(searchParams) && searchParams.map((info, indx) => {
        if (params && params.api && params.api.getFilterInstance(info.key)) {
          const type = info.type;
          if (info.key !== 'labReceivedDate' && info.type !== null && info.value) {
            params.api.getFilterInstance(info.key).setModel(
              {
                type: info.type,
                values: type === 'set' ? info.value : null,
                filter: type === 'set' ? null : info.value
              }
            );
          }
          else if (info.key === 'labReceivedDate' && info.type !== null) {
            const dateFromTo = info.value ? info.value.split(',') : [];
            const dateStringFrom = dateFromTo.length > 0 ? dateFromTo[0].split('-') : '';
            const dateStringTo = dateFromTo.length > 0 ? dateFromTo[1].split('-') : '';
            let convertedFromDate = dateStringFrom[2] + "-" + dateStringFrom[1] + "-" + dateStringFrom[0]
            let convertedToDate = dateStringTo[2] + "-" + dateStringTo[1] + "-" + dateStringTo[0]
            params.api.getFilterInstance(info.key).setModel(
              {
                type: info.type,
                //dateFrom:new Date(dateFromTo[0]),
                dateFrom: dateFromTo.length > 0 ? convertedFromDate : null,
                dateTo: info.type === 'inRange' && dateFromTo.length > 0 ? convertedToDate : null
              }
            );
          }
          // else if (info.key === 'action') {
          //   let arrayOfValues = Object.keys(filterParams[info.key].filterPromise.resolution.model.selectedValuesMap)
          //   if (info.type === 'set') {
          //     params.api.getFilterInstance(info.key).setModel(
          //       {
          //         type: 'set',
          //         values: arrayOfValues ? arrayOfValues : info.value,
          //       }
          //     );
          //   }
          // }
          params.api.onFilterChanged();
        }

        return null;
      }
      )
    }
  }

  onDisplayedColumnsChanged = params => {
    const { setDisplayColumns } = this.props;
    const columns = params.columnApi.getAllDisplayedColumns().map(item => {
      return item.colId;
    });
    params.api.sizeColumnsToFit();
    setDisplayColumns && setDisplayColumns(columns);
  }
  render() {
    const {
      columnDefs,
      rowData,
      showFilter,
      adjustHeight,
      uploadHeight,
      headerHeight,
      onRowClick,
      onCellValueChanged,
      onCellClicked,
      onPaginationChanged,
      edit,
      suppressEdit,
      customNoRowTemplete,
      overlayLoadingTemplate,
      rowSelection,
      rowMultiSelectWithClick,
      groupSelectsChildren,
      setMaterialHeight,
      onSelectionChanged,
      filterKeepAlive,
      autoGroupColumnDef
    } = this.props;
    const { sideBar, frameworkComponents } = this.state;
    return (
      uploadHeight,
      <div className={`ag-theme-balham ${uploadHeight ? 'upload-height' : (!adjustHeight && !setMaterialHeight ? 'custom-height' : (setMaterialHeight ? 'custom-height-material p1-sm' : 'custom-height-lg'))}`} >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          enableSorting
          enableFilter
          animateRows
          floatingFilter={showFilter}
          frameworkComponents={frameworkComponents}
          pagination={true}
          //paginationAutoPageSize={!setMaterialHeight ? true : false}
          paginationPageSize={100}
          singleClickEdit={edit}
          suppressClickEdit={suppressEdit}
          stopEditingWhenGridLosesFocus
          onGridReady={this.onGridReady}
          onGridSizeChanged={this.onGridSizeChanged}
          onFilterChanged={this.onFilterChanged}
          onFilterModified={this.onFilterModified}
          onRowDataChanged={this.onRowDataChanged}
          getRowData={this.getRowData}
          rowSelection={rowSelection || "single"}
          {...(groupSelectsChildren && { groupSelectsChildren: true })}
          {...(rowMultiSelectWithClick && { rowMultiSelectWithClick: true })}
          {...(onSelectionChanged && { onSelectionChanged: onSelectionChanged })}
          onRowClicked={onRowClick}
          onPaginationChanged={onPaginationChanged}
          onCellClicked={onCellClicked}
          onCellValueChanged={onCellValueChanged}
          headerHeight={headerHeight}
          overlayLoadingTemplate={overlayLoadingTemplate}
          overlayNoRowsTemplate={customNoRowTemplete}
          sideBar={filterKeepAlive ? sideBar : false}
          onDisplayedColumnsChanged={this.onDisplayedColumnsChanged}
          onToolPanelVisibleChanged={this.onToolPanelVisibleChanged}
          autoGroupColumnDef={autoGroupColumnDef}
          rowBuffer={101}
        />
      </div>
    );
  }
}
Grid.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  showFilter: PropTypes.bool,
  edit: PropTypes.bool,
  suppressEdit: PropTypes.bool,
  adjustHeight: PropTypes.bool,
  uploadHeight: PropTypes.bool,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  onCellValueChanged: PropTypes.func,
  setParams: PropTypes.func,
  customNoRowTemplete: PropTypes.object,
  overlayLoadingTemplate: PropTypes.object,
  setMaterialHeight: PropTypes.bool
};
Grid.defaultProps = {
  showFilter: true,
  headerHeight: 32,
  filterKeepAlive: false,
  infiniteScroll: false,
  autoGroupColumnDef: null
};
export default connect()(Grid);
