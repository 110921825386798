import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactFileReader from 'react-file-reader';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from '../../../components/index.js';
import labConstants from '../Lab.constants';
import {ExcelRenderer} from 'react-excel-renderer';

class Upload extends Component {
  handleFiles = files => {
    if (files.fileList[0].name.indexOf('xlsx') !== -1) {
      let fileObj = files.fileList[0];
      const { onUpdate } = this.props;
      ExcelRenderer(fileObj, (err,resp) => {
        if (!err){
        const lines = resp.rows;
        let keys = lines[0];
        const jsonArr = [];
        let json = {};
        if (
          keys.length === 7 &&
          (keys[0] === 'Chemical Class' || keys[0] === 'chemicalClass') &&
          (keys[1] === 'Chemical Name' || keys[1] === 'chemicalName') &&
          (keys[2] === 'CAS Number' || keys[2] === 'CAS') &&
          (keys[3] === 'Required Laboratory Reporting Limit' || keys[3] === 'labLimit') &&
          (keys[4] === 'Nike Limit' || keys[4] === 'nikeLimit') &&
          (keys[5] === 'Test Result' || keys[5] === 'testResultValue') &&
          (keys[6] === 'Unit' || keys[6] === 'unit')
        ) {
          keys = ['chemicalClass', 'chemicalName', 'CAS', 'labLimit', 'nikeLimit', 'testResultValue', 'unit'];
          for (let lineCount = 0; lineCount < lines.length; lineCount++) {
            const lineData = lines[lineCount];
            if (lineCount !== 0) {
              json = {};
              const columns = [0, 1, 2, 3, 4, 5, 6];
              for (let j = 0; j < keys.length; j++) {
                json[keys[j]] = lineData[columns[j]];
              }
              jsonArr.push(json);
            }
          }
        }
        onUpdate(jsonArr);
        }        
      });      
    } else {
      toast.error(labConstants.invalidFile_msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  render() {
    return (
      <div>
        <ReactFileReader fileTypes={['.xlsx']} base64 multipleFiles={false} handleFiles={this.handleFiles}>
          <span className="download-section upload">
            <i className="fas fa-upload" />
            <Button className="text-color-accent download-button">UPLOAD</Button>
          </span>
        </ReactFileReader>
      </div>
    );
  }
}
Upload.propTypes = {
  onUpdate: PropTypes.func
};

export default connect()(Upload);
