import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { OktaAuth,toRelativeUrl } from '@okta/okta-auth-js';

import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import PropTypes from 'prop-types';

import appHistory from '../util/history.js';
import { oidc } from '../configs/oAuthConfig.js';
import Login from '../views/auth/login.js'
import { history } from '../util/index.js';
import { ConnectedRouter } from 'react-router-redux';
// import Login from '../views/auth/login.js'

function customAuthHandler(authService) {
  // Redirect to the /login page that has a CustomLoginComponent
  // This example is specific to React-Router
  history.push('/');
}


const Routes = ({ history = appHistory }) => {
  const oktaAuth = new OktaAuth(oidc);
  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  try {
    if (localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).idToken && JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims && JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.exp && (new Date() > new Date(JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.exp * 1000))){
      sessionStorage.setItem("token-satus", "expired");
      localStorage.removeItem('okta-cache-storage')
      localStorage.removeItem('okta-token-storage')
    }
    if (window.performance) {
      if (performance.navigation.type === 1) {
        history = appHistory
      }
      else {
        history = appHistory
      }
    }

    return (
    
        <Router history={history}>
       
         <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
           
              <Route path="/implicit/callback" component={LoginCallback} />
              <SecureRoute path="/" component={Login} />
              
            </Security> 
      
        </Router>
     
    );
  }
  catch (e) {
    return ( 
     <Router>
      <ConnectedRouter history={history}>
          <Security oktaAuth={oktaAuth}  onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            <Route path="/implicit/callback" component={LoginCallback} />
            <SecureRoute path="/" component={Login} />
          </Security> 
      </ConnectedRouter>
    </Router>

    );
  }
};

Routes.propTypes = {
  component: PropTypes.func,
  history: PropTypes.func
};
export {Routes}
export default Routes;