
import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';
import moment from 'moment';

function* listener(serviceType, action) {
    try {

        const data = action.data;
        const infoPopDuration = 1000;
        const todayDate = moment.utc(new Date()).format('YYYY-MM-DD');

        // yield call(displayMessage, 'info', 'Submitting your data..', infoPopDuration);
        yield put(createServiceAction(serviceType, 'loading')());
        const res = yield call(postRequest, apiConfig.readNotification.endPointUrl, data);
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
        if (!statusErrorMessage) {
            const response = yield res.json();
            // Display server error message
            const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
            if (!serverErrorMessage && response.errorCode === 0) {
                yield put(createServiceAction(serviceType, 'success')(response));
                yield put(createServiceAction('listNotificationAfterRead', 'requested')(todayDate));
                //const message = `${response.message}`;
                // Display success message
                //yield call(displayMessage, 'success', message, 5000);
            }
        }
    } catch (e) {
        yield put(createServiceAction(serviceType, 'error')(e));
    }
}

function* readNotificationSaga() {
    const serviceType = 'readNotification';
    yield takeEvery(createServiceAction(serviceType, 'submitted').type, listener, serviceType);
}

export default readNotificationSaga;
