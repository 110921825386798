import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import TrfReview from '../../trf_review/TrfReview';
import { H4, H5, Button } from '../../../components/index.js';
import { createServiceAction } from '../../../util/actionHelper.js';
import PageTemplate from '../../test_details/pageTemplate';
import { PDFExport } from '@progress/kendo-react-pdf';
import TrfPrintable from '../../trf_review/Trf/TrfPrintable';

const headerPageTemplate = props => (

  <PageTemplate  header="NIKE TEST REQUEST FORM (TRF)" isTRFReport="true" {...props} />

);

class ReviewDownload extends Component {
  componentDidMount() {
    const { location } = this.props;
    const { getTrfData } = this.props;
    getTrfData(location.state && location.state.trfId);
  }
  exportTRFPDF = () => {
    this.testTRFPdf.save();
  }

  render() {
    const { trfResult, location } = this.props;

    return (
      <form className="trf-review page-align-items">
        <div className="p2-sm">
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">REVIEW & DOWNLOAD</H4>
          <H5 className="u-bold description"> Please review & download the values you Submitted</H5>
        </div>
        {typeof trfResult !== 'undefined' ? (
          <div id="divToPrint" className="mt4 print-area">
            <div className="pdf-header">
              <div className="ncss-col-sm-4">
                <h3 className="fs20-sm lh20-sm fs24-md lh24-md fs28-lg lh28-lg inline-display relative-position header-brand-pdf">
                  <span className="ncss-brand text-color-accent u-bold">RSL</span>
                </h3>
              </div>
              <div className="ncss-col-sm-2 align-center">
                <i className="g72-swoosh nav-brand fs22-sm menu-logo-nike" />
              </div>
              <div className="ncss-col-sm-1 align-center">
                <i className="g72-jordan nav-brand fs22-sm menu-logo" />
              </div>
              <div className="ncss-col-sm-4 align-center pl0-sm">
                <i className="g72-converse nav-brand fs22-sm menu-logo" />
              </div>
            </div>
            <div className="trf-content">
              {trfResult.data ? (
                <TrfReview trfData={trfResult.data} isDownload />
              ) : (
                <div>Technical error has occured.</div>
              )}
              <div className="supplier-info-container">
                <h5 className="fs16-sm lh16-sm text-color-accent u-bold page-title-has-back sub-heading">
                  BILLING INFORMATION
                </h5>
                <div>
                  <span className="ncss-col-sm-3 description u-bold p2-sm "> Name: </span>
                  <span className="ncss-col-sm-7 ncss-input-container free-text" />
                </div>
                <div>
                  <span className="ncss-col-sm-3 description u-bold p2-sm"> Street Address: </span>
                  <span className="ncss-col-sm-7 ncss-input-container free-text" />
                </div>
                <div>
                  <span className="ncss-col-sm-3 description u-bold p2-sm label-supplier-info"> City: </span>
                  <span className="ncss-col-sm-3 ncss-input-container free-text" />
                  <span className="ncss-col-sm-3 description u-bold p2-sm label-supplier-info space">
                    {' '}
                    Postal Code:{' '}
                  </span>
                  <span className="ncss-col-sm-3 ncss-input-container free-text" />
                </div>
                <div>
                  <span className="ncss-col-sm-3 description u-bold p2-sm label-supplier-info ">
                    {' '}
                    Region/State/Province:{' '}
                  </span>
                  <span className="ncss-col-sm-3 ncss-input-container free-text" />
                  <span className="ncss-col-sm-3 description u-bold p2-sm label-supplier-info space">
                    {' '}
                    Country:{' '}
                  </span>
                  <span className="ncss-col-sm-3 ncss-input-container free-text" />
                </div>
              </div>
            </div>
            <div className="disclaimer">
              <h5>IMPORTANT NOTICE:</h5> This Test Request Form (TRF) is intended to be received only by
              persons entitled to receive the confidential information it may contain.  Test Requests for
              product that presumptively could be used 
              by NIKE, Inc. and its subsidiaries are confidential and legally privileged;  Do not read, copy,
              forward, print or store this TRF or any part of it unless you are an intended recipient of it.
              If you have received this TRF 
              in error, please notify the NIKE RSL team immediately at RSLSupport@nike.com, and permanently
              delete this original and all copies of the TRF.
            </div>            
          </div>
        ) : null}
        <div className="p2-sm align-center download-button-trf">
          <Button onClick={this.exportTRFPDF }>
            Download PDF
          </Button>
        </div>
      {trfResult !== undefined && <div className="testDetailsPDF">
     <span >                                                                                                                                                                                                                                                                                                                                  
        <PDFExport paperSize="A4"
           pageTemplate={headerPageTemplate}
           scale={0.8}
           margin={{ top: 50, left: 20, right: 20, bottom: 50 }}
           multiPage
           forcePageBreak=".page-break"
           fileName={`TRF Report - R${location.state.trfId}`}
           repeatHeaders
           title=""
           subject=""
           keywords=""
           landscape="true"
           keepTogether=".prevent-split"
           ref={(r) => this.testTRFPdf = r}>
           <div className="ncss-col-sm-12">
             <TrfPrintable trfDetails={trfResult.data.trfDetails} showBilling={true} />
           </div>
         </PDFExport>
         </span>  
         </div>
            }                                                                                                                                                                                    
      </form>
    );
  }
}
function mapStateToProps(state) {
  return {
    trfResult: state.trf.trfData
  };
}
const mapDispatchToProps = dispatch => ({
  getTrfData: trfId => {
    dispatch(createServiceAction('trfDetails', 'requested')(trfId));
  }
});
ReviewDownload.propTypes = {
  getTrfData: PropTypes.func,
  trfResult: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      trfId: PropTypes.string.isRequired
    })
  })
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReviewDownload)
);
