import { oAuthConfig } from './configs/oAuthConfig.js';

const config = {
  appName: 'RSL TESTING',
  appFullName: 'RSL TESTING',
  oAuth: {
    client_id: 'nike.gsm.rsltest',
    responseType: 'id_token token',
    scope: 'openid profile email address phone offline_access',
    redirectUri: window.location.origin,
    authorization: oAuthConfig.authorizationUrlProd,
    OktaTokenUrlProd: oAuthConfig.OktaTokenUrlProd
  }
};

export default config;