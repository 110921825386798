import { createAction } from '../util/index.js';

export const toggleAddMaterailModal = createAction(
  'TOGGLE_ADD_MATERIAL_MODAL',
  requestObject => requestObject
);
export const displaySubmitButtonTrf = createAction(
  'DISPLAY_SUBMIT_BUTTON_TRF',
  requestObject => requestObject
);
export const displayClearButtonTrf = createAction('DISPLAY_CLEAR_BUTTON_TRF', requestObject => requestObject);
export const updateMaterialField = createAction('UPDATE_MATERIAL_INFO', requestObject => requestObject);
export const clearFormValueTRF = createAction('CLEAR_FORM_VALUE_TRF', requestObject => requestObject);
export const InitialProductTypeStore = createAction('INITIAL_PRODUCT_TYPE', requestObject => requestObject);
export const currentProductTypeStore = createAction('CURRENT_PRODUCT_TYPE', requestObject => requestObject);
export const onChangeProductType = createAction('ONCHANGE_PRODUCT_TYPE', requestObject => requestObject);
export const onChangeScreeningInkVarient = createAction('ONCHANGE_SCREENING_ID', requestObject => requestObject);
export const getProductIdEdit = createAction('EDIT_PRODUCT_TYPE', requestObject => requestObject);
export const initialCertificationArry = createAction('INITIAL_CERTIFICATION', requestObject => requestObject);
export const recycledContentValue = createAction('RECYCLED_CONTENT_VALUE', requestObject => requestObject);
export const onChangeRetest = createAction('IS_RETEST', requestObject => requestObject);
