import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';

import { put, takeEvery, call } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const options = {
        q: `${action.data}*`
      };
      let res='';
      if(action.data.hasOwnProperty("includeInternal") !==undefined){
         res = yield call(getRequest, apiConfig.supplierSearch.endPointUrl, action.data);
      }else{
       res = yield call(getRequest, apiConfig.supplierSearch.endPointUrl, options);
      }
      if(res.status === 400 || res.status === 504){ 
        const response = yield res.json();
        yield put(createServiceAction(serviceType, 'error')(response));
        }
      else
      {
      const response = yield res.json();
      yield put(createServiceAction(serviceType, 'success')(response));
      }
    } else {
      yield put(createServiceAction(serviceType, 'success')({}));
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* supplierSearchSagas() {
  const serviceType = 'supplierSearch';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default supplierSearchSagas;
