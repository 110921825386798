import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { H5, PageHeader, PageTitle } from '../../../components/index.js';
import VideoModal from './modal/VideoModal.js';
import videoFile from '../../../constants/json/videoList/videoList.json';
import { toggleVideoModal } from '../../../actions/trainingActions.js';



export class Videos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentVideo: videoFile[0]
        };

        this.videoChange = this.videoChange.bind(this)
    }


    videoChange = (video) => {
        this.setState({ currentVideo: video })
    }

    render() {
        const { currentVideo } = this.state;
        const { isVideoModalOpen, toggleVideoModal } = this.props;
        return (
            <div className="userpage ncss-col-sm-12">
                <div className="video-align-items pdf-page-bottom-align">
                    <PageTitle title="TUTORIAL VIDEOS" />
                    <PageHeader title="TUTORIAL VIDEOS">
                        <div className="user-page">
                            <i className="fas fa-video"></i>
                        </div>
                    </PageHeader>
                </div>
                <div className="video-list ncss-col-sm-11 mt2-sm pl0-sm pr0-sm">
                    {videoFile.map((video, indx) => (
                        <div className="ncss-col-sm-4 video-card">
                        <div className={`ml2-sm-video ncss-col-sm-12 mt2-sm pl0-sm pr0-sm mb2-sm pointer-mouse video-player-border`} onClick={() => { this.videoChange(video); toggleVideoModal(true) }}>
                            <img src={video.poster} alt="poster" className="video-player-border" />
                            <div class="video-list-caption">
                                <H5><b>{video.title}</b></H5>
                                <H5><b>{video.description}</b></H5>
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
                <VideoModal
                    isModalOpen={isVideoModalOpen}
                    toggleModal={toggleVideoModal}
                    activeVideo={currentVideo}
                />
            </div>
        );
    }
}

Videos.propTypes = {
    isVideoModalOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
    isVideoModalOpen: state.training.isVideoModalOpen

});

const mapDispatchToProps = dispatch => ({
    toggleVideoModal: isOpen => {
        dispatch(toggleVideoModal(isOpen));
    }

});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Videos);

