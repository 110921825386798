import { createServiceAction } from '../util/actionHelper.js';


const defaultState = {
    licenseeDetails: [],
};

const convertArrayToObject = (array, key) => {

    return array.map(value => ({ [key]: value }))
}
function formatLicenseeList(data) {
    var licensee_List = data.map((data, indx) => ({ id: indx, label: data, value: data }));
    return licensee_List;
}
export default function licensees(state = defaultState, action) {
    switch (action.type) {
        case createServiceAction('addLicenseeSearch', 'requested').type:
            return { ...state, licenseeDetails: null };
        case createServiceAction('addLicenseeSearch', 'success').type:
            return {
                ...state, licenseeDetails: convertArrayToObject(action.data.data.searchRes, 'name')
            };
        case createServiceAction('activeLicensee', 'requested').type:
            return {
                ...state, activeLicensees: []
            }
        case createServiceAction('activeLicensee', 'success').type:
            return {
                ...state, activeLicensees: formatLicenseeList(action.data.data.licensees)
            }
        case createServiceAction('listLicensee', 'requested').type:
            return {
                ...state,
                licenseeList: null,
                totalCount: {},
                currentPage: {},
                nextPage: 1,
                countPerPage: {},
                listLicenseeRemainingPages: {}
            }

        case createServiceAction('listLicensee', 'loading').type:
            return {
                ...state,
                licenseeList: [],
                totalCount: {},
                currentPage: {},
                nextPage: 1,
                countPerPage: {},
                listLicenseeRemainingPages: {}
            }
        case createServiceAction('listLicensee', 'success').type:
            return {
                ...state,
                licenseeList: state.licenseeList.concat(action.data.licenseeList),
                totalCount: action.data.totalCount,
                currentPage: action.data.currentPage,
                nextPage: action.data.currentPage + 1,
                countPerPage: action.data.countPerPage,
                listLicenseeRemainingPages: Math.ceil(parseInt(action.data.totalCount) / action.data.countPerPage) - parseInt(action.data.currentPage)
            }
        default:
            return state;
    }
}


export const getLicenseeList = state => state.licensee.licenseeList;
export const getlistLicenseeRemainingPages = state => state.licensee.listLicenseeRemainingPages;

export const getNextPage = state => state.licensee.nextPage;