import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Collapsible, PageHeader, PageTitle, PdfViewer } from '../../../components/index.js';
import { isSupplierUser, isLabUser, isRslUser, isFactoryUser, isNloUser } from '../../../reducers/authReducer.js';

const style = {
  fontWeight: '800',
  fontSize: '1.8rem',
  lineHheight: '1.5',
  color: '#0078B9'
};
const lanStyle={
  fontWeight: '800',
  fontSize: '1.8rem',
  color: '#0078B9'
}

export class Training extends Component {

  render() {
    const {
      isSupplierUser,
      isRslUser,
      isFactoryUser,
      isLabUser,
      isNloUser
    } = this.props;
    return (
      <div className="userpage">
        <div className="pdf-align-items pdf-page-bottom-align">
          <PageTitle title="How To Guide" />
          <PageHeader title="How To Guide">
            <div className="user-page">
              <i className="fas fa-file-pdf"></i>
            </div>
          </PageHeader>
          {isFactoryUser || isSupplierUser || isRslUser || isNloUser || isLabUser ? (

            <div>
              <Collapsible className="training_color"
                trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-user-cog"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Supplier/Factory How to Guide
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i class="fas fa-user-cog"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Supplier/Factory How to Guide
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">ENGLISH
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                 triggerStyle={lanStyle}
                 triggerClassName='white-background'
                >
                  <div id="english"></div>
                  <PdfViewer fileName="../training/EN_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="english" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">SPANISH
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}
                >
                  <div id="spanish"></div>
                  <PdfViewer fileName="../training/ES_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="spanish" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">INDONESIAN
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}>
                  <div id="INDONESIAN"></div>
                  <PdfViewer fileName="../training/IND_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="INDONESIAN" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">JAPANESE
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}>
                  <div id="japanese"></div>
                  <PdfViewer fileName="../training/JA_RSLTestingApp_SupplierHowtoGuide_Dec2023.pdf" containerId="japanese" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">KOREAN
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}>
                  <div id="korean"></div>
                  <PdfViewer fileName="../training/KOR_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="korean" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">PORTUGUESE (BR)
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}>
                  <div id="PT"></div>
                  <PdfViewer fileName="../training/PT-BR_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="PT" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">VIETNAMESE
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}>
                  <div id="VI"></div>
                  <PdfViewer fileName="../training/VI_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="VI" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">SIMPLIFIED CHINESE
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}>
                  <div id="ZH_CN"></div>
                  <PdfViewer fileName="../training/ZH-CN_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="ZH_CN" />
                </Collapsible>
                <Collapsible className='white-background' trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-language"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">TRADITIONAL CHINESE
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                } 
                triggerStyle={lanStyle}>
                  <div id="ZH_TW"></div>
                  <PdfViewer fileName="../training/ZH-TW_RSLTestingApp_Supplier_HowtoGuide_Dec2023.pdf" containerId="ZH_TW" />
                </Collapsible>
              </Collapsible>
            </div>


          ) : null}
         
          {isLabUser || isRslUser || isNloUser ? (
            <div>
              <Collapsible className="training_color"

                trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-flask"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Lab Users Training Guide
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-flask"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Lab Users Training Guide
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div id="lab"></div>
                { /* Updated a new doc for Lab */}
                <PdfViewer fileName="../training/RSL_Testing_Application_LAB_HowToGuide_Dec2023.pdf" containerId="lab" />
              </Collapsible>
            </div>
          ) : null}
          {isFactoryUser || isSupplierUser || isRslUser? (
            <div>
              <Collapsible className="training_color"

                trigger={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-tasks" /></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Action Center – OSH Guide
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerWhenOpen={
                  <span>
                    <div className="ncss-col-sm-1 collapse-p0 pr0-sm col-width-adjust"> <span className="training_page text-size-new pr0-sm"><i className="fas fa-tasks"></i></span>
                    </div>
                    <div className="ncss-col-sm-11 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0 pl0-sm text-size-new">Action Center – OSH Guide
                      </div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right text-size-new-carat" />
                      </div>
                    </div>
                  </span>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div id="actionCenter"></div>
                <PdfViewer fileName="../training/Action_Center - OSH_Guide.pdf" containerId="actionCenter" />
              </Collapsible>
            </div>
          ) : null}

        </div>
      </div>
    );
  }
}

Training.propTypes = {
  isSupplierUser: PropTypes.bool,
  isFactoryUser: PropTypes.bool,
  isRslUser: PropTypes.bool,
  isLabUser: PropTypes.bool,
  isNloUser: PropTypes.bool
};

const mapStateToProps = state => ({
  isSupplierUser: isSupplierUser(state),
  isFactoryUser: isFactoryUser(state),
  isRslUser: isRslUser(state),
  isLabUser: isLabUser(state),
  isNloUser: isNloUser(state)
});

export default compose(
  connect(
    mapStateToProps,

  )
)(Training);