import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Link, H3, H4 } from '../../components/index.js';
import * as Constants from '../../constants/header.js';
import { navMenuToggle, navMenuclose } from '../../actions/navigationActions.js';
import { clearArchive } from '../../actions/archiveActions.js'
import { selectIsMenuOpen } from '../../reducers/navigationReducer.js';
import { isRslUser, isNloUser } from '../../reducers/authReducer.js';

const navStyle = 'nav pt4-sm pb4-sm prl6-sm u-no-ws';

const isIE = /*@cc_on!@*/false || !!document.documentMode;

const NavLinkItem = ({ to, label, icon, heading, url, requestClearArchive, isRslUser, isNloUser }) =>
  !url ? (
    <li className="text-color-grey">
      <NavLink to={to} activeClassName="text-color-accent" exact>
        {heading != null ? (
          <span>
            <hr className="seperation-line" />
            <H4 className="u-bold text-color-grey pb4-sm ">{heading}</H4>
            {(to === '/rsl-admin' && '/notification') && (isRslUser) ?
              <span className="fs18-sm u-bold pb2-sm pt2-sm">
                <i className={`fas fa-${icon} mr3-sm  menu-icons-nav`} />
                {label}
              </span> : null}
            {(to !== '/rsl-admin') && (to !== '/notification') ?
              <span className="fs18-sm u-bold pb2-sm pt2-sm">
                <i className={`fas fa-${icon} mr3-sm  menu-icons-nav`} />
                {label}
              </span> : null}
             
          </span>
        ) : (
            <span>
              {(to === '/rsl-admin' || '/notification') && (isRslUser) ?
                <span className="fs18-sm u-bold pb2-sm pt2-sm">
                  <i className={`fas fa-${icon} mr3-sm  menu-icons-nav`} />
                  {label} 
                </span> : null}
              {(to !== '/rsl-admin') && (to !== '/notification')  && (!isRslUser) ?
                <span className="fs18-sm u-bold pb2-sm pt2-sm">
                  <i className={`fas fa-${icon} mr3-sm  menu-icons-nav`} />
                  {label}
                </span> :  null}
            </span>
          )}
      </NavLink>
    </li>
  ) : (
    (to !== '/help') ? 
      (<li className="text-color-grey">
      <a href={url} target={to !== '/help' && to !=='userValidation'? '_blank' : null} rel="noopener noreferrer" download>
        <span className="fs18-sm  u-bold pb2-sm pt2-sm">
          <i className={`fas fa-${icon} mr3-sm  menu-icons-nav`} />
          {label}
        </span>
      </a>
    </li>) :
    (<li>
      <span className="fs18-sm  u-bold pb2-sm pt2-sm">
        <i className={`fas fa-${icon} mr3-sm  menu-icons-nav`} />
        {label}  -  <a href='mailto:RSLSupport@nike.com'>RSL</a>  | <a href='mailto:PRSL.Support@nike.com'>PRSL</a></span>
    </li>));
const SlideMenuContainer = ({ isOpen, requestNavMenuclose, requestClearArchive, children, navLinks, isRslUser, isNloUser }) => (
<div className={`menu-container ${isOpen ? 'show-menu' : ''}`}>
    <div className="menu-aside border-right-light-grey bg-white" role="none" onClick={requestNavMenuclose}>
      { // Fix for IE style issue header component not getting hidden 
        !isIE &&
        <Link to="/" className="pt1-sm prl6-sm pb1-sm d-sm-b border-bottom-light-grey menu-header">
          <H3 className="inline-display header-brand">
            <i className="g72-swoosh nav-brand fs22-sm menu-logo" />
            <span className="ncss-brand text-color-accent u-bold">{Constants.APPNAME}</span>
          </H3>
        </Link>
      }
      <nav className={navStyle}>
        <ul>
           {navLinks.map(({ to, label, icon, heading, id, url }) => (
            <NavLinkItem
              key={id}
              to={to}
              url={url}
              heading={heading}
              isRslUser={isRslUser}
              isNloUser={isNloUser}
              label={
                <span className="fs18-sm  u-bold"  onClick={to ==='/archive'?requestClearArchive:null}>
                  <i className={`fas fa-${icon} mr3-sm pb2-sm menu-icons-nav`} />
                  {label}
                </span>
              }
            />
          ))}
        </ul>
      </nav>
    </div>
    <div className="viewport">
      {}
      {children}
    </div>
    <div className="menu-lightbox" role="none" onClick={requestNavMenuclose} />
  </div>
);
SlideMenuContainer.propTypes = {
  isOpen: PropTypes.bool,
  navMenuclose: PropTypes.func,
  navLinks: PropTypes.array
};
NavLinkItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.object,
  icon: PropTypes.string,
  heading: PropTypes.string,
  url: PropTypes.string
};
const mapStateToProps = (state, ownProps) => ({
  isRslUser: isRslUser(state),
  isNloUser: isNloUser(state),
  isOpen: selectIsMenuOpen(state),
  navLinks: ownProps.navLinks || []
});

const mapDispatchToProps = dispatch => ({
  requestnavMenuToggle: () => {
    dispatch(navMenuToggle())
  },
  requestNavMenuclose: () => {
    dispatch(navMenuclose())
  },
  requestClearArchive: () => {
    dispatch(clearArchive(null))
  },

  })
export default withRouter(
  connect(mapStateToProps,
    mapDispatchToProps
    //{ navMenuToggle, navMenuclose,clearArchive }
  )(SlideMenuContainer)
);
