import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { createServiceAction } from '../../../util/actionHelper.js';
import { H4, H5, TextInput, Button, IconButton, DropDown, Spinner, ContentPopup } from '../../../components/index.js';
import Grid from '../../../components/grid/Grid.js';
import supplierGridConfig from '../../../configs/gridConfigs/userPage/supplierGridConfig.js';
import { history } from '../../../util/index.js';
import companyType from '../../../constants/json/dropdowns/companyType.json';
import { clearEditLocationList } from '../../../actions/adminUserActions.js';
import {getData} from 'country-list';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#app-host');



export class AddSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: false,
      activeNewSupplierAdd: true,
      activeEditLocation: false,
      locationId : null, 
      locationName: null,
      isOpenSupplierList: false
    };
    this.newSupplierAdd = this.newSupplierAdd.bind(this);
    this.editLocationButton = this.editLocationButton.bind(this);
 
  }

  componentDidMount() {
    const { clearEditLocationList } = this.props;
    clearEditLocationList();

  }
  componentDidUpdate(prevProps) {
    const { addSupplierInfoStates, requestLocationDetails, locationDetails, change } = this.props;
    if (addSupplierInfoStates && addSupplierInfoStates.locationName && prevProps.addSupplierInfoStates.locationName !== addSupplierInfoStates.locationName) {
      requestLocationDetails(addSupplierInfoStates.locationName);
    }
    if (prevProps.locationDetails && locationDetails !== prevProps.locationDetails) {
      change('address1', locationDetails.address1 ? locationDetails.address1 : '');
      change('address2', locationDetails.address2 ? locationDetails.address2 : '');
      change('country', locationDetails.country ? locationDetails.country : '');
      change('city', locationDetails.city ? locationDetails.city : '');
      this.setState({ locationId: locationDetails.locationId, locationName: locationDetails.locationName })
    }
  }

  filterNewSupplierInfo = (newSupplierInfo) => {
    const resultArray = {}
    const { submitSupplierData, change } = this.props;
    resultArray.supplierName = newSupplierInfo.supplierName;
    resultArray.OSHId = newSupplierInfo.OSHId;
    resultArray.parentCompany = newSupplierInfo.parentCompany;
    resultArray.mLocCode = newSupplierInfo.mLocCode;
    resultArray.companyType = newSupplierInfo.companyType;
    resultArray.locationName = newSupplierInfo.newLocationName;
    resultArray.address1 = newSupplierInfo.newAddress1;
    resultArray.address2 = newSupplierInfo.newAddress2;
    resultArray.city = newSupplierInfo.newCity;
    resultArray.country = newSupplierInfo.newCountry;
    change('newSupplierName', '');
    change('supplierID', '');
    change('companyType', '');
    change('newLocationName', '');
    change('newAddress1', '');
    change('newAddress2', '');
    change('newCountry', '');
    change('newCity', '');
    change('OSHId', '');
    change('parentCompany', '');
    submitSupplierData(resultArray);

  }

  clearForm = () => {
    const { change } = this.props;
    change('supplierName', '');
    change('supplierID', '');
    change('companyType', '');
    change('newLocationName', '');
    change('newAddress1', '');
    change('newAddress2', '');
    change('newCountry', '');
    change('newCity', '');
    change('OSHId', '');
    change('parentCompany', '');
  }

  filterUpdateLocationInfo = (supplierInfo, locationId, locationName) => {
    const resultArray = {}
    const { updateLocationData } = this.props;
    resultArray.locId = locationId;
    resultArray.locName = locationName;
    resultArray.addr1 = supplierInfo.address1;
    resultArray.addr2 = supplierInfo.address2;
    resultArray.city = supplierInfo.city;
    resultArray.country = supplierInfo.country;

    updateLocationData(resultArray);

  }

  newSupplierAdd = (status) => {
    this.setState({ activeNewSupplierAdd: status, activeEditLocation: false });

  }
  editLocationButton = (status) => {
    this.setState({ activeNewSupplierAdd: false, activeEditLocation: status });
  }
  cancelAddSupplier = () => {

    history.push('/');
  };

  openSupplierListModal = () => {
    const { change } = this.props;
    change('supplierCompanyType', '');
    change('locationName', [{ id: '' }])
    change('address1', '');
    change('address2', '');
    change('country', '');
    change('city', '');
    this.setState({ isOpenSupplierList: true });
  };

  closeSupplierListModal = () => {
    this.setState({ isOpenSupplierList: false });
  };

  onRowClick = params => {
    const { change, requestAddSupplierLocationList } = this.props;
    if (params.data.supplierID) {
      requestAddSupplierLocationList(params.data.supplierID)
      this.setState({
        supplierId: params.data.supplierID
      })
    }

    this.closeSupplierListModal();
    change('supplierName', params.data.supplierName ? params.data.supplierName : '');
    change('supplierID', params.data.supplierID ? params.data.supplierID : '');
    change('supplierCompanyType', params.data.companyType ? params.data.companyType : '');
    change('parentCompany', params.data.companyType ? params.data.companyType : '');
  };



  render() {
    const { isModalOpen,
      toggleModal,
      addSupplierInfoStates,
      isAdd,
      supplierData,
      errorStatusAddSupplier
    } = this.props;

  const { activeNewSupplierAdd, activeEditLocation, isOpenSupplierList } = this.state;
  const countryList = getData().map(({
    code: id,
    name:label,
    ...rest
  }) => ({
    id,
    label,
    ...rest
  }));
    return (
      <span>
        {
          (!isAdd && !addSupplierInfoStates) ?
            <Spinner large />
            :
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => { toggleModal(false) }}
              className="modal-container add-supplier-modal-container"
              overlayClassName="modal-overlay"
            >
              <IconButton icon="times" className="modal-close-btn" onClick={() => { toggleModal(false) }} />
              <div className="p2-sm">
                <H4 className="text-color-accent u-bold page-title-has-back sub-heading">ADD SUPPLIER LOCATION</H4>
              </div>
              {activeNewSupplierAdd || activeEditLocation ?
                <span>
                  {/* {
                      activeNewSupplierAdd ?
                        <span>
                          <div className="ncss-col-sm-2 inline-flex-display">
                            <H5 className="u-bold description p2-top-sm p2-bottom-sm add-supplier-text">New Supplier Name<span className="mandatory_supplier">*</span></H5>
                          </div>
                          <div className="ncss-col-sm-6 ncss-input-container inline-flex-display no-padding-supplier">
                            <Field component={TextInput} name="newSupplierName" type="text" containerClass="u-full-width" />
                          </div>
                        </span>
                        :
                        null
                    } */}
                </span> : null}

              {activeNewSupplierAdd || activeEditLocation ?
                <span>
                  <div>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">Facility Name<span className="mandatory_supplier">*</span></H5>
                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm ">
                      <Field component={TextInput} name="supplierName" type="text" containerClass="u-full-width" />
                    </div>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">Parent Company Name</H5>
                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm ">
                      <Field component={TextInput} name="parentCompany" type="text" containerClass="u-full-width" />
                    </div>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">OS ID<span className="mandatory_supplier">*</span></H5>
                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm ">
                      <Field component={TextInput} name="OSHId" type="text" containerClass="u-full-width" />
                    </div>
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">

                      <H5 className="u-bold description add-supplier-text">Manufacturing Location Code</H5>

                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm ">

                      <Field component={TextInput} name="mLocCode" type="text" containerClass="u-full-width" />

                    </div>
                    <div className="ncss-col-sm-2 inline-flex-display">

                      <H5 className="u-bold description p2-top-sm p2-bottom-sm add-supplier-text">Company Type<span className="mandatory_supplier">*</span></H5>

                    </div>
                    <div className="ncss-col-sm-4 ncss-input-container inline-flex-display no-padding-supplier">
                      <div className="dropdown-add-supplier">
                        {
                          activeNewSupplierAdd ?
                            <Field component={DropDown} name="companyType" placeholder="Select Company Type" options={companyType[0].types} />
                            :
                            null
                        }
                      </div>
                    </div>
                  </div>
                  

                  {activeNewSupplierAdd || activeEditLocation ?
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">Address 1<span className="mandatory_supplier">*</span></H5>
                    </div> : null}
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">
                    <Field component={TextInput} name="newAddress1" type="text" containerClass="u-full-width" />

                  </div>
                  {activeNewSupplierAdd || activeEditLocation ?
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm pl10-sm">
                      <H5 className="u-bold description add-supplier-text ">Address 2</H5>
                    </div> : null}
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">
                    <Field component={TextInput} name="newAddress2" type="text" containerClass="u-full-width" />

                  </div>
                  {activeNewSupplierAdd || activeEditLocation ?
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm">
                      <H5 className="u-bold description add-supplier-text">Country<span className="mandatory_supplier">*</span></H5>
                    </div> : null}
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pb3-sm pl0-sm">
                    <Field component={DropDown} name="newCountry" placeholder="Select Country" options={countryList} />

                  </div>
                  {activeNewSupplierAdd || activeEditLocation ?
                    <div className="ncss-col-sm-2 inline-flex-display pr0-sm pb3-sm pl10-sm">
                      <H5 className="u-bold description add-supplier-text">City<span className="mandatory_supplier">*</span></H5>
                    </div> : null}
                  <div className="ncss-col-sm-4 ncss-input-container inline-flex-display pl0-sm pb3-sm">

                    <Field component={TextInput} name="newCity" type="text" containerClass="u-full-width" />

                  </div>
                </span> : null}
              <div>

              </div>




              <ContentPopup
                title="Select Supplier:"
                isOpen={isOpenSupplierList}
                closeModal={this.closeSupplierListModal}
                content={
                  <Grid rowData={supplierData} columnDefs={supplierGridConfig} onRowClick={this.onRowClick}
                    customNoRowTemplete={
                      supplierData === undefined
                        ? "<span class='ag-overlay-loading-center'>No results found</span>"
                        : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                    }
                  />
                }
              />
              {activeNewSupplierAdd || activeEditLocation ?
                <div className="ncss-col-sm-12 ">

                  <div className="ncss-col-sm-12 align-center">
                    <Button className="ncss-btn-secondary-dark u-bold secondary-button add-supplier-location-buttton"
                      onClick={() => { this.clearForm(); toggleModal(true) }}>
                      CANCEL
                    </Button>
                    <span className={`${(typeof errorStatusAddSupplier === 'undefined' || errorStatusAddSupplier === true) ? 'cursor-not-allowed' : ''}`}>
                      <Button className={`ncss-btn-grey u-bold new-material add-supplier-location-buttton ${(typeof errorStatusAddSupplier === 'undefined' || errorStatusAddSupplier === true) ? 'disabled' : ''}`}
                        onClick={() => { this.filterNewSupplierInfo(addSupplierInfoStates); toggleModal(true) }}>
                        ADD SUPPLIER
                      </Button>
                    </span>
                  </div>

                </div>
                : null}

            </Modal>
        }
      </span>
    );
  }
}

AddSupplier.propTypes = {
    requestSupplierList: PropTypes.func,
};
AddSupplier.defaultProps = {
  isAdd: true
};

const mapStateToProps = state => ({
  addSupplierInfoStates: getFormValues('addSupplierInfo')(state),
  allData: state,
  supplierData: state.adminUser.supplierData,
  supplierLocationList: state.adminUser.supplierLocationList,
  locationDetails: state.adminUser.locationDetails,
  errorStatusAddSupplier: state.adminUser.errorStatusAddSupplier,
  errorStatusUpdateSupplier: state.adminUser.errorStatusUpdateSupplier,
});

export const mapDispatchToProps = dispatch => ({

  requestAddSupplierLocationList: supplierId => {
    dispatch(createServiceAction('addSupplierLocationSearch', 'requested')(supplierId));
  },

  clearEditLocationList: () => {
    dispatch(clearEditLocationList());
  },
  submitSupplierData: (data) => {
    dispatch(createServiceAction('addSupplier', 'submited')({ ...data }));
  },

  updateLocationData: (data) => {
    dispatch(createServiceAction('locationUpdate', 'submitted')({ ...data }));
  },

  requestLocationDetails: locationId => {
    dispatch(createServiceAction('locationDetails', 'requested')(locationId));
  },

  requestSupplierList: searchTerm => {
    dispatch(createServiceAction('addsupplierSearch', 'requested')(searchTerm));
  }
});

export default reduxForm({ form: 'addSupplierInfo', enableReinitialize: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddSupplier));
