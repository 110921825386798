import { createServiceAction } from '../util/actionHelper.js';
import { displaySubmitButtonFrf, displayClearButtonFrf,attachmentFrf,clearFormValueFRF } from '../actions/frfActions.js';
import {convertDateToDBFormat} from '../util/index.js'
import get from 'lodash/get';
import moment from 'moment';

const defaultState = {
  errorClassName: [false, true, true],
  attachment: [],
  frfData: {},
  attachmentNew: [],
  trfDetail:{}
};

let frfArray = {
  failureDesc: '',
  dateOfCompletion: '',
  correctiveAction: '',
  rootCause: '',
  PDCA: '',
  isUploaded: '',
  isError: true
};

function attachmentupdate(element) {
  
    const attachment =element.map(data =>(
      {fileName:data.fileName,
       format: data.fileName.split('.').pop(),
       submittedDate: moment(new Date()).format('MM/DD/YYYY')
    }))
  return attachment
  
}

function isValidated(formDetails) {
  
  if (formDetails.meta.form === 'Frfinfo') {
  
    if (formDetails.meta.field.indexOf('failureDesc') !== -1) {
      frfArray.failureDesc = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('dateOfCompletion') !== -1) {
      frfArray.dateOfCompletion = convertDateToDBFormat(formDetails.payload);
      //date picker component default value is changed to empty string.
      frfArray.dateOfCompletion = (frfArray.dateOfCompletion === '1970-01-01' ? '' : frfArray.dateOfCompletion); 
     
    }
    if (formDetails.meta.field.indexOf('correctiveAction') !== -1) {
      frfArray.correctiveAction = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('rootCause') !== -1) {
      frfArray.rootCause = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('PDCA') !== -1) {
      frfArray.PDCA = formDetails.payload;
    }
    if (
      frfArray.failureDesc !== '' &&
      frfArray.dateOfCompletion !== '' &&
      frfArray.rootCause !== '' &&
      frfArray.correctiveAction !== '' &&
      frfArray.PDCA !== ''
     
    ) {
      frfArray.isError = false;
    } else {
      frfArray.isError = true;
    }
    return [false, frfArray.isError, frfArray.isError];
  }
  if (formDetails.meta.form === 'TRFQRF') {
    return [false, frfArray.isError, frfArray.isError];
  }
  if (formDetails.meta.form === 'FrfReview') {
    return [false, frfArray.isError, frfArray.isError];
  }
}

export function clearForm(){
  frfArray ={
    failureDesc:'',
    dateOfCompletion:'' ,
    potentialCause:'',
    correctiveAction:'',
    rootCause:'',
    PDCA:'',
    isError: true
}
return [false,true,true]
}

function validate(formDetails) {
  if (formDetails.meta.form === 'Frfinfo') {
    if (formDetails.payload.failureDesc) {
      frfArray.failureDesc = formDetails.payload;
    }
    if (formDetails.payload.dateOfCompletion) {
      frfArray.dateOfCompletion = convertDateToDBFormat(formDetails.payload);
  
    }
    if (formDetails.payload.correctiveAction) {
      frfArray.correctiveAction = formDetails.payload;
    }
    if (formDetails.payload.rootCause) {
      frfArray.rootCause = formDetails.payload;
    }
    if (formDetails.payload.PDCA) {
      frfArray.PDCA = formDetails.payload;
    }
    
    if (
      frfArray.failureDesc !== '' &&
      frfArray.dateOfCompletion !== '' &&
      frfArray.rootCause !== '' &&
      frfArray.correctiveAction !== '' &&
      frfArray.PDCA !== '' 
    ) {
     
      frfArray.isError = false;
    } else {
      frfArray.isError = true;
    }
    return [false, frfArray.isError, frfArray.isError];
  }
  if (formDetails.meta.form === 'TRFQRF') {
    return [false, frfArray.isError, frfArray.isError];
  }
  if (formDetails.meta.form === 'FrfReview') {
    return [false, frfArray.isError, frfArray.isError];
  }
}
export default function frf(state = defaultState, action) {
  switch (action.type) {
    case '@@redux-form/CHANGE':
      return { ...state, errorClassName: isValidated(action) };
    case '@@redux-form/INITIALIZE':
      return { ...state, errorClassName: validate(action)};
    case createServiceAction('trfDetailsForFrf', 'success').type:
      return { 
        ...state,
        trfDetail: action.data, 
        attachment: attachmentupdate(get(action.data,'data.frfAttachmentDetails')),
        attachmentNew: [],
        errorClassName: action.data.data.frfDetails.frfId ? [false,false,false] : [false,true,true] 
      };
    case createServiceAction('frfSubmit', 'success').type:
      return { ...state, frfData: action.data };
    case createServiceAction('frfUpdate', 'success').type:
      return { ...state, frfData: action.data };
    case displaySubmitButtonFrf.type:
      return { ...state, shouldDisplaySubmit: action.payload };
    case displayClearButtonFrf.type:
      return { ...state, shouldDisplayClear: action.payload };
    case attachmentFrf.type:
      return { 
        ...state,
        attachment: [...state.attachment, action.payload],
        attachmentNew: [...state.attachmentNew, action.payload]
      }; 
    case clearFormValueFRF.type:
      return {...state,errorClassName:clearForm()} 
    default:
      return state;
  }
}
