import React, { Component } from 'react';
// import StepZilla from 'react-stepzilla';
import PropTypes from 'prop-types';

import StepZilla from './main/Main';

class ProgressTracker extends Component {
  constructor(props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
  }

  handleInput = stepNum => {
    const { currentStepNumber } = this.props;
    currentStepNumber(stepNum);
  }

  render() {
    const { steps, errorClassName, clickNext, clickPrevious } = this.props;
    return (
      <div className="step-progress">
        <StepZilla
          steps={steps}
          errorClassName={errorClassName}
          showNavigation={false}
          dontValidate
          prevBtnOnLastStep={false}
          startAtStep={0}
          clickNext={clickNext}
          clickPrevious={clickPrevious}
          getInput={this.handleInput}
        />
      </div>
    );
  }
}

export default ProgressTracker;

ProgressTracker.propTypes = {
  steps: PropTypes.array,
  errorClassName: PropTypes.array,
  clickNext: PropTypes.func,
  clickPrevious: PropTypes.func,
  currentStepNumber: PropTypes.func
};
