import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { getMaterialNextPage, getMaterialRemainingPages} from '../reducers/materialListReducer.js';
import { put, takeLatest,call, select } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const options = {
      page: yield select(getMaterialNextPage),// Temporarily setting static page number till server side pagination in ag grid is implemented
      limit:500
    };

    const res = yield call(getRequest, apiConfig.getMaterialList.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){ 
      const response = yield res.json();
      //Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.errorCode === 0) {
            yield put(createServiceAction(serviceType, 'success')(response));
            const remainingPages = yield select(getMaterialRemainingPages);
            if (remainingPages > 0) {
                yield put(createServiceAction(`materialDetailsLazyLoad`, 'requested')(action.data));
            }
            else {
                yield put(createServiceAction(`materialDetailsLazyLoad`, 'completed')(true));
              }
        }else {
            yield put(createServiceAction(serviceType, 'server-error')());
        }
    }
    
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* materialDetailsSagas() {
  
  yield takeLatest(createServiceAction('materialDetails', 'requested').type, listener, 'materialDetails');
  yield takeLatest(createServiceAction('materialDetailsLazyLoad', 'requested').type, listener, 'materialDetailsLazyLoad');

}

export default materialDetailsSagas;
