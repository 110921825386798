import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { selectEmail, selectFullName } from '../reducers/authReducer.js';

import { put, takeEvery, take, call, select } from 'redux-saga/effects';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const email = yield select(selectEmail);
    const name = yield select(selectFullName);

    const options = {
      email,
      name,
      ...(action.data && action.data.formValues)
    };

    const res = yield call(postRequest, apiConfig.newUserDetails.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){  
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.errorCode === 0) {
        yield put(createServiceAction(serviceType, 'success')(response));
        yield put(createServiceAction('getUserProfile', 'requested')());
        if(action.data){
          // Display success message only for user details added through form
          yield call(displayMessage, 'success', response.message, 5000);
        }
        yield take(createServiceAction('getUserProfile', 'success').type);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* createProfileSagas() {
  const serviceType = 'createUserProfile';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default createProfileSagas;
