import {
    toggleVideoModal
} from '../actions/trainingActions.js';

const defaultState = {
    isVideoModalOpen: false,
};

export default function statusButton(state = defaultState, action) {

    switch (action.type) {
        case toggleVideoModal.type:
            return { ...state, isVideoModalOpen: action.payload };


        default:
            return state;
    }
}

