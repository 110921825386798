import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import { displaySubmitButtonFrf, displayClearButtonFrf, attachmentFrf,clearFormValueFRF} from '../../actions/frfActions';
import { createServiceAction } from '../../util/actionHelper.js';
import { H4, Button, ProgressTracker, Container, ConfirmationBox } from '../../components/index.js';
import { isLabUser } from '../../reducers/authReducer.js';

import TRF from './TrfAndQrf/TRFQRF';
import Frfinfo from './FrfInfo/FrfInfo';
import Review from './FrfReview/FrfReview';


class Frf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      status: null,
      stepState: 0
    };
    this.hidden = {
      display: 'none'
    };
    this.handleQrfSubmit = this.handleQrfSubmit.bind(this);
    this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
    this.currentStep = this.currentStep.bind(this);
    this.cancelFrf = this.cancelFrf.bind(this);
    // this.getAttachmentData =this.getAttachmentData.bind(this)
  }

  componentDidMount() {
    const { location, getTrfDetails, clearFormValueFRF } = this.props;
    getTrfDetails(location.state && location.state.trfId); // Temp hardcoding trf ID for QA team to review the page
    clearFormValueFRF();

  }

  onClickNext = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState + 1
    }));
    this.clickNext();
  };

  onClickPrevious = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState - 1
    }));
    this.clickPrevious();
  };

  closeSubmitModal = () => this.setState({ submitModal: false });

  confirmationBoxOpen = (modalopen, status) => this.setState({ submitModal: true, status });

  cancelFrf = () => {
    const { history, attachment } = this.props;
    while(attachment.length>0){
      attachment.pop()
    }
    history.push('/');
  };

  handleQrfSubmit = FrfInfor => {
    const { submitFrf, updateFrf, history, trfDetails, attachmentNew } = this.props;
    const requestObject = {};
    requestObject.trfId = get(trfDetails, `data.trfDetails.trfId`, ``);
    requestObject.qrfId = get(trfDetails, `data.qrfDetails.qrfId`, ``);
    requestObject.PDCA = FrfInfor.PDCA;
    requestObject.failureDesc = FrfInfor.failureDesc;
    requestObject.dateOfCompletion = FrfInfor.dateOfCompletion
    requestObject.correctiveAction = FrfInfor.correctiveAction;
    requestObject.rootCause = FrfInfor.rootCause;
    requestObject.attachment = attachmentNew;
    if (FrfInfor.frfId) {
      requestObject.frfId = FrfInfor.frfId;
     
      updateFrf(requestObject, history);
    } else {
      submitFrf(requestObject, history);
    }
  };

  currentStep = step => this.setState({ stepState: step });

  render() {
    
    const {
      FrfInfoStates,
      errorClassName,
      displayClearButtonFrf,
      shouldDisplaySubmit,
      shouldDisplayClear,
      attachmentFrf,
      displaySubmitButtonFrf,
      attachment,
      frfDetails,
      frfDetailsForEdit,
      isLabUser
    } = this.props;
  
    const { submitModal, status, stepState } = this.state;
    const steps = [
      {
        name: 'TRF & QRF INFORMATION',
        component: (
          <TRF
            displayClearButtonFrf={displayClearButtonFrf}
            displaySubmitButtonFrf={displaySubmitButtonFrf}
            sucessFill={false}
          />
        )
      },
      {
        name: 'FRF INFORMATION',
        component: (
          <Frfinfo
            attachmentFrf={attachmentFrf}
            displayClearButtonFrf={displayClearButtonFrf}
            displaySubmitButtonFrf={displaySubmitButtonFrf}
            attachment={attachment}
            { ...( frfDetailsForEdit.frfId?{initialValues:frfDetailsForEdit}:{initialValues:frfDetails})}
            successFill={typeof errorClassName !== 'undefined' ? errorClassName[1] : true}
          />
        )
      },
      {
        name: 'REVIEW & SUBMIT',
        component: (
          <Review
            displayClearButtonFrf={displayClearButtonFrf}
            displaySubmitButtonFrf={displaySubmitButtonFrf}
            attachment= {attachment}
            {...FrfInfoStates}
            successFill={typeof errorClassName !== 'undefined' ? errorClassName[2] : true}
          />
        )
      }
    ];
    return (
      <div className="page-align-fixed-items">
        {!isLabUser ? (
          <div className="TRFpage">
            <div section className="prevent-scroll top-scroll-fix">
              <H4 className="text-color-accent u-bold page-title-has-back sub-heading p2-bottom-sm">
                FAILURE RESOLUTION FORM(FRF)
                
              </H4>
            </div>
            <div section className="clear-margin progress-tracker-align">
              <ProgressTracker
                steps={steps}
                errorClassName={errorClassName}
                clickNext={clickNext => (this.clickNext = clickNext)}
                clickPrevious={clickPrevious => (this.clickPrevious = clickPrevious)}
                currentStepNumber={this.currentStep}
              />
            </div>
            <Container section className="prevent-scroll bottom-scroll-fix bottom-section remove-margin">
              <Button
                style={stepState === 0 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-prevoius-button trf-button-selected"
                onClick={() => this.onClickPrevious('previous')}
              >
                PREVIOUS
              </Button>
              {shouldDisplaySubmit && (
                <span>
                  {errorClassName && errorClassName.every(element => element === false) ? (
                    <span>
                      <Button
                        className="ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected"
                        onClick={() => this.confirmationBoxOpen(true, 'submit')}
                      >
                        SUBMIT
                      </Button>
                    </span>
                  ) : (
                    <Button className="ncss-btn-primary-dark disabled u-bold submit-button trf-button-selected">
                      SUBMIT
                    </Button>
                  )}
                </span>
              )}
              {shouldDisplayClear && (
                <Button
                  className="ncss-btn-secondary-dark u-bold secondary-button trf-button-selected"
                  onClick={() => this.confirmationBoxOpen(true, 'clear')}
                  background="bg-dark-grey"
                >
                  CLEAR
                </Button>
              )}
              <Button
                style={stepState === 2 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-next-button trf-button-selected"
                onClick={() => this.onClickNext('next')}
              >
                NEXT
              </Button>
            </Container>
            <ConfirmationBox
              message={
                status === 'submit'
                  ? 'Are you sure you want to submit the FRF ?'
                  : 'Are you sure you want to clear the FRF ? '
              }
              submitModal={submitModal}
              submitValue={
                status === 'submit'
                  ? () => {
                      this.handleQrfSubmit(FrfInfoStates);
                    }
                  : () => this.cancelFrf()
              }
              closeModal={this.closeSubmitModal}
            />
          </div>
        ) : (
          <div className="pagenotFound">Error! Page you are looking for is not found</div>
        )}
      </div>
    );
  }
}


 
function mapStateToProps(state) {
  return {
    FrfInfoStates: getFormValues('Frfinfo')(state),
    errorClassName: state.frf.errorClassName,
    shouldDisplaySubmit: state.frf.shouldDisplaySubmit,
    shouldDisplayClear: state.frf.shouldDisplayClear,
    trfDetails: state.frf.trfDetail,
    frfDetails: {
      frfId: '',
      PDCA:  get(state, `form.Frfinfo.values.PDCA`, ''),
      failureDesc: get(state, `form.Frfinfo.values.failureDesc`, ''),
      dateOfCompletion: get(state, `form.Frfinfo.values.dateOfCompletion`, new Date()),
      correctiveAction:get(state, `form.Frfinfo.values.correctiveAction`, ''),
      rootCause:get(state, `form.Frfinfo.values.rootCause`, '')
    },
    frfDetailsForEdit: {
      frfId: get(state.frf.trfDetail, `data.frfDetails.frfId`, ''),
      PDCA:  get(state, `form.Frfinfo.values.PDCA`, get(state.frf.trfDetail, `data.frfDetails.PDCA`, '')),
      failureDesc: get(state, `form.Frfinfo.values.failureDesc`,get(state.frf.trfDetail, `data.frfDetails.failureDesc`, '')),
      dateOfCompletion: get(state, `form.Frfinfo.values.dateOfCompletion`,(get(state.frf.trfDetail, `data.frfDetails.dateOfCompletion`, '') && new Date(get(state.frf.trfDetail, `data.frfDetails.dateOfCompletion`, '')))),
      correctiveAction:  get(state, `form.Frfinfo.values.correctiveAction`,get(state.frf.trfDetail, `data.frfDetails.correctiveAction`, '')),
      rootCause:  get(state, `form.Frfinfo.values.rootCause`, get(state.frf.trfDetail, `data.frfDetails.rootCause`, ''))
    },
    attachment: state.frf.attachment,
    attachmentNew:state.frf.attachmentNew,
    isLabUser: isLabUser(state)
  };
}

const mapDispatchToProps = dispatch => ({
  submitFrf: (data, history) => {
    dispatch(createServiceAction('frfSubmit', 'submitted')({ ...data, history }));
  },
  updateFrf: (data, history) => {
    dispatch(createServiceAction('FrfUpdate', 'submitted')({ ...data, history }));
  },
  getTrfDetails: data => {
    dispatch(createServiceAction('trfDetailsForFrf', 'requested')(data));
  },
  attachmentFrf: data => {
    dispatch(attachmentFrf(data));
  },
  displaySubmitButtonFrf: isOpen => {
    dispatch(displaySubmitButtonFrf(isOpen));
  },
  displayClearButtonFrf: isOpen => {
    dispatch(displayClearButtonFrf(isOpen));
  },
  clearFormValueFRF: () => {
  dispatch(clearFormValueFRF());
}});

Frf.propTypes = {
  errorClassName: PropTypes.array,
  getTrfDetails: PropTypes.func,
  location: PropTypes.object,
  displaySubmitButtonFrf: PropTypes.func,
  displayClearButtonFrf: PropTypes.func,
  clearFormValueFRF: PropTypes.func,
  attachment: PropTypes.object,
  attachmentNew:PropTypes.object,
  shouldDisplaySubmit: PropTypes.bool,
  shouldDisplayClear: PropTypes.bool,
  submitFrf: PropTypes.func,
  history: PropTypes.object,
  FrfInfoStates: PropTypes.object,
  attachmentFrf: PropTypes.func,
  trfDetails: PropTypes.object,
  attachmentupdate:PropTypes.func,
  isLabUser: PropTypes.bool
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Frf)
);
