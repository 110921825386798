import get from 'lodash/get';
import moment from 'moment';

import { createServiceAction } from '../util/actionHelper.js';
import { displaySubmitButtonQrf, displayClearButtonQrf,clearFormValueQRF, attachmentQrf } from '../actions/qrfActions.js';

const defaultState = {
  errorClassName: [false, true, true],
  qrfData: {},
  attachment: [],
  attachmentNew: [],
};

let qrfArray = {
  affectedMaterial: '',
  materialAffectedQty: '',
  materialWorth:'',
  affectedMaterialLeft: false,
  factoryList: '',
  affectedMaterialForKid: false,
  immediateStep: '',
  isError: true
};

const reviewFlag = {
  isError: true
};

function isValidated(formDetails) {
  if (formDetails.meta.form === 'QrfInfo') {
    if (formDetails.meta.field==='affectedMaterial') {
      qrfArray.affectedMaterial = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('materialAffectedQty') !== -1) {
      qrfArray.materialAffectedQty = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('materialWorth') !== -1) {
      qrfArray.materialWorth = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('affectedMaterialLeft') !== -1) {
      qrfArray.affectedMaterialLeft = true;
    }
    if (formDetails.meta.field.indexOf('factoryList') !== -1) {
      qrfArray.factoryList = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('immediateStep') !== -1) {
      qrfArray.immediateStep = formDetails.payload;
    }
    if (formDetails.meta.field.indexOf('affectedMaterialForKid') !== -1) {
      qrfArray.affectedMaterialForKid = true;
    }
  

    if (
      qrfArray.affectedMaterial !== '' &&
      qrfArray.materialAffectedQty !== '' &&
      qrfArray.materialWorth !== '' &&
      qrfArray.factoryList !== '' &&
      qrfArray.immediateStep !== '' &&
      qrfArray.affectedMaterialLeft &&
      qrfArray.affectedMaterialForKid
    ) {
      qrfArray.isError = false;
    } else {
      qrfArray.isError = true;
    }
    if (!qrfArray.isError) {
      reviewFlag.isError = false;
    } else {
      reviewFlag.isError = true;
    }
  }
  return [false, qrfArray.isError, reviewFlag.isError];
}

function validate(formDetails){
  if (formDetails.meta.form === 'QrfInfo') {
    if (formDetails.payload.affectedMaterial) {
      qrfArray.affectedMaterial = formDetails.payload.affectedMaterial;
    }
    if (formDetails.payload.materialAffectedQty) {
      qrfArray.materialAffectedQty = formDetails.payload.materialAffectedQty;
    }
    if (formDetails.payload.materialWorth) {
      qrfArray.materialWorth = formDetails.payload.materialWorth;
    }
    if (formDetails.payload.affectedMaterialLeft) {
      qrfArray.affectedMaterialLeft = true;
    }
    if (formDetails.payload.factoryList) {
      qrfArray.factoryList = formDetails.payload.factoryList;
    }
    if (formDetails.payload.immediateStep) {
      qrfArray.immediateStep = formDetails.payload.immediateStep;
    }
    if (formDetails.payload.affectedMaterialForKid) {
      qrfArray.affectedMaterialForKid = true;
    }

    if (
      qrfArray.affectedMaterial !== '' &&
      qrfArray.materialAffectedQty !== '' &&
      qrfArray.materialWorth !== '' &&
      qrfArray.factoryList !== '' &&
      qrfArray.immediateStep !== '' &&
      qrfArray.affectedMaterialLeft &&
      qrfArray.affectedMaterialForKid
    ) {
      qrfArray.isError = false;
    } else {
      qrfArray.isError = true;
    }
    if (!qrfArray.isError) {
      reviewFlag.isError = false;
    } else {
      reviewFlag.isError = true;
    }
  }
  return [false, qrfArray.isError, reviewFlag.isError];
}
function attachmentupdate(element) {
  const attachment =element.map(data =>(
    {fileName:data.fileName,
     format: data.fileName.split('.').pop(),
     submittedDate: moment(new Date()).format('MM/DD/YYYY')
  }))
return attachment
}


function clearValues() {
  qrfArray = {
    affectedMaterial: '',
    materialAffectedQty: '',
    materialWorth: '',
    affectedMaterialLeft: false,
    factoryList: '',
    affectedMaterialForKid: false,
    immediateStep: '',

    isError: true
  };

  return defaultState.errorClassName;
}
export default function qrf(state = defaultState, action) {
  switch (action.type) {
    case '@@redux-form/CHANGE':
      return { ...state, errorClassName: isValidated(action) };
      case '@@redux-form/INITIALIZE':
      return { ...state, errorClassName: validate(action) };
    case clearFormValueQRF.type:
      return { ...state, errorClassName: clearValues() };
      

    case createServiceAction('qrfSubmit', 'success').type:
      return { ...state, qrfData: action.data };
    case createServiceAction('qrfUpdate', 'success').type:
      return { ...state, qrfData: action.data };

      case createServiceAction('trfDetailsForQrf', 'success').type:
      return { 
        ...state,
        trfDetail: action.data, 
        attachment: attachmentupdate(get(action.data,'data.qrfAttachmentDetails')),
        attachmentNew: [],
        errorClassName: action.data.data.qrfDetails.qrfId ? [false,false,false] : [false,true,true] 
      };
    case displaySubmitButtonQrf.type:
      return { ...state, shouldDisplaySubmit: action.payload };
    case displayClearButtonQrf.type:
      return { ...state, shouldDisplayClear: action.payload };
    case attachmentQrf.type:
      return { 
        ...state,
        attachment: [...state.attachment, action.payload],
        attachmentNew: [...state.attachmentNew, action.payload]
      }; 
    default:
      return state;
  }
}
